/* import __COLOCATED_TEMPLATE__ from './reason-details.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { TextAreaField } from '@repo/design-system-kit';

export default class SubscriptionCloseStepsReasonDetailsComponent extends Component {
  textAreaField = TextAreaField;

  @tracked showError = false;

  @service intl;

  get error() {
    let { closingReasonDetailsOther } = this.args.context;
    return !closingReasonDetailsOther
      ? this.intl.t('subscription.account-closing.errors.explain-closing-reason')
      : null;
  }

  @action updateNote(val) {
    this.showError = false;
    this.args.context.closingReasonDetailsOther = val;
  }

  @action onSubmit(e) {
    e.preventDefault();
    if (this.error) {
      this.showError = true;
      return;
    }
    this.args.transitionToNext();
  }
}
