export default {
  "journey": "cQX",
  "content": "cQC",
  "distance": "cQk",
  "google-link-container": "cQJ",
  "link-icon": "cQs",
  "continue-btn": "cQF",
  "origin": "cQg",
  "input-width": "cQD"
};
