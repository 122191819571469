export default {
  "mandate-list-item": "cFL",
  "selected": "cFT",
  "status-avatar": "cFz",
  "infos": "cFa",
  "info-title": "cFH body-1",
  "info-title-suspended": "cFO",
  "info-title-status-suspended": "cFm",
  "info-mandate-count": "cFp body-2"
};
