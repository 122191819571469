/* import __COLOCATED_TEMPLATE__ from './initial.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { ONBOARDING_STATUS, ONBOARDING_TRACKING_EVENTS } from 'qonto/constants/receivable-invoice';
import { NextStep } from 'qonto/react/components/flows/invoices/next-step';

export default class AccountReceivableOnboardingSuccess extends Component {
  @service intl;
  @service router;
  @service segment;
  @service toastFlashMessages;

  @reads('args.context.onboardingState') onboardingState;

  nextStepComponent = NextStep;

  @action
  continueWithOnboarding() {
    this.segment.track(ONBOARDING_TRACKING_EVENTS.GUIDED_SETUP);
    this.args.transitionToNext();
  }

  goToInvoiceCreation = dropTask(async () => {
    try {
      this.segment.track(ONBOARDING_TRACKING_EVENTS.MANUAL_SETUP);
      this.onboardingState.status = ONBOARDING_STATUS.SKIPPED;
      await this.onboardingState.save();
      this.router.transitionTo('receivable-invoices.new');
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
  });
}
