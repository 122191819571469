/* import __COLOCATED_TEMPLATE__ from './select-account.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class F24ManualDeclarationSelectAccountComponent extends Component {
  @service segment;
  @service organizationManager;

  @action setAccount(account) {
    this.segment.track('f24_create-flow_step4-account-selected');
    this.args.context.f24Order.bankAccount = account;
  }

  @action hasSufficientFunds({ authorizedBalance }) {
    return authorizedBalance >= this.args.context.f24Order.documentAmount;
  }

  get shouldDisplayTopUpInstruction() {
    let f24Amount = this.args.context.f24Order.documentAmount;

    // compare to f24 total amount
    // if false: you can pay
    let shouldDisplay = !this.organizationManager.accounts.some(
      elem => elem.authorizedBalance >= f24Amount
    );

    return shouldDisplay;
  }
}
