/* import __COLOCATED_TEMPLATE__ from './recommendation-card.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { GET_STARTED_ACTIONS_KEY } from 'qonto/constants/overview';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
export default class GetStartedActionsRecommendationCardComponent extends Component {
  @service segment;

  constructor() {
    super(...arguments);

    if (this.args.impressionEvent) {
      this.segment.track(this.args.impressionEvent);
    }
  }

  @action
  onClick() {
    if (this.args.origin === 'pay_later') {
      safeLocalStorage.setItem(GET_STARTED_ACTIONS_KEY, true);
    }

    this.segment.track(this.args.trackEvent, { origin: this.args.origin });
  }
}
