/* import __COLOCATED_TEMPLATE__ from './description.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { TextAreaField } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { apiBaseURL } from 'qonto/constants/hosts';
import { CLAIM_TYPES } from 'qonto/constants/transactions';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class FlowsRequestCardsNoteComponent extends Component {
  @service intl;
  @service networkManager;
  @service segment;
  @service toastFlashMessages;
  @service sentry;

  textAreaField = TextAreaField;

  @tracked description = null;
  @tracked errorMessage = null;

  @action
  onUpdateDescription(value) {
    this.errorMessage = null;
    this.description = value;
  }

  get isFraud() {
    return (
      variation('feature--boolean-boi-chargeback-claim-flow') &&
      this.args.context.claimType === CLAIM_TYPES.fraud
    );
  }

  submitTask = dropTask(async () => {
    if (!this.description || !this.description.trim()) {
      this.errorMessage = this.intl.t('dispute-flow.additional-info.form.error');
      this.description = null;
      return;
    }

    if (this.isFraud) {
      this.segment.track('cards-chargeback_add-description-cta_clicked');
      this.args.context.description = this.description;
      this.args.transitionToNext();
      return;
    }

    try {
      let { claimType, selectedTransactions, files } = this.args.context;

      let payload = {
        data: {
          type: 'claims',
          attributes: {
            type: claimType,
            description: this.description,
          },
          relationships: {
            transactions: {
              data: selectedTransactions.map(transaction => ({
                type: 'transactions',
                id: transaction.id,
              })),
            },
            documents: {
              data: files.flat().map(file => ({ id: file.id, type: 'documents' })),
            },
          },
        },
      };

      this.segment.track('cards-chargeback_submit-dispute-cta_clicked');

      await this.networkManager.request(`${apiBaseURL}/v1/claims`, {
        method: 'POST',
        data: payload,
      });

      this.args.transitionToNext();
    } catch (error) {
      let errorMessage = this.intl.t('toasts.errors.server_error');
      this.toastFlashMessages.toastError(errorMessage);

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });
}
