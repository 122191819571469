/* import __COLOCATED_TEMPLATE__ from './date.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import dayjs from 'dayjs';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';

export default class ExpressionDateComponent extends Component {
  @service intl;

  get selectedInterval() {
    return this.intervalOptions.find(({ key }) => key === this.args.expression.values?.[0]);
  }

  get dateFrom() {
    let date = this.args.expression.values[0];
    return date && !isNaN(date) ? dayjs(date).format(DATE_PICKER_FIELD_FORMAT) : null;
  }

  get dateTo() {
    let date = this.args.expression.values[1];
    return date && !isNaN(date) ? dayjs(date).format(DATE_PICKER_FIELD_FORMAT) : null;
  }

  get intervalOptions() {
    return [
      { key: 'today', label: this.intl.t('transactions.filters.dates.intervals.today') },
      { key: 'yesterday', label: this.intl.t('transactions.filters.dates.intervals.yesterday') },
      {
        key: 'current_week',
        label: this.intl.t('transactions.filters.dates.intervals.current-week'),
      },
      { key: 'last_week', label: this.intl.t('transactions.filters.dates.intervals.past-week') },
      {
        key: 'current_month',
        label: this.intl.t('transactions.filters.dates.intervals.current-month'),
      },
      {
        key: 'last_month',
        label: this.intl.t('transactions.filters.dates.intervals.past-month'),
      },
    ];
  }

  // Min and max values copy the logic from qonto-api
  // https://gitlab.qonto.co/qonto/qonto-api/-/blob/master/app/contexts/transactions/contracts/query_language/filter.rb

  get minDate() {
    return new Date('2015-01-02');
  }

  get maxDate() {
    return dayjs().add(2, 'year').subtract(1, 'day').toDate();
  }

  @action
  updateDateFrom(date) {
    let { operator, operatorType } = this.args.expression;
    let isSingleDate = ['lt', 'lte', 'gt', 'gte'].includes(operator);

    let start = dayjs(date).startOf('day').valueOf();
    let end = operatorType === 'range' ? null : dayjs(date).endOf('day').valueOf();

    let value;

    if (isSingleDate) {
      if (operator === 'lte') {
        start = dayjs(date).endOf('day').valueOf();
      }

      value = date ? [start] : [];
    } else {
      value = date ? [start, end] : [null, this.dateTo ? dayjs(this.dateTo).valueOf() : undefined];
    }

    this.args.onSelect(value);
  }

  @action
  updateDateTo(date) {
    let { operatorType } = this.args.expression;

    let start = this.dateFrom ? dayjs(this.dateFrom).valueOf() : undefined;
    let end = dayjs(date).endOf('day').valueOf();
    let emptyDateValue = operatorType === 'range' ? [start, null] : [start];

    let value = date ? [start, end] : emptyDateValue;

    this.args.onSelect(value);
  }

  @action
  updateInterval({ key }) {
    this.args.onSelect([key]);
  }
}
