/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { SkeletonLoader } from '@repo/design-system-kit';

import { PROVIDERS_MAP } from 'qonto/constants/insurance-hub';

export default class InsuranceHubProductHeaderComponent extends Component {
  placeholderHeader = SkeletonLoader.Header;
  placeholderBlock = SkeletonLoader.Block;
  placeholderLine = SkeletonLoader.Line;
  placeholderAvatar = SkeletonLoader.Avatar;

  @service router;
  @service segment;
  @service organizationManager;

  @action
  onClick() {
    let { hasActiveContract, product, onClick } = this.args;
    let country = this.organizationManager.organization.legalCountry;

    this.segment.track('insurance-hub-offer-details_subscription-button_clicked', {
      country,
      isSubscribed: Boolean(hasActiveContract),
      product,
      provider: PROVIDERS_MAP[country],
    });

    onClick?.();
  }

  @action
  managePolicy() {
    let { product } = this.args;
    let country = this.organizationManager.organization.legalCountry;

    this.segment.track(
      'insurance-hub-offer-details_subscription-button_manage-policy-dropdown_clicked',
      {
        country,
        product,
        provider: PROVIDERS_MAP[country],
      }
    );
    this.router.transitionTo('insurance-hub.policies');
  }
}
