export default {
  "wrapper": "vj",
  "legend": "vW",
  "grid": "vu",
  "subtitle": "oS body-2",
  "fiscal-code": "oc",
  "personal-data-name": "oq",
  "personal-data-birth": "oZ",
  "personal-data": "oR",
  "tax-residence": "oQ",
  "payer-agent": "oe",
  "errors": "oB",
  "business": "oE",
  "address": "od"
};
