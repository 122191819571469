/* import __COLOCATED_TEMPLATE__ from './it-company-details.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { PLACEHOLDER_SECTIONS } from '@qonto/ui-kit/constants/pdf-preview';
import { dropTask } from 'ember-concurrency';

import {
  ONBOARDING_STEP_STATUS,
  ONBOARDING_TRACKING_EVENTS,
} from 'qonto/constants/receivable-invoice';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class FlowsAccountReceivableOnboardingItCompanyDetails extends Component {
  @service segment;
  @service sentry;
  @service intl;
  @service organizationManager;
  @service networkManager;
  @service toastFlashMessages;

  @tracked displayValidations = false;

  constructor() {
    super(...arguments);

    this.#prefillEinvoiceActivation();
  }

  willDestroy() {
    super.willDestroy(...arguments);

    if (this.settings && !this.settings.isDestroyed && !this.settings.isDestroying) {
      this.settings.rollbackAttributes();
    }

    if (
      this.eInvoiceActivation &&
      !this.eInvoiceActivation.isDestroyed &&
      !this.eInvoiceActivation.isDestroying
    ) {
      this.eInvoiceActivation.rollbackAttributes();
    }
  }

  get stepStatusCompanyDetails() {
    return this.args.context.onboardingState.stepStatusCompanyDetails;
  }

  get prefillData() {
    return this.onboardingState?.prefillData;
  }

  get organization() {
    return this.organizationManager.organization;
  }

  get eInvoiceActivation() {
    return this.args.context.eInvoiceActivation;
  }

  get settings() {
    return this.args.context.settings;
  }

  get onboardingState() {
    return this.args.context.onboardingState;
  }

  #prefillEinvoiceActivation() {
    if (this.stepStatusCompanyDetails !== 'confirmed') {
      this.settings.setProperties({
        contactEmail: this.prefillData?.contactEmail || this.organization.contactEmail,
        vatNumber: this.prefillData?.vatNumber || this.organization.vatNumber,
      });

      this.eInvoiceActivation.setProperties({
        legalTvaNumber: this.prefillData?.vatNumber || this.organization.vatNumber,
      });
    }
  }

  get title() {
    let { organization } = this.organizationManager;
    if (organization.isAssociation) {
      return this.intl.t('receivable-invoices.onboarding.company-details.title.association', {
        legalCountry: organization.legalCountry,
      });
    } else if (organization.isBusiness) {
      return this.intl.t('receivable-invoices.onboarding.company-details.title.business', {
        legalCountry: organization.legalCountry,
      });
    } else {
      return this.intl.t('receivable-invoices.onboarding.company-details.title.company', {
        legalCountry: organization.legalCountry,
      });
    }
  }

  get placeholderConfig() {
    return {
      enablePlaceholders: true,
      activeSections: [
        this.exampleInvoice.number && PLACEHOLDER_SECTIONS.NUMBER,
        PLACEHOLDER_SECTIONS.CONTACT_ORGANIZATION,
      ],
    };
  }

  get exampleInvoice() {
    return {
      number: this.settings?.invoiceNextNumberFormatted,
      organization: {
        legalName: this.organization.legalName,
        legalNumber: this.organization.legalNumber,
        address: this.organization.address,
        vatNumber: this.settings.vatNumber,
      },
      contactEmail: this.settings.contactEmail,
    };
  }

  @action
  handleProvinceChange(provinceCode) {
    this.eInvoiceActivation.provinceCode = provinceCode;
  }

  @action
  handleTaxRegimeChange(taxRegime) {
    this.eInvoiceActivation.taxRegime = taxRegime;
  }

  @action
  handleEmailUpdate(email) {
    this.eInvoiceActivation.contactEmail = email;
    this.settings.contactEmail = email;
  }

  @action
  handleFiscalCodeUpdate(fiscalCode) {
    this.eInvoiceActivation.fiscalCode = fiscalCode;
  }

  @action
  handleLegalTvaNumberUpdate(legalTvaNumber) {
    this.eInvoiceActivation.legalTvaNumber = legalTvaNumber;
    this.settings.vatNumber = legalTvaNumber;
  }

  @action
  toggleTermsAndConditions() {
    this.eInvoiceActivation.eInvoicingTermsAndConditionsAccepted =
      !this.eInvoiceActivation.eInvoicingTermsAndConditionsAccepted;
  }

  confirmTask = dropTask(async () => {
    this.segment.track(ONBOARDING_TRACKING_EVENTS.STEP_COMPLETED, {
      source: this.args.context.source,
      step: 'company-details',
    });
    try {
      await this.eInvoiceActivation.validate();
      this.displayValidations = true;
      if (this.eInvoiceActivation.validations.isValid) {
        // Update settings
        await this.settings.save();
        await this.eInvoiceActivation.save();

        // Update onboarding status
        this.onboardingState.stepStatusCompanyDetails = ONBOARDING_STEP_STATUS.CONFIRMED;
        this.onboardingState.substepStatusCompanyDetails = ONBOARDING_STEP_STATUS.CONFIRMED;
        await this.onboardingState.save();

        this.args.backToStep('summary');
      }
    } catch (error) {
      if (error.status === 422) {
        let normalizedErrors = this._normalizeErrors(error.errors);
        this.networkManager.errorModelInjector(this.settings, normalizedErrors, error);
      } else {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    }
  });

  _normalizeErrors(errors) {
    return errors
      .filter(e => Boolean(e.source?.pointer))
      .reduce((errs, { source, detail }) => {
        let attribute = source.pointer.split('/').pop();
        //camelize did not work here for some reason so we are temporarily going with this solution
        switch (attribute) {
          case 'contactemail':
            attribute = 'contactEmail';
            break;
          case 'vatnumber':
            attribute = 'vatNumber';
            break;
        }
        return {
          [attribute]: detail,
          ...errs,
        };
      }, {});
  }
}
