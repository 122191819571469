/* import __COLOCATED_TEMPLATE__ from './disclaimer.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { Checkbox, Disclaimer } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { AccountClosingBanner } from 'qonto/react/components/account-closing/account-closing-banner';
import { SubscriptionCloseStepsClosingDate } from 'qonto/react/components/subscription/close/closing-date';

export default class SubscriptionCloseStepsDisclaimerComponent extends Component {
  checkbox = Checkbox;
  accountClosingBanner = AccountClosingBanner;
  disclaimerBlock = Disclaimer.Block;

  @tracked isConfirmationChecked = false;
  @tracked showCheckboxError = false;
  SubscriptionCloseStepsClosingDate = SubscriptionCloseStepsClosingDate;

  @service flow;
  @service intl;
  @service segment;
  @service subscriptionManager;
  @service organizationManager;
  @service toastFlashMessages;

  constructor() {
    super(...arguments);
    this.flow.dataContext.hearFromYou = false;
  }

  get hasError() {
    return !this.isConfirmationChecked;
  }

  get planName() {
    return this.subscriptionManager.currentPricePlan.localName;
  }

  get currentPricePlanRecurrence() {
    return this.subscriptionManager.currentSubscription.recurrence;
  }

  get isSlice3FeatureFlagActive() {
    switch (variation('feature--improve-retention-slice-3')) {
      case 'banner':
        return true;
      case 'banner-screen':
        return this.flow.dataContext.closingReasonCategory?.key !== 'service_quality';
      default:
        return false;
    }
  }

  get shouldDisplayBanner() {
    // eslint-disable-next-line no-restricted-syntax -- We are not using this feature for 'solo-basic' users as it might be an expensive feature to offer
    let isSoloBasic = this.subscriptionManager?.currentPricePlan?.groupCode === 'solo_basic';
    let isCompanyClosing = this.args.context.closingReasonCategory?.key === 'company_closure';
    let isDisplayingBanner = this.isSlice3FeatureFlagActive && !isSoloBasic && !isCompanyClosing;

    if (isDisplayingBanner) {
      this.segment.track('account-closing_cs-callback_banner-displayed', {
        pricePlan: this.subscriptionManager.currentPricePlan?.code,
        closingReasonCategory: this.args.context.closingReasonCategory.key,
      });
    }

    return isDisplayingBanner;
  }

  @action
  abort() {
    this.args.abort();
    this.segment.track('account_closing_consequences_keep_account_clicked');
  }

  @action
  toggleConfirmationChecked() {
    this.isConfirmationChecked = !this.isConfirmationChecked;
    if (this.isConfirmationChecked) {
      this.showCheckboxError = false;
    }
  }

  @action
  onBookACall() {
    this.segment.track('account-closing_cs-callback_banner-clicked', {
      pricePlan: this.subscriptionManager.currentPricePlan?.code,
      closingReasonCategory: this.args.context.closingReasonCategory.key,
    });
    this.flow.dataContext.hearFromYou = true;
    this.flow.next();
  }

  createDeactivationTask = dropTask(async () => {
    try {
      let {
        closingReasonCategory,
        closingReasonSubCategory,
        closingReasonDetails,
        closingReasonDetailsOther,
      } = this.args.context;
      if (!this.isConfirmationChecked) {
        this.showCheckboxError = true;
        return;
      }

      let { organization } = this.organizationManager;
      let { due_date } = await organization.createDeactivation({
        closing_reason_category: closingReasonCategory?.key,
        closing_reason_sub_category: closingReasonSubCategory?.key || '',
        closing_reason_details: closingReasonDetails?.key || '',
        closing_reason_details_other: closingReasonDetailsOther || '',
      });

      this.args.context.deactivationDueDate = dayjs(due_date).toDate();
      this.segment.track('account_closing_requirements_clicked');
      this.args.transitionToNext();
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }
      let message = this.intl.t('toasts.errors.server_error');
      this.toastFlashMessages.toastError(message);
    }
  });
}
