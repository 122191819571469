export default {
  "container": "fY",
  "summary": "fh",
  "sideview": "ff",
  "steps-container": "fK",
  "invoice-upload-container": "fG",
  "pdf-preview": "fr",
  "uploader": "fb",
  "file-dropzone": "fy",
  "overlay": "fU",
  "footer": "fj",
  "upload-prompt": "fW",
  "step-selector": "fu",
  "disclaimer": "KS"
};
