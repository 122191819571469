export default {
  "row": "Yc",
  "active": "Yq",
  "cell": "YZ",
  "empty": "YR",
  "supplier": "YQ",
  "supplier-content": "Ye",
  "declined": "YB",
  "total-repayment": "YE body-1",
  "amount": "Yd",
  "supplier-avatar": "YI mr-16",
  "financing-status": "Yt body-2",
  "installments": "YM body-2",
  "overflow-hidden": "YP",
  "ellipsis": "Yl",
  "completed-on": "YX",
  "financed-amount": "YC body-2"
};
