export default {
  "selector": "bV",
  "metal-design": "bL",
  "content": "bT",
  "header": "bz",
  "big": "ba",
  "header-name": "bH",
  "subtitle": "bO",
  "button": "bm",
  "features": "bp",
  "feature": "bx",
  "highlighted": "bw"
};
