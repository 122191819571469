export default {
  "member-details": "cFw",
  "header": "cFN",
  "no-email": "cFv",
  "header-img": "cFo",
  "invited-persona": "cFn",
  "revoked-persona": "cFi",
  "invited-icon": "cFA",
  "revoked-icon": "cFY",
  "revised": "cFh",
  "invitable-icon": "cFf",
  "dropdown": "cFK",
  "header-body": "cFG",
  "status": "cFr",
  "name": "cFb title-3",
  "email": "cFy caption-bold",
  "actions": "cFU body-2",
  "actions-cards": "cFj",
  "actions-transactions": "cFW",
  "body": "cFu",
  "expense-permissions-section": "cgS",
  "body-title": "cgc title-4",
  "body-attr": "cgq",
  "body-label": "cgZ",
  "attr-buttons": "cgR",
  "body-role": "cgQ small",
  "details-actions": "cge",
  "actions-item": "cgB",
  "disclaimer": "cgE",
  "permissions": "cgd",
  "permission": "cgI",
  "permission-label": "cgt",
  "permission-check": "cgM",
  "permission-missing": "cgP",
  "editable-section": "cgl",
  "hris-placeholder": "cgX",
  "imported-from": "cgC",
  "integration-logo": "cgk",
  "permission-value": "cgJ",
  "tooltip": "cgs",
  "check-icon": "cgF"
};
