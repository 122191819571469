/* import __COLOCATED_TEMPLATE__ from './missing-details.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { PAY_LATER_VALIDATION_ERRORS } from 'qonto/constants/financing';

const MISSING_ERRORS_ORDER = [
  PAY_LATER_VALIDATION_ERRORS.MISSING_SUPPLIER_NAME,
  PAY_LATER_VALIDATION_ERRORS.MISSING_INVOICE_AMOUNT,
  PAY_LATER_VALIDATION_ERRORS.MISSING_ISSUE_DATE,
  PAY_LATER_VALIDATION_ERRORS.MISSING_SUPPLIER_IDENTIFIER,
];

export default class FlowsTransfersSepaPayLaterErrorsMissingDetailsComponent extends Component {
  @service intl;
  @service payByInvoiceUploadManager;
  @service organizationManager;

  get errors() {
    let { errors } = this.args.context.validationError;
    let sortedErrors = [];

    MISSING_ERRORS_ORDER.forEach(error => {
      if (errors.includes(error)) sortedErrors.push(error);
    });

    return sortedErrors;
  }

  get detailsErrorMap() {
    return {
      [PAY_LATER_VALIDATION_ERRORS.MISSING_INVOICE_AMOUNT]: this.intl.t(
        'pay-later-flow.errors.missing-details.amount'
      ),
      [PAY_LATER_VALIDATION_ERRORS.MISSING_ISSUE_DATE]: this.intl.t(
        'pay-later-flow.errors.missing-details.issue-date'
      ),
      [PAY_LATER_VALIDATION_ERRORS.MISSING_SUPPLIER_IDENTIFIER]: this.intl.t(
        'pay-later-flow.errors.missing-details.supplier-identifier',
        {
          htmlSafe: true,
          legalCountry: this.organizationManager.organization.legalCountry.toLowerCase(),
        }
      ),
      [PAY_LATER_VALIDATION_ERRORS.MISSING_SUPPLIER_NAME]: this.intl.t(
        'pay-later-flow.errors.missing-details.supplier-name'
      ),
    };
  }

  @action
  handleRestart() {
    this.payByInvoiceUploadManager.resetState();
    this.args.transitionToFlow({
      flowName: 'pay-later-transfer',
      stepId: 'invoice-upload',
      queryParams: {
        invoiceId: null,
        reset: true,
      },
    });
  }
}
