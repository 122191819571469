/* import __COLOCATED_TEMPLATE__ from './set-pin.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class FLowsCardsSetPin extends Component {
  @service intl;

  @tracked pinError = '';

  @action
  pinChanged(pin) {
    let { card } = this.args.context;
    card.pin = pin;

    this.clearInputError();
    this.validatePin(card);
  }

  @action
  continueToNextStep(event) {
    event.preventDefault();

    let { card } = this.args.context;

    this.validatePin(card);

    if (card.validations.attrs.pin.isValid) {
      this.args.transitionToNext();
    }
  }

  validatePin(card) {
    if (!this.isNumber(card.pin)) {
      this.pinError = this.intl.t('validations.errors.pin_not_a_number');
    } else if (!card.pin || card.pin.length < 4) {
      this.pinError = this.intl.t('validations.errors.pin_invalid_length');
    } else if (card.pin.length === 4) {
      this.pinError = card.validations.attrs.pin.message;
    }
  }

  clearInputError() {
    this.pinError = '';
  }

  isNumber(pin) {
    return !pin || /^[0-9]+$/.test(pin);
  }
}
