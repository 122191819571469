/* import __COLOCATED_TEMPLATE__ from './account-selection.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

export default class AccountSelectionComponent extends Component {
  disclaimerBlock = Disclaimer.Block;

  @service intl;
  @service segment;

  @reads('args.context.payment') payment;

  get amount() {
    return this.intl.formatNumber(this.payment.amount.value, {
      currency: this.payment.amount.currency,
      style: 'currency',
    });
  }

  get feeAmountFormatted() {
    if (parseFloat(this.payment.feeEstimateAmount?.value) > 0) {
      return this.intl.formatNumber(this.payment.feeEstimateAmount.value, {
        currency: this.payment.feeEstimateAmount.currency,
        style: 'currency',
      });
    }
  }

  get creditorName() {
    return this.payment.creditorName;
  }

  get paymentDisclaimer() {
    return this.feeAmountFormatted
      ? this.intl.t('pagopa.payment-creation.account-selection.disclaimer-fee', {
          amount: this.amount,
          beneficiary: this.creditorName,
          feeAmount: this.feeAmountFormatted,
        })
      : this.intl.t('pagopa.payment-creation.account-selection.disclaimer', {
          amount: this.amount,
          beneficiary: this.creditorName,
        });
  }

  @action
  setBankAccount(bankAccount) {
    this.segment.track('pagopa_payment-flow_account_select-button_clicked');
    this.payment.bankAccount = bankAccount;
  }

  @action
  isBankAccountEnabled({ authorizedBalance }) {
    let amount = Number(this.payment.amount.value);
    return authorizedBalance >= amount;
  }
}
