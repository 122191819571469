/* import __COLOCATED_TEMPLATE__ from './transaction-amount.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { AmountField } from '@repo/design-system-kit';
import { restartableTask, task, timeout } from 'ember-concurrency';

import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class QontoHubNotificationsCreateStepsTransactionAmount extends Component {
  amountField = AmountField;

  @service sentry;
  @service intl;
  @service toastFlashMessages;

  get amountInEUR() {
    return this.args.context.hubNotificationRule.value / 100;
  }

  get errorMessage() {
    let amount = this.args.context.hubNotificationRule.value;

    if (!amount || amount < 0) {
      return this.intl.t('validations.errors.blank');
    }

    return null;
  }

  estimateAlertCountPerDayTask = restartableTask(async () => {
    try {
      await timeout(DEBOUNCE_MS);
      let { estimate } = await this.args.context.hubNotificationRule.estimate();

      return estimate.average;
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      if (!error.isAdapterError) {
        throw error;
      }
    }
  });

  confirmAmountTask = task(async e => {
    e.preventDefault();
    try {
      await this.args.context.hubNotificationRule.save();

      this.args.transitionToNext();
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      if (!error.isAdapterError) {
        throw error;
      }
    }
  });

  changeValueTask = task(async value => {
    await this.estimateAlertCountPerDayTask.cancelAll({ resetState: true });

    let amount = parseInt(value, 10);

    this.args.context.hubNotificationRule.set('value', Number.isNaN(amount) ? 0 : amount * 100);

    if (amount > 0) {
      await this.estimateAlertCountPerDayTask.perform();
    }
  });
}
