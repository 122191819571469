export default {
  "mandates": "NU",
  "mandates-list": "Nj",
  "left-section": "NW",
  "header-search": "Nu",
  "header": "vS",
  "header-content": "vc",
  "search": "vq",
  "search-bar": "vZ",
  "body": "vR",
  "list-title": "vQ caption-bold",
  "details": "ve",
  "avatar": "vB mr-16",
  "header-beneficiary": "vE",
  "header-beneficiary-activity": "vd",
  "header-beneficiary-details": "vI",
  "header-beneficiary-details-recurring": "vt",
  "infos": "vM",
  "infos-title": "vP",
  "infos-empty": "vl",
  "infos-list": "vX",
  "infos-list-item": "vC",
  "infos-list-item-left": "vk",
  "infos-list-item-right": "vJ",
  "infos-list-item-attachment": "vs"
};
