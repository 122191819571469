export default {
  "wrapper": "om",
  "subtitle": "op body-2",
  "legend": "ox",
  "table": "ow",
  "footer": "oN",
  "footer-total-c": "ov",
  "footer-total-d": "oo",
  "footer-balance": "on"
};
