/* import __COLOCATED_TEMPLATE__ from './google-pay-button.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { isTesting, macroCondition } from '@embroider/macros';
import { dropTask, task, waitForQueue } from 'ember-concurrency';
import window from 'ember-window-mock';

import styles from 'qonto/components/google-pay-button.module.css';
import ENV from 'qonto/config/environment';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import { loadScript } from 'qonto/utils/load-script';

const BASE_REQUEST = {
  apiVersion: 2,
  apiVersionMinor: 0,
};

const ALLOWED_CARD_NETWORKS = ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'];

const ALLOWED_CARD_AUTH_METHODS = ['PAN_ONLY'];

const BASE_CARD_PAYMENT_METHOD = {
  type: 'CARD',
  parameters: {
    allowedAuthMethods: ALLOWED_CARD_AUTH_METHODS,
    allowedCardNetworks: ALLOWED_CARD_NETWORKS,
  },
};

const CARD_PAYMENT_METHOD = {
  ...BASE_CARD_PAYMENT_METHOD,
  ...{
    tokenizationSpecification: {
      type: 'PAYMENT_GATEWAY',
      parameters: {
        gateway: 'checkoutltd',
        gatewayMerchantId: ENV.checkout.publicKey,
      },
    },
  },
};

export default class GooglePayButton extends Component {
  paymentsClient = null;
  btnContainerId = `btn-container-gpay-${guidFor(this)}`;
  @service sentry;

  constructor() {
    super(...arguments);
    this.loadAndInitGooglePayTask.perform().catch(ignoreCancelation);
  }
  _addGooglePayButton(element) {
    let button = this.paymentsClient.createButton({
      buttonType: 'short',
      buttonSizeMode: 'fill',
      // The button will be created with a CSS class with the locale code:  eg: `.en` or `.fr`
      // We have a global css that has the `.fr` defined with some strange positioning rules (eg position:absolute)
      // and it will interfere with the layout of the Google Pay button.
      // Given that the locale doesn't actually change anything when the `buttonType` is `'short'`
      // I'll always use the EN locale.
      buttonLocale: 'en',
      onClick: () => this._onGooglePaymentButtonClickedTask.perform(),
      allowedPaymentMethods: [BASE_CARD_PAYMENT_METHOD],
    });
    button.setAttribute('class', styles['google-pay-button']);
    element.appendChild(button);
  }

  loadAndInitGooglePayTask = dropTask(async () => {
    try {
      await waitForQueue('afterRender');
      let btnContainer = document.getElementById(this.btnContainerId);
      if (macroCondition(!isTesting())) {
        await this.loadGooglePayTask.perform();
      }

      await this._initGooglePayTask.perform(btnContainer);
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  loadGooglePayTask = dropTask(async () => {
    if (!window.google?.payments) {
      await loadScript('https://pay.google.com/gp/p/js/pay.js');
    }
  });

  _initGooglePayTask = dropTask(async element => {
    this._initGooglePaymentsClient();

    let request = {
      ...BASE_REQUEST,
      ...{
        allowedPaymentMethods: [BASE_CARD_PAYMENT_METHOD],
      },
    };

    try {
      let response = await this.paymentsClient.isReadyToPay(request);

      if (response.result) {
        this._addGooglePayButton(element);
      }

      this.args.onReadyToPay?.(response.result);
    } catch (error) {
      this.args.onError?.(error);
    }
  });

  @action
  _initGooglePaymentsClient() {
    if (this.paymentsClient === null) {
      this.paymentsClient = new window.google.payments.api.PaymentsClient({
        environment: ENV.deployTarget === 'production' ? 'PRODUCTION' : 'TEST',
      });
    }
  }

  _getGooglePaymentDataRequest() {
    let paymentDataRequest = {
      ...BASE_REQUEST,
      allowedPaymentMethods: [CARD_PAYMENT_METHOD],
      transactionInfo: {
        countryCode: 'FR',
        currencyCode: 'EUR',
        totalPriceStatus: 'FINAL',
        totalPrice: this.args.amount,
      },
      merchantInfo: {
        merchantId: ENV.googlePay.merchantId,
        merchantName: ENV.deployTarget === 'production' ? 'Qonto' : 'Example Merchant',
      },
    };

    return paymentDataRequest;
  }

  _onGooglePaymentButtonClickedTask = task(async () => {
    let paymentDataRequest = this._getGooglePaymentDataRequest();

    try {
      let paymentData = await this.paymentsClient.loadPaymentData(paymentDataRequest);
      this.args.onTokenReady?.(paymentData);
    } catch (error) {
      this.args.onError?.(error);
    }
  });
}
