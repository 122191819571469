/* import __COLOCATED_TEMPLATE__ from './add-client.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class FlowsDirectDebitCollectionsAddClientComponent extends Component {
  @service store;
  @service sentry;
  @service intl;
  @service segment;
  @service toastFlashMessages;

  @tracked isValidationEnabled = false;

  get client() {
    return this.args.context.newClient;
  }

  onSaveClientTask = dropTask(async newClient => {
    this.isValidationEnabled = true;

    newClient.validate({}, false);

    if (newClient.validations.isValid) {
      try {
        await newClient.save({
          adapterOptions: {
            context: 'sdd',
          },
        });
      } catch (error) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.try-later'));

        return;
      }

      this.args.context.directDebitSubscription.client = newClient;

      this.args.transitionToNext();

      this.segment.track('incoming-direct-debit-flow_client_selected', {
        client_address: Boolean(newClient.address),
        client_selection: 'new',
      });
    }
  });
}
