/* import __COLOCATED_TEMPLATE__ from './details-and-scheduling.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { TextAreaField } from '@repo/design-system-kit';
import dayjs from 'dayjs';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import { RECURRENCE, STATUS } from 'qonto/constants/invoice-subscriptions';

const CUSTOM_OPTION = 'custom';

export default class InvoiceSubscriptionsFormDetailsAndSchedulingComponent extends Component {
  @service intl;
  @service organizationManager;

  textAreaField = TextAreaField;

  @tracked customFrequencyValue = this.defaultCustomFrequencyValue;
  @tracked customFrequencyRecurrence = this.defaultCustomFrequencyRecurrence;
  @tracked customPaymentTerm = this.defaultCustomPaymentTermValue;

  @tracked selectedFrequencyOption = this.defaultSelectedFrequencyOption;
  @tracked selectedPaymentTermsOption = this.defaultSelectedPaymentTermsOption;

  @tracked isHeaderTextFieldVisible = false;

  get minStartDate() {
    return dayjs().format(DATE_PICKER_FIELD_FORMAT);
  }

  get startDateErrorMessage() {
    let errors = this.args.subscription.errors.get('startDate');
    if (!errors) return;

    if (errors.some(({ message }) => message === 'required')) {
      return this.intl.t('recurring-invoices.new.form.validation.start-date.empty');
    }
  }

  get selectedPaymentTerms() {
    return this.args.subscription.paymentTermsDays === 1
      ? this.args.subscription.paymentTermsDays
      : CUSTOM_OPTION;
  }

  get recurrenceOptions() {
    return Object.values(RECURRENCE).map(frequency => {
      return {
        value: frequency,
        label: this.#getLabelForFrequencyCustomUnit(frequency),
      };
    });
  }

  get defaultCustomFrequencyValue() {
    return this.isCustomFrequency ? String(this.args.subscription.frequency?.value) : undefined;
  }

  get defaultCustomFrequencyRecurrence() {
    return this.recurrenceOptions.find(
      ({ value }) => value === (this.args.subscription.frequency?.recurrence ?? RECURRENCE.MONTHLY)
    );
  }

  get defaultSelectedFrequencyOption() {
    return {
      value: this.isCustomFrequency ? CUSTOM_OPTION : this.args.subscription.frequency?.recurrence,
      clearable: this.isCustomFrequency,
    };
  }

  get frequencyErrorMessage() {
    let valueErrors = this.args.subscription.errors.get('frequency/value') ?? [];
    let recurrenceErrors = this.args.subscription.errors.get('frequency/recurrence') ?? [];
    let errors = [...valueErrors, ...recurrenceErrors];
    if (!errors.length) return;

    if (errors.some(({ message }) => message === 'required')) {
      return this.isCustomFrequencySelected
        ? this.intl.t('recurring-invoices.new.form.validation.custom-frequency.empty')
        : this.intl.t('recurring-invoices.new.form.validation.frequency.empty');
    } else if (
      this.isCustomFrequencySelected &&
      errors.some(({ message }) => message === 'invalid')
    ) {
      return this.intl.t('recurring-invoices.new.form.validation.custom-frequency.invalid-number');
    }
  }

  get frequencyOptions() {
    let labels = {
      [RECURRENCE.WEEKLY]: this.intl.t('recurring-invoices.frequency.option.weekly'),
      [RECURRENCE.MONTHLY]: this.intl.t('recurring-invoices.frequency.option.monthly'),
      [RECURRENCE.QUARTERLY]: this.intl.t('recurring-invoices.frequency.option.quarterly'),
      [RECURRENCE.YEARLY]: this.intl.t('recurring-invoices.frequency.option.yearly'),
    };

    let predefinedOptions = Object.values(RECURRENCE).map(frequency => {
      return {
        value: frequency,
        label: labels[frequency],
        clearable: false,
      };
    });

    return [
      ...predefinedOptions,
      {
        value: CUSTOM_OPTION,
        clearable: true,
        label: this.intl.t('recurring-invoices.frequency.option.custom'),
      },
    ];
  }

  get readOnlyFrequencyLabel() {
    let labels = {
      [RECURRENCE.WEEKLY]: this.intl.t('recurring-invoices.frequency.option.weekly'),
      [RECURRENCE.MONTHLY]: this.intl.t('recurring-invoices.frequency.option.monthly'),
      [RECURRENCE.QUARTERLY]: this.intl.t('recurring-invoices.frequency.option.quarterly'),
      [RECURRENCE.YEARLY]: this.intl.t('recurring-invoices.frequency.option.yearly'),
    };
    return this.isCustomFrequency
      ? this.intl.t('recurring-invoices.frequency.option.custom')
      : labels[this.args.subscription.frequency.recurrence];
  }

  get defaultSelectedPaymentTermsOption() {
    return this.isCustomPaymentTerm
      ? this.paymentTermsOptions.find(option => option.value === CUSTOM_OPTION)
      : this.paymentTermsOptions.find(
          option => option.value === this.args.subscription.paymentTermsDays
        );
  }

  get defaultCustomPaymentTermValue() {
    return this.isCustomPaymentTerm ? String(this.args.subscription.paymentTermsDays) : undefined;
  }

  get paymentTermsErrorMessage() {
    let errors = this.args.subscription.errors.get('paymentTermsDays');
    if (!errors) return;

    if (errors.some(({ message }) => message === 'required')) {
      return this.isCustomPaymentTermSelected
        ? this.intl.t('recurring-invoices.new.form.validation.custom-payment-terms.empty')
        : this.intl.t('recurring-invoices.new.form.validation.payment-terms.empty');
    } else if (errors.some(({ message }) => message === 'invalid')) {
      return this.intl.t(
        'recurring-invoices.new.form.validation.custom-payment-terms.invalid-number'
      );
    }
  }

  get paymentTermsOptions() {
    return [
      {
        value: 0,
        label: this.intl.t('recurring-invoices.new.form.payment-terms.on-receipt'),
        clearable: false,
      },
      {
        value: 15,
        label: this.intl.t('recurring-invoices.new.form.payment-terms.in-15-days'),
        clearable: false,
      },
      {
        value: 30,
        label: this.intl.t('recurring-invoices.new.form.payment-terms.in-30-days'),
        clearable: false,
      },
      {
        value: 'custom',
        label: this.intl.t('recurring-invoices.new.form.payment-terms.custom'),
        clearable: true,
      },
    ];
  }

  get isCustomFrequency() {
    return (
      this.args.subscription.frequency?.value !== undefined &&
      this.args.subscription.frequency?.value !== 1
    );
  }

  get isCustomFrequencySelected() {
    return this.selectedFrequencyOption?.value === CUSTOM_OPTION;
  }

  get isCustomPaymentTerm() {
    let isCustomTerm = !this.paymentTermsOptions.some(
      ({ value }) => value === this.args.subscription.paymentTermsDays
    );
    return Boolean(this.args.subscription.paymentTermsDays !== undefined && isCustomTerm);
  }

  get isCustomPaymentTermSelected() {
    return this.selectedPaymentTermsOption?.value === CUSTOM_OPTION;
  }

  get customPaymentTermOption() {
    return {
      value: 'custom',
      label: this.intl.t('recurring-invoices.new.form.payment-terms-custom.days', {
        count: this.args.subscription.paymentTermsDays,
      }),
    };
  }

  get isGermanOrganization() {
    return this.organizationManager.organization.legalCountry === 'DE';
  }

  get isHeaderVisible() {
    return this.args.subscription.header || this.isHeaderTextFieldVisible;
  }

  get isReadOnly() {
    return [STATUS.ACTIVE, STATUS.SUSPENDED].includes(this.args.subscription.status);
  }

  @action
  updateFrequency(option) {
    if (!option.value) {
      this.#resetFrequencyToDefaults();
    } else {
      this.args.subscription.frequency = {
        value: option.value === CUSTOM_OPTION ? this.customFrequencyValue : 1,
        recurrence:
          option.value === CUSTOM_OPTION ? this.customFrequencyRecurrence.value : option.value,
      };
    }

    this.selectedFrequencyOption = option;
    this.args.subscription.errors.remove('frequency/value');
    this.args.subscription.errors.remove('frequency/recurrence');
  }

  @action
  updateCustomFrequencyValue(value) {
    this.customFrequencyValue = value;
    this.args.subscription.frequency = {
      ...this.args.subscription.frequency,
      value: parseInt(value, 10),
    };
    this.args.subscription.errors.remove('frequency/value');
  }

  @action
  updateCustomFrequencyRecurrence(option) {
    this.customFrequencyRecurrence = option;
    this.args.subscription.frequency = {
      ...this.args.subscription.frequency,
      recurrence: option.value,
    };
    this.args.subscription.errors.remove('frequency/recurrence');
  }

  @action
  updatePaymentTerms(option) {
    if (!option.value) {
      this.customPaymentTerm = undefined;
    }
    this.args.subscription.paymentTermsDays =
      option.value === CUSTOM_OPTION ? this.customPaymentTerm : option.value;
    this.selectedPaymentTermsOption = option;
    this.args.subscription.errors.remove('paymentTermsDays');
  }

  @action
  updateCustomPaymentTerm(days) {
    this.args.subscription.paymentTermsDays = parseInt(days, 10);
    this.customPaymentTerm = days;
  }

  @action
  handleClickShowHeaderTextField() {
    this.isHeaderTextFieldVisible = true;
  }

  @action
  handleCloseHeaderTextField() {
    this.isHeaderTextFieldVisible = false;
    this.args.subscription.header = '';
  }

  #resetFrequencyToDefaults() {
    this.customFrequencyValue = undefined;
    this.customFrequencyRecurrence = this.recurrenceOptions.find(
      ({ value }) => value === RECURRENCE.MONTHLY
    );
    this.args.subscription.frequency = undefined;
  }

  #getLabelForFrequencyCustomUnit(value) {
    switch (value) {
      case RECURRENCE.WEEKLY: {
        return this.intl.t('sdd-collections.recurrence.weeks');
      }
      case RECURRENCE.MONTHLY: {
        return this.intl.t('sdd-collections.recurrence.months');
      }
      case RECURRENCE.QUARTERLY: {
        return this.intl.t('recurring-invoices.recurrence.quarters');
      }
      case RECURRENCE.YEARLY: {
        return this.intl.t('sdd-collections.recurrence.years');
      }
    }
  }
}
