/* import __COLOCATED_TEMPLATE__ from './user-info.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { parseDate } from '@internationalized/date';
import { DateField } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

import styles from './user-info.module.css';

const MEMBERSHIP_FIELDS = ['nationality'];

const USER_FIELDS = ['gender', 'birthCountry', 'birthCity', 'phoneNumber', 'birthdate'];

export default class KYCUserInfoController extends Component {
  @service toastFlashMessages;
  @service intl;
  @service userManager;
  @service sentry;

  @tracked enableValidation = false;

  dateField = DateField;

  styles = styles;

  get invite() {
    return this.args.invite;
  }

  get currentUser() {
    return this.userManager.currentUser;
  }

  get membershipBirthday() {
    let birthdate = this.currentUser?.birthdate;
    return birthdate ? parseDate(birthdate) : birthdate;
  }

  confirmTask = dropTask(async () => {
    let { validations: membershipValidation } = await this.invite.validate({
      on: MEMBERSHIP_FIELDS,
    });

    let { validations: userValidation } = await this.currentUser.validate({
      on: USER_FIELDS,
    });

    if (membershipValidation.isInvalid || userValidation.isInvalid) {
      this.enableValidation = true;
      return;
    }

    try {
      await this.currentUser.save();
      await this.invite.reload();

      this.args.onSubmit();
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
  });

  @action
  updateBirthdate(value) {
    if (value) {
      this.currentUser.birthdate = value.toString();
    }
  }

  @action
  handleGenderSelection(gender) {
    this.currentUser.gender = gender;
  }
}
