/* import __COLOCATED_TEMPLATE__ from './success-new-physical.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';

import { CARD_LEVELS_TRACKING } from 'qonto/constants/cards';

export default class FlowsCardsSuccessNewPhysical extends Component {
  @service intl;
  @service modals;
  @service segment;
  @service abilities;

  @reads('args.context.cardUpsellDesign') cardUpsellDesign;
  @reads('args.context.cardUpsellLevel') cardUpsellLevel;

  animationReady = false;

  constructor() {
    super(...arguments);
    let { card } = this.args.context;

    // The existence of cardUpsellLevel means we're in the upsell flow
    if (this.cardUpsellLevel) {
      this.segment.track('cards_upsell_completed', {
        card_level_origin: CARD_LEVELS_TRACKING[card.cardLevel],
        card_level_upsell: CARD_LEVELS_TRACKING[this.cardUpsellLevel],
      });
    } else {
      let { flowTrackingOrigin, isUserCardHolder } = this.args.context;
      this.segment.track('cards_order_completed', {
        card_holder: isUserCardHolder ? 'self' : 'team_member',
        card_type: this.trackingCardType,
        origin: flowTrackingOrigin,
      });
    }
  }

  get cardImageInfo() {
    let { card } = this.args.context;
    return this.cardUpsellLevel
      ? {
          design: this.cardUpsellDesign || this.cardUpsellLevel,
          expDate: null,
          maskPan: '•'.repeat(16),
        }
      : {
          design: card.cardDesign,
          expDate: card.expDate,
          maskPan: card.displayedMaskPan,
        };
  }

  get trackingCardType() {
    let { card } = this.args.context;
    return CARD_LEVELS_TRACKING[card.cardLevel];
  }

  get title() {
    let { isUserCardHolder } = this.args.context;

    if (isUserCardHolder) {
      return this.intl.t('cards.steps.success.title.physical.holder');
    }

    return this.intl.t('cards.steps.success.title.physical.non-holder');
  }

  get subtitle() {
    let { isUserCardHolder, card } = this.args.context;

    if (isUserCardHolder) {
      return this.abilities.cannot('see x pay card')
        ? this.intl.t('cards.steps.success.subtitle.physical.no-xpay')
        : this.intl.t('cards.steps.success.subtitle.physical.holder');
    }

    return this.intl.t('cards.steps.success.subtitle.physical.non-holder', {
      cardholder: card.holder.get('fullName'),
    });
  }

  @action
  handleOpenEducationalModal() {
    this.modals.open('card/modals/digital-first', {
      isFullScreenModal: true,
    });

    if (this.cardUpsellLevel) {
      this.segment.track('cards_upsell_success_digital_first_education_opened', {
        card_level_upsell: CARD_LEVELS_TRACKING[this.cardUpsellLevel],
      });
    } else {
      let { flowTrackingOrigin } = this.args.context;
      this.segment.track('cards_order_digital_first_education_opened', {
        card_type: this.trackingCardType,
        origin: flowTrackingOrigin,
      });
    }
  }

  @action
  handleNext() {
    let { transitionToNext } = this.args;

    if (this.cardUpsellLevel) {
      this.segment.track('cards_upsell_success_see_card', {
        card_level_upsell: CARD_LEVELS_TRACKING[this.cardUpsellLevel],
      });
    } else {
      let { flowTrackingOrigin } = this.args.context;
      this.segment.track('cards_order_success_complete', {
        card_type: this.trackingCardType,
        origin: flowTrackingOrigin,
      });
    }

    transitionToNext();
  }
}
