/* import __COLOCATED_TEMPLATE__ from './custom-label.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';

export default class FiltersCustomLabel extends Component {
  @service intl;

  get selectedOptions() {
    let { selectedLabels } = this.args;
    let { options } = this;

    if (!selectedLabels) return [];

    return options.filter(option => selectedLabels.find(id => id === option.key));
  }

  @cached
  get options() {
    let { showEmptyOption } = this.args;

    if (showEmptyOption) {
      return [this.emptyOption, ...this.labelOptions];
    }

    return this.labelOptions;
  }

  get labelOptions() {
    let { list } = this.args;

    return list.labels.map(({ id, name }) => ({ key: id, value: name }));
  }

  get label() {
    let { name } = this.args.list;

    if (this.selectedOptions.length) {
      return `${name} (${this.selectedOptions.length})`;
    }

    return name;
  }

  get emptyOption() {
    return {
      key: 'missing',
      value: this.intl.t('custom-labels.filter.missing-label'),
    };
  }
}
