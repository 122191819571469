/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class InContextIntegrationsSideDrawerIntegrationQualityItemComponent extends Component {
  @service intl;

  get dataFlowText() {
    let { dataFlow } = this.args;

    if (dataFlow === 'in') {
      return this.intl.t(
        'in-context-integrations.side-drawer.integration.accordion.data-flow.import'
      );
    } else if (dataFlow === 'out') {
      return this.intl.t(
        'in-context-integrations.side-drawer.integration.accordion.data-flow.export'
      );
    }
  }

  get dataFlowIcon() {
    let { dataFlow } = this.args;

    if (dataFlow === 'in') {
      return 'icon-download-outlined';
    } else if (dataFlow === 'out') {
      return 'icon-upload-outlined';
    }
  }

  get qualities() {
    let { qualities } = this.args;

    return qualities.map((quality, index) => {
      if (index === qualities.length - 1) {
        return quality;
      } else {
        return quality + ', ';
      }
    });
  }
}
