export default {
  "wrapper": "ctE input-field mb-4",
  "error": "ctd",
  "inactive": "ctI",
  "hidden-value": "ctt",
  "percentage-sign": "ctM",
  "black": "ctP",
  "input-field": "ctl",
  "disabled": "ctX"
};
