/* import __COLOCATED_TEMPLATE__ from './currency-select.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { getFullCurrencyName } from 'qonto/utils/currency';

export default class FormFieldsCurrencySelectComponent extends Component {
  @service internationalOutManager;
  @service intl;
  @service localeManager;
  @service store;

  getCurrencyName = currencyCode => {
    let currencyDisplayName = getFullCurrencyName({
      currencyCode,
      locale: this.localeManager.locale,
    });
    return !currencyDisplayName || currencyCode === currencyDisplayName
      ? currencyCode
      : `${currencyCode} - ${currencyDisplayName}`;
  };

  get selectedCurrencyName() {
    return this.args.selected ? this.getCurrencyName(this.args.selected) : '';
  }

  fetchCurrencyCodesTask = dropTask(async () => {
    let currencies = await this.internationalOutManager.getTargetCurrencies();
    // Using a Set in order to remove potential 'EUR' currency duplicates
    return [...new Set([...currencies.map(currency => currency.code), 'EUR'])].sort();
  });
}
