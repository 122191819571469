/* import __COLOCATED_TEMPLATE__ from './signature-failure.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class FlowsTransfersPayLaterApplicationSignatureFailureComponent extends Component {
  @service segment;

  constructor() {
    super(...arguments);
    this.segment.track('pay-later_signed_error_displayed', { origin: 'transfer' });
  }

  @action
  backToWarning() {
    this.segment.track('pay-later_try-again_clicked', { origin: 'yousign_failure' });
    this.args.backToStep('signature-warning');
  }
}
