/* import __COLOCATED_TEMPLATE__ from './values.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import CATEGORIES from 'qonto/constants/categories';
import { Category } from 'qonto/react/components/transactions/sidebar/category/cashflow-category';
import { isLabelProperty } from 'qonto/utils/expressions-serializer';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import { sortByKey } from 'qonto/utils/sort-by-keys';

const MULTISELECT_PROPERTIES = [
  'activity_tag',
  'category_assignment_category_id',
  'attachment_status',
  'operation_method',
  'status',
  'vat_completeness',
  'team_id',
];

export default class ExpressionValuesComponent extends Component {
  @service abilities;
  @service intl;
  @service organizationManager;
  @service categoriesManager;
  @service segment;

  labelId;

  allOperationMethods = [];
  allActivityTags = [];
  allLabels = [];
  allTeams = [];

  Category = Category;

  allAttachmentStatuses = [
    { key: 'missing', value: this.intl.t('options.missing') },
    { key: 'added', value: this.intl.t('options.added') },
    { key: 'lost_or_not_required', value: this.intl.t('options.lost_or_not_required') },
  ];

  allTransactionStatuses = [
    {
      key: 'completed',
      value: this.intl.t('transactions.status.completed'),
    },
    {
      key: 'pending',
      value: this.intl.t('transactions.status.pending'),
    },
    {
      key: 'declined',
      value: this.intl.t('transactions.status.declined'),
    },
  ];

  allVatCompleteness = [
    {
      key: 'missing',
      value: this.intl.t('transactions.filter_vat.missing'),
    },
    {
      key: 'filled_in',
      value: this.intl.t('transactions.filter_vat.existing'),
    },
  ];

  allVerifiedOptions = [
    {
      key: true,
      value: this.intl.t('transactions.filters.values.done'),
    },
    {
      key: false,
      value: this.intl.t('transactions.filters.values.todo'),
    },
  ];

  constructor() {
    super(...arguments);

    this.setAllOperationsMethods();
    this.setAllActivityTags();
    this.setAllLabels();
    this.setAllTeams();

    if (this.abilities.can('assign category')) {
      this.categoriesManager.fetchCategoriesTask
        .perform(this.organizationManager.organization.id)
        .catch(ignoreCancelation);
    }
  }

  get isLabelProperty() {
    let { property, values } = this.args.expression;
    return isLabelProperty(values[0], property, this.args.labelLists);
  }

  get isMultiSelect() {
    return MULTISELECT_PROPERTIES.includes(this.args.expression.property) || this.isLabelProperty;
  }

  get isCashflowCategorySelect() {
    return this.args.expression.property === 'category_assignment_category_id';
  }

  get selectedOperationMethods() {
    return this.args.expression.values.map(method =>
      this.allOperationMethods.find(option => option.key === method)
    );
  }

  get selectedAttachmentStatuses() {
    return this.args.expression.values.map(value =>
      this.allAttachmentStatuses.find(option => option.key === value)
    );
  }

  get selectedTransactionStatuses() {
    return this.args.expression.values.map(value =>
      this.allTransactionStatuses.find(option => option.key === value)
    );
  }

  get selectedActivityTags() {
    return this.args.expression.values.map(it =>
      this.allActivityTags.find(option => option.key === it)
    );
  }

  get selectedCashflowCategories() {
    return this.categoriesManager.flatCategories.filter(({ id }) =>
      this.args.expression.values.includes(id)
    );
  }

  get selectedVatCompleteness() {
    return this.allVatCompleteness.filter(({ key }) => this.args.expression.values.includes(key));
  }

  get selectedVerifiedOption() {
    let [key] = this.args.expression.values;
    return this.allVerifiedOptions.find(it => it.key === key);
  }

  get selectedLabels() {
    return this.args.expression.values.map(it => this.allLabels.find(({ key }) => it === key));
  }

  get selectedTeams() {
    return this.args.expression.values.map(team =>
      this.allTeams.find(option => option.key === team)
    );
  }

  get multiSelectValues() {
    let { property } = this.args.expression;

    if (property === 'operation_method') {
      return this.selectedOperationMethods;
    } else if (property === 'attachment_status') {
      return this.selectedAttachmentStatuses;
    } else if (property === 'status') {
      return this.selectedTransactionStatuses;
    } else if (property === 'activity_tag') {
      return this.selectedActivityTags;
    } else if (property === 'category_assignment_category_id') {
      return this.selectedCashflowCategories;
    } else if (property === 'vat_completeness') {
      return this.selectedVatCompleteness;
    } else if (property === 'team_id') {
      return this.selectedTeams;
    } else if (this.isLabelProperty) {
      return this.selectedLabels;
    }

    return [];
  }

  get multiSelectOptions() {
    let { property } = this.args.expression;

    if (property === 'operation_method') {
      return this.allOperationMethods;
    } else if (property === 'attachment_status') {
      return this.allAttachmentStatuses;
    } else if (property === 'status') {
      return this.allTransactionStatuses;
    } else if (property === 'activity_tag') {
      return this.allActivityTags;
    } else if (property === 'category_assignment_category_id') {
      return [...this.categoriesManager.flatCategories] || [];
    } else if (property === 'vat_completeness') {
      return this.allVatCompleteness;
    } else if (property === 'team_id') {
      return this.allTeams;
    } else if (this.isLabelProperty) {
      if (this.labelId !== this.args.expression.property) {
        this.setAllLabels();
      }
      return this.allLabels;
    }

    return [];
  }

  get optionType() {
    return this.args.expression.property === 'activity_tag' ? 'icon' : 'string';
  }

  setAllOperationsMethods() {
    let operationMethods = [
      { key: 'transfer', value: this.intl.t('transactions.operation-types.transfer') },
      { key: 'direct_debit', value: this.intl.t('transactions.operation-types.direct-debit') },
      {
        key: 'card_acquirer_payout',
        value: this.intl.t('transactions.operation-types.card-acquirer-payout'),
        isHidden: !this.organizationManager.organization.hasTapToPayFeature,
      },
      {
        key: 'direct_debit_hold',
        value: this.intl.t('transactions.operation-types.direct-debit-hold'),
      },
      { key: 'card', value: this.intl.t('transactions.operation-types.card') },
      {
        key: 'cheque',
        value: this.intl.t('transactions.operation-types.cheque'),
        isHidden: this.abilities.cannot('view check'),
      },
      { key: 'biller', value: this.intl.t('transactions.operation-types.biller') },
      { key: 'tax', value: this.intl.t('transactions.operation-types.tax') },
      {
        key: 'pagopa_payment',
        value: this.intl.t('transactions.operation-types.pagopa-payment'),
        isHidden: this.abilities.cannot('view filter pagopa'),
      },
      {
        key: 'pay_later',
        value: this.intl.t('transactions.operation-types.pay-later'),
        isHidden: !this.organizationManager.organization.hasPayLaterFeature,
      },
      {
        key: 'other',
        value: this.intl.t('transactions.operation-types.unknown'),
        isHidden: !this.organizationManager.organization.hasExternalAccounts,
      },
    ];

    this.allOperationMethods = operationMethods.filter(({ isHidden }) => !isHidden);
  }

  setAllActivityTags() {
    this.allActivityTags = CATEGORIES.codes
      .map(code => ({ key: code, value: this.intl.t(`activities.${code}`) }))
      .sort(sortByKey('value'));
  }

  setAllLabels() {
    this.labelId = this.args.expression.property;
    let labels =
      this.args.labelLists.find(({ id }) => this.args.expression.property === id)?.labels || [];
    this.allLabels = labels.map(label => ({ key: label.id, value: label.name }));
  }

  setAllTeams() {
    let { teams } = this.organizationManager.organization;

    this.allTeams = Array.isArray(teams)
      ? teams.map(team => ({ key: team.id, value: team.name }))
      : [];
  }

  @action
  trackMultiSelectOpen() {
    if (this.isCashflowCategorySelect) {
      this.segment.track('filter-transactions_category_picker_opened');
    }
  }

  @action
  updateValues(selectedOptions, select) {
    let values = [];

    if (Array.isArray(selectedOptions)) {
      values = selectedOptions.map(({ key }) => key);
    }

    if (this.isCashflowCategorySelect) {
      let categoryOptions = this._getSelectedCashflowCategories(
        selectedOptions,
        select?.highlighted,
        select?.options
      );

      let isSelected = selectedOptions?.find(({ id }) => id === select?.highlighted?.id);
      this.segment.track('filter-transactions_category_toggle', {
        category: select?.highlighted?.nameKey,
        selection: isSelected ? 'selected' : 'unselected',
      });

      values = categoryOptions.map(({ id }) => id);
    }

    this.args.updateValues([...new Set(values)]);
  }

  @action
  updateAmount(value) {
    this.args.updateValues([value]);
    this.args.onClose?.();
  }

  @action
  updateValue({ key }) {
    this.args.updateValues([key]);
  }

  _getSelectedCashflowCategories(selectedOptions, highlightedOption, allOptions) {
    if (!highlightedOption || !allOptions) {
      return [...selectedOptions];
    }

    let isParentCategory = Boolean(highlightedOption?.subcategories?.length);

    if (isParentCategory) {
      let optionsKeys = selectedOptions;
      let subcategoriesKeys = selectedOptions
        .filter(({ subcategories }) => subcategories?.length)
        .map(({ subcategories }) => subcategories)
        .flat();

      return [...optionsKeys, ...subcategoriesKeys];
    }

    let parentCategory = allOptions
      .filter(({ subcategories }) => subcategories?.length)
      .find(({ subcategories }) => subcategories?.find(({ id }) => id === highlightedOption.id));
    let parentCategoryKey = parentCategory?.id;

    let hasSelectedParentCategory = selectedOptions.find(({ id }) => id === parentCategoryKey);

    if (hasSelectedParentCategory) {
      return [...selectedOptions].filter(({ id }) => id !== parentCategoryKey);
    }

    return [...selectedOptions];
  }
}
