/* import __COLOCATED_TEMPLATE__ from './account.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class FlowsCheckAccountComponent extends Component {
  @service segment;

  @action
  setAccount(account) {
    let { context } = this.args;
    context.check.bankAccount = account;
    this.segment.track('checks_deposit_account_selected');
  }
}
