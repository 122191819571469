/* import __COLOCATED_TEMPLATE__ from './login-form.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { Spinner } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class LoginForm extends Component {
  spinner = Spinner;

  @service toastFlashMessages;
  @service intl;
  @service segment;
  @service errors;
  @service sentry;

  @tracked identification = '';
  @tracked password = '';
  @tracked emptyEmailError = null;
  @tracked emptyPasswordError = null;
  @tracked serverError = null;

  authenticateTask = dropTask(async (email, password) => {
    // Technically, this could be either a click or a keypress, because the action is wired to the
    // `submit` event on the form.
    this.segment.track('login_clicked', {
      authentication_method: 'email_pwd',
    });

    if (!email.length) {
      this.emptyEmailError = this.intl.t('errors.empty_email_address');
      return;
    }

    if (!password.length) {
      this.emptyPasswordError = this.intl.t('errors.empty_password');
      return;
    }

    try {
      await this.args.authenticate(email, password);
    } catch (error) {
      this.segment.track('login_failed', {
        authentication_method: 'email_pwd',
      });

      if (hasMFAError(error.responseJSON?.errors)) {
        throw error.responseJSON;
      }

      let { status } = error;
      if (status === 401) {
        this.serverError = this.intl.t('errors.invalid_credentials');
        return;
      }

      if (status === 403) {
        this.serverError = this.intl.t('errors.account_locked');
        return;
      }

      this.toastFlashMessages.toastError(
        this.errors.messageForStatus(error) || this.intl.t('toasts.errors.server_error')
      );

      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  @action
  handleUpdateIdentification(identification) {
    this.identification = identification;
    this.emptyEmailError = null;
    this.serverError = null;
  }

  @action
  handleUpdatePassword(password) {
    this.password = password;
    this.emptyPasswordError = null;
    this.serverError = null;
  }

  get emptyPasswordOrServerError() {
    return this.emptyPasswordError ?? this.serverError;
  }
}
