/* import __COLOCATED_TEMPLATE__ from './iban-field.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';

import { formatIban, handleFormatIban } from 'qonto/utils/format-input';

export default class IbanField extends Component {
  @service organizationManager;
  @reads('organizationManager.organization.legalCountry') legalCountry;

  get iban() {
    let { isIBANObfuscated, value } = this.args;
    return formatIban(value, isIBANObfuscated);
  }

  @action
  handleOnInput(event) {
    let { target } = event;
    handleFormatIban(target, this.args.isIBANObfuscated);
    this.args.update(target.value.replace(/[^a-zA-Z0-9]/g, ''));
  }
}
