/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { TrackedArray } from 'tracked-built-ins';

export default class FlowsCardChargebackUploadLayoutComponent extends Component {
  @service intl;
  @service segment;

  errors = new TrackedArray();

  get files() {
    let { context, uploadStepIndex } = this.args;
    return context.files[uploadStepIndex];
  }

  @action
  onFileSubmission() {
    if (this.files.length === 0) {
      this.errors.length = 0;

      return this.errors.push({
        message: this.intl.t('dispute-flow.documents-upload.steps.error'),
      });
    }

    this.continue();
  }

  continue() {
    this.segment.track('cards-chargeback_document-upload-continue-cta_clicked', {
      type: this.args.trackingType,
    });
    this.args.transitionToNext();
  }
}
