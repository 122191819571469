/* import __COLOCATED_TEMPLATE__ from './success-trial.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

import { TRACKING_TRIAL_STATE } from 'qonto/constants/subscriptions';

export default class SuccessTrialComponent extends Component {
  lottiePlayer = LottiePlayer;

  @service organizationManager;
  @service segment;

  TRACKING_TRIAL_STATE = TRACKING_TRIAL_STATE;

  constructor() {
    super(...arguments);
    this.sendTrackingEventOnLoad();
  }

  sendTrackingEventOnLoad() {
    let { context } = this.args;
    this.segment.track('plans_change_confirmation_success', {
      recurrence: context.recurrence,
      trial_state: context.isFreeTrial
        ? TRACKING_TRIAL_STATE.FREE_TRIAL
        : TRACKING_TRIAL_STATE.INITIAL_TRIAL,
      target_plan: context.subscription.product?.get('code'),
      current_plan: context.currentPricePlanCode,
    });
  }
}
