export default {
  "summary-description": "cIM",
  "detail": "cIP",
  "detail-value": "cIl",
  "account-icon": "cIX",
  "color-secondary": "cIC",
  "beneficiary-info": "cIk",
  "currency": "cIJ",
  "beneficiary-name": "cIs",
  "separator": "cIF",
  "instant-subtitle": "cIg",
  "instant-wrapper": "cID",
  "instant-header": "cIV",
  "instant-toggle": "cIL",
  "instant--disabled": "cIT",
  "limit-exceeded-disclaimer": "cIz",
  "sticky-panel": "cIa",
  "total-interest": "cIH",
  "tooltip-icon": "cIO",
  "tooltip": "cIm",
  "payment-options": "cIp"
};
