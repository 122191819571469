/* import __COLOCATED_TEMPLATE__ from './document-selection.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';

export default class InvitationsNewUserDocumentSelection extends Component {
  @service segment;
  @service organizationManager;
  @reads('organizationManager.organization.legalCountry') legalCountry;

  @action
  onClick(documentType) {
    this.segment.track('join_team_information_poi_type_chosen', {
      poi_type: documentType,
    });
    this.args.onSelect(documentType);
  }
}
