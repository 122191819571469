export default {
  "header": "Aw",
  "header-top": "AN",
  "header-inner": "Av",
  "disclaimer": "Ao mb-16",
  "header-main-wrapper": "An",
  "header-main": "Ai",
  "logo": "AA mr-16",
  "header-right": "AY",
  "partner-description": "Ah body-2"
};
