/* import __COLOCATED_TEMPLATE__ from './success-set-pin.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class FlowsCardsSuccessSetPin extends Component {
  @service modals;
  @service segment;

  @action
  handleClick() {
    let { transitionToNext } = this.args;

    this.segment.track('set_pin_success_complete');

    transitionToNext();
  }

  @action handleOpenEducationalModal() {
    this.modals.open('card/modals/digital-first', {
      isFullScreenModal: true,
    });
    this.segment.track('set_pin_digital_first_education_opened');
  }
}
