/* import __COLOCATED_TEMPLATE__ from './transition.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class FlowsTransfersKycTransition extends Component {
  @service segment;

  constructor() {
    super(...arguments);

    this.segment.track('submit_kyc_information_viewed', {
      origin: 'transfer_flow',
    });
  }

  @action
  handleTransitionToNext() {
    this.args.pushForwardFlow('management-kyc', 'user-info');
  }
}
