/* import __COLOCATED_TEMPLATE__ from './transactions-item.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Checkbox } from '@repo/design-system-kit';

export default class FlowsCardChargebackTransactionsBulkSelectionTransactionsItemComponent extends Component {
  checkbox = Checkbox;

  @service intl;
  @service zendeskLocalization;

  get isNotEligible() {
    let { transaction, type } = this.args;
    if (!transaction) return false;
    return (
      transaction.pending ||
      transaction.canceled ||
      transaction.declined ||
      transaction.reversed ||
      transaction.chargebackDisputedTypes.includes(type) ||
      transaction.chargebackDisputingTypes.length > 0
    );
  }

  get isSelected() {
    let { selectedTransactions, transaction } = this.args;
    let selected = selectedTransactions.includes(transaction);
    return selected;
  }

  toggleSelection = () => {
    let { selectedTransactions, transaction } = this.args;

    if (this.isSelected) {
      let index = selectedTransactions.indexOf(transaction);
      selectedTransactions.splice(index, 1);
    } else {
      selectedTransactions.push(transaction);
    }
  };

  get disclaimer() {
    let { transaction, type } = this.args;
    if (!transaction) return;

    let learnMoreLink = this.intl.t(
      'dispute-flow.transactions-selection.bulk-selection.learn-more'
    );
    switch (true) {
      case transaction.pending:
        return {
          key: 'dispute-flow.transactions-selection.bulk-selection.transaction-disclaimer.processing.body',
          body: this.intl.t(
            'dispute-flow.transactions-selection.bulk-selection.transaction-disclaimer.processing.body',
            { learnMoreLink }
          ),
          url: this.intl.t(
            'dispute-flow.transactions-selection.bulk-selection.transaction-disclaimer.processing.url',
            {
              faqUrl: this.zendeskLocalization.getLocalizedArticle(9015327),
            }
          ),
        };
      case transaction.canceled || transaction.declined || transaction.reversed:
        return {
          key: 'dispute-flow.transactions-selection.bulk-selection.transaction-disclaimer.fail-statuses.body',
          body: this.intl.t(
            'dispute-flow.transactions-selection.bulk-selection.transaction-disclaimer.fail-statuses.body',
            {
              learnMoreLink,
              placeholder: this.placeholderText(transaction),
            }
          ),
          url: this.intl.t(
            'dispute-flow.transactions-selection.bulk-selection.transaction-disclaimer.fail-statuses.url',
            {
              faqUrl: this.zendeskLocalization.getLocalizedArticle(9015327),
            }
          ),
        };

      // transaction is in dispute
      case transaction.chargebackDisputingTypes.length > 0:
        return {
          key: 'dispute-flow.transactions-selection.bulk-selection.transaction-disclaimer.disputing.body',
          body: this.intl.t(
            'dispute-flow.transactions-selection.bulk-selection.transaction-disclaimer.disputing.body',
            {
              learnMoreLink,
            }
          ),
          url: this.intl.t(
            'dispute-flow.transactions-selection.bulk-selection.transaction-disclaimer.disputing.url',
            {
              faqUrl: this.zendeskLocalization.getLocalizedArticle(9015327),
            }
          ),
        };

      // transaction has been disputed for same type
      case transaction.chargebackDisputedTypes.includes(type):
        return {
          key: 'dispute-flow.transactions-selection.bulk-selection.transaction-disclaimer.disputed.body',
          body: this.intl.t(
            'dispute-flow.transactions-selection.bulk-selection.transaction-disclaimer.disputed.body',
            {
              learnMoreLink,
            }
          ),
          url: this.intl.t(
            'dispute-flow.transactions-selection.bulk-selection.transaction-disclaimer.disputed.url',
            {
              faqUrl: this.zendeskLocalization.getLocalizedArticle(9015327),
            }
          ),
        };
      default:
        return null;
    }
  }

  placeholderText(transaction) {
    if (transaction.canceled) {
      return this.intl.t(
        'dispute-flow.transactions-selection.bulk-selection.transaction-disclaimer.fail-statuses.status.canceled'
      );
    } else if (transaction.declined) {
      return this.intl.t(
        'dispute-flow.transactions-selection.bulk-selection.transaction-disclaimer.fail-statuses.status.declined'
      );
    } else if (transaction.reversed) {
      return this.intl.t(
        'dispute-flow.transactions-selection.bulk-selection.transaction-disclaimer.fail-statuses.status.reverted'
      );
    }
    return '';
  }
}
