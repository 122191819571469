export default {
  "container": "yR",
  "centered": "yQ",
  "content": "ye",
  "spinner": "yB",
  "holder-selector-wrapper": "yE mb-16",
  "holder-selector-error": "yd",
  "info-text": "yI",
  "error-text": "yt",
  "lottie-illustration": "yM",
  "info-text-1": "yP",
  "info-text-2": "yl"
};
