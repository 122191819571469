/* import __COLOCATED_TEMPLATE__ from './customization-upsell.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class FlowsCardsCustomizationUpsell extends Component {
  @action
  onColorChange(design) {
    this.args.context.cardUpsellDesign = design;
  }

  @action
  onTypeOfPrintChange(typeOfPrint) {
    this.args.context.cardUpsellTypeOfPrint = typeOfPrint;
  }
}
