/* import __COLOCATED_TEMPLATE__ from './success-renewal.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class FlowsCardsSuccessRenewal extends Component {
  @service modals;
  @service segment;

  @action handleOpenEducationalModal() {
    this.modals.open('card/modals/digital-first', {
      isFullScreenModal: true,
    });
    this.segment.track('cards_renewal_digital_first_education_opened');
  }
}
