/* import __COLOCATED_TEMPLATE__ from './bank-account.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { CARD_LEVELS_TRACKING } from 'qonto/constants/cards';

export default class FlowsCardsBankAccount extends Component {
  @service segment;
  @service organizationManager;

  @action
  handleNext() {
    let {
      context: { card, flowTrackingOrigin },
      transitionToNext,
    } = this.args;

    let isMainAccount = card.bankAccount.main;
    this.segment.track('card_account_type_chosen', {
      account_type: isMainAccount ? 'main' : 'secondary',
      origin: flowTrackingOrigin,
      card_type: CARD_LEVELS_TRACKING[card.cardLevel],
    });

    transitionToNext();
  }

  get accountsCount() {
    let { activeAccounts } = this.organizationManager.organization;
    return activeAccounts?.length;
  }
}
