/* import __COLOCATED_TEMPLATE__ from './customization-renew.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class FlowsCardsCustomizationRenew extends Component {
  @action
  onColorChange(design) {
    this.args.context.cardDesign = design;
  }

  @action
  onTypeOfPrintChange(typeOfPrint) {
    this.args.context.typeOfPrint = typeOfPrint;
  }
}
