export default {
  "integration-item": "clk",
  "icon-container": "clJ",
  "content": "cls",
  "title-container": "clF",
  "new-badge": "clg",
  "title": "clD",
  "description": "clV",
  "no-attributes": "clL",
  "connect-cta": "clT"
};
