/* import __COLOCATED_TEMPLATE__ from './empty-state.hbs'; */
import Component from '@glimmer/component';

import { BadgeHighlight, LottiePlayer } from '@repo/design-system-kit';

/**
 * An Empty state component with
 *
 * ```
 * <EmptyState @options={{emptyStateOptions}} />
 *
 * ```
 *
 * @extends Component
 * @param {Object} options Object containing all the values needed for the component to render properly
 * @param {String} options.badge Containing the string to display in the badge
 * @param {String} options.title Containing the string to display in the title
 * @param {String} options.subtitle Containing the string to display in the subtitle
 * @param {String} options.button.label Containing the string to display in the button
 * @param {Func}   options.button.callback Containing the function that will be triggered clicking upon the button
 */
export default class EmptyStateComponent extends Component {
  lottiePlayer = LottiePlayer;

  badgeHighlight = BadgeHighlight;

  animationReady = false;

  get actAsPrimaryOrSecondary() {
    let opt = this.args.options;

    return opt.button && opt.secondaryButton ? 'btn btn--secondary mr-16' : 'btn btn--primary';
  }
}
