/* import __COLOCATED_TEMPLATE__ from './account-selection.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class FlowsMandateAccountSelection extends Component {
  @service segment;

  @action
  transitionToNext() {
    this.segment.track('app_sdd_bank_account_chosen', {
      account_name: this.args.context.mandate.bankAccount.name,
    });

    this.args.transitionToNext();
  }
}
