/* import __COLOCATED_TEMPLATE__ from './benefits-list.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { BENEFITS_LIST_WARNINGS } from 'qonto/constants/subscriptions';

export default class FlowsSubscriptionChangeBenefitsListComponent extends Component {
  @service intl;
  @service segment;
  @service modals;
  @service subscriptionManager;
  @service organizationManager;

  constructor() {
    super(...arguments);
    this.segment.track('change_plan_lose_benefits_displayed');
  }

  @action
  transitionToNext() {
    this.segment.track('change_plan_lose_benefits_clicked');
    if (this.args.context.errors?.length) {
      this.openBlockerModal();
    } else {
      this.args.transitionToNext();
    }
  }

  @action
  onKeepCurrentPlan() {
    this.segment.track('change_plan_keep_benefits_clicked');
    this.args.transitionToPrevious();
  }

  openBlockerModal() {
    let { errors, subscription, currentPricePlanCode } = this.args.context;

    this.modals.open('subscription/blockers-modal', {
      isFullScreenModal: true,
      errors,
      subscription,
      currentPricePlanCode,
      transitionToFlow: this.args.transitionToFlow,
    });
  }

  get warnings() {
    return this.args.context.warnings
      .map(warning => this.mapWarningToTranslation(warning.code, warning.additional_data))
      .filter(Boolean);
  }

  /* eslint-disable no-case-declarations */
  mapWarningToTranslation(code, additionalData) {
    switch (code) {
      case BENEFITS_LIST_WARNINGS.ADVERTISING_CARDS_DISALLOWED:
        return {
          icon: 'feature_physical_card-m',
          text: this.intl.t('subscription.change.confirmation.warnings.advertising'),
        };

      case BENEFITS_LIST_WARNINGS.FEATURE_BOOKKEEPING_DISABLED:
        return {
          icon: 'feature_label-m',
          text: this.intl.t('subscription.change.confirmation.warnings.bookkeeping'),
        };

      case BENEFITS_LIST_WARNINGS.FLASH_CARDS_DISALLOWED:
        return {
          icon: 'feature_flash_card-m',
          text: this.intl.t('subscription.change.confirmation.warnings.flash'),
        };

      case BENEFITS_LIST_WARNINGS.MANAGER_ROLE_DISALLOWED:
        return {
          icon: 'feature_user-m',
          text: this.intl.t('subscription.change.confirmation.warnings.manager'),
        };

      case BENEFITS_LIST_WARNINGS.INCLUDED_TRANSFERS_DECREASE:
        let currentPlanLimit = this.subscriptionManager.getLimitByFeatureCode('sepa_transfers');
        let newPlanLimit = additionalData?.sepa_limit;
        let transferPrice = additionalData?.transfer_price;

        return {
          icon: 'feature_transfer_recurring-m',
          text: this.intl.t('subscription.change.confirmation.warnings.transfers-decrease', {
            count1: currentPlanLimit,
            count2: newPlanLimit,
            transferPrice: this.intl.formatNumber(transferPrice.value, {
              displayUndefined: true,
              currency: transferPrice.currency,
              style: 'currency',
            }),
          }),
        };

      case BENEFITS_LIST_WARNINGS.INCLUDED_CHECKS_DECREASE:
        let currentCheckLimit = this.subscriptionManager.getLimitByFeatureCode('cheque');
        let newCheckLimit = additionalData?.check_limit;

        return {
          icon: 'feature_check-m',
          text: this.intl.t('subscription.change.confirmation.warnings.checks-decrease', {
            count1: currentCheckLimit,
            count2: newCheckLimit,
          }),
        };

      case BENEFITS_LIST_WARNINGS.FEATURE_REQUESTS_DISABLED:
        return {
          icon: 'feature_request-m',
          text: this.intl.t('subscription.change.confirmation.warnings.pending-requests'),
        };

      case BENEFITS_LIST_WARNINGS.FEATURE_SUPPLIER_INVOICES_PENDING_DISABLED:
        return {
          icon: 'feature_supplier_invoice-m',
          text: this.intl.t(
            'subscription.change.confirmation.warnings.supplier-invoices-pending-requests'
          ),
        };

      case BENEFITS_LIST_WARNINGS.CONNECT_EBICS_INTEGRATIONS_DISABLED:
        return {
          icon: 'feature_connect-m',
          text: this.intl.t('subscription.change.confirmation.warnings.connect.ebics'),
        };

      case BENEFITS_LIST_WARNINGS.CONNECT_PLUS_INTEGRATIONS_DISABLED:
        return {
          icon: 'feature_connect-m',
          text: this.intl.t('subscription.change.confirmation.warnings.connect.api-integrations', {
            htmlSafe: true,
          }),
        };

      case BENEFITS_LIST_WARNINGS.UNLIMITED_USERS_DISALLOWED:
        return {
          icon: 'feature_user-m',
          text: this.intl.t('subscription.change.confirmation.warnings.unlimited-users', {
            amount: this.intl.formatNumber(additionalData?.additional_user_price.value, {
              currency: additionalData?.additional_user_price.currency,
              style: 'currency',
            }),
          }),
        };

      case BENEFITS_LIST_WARNINGS.FREE_USERS_DISALLOWED:
        return {
          icon: 'feature_user-m',
          text: this.intl.t('subscription.change.confirmation.warnings.free-users', {
            amount: this.intl.formatNumber(additionalData?.additional_user_price.value, {
              currency: additionalData?.additional_user_price.currency,
              style: 'currency',
            }),
          }),
        };

      default:
        return null;
    }
  }
}
