/* import __COLOCATED_TEMPLATE__ from './eligible-teams.hbs'; */
import { service } from '@ember/service';
import { compare } from '@ember/utils';
import Component from '@glimmer/component';

import { task } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class BudgetsTeamsProvider extends Component {
  @service organizationManager;
  @service store;
  @service sentry;
  @service toastFlashMessages;
  @service intl;

  constructor() {
    super(...arguments);
    this.fetchTeamsTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      });
  }

  fetchTeamsTask = task(async () => {
    let result = await this.store.adapterFor('budget').getEligibleTeams();

    let eligibleTeamsIds = result.teams.map(team => team.id);

    let { organization } = this.organizationManager;

    await this.organizationManager.organization.loadTeams();

    let { teams } = this.organizationManager.organization;

    let eligibleTeams = teams.filter(team => eligibleTeamsIds.includes(team.id));

    let managerIdsToLoad = eligibleTeams.reduce(
      (acc, team) => acc.concat(team.hasMany('teamManagers').ids()),
      []
    );

    let memberships = [];
    if (managerIdsToLoad.length > 0) {
      memberships = await this.store.query('membership', {
        organization_id: organization.id,
        filters: { ids: managerIdsToLoad },
        per_page: managerIdsToLoad.length,
      });
    }

    return eligibleTeams
      .sort((a, b) => compare(a.name, b.name))
      .map(team => ({
        team,
        memberships: memberships.filter(
          membership => membership.belongsTo('team').id() === team.id
        ),
      }));
  });
}
