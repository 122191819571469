/* import __COLOCATED_TEMPLATE__ from './payment-method.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class PaymentMethodComponent extends Component {
  @service segment;

  PAYMENT_METHOD_STEP_MAP = {
    BANK_DETAILS: 'bank-details',
    CARD_DETAILS: 'card-details',
  };

  @action
  goToStep(nextStepId) {
    this.args.context.chosenPaymentStep = nextStepId;

    if (nextStepId === this.PAYMENT_METHOD_STEP_MAP.BANK_DETAILS) {
      this.segment.track('onboarding_top_up_transfer_selected');
    } else {
      this.segment.track('onboarding_top_up_card_selected');
    }

    this.args.transitionToNext();
  }
}
