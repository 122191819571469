/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

export default class SuccessComponent extends Component {
  lottiePlayer = LottiePlayer;

  defaultAsset = '/lotties/success.json';
  get assetPath() {
    return this.args.asset ?? this.defaultAsset;
  }
}
