export default {
  "members-loading": "cDV",
  "members-loading--ff": "cDL",
  "header": "cDT",
  "header-search-bar": "cDz",
  "body": "cDa",
  "members": "cDH",
  "placeholder-container": "cDO",
  "icon": "cDm",
  "details": "cDp",
  "member-loading": "cDx",
  "member-loading--no-ff": "cDw",
  "body-members": "cDN",
  "body-details": "cDv"
};
