export default {
  "header-cell": "nT caption-bold",
  "sortable": "nz",
  "animated": "na",
  "fadein-item": "nH",
  "animated-cell": "nO",
  "col1": "nm",
  "header-content": "np caption-bold",
  "active": "nx"
};
