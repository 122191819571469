/* import __COLOCATED_TEMPLATE__ from './transactions-bulk-selection.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { reads } from 'macro-decorators';

import { CLAIM_TYPES } from 'qonto/constants/transactions';
import { CardDescriptionBox } from 'qonto/react/components/flows/chargeback-claim/card-description-box';

export default class FlowsChargebackClaimTransactionsBulkSelectionTransactionsBulkSelectionComponent extends Component {
  @service intl;
  @service segment;

  CardDescriptionBox = CardDescriptionBox;

  @reads('args.context.selectedTransactions') selectedTransactions;

  @tracked shouldShowFooter = true;

  get subtitle() {
    let { claimType } = this.args.context;
    switch (claimType) {
      case CLAIM_TYPES.atm:
        return this.intl.t('dispute-flow.transactions-selection.subtitle.atm');
      case CLAIM_TYPES.fraud:
        return this.intl.t('dispute-flow.transactions-selection.subtitle.fraud');
      case CLAIM_TYPES.commercial:
        return this.intl.t('dispute-flow.transactions-selection.subtitle.commercial');
      default:
        return null;
    }
  }

  get total() {
    return this.selectedTransactions?.reduce((total, transaction) => total + transaction.amount, 0);
  }

  get isContinueDisabled() {
    return this.selectedTransactions?.length === 0;
  }

  @action
  setShouldShowFooter(value) {
    this.shouldShowFooter = value;
  }

  @action
  continue() {
    this.segment.track('cards-chargeback_bulk-selection-continue-cta_clicked');
    this.args.transitionToNext();
  }
}
