/* import __COLOCATED_TEMPLATE__ from './capital-share-input.hbs'; */
import Component from '@glimmer/component';

import { AmountField } from '@repo/design-system-kit';

const CAPITAL_SHARE_LIMIT = 9999999999.99;

export default class CapitalShareInputComponent extends Component {
  amountField = AmountField;
  maxCapitalShare = CAPITAL_SHARE_LIMIT;
}
