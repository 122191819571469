export default {
  "wrapper": "NO",
  "content": "Nm",
  "compact": "Np",
  "full-width": "Nx",
  "left": "Nw",
  "right-image": "NN",
  "right-lottie": "Nv",
  "badge": "No",
  "badge-icon": "Nn",
  "animation": "Ni",
  "hidden": "NA"
};
