/* import __COLOCATED_TEMPLATE__ from './intro.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

export default class FlowsFinancingPayLaterApplicationIntroComponent extends Component {
  lottiePlayer = LottiePlayer;

  @service segment;

  @action
  goToNextStep() {
    this.segment.track('pay-later-intro_check-eligibility_clicked');
    this.args.transitionToNext();
  }
}
