/* import __COLOCATED_TEMPLATE__ from './verify-delivery-address.hbs'; */
import { action, set } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { task } from 'ember-concurrency';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class FlowsCardsVerifyDeliveryAddress extends Component {
  @service segment;
  @service sensitiveActions;

  constructor() {
    super(...arguments);

    this.args.context.isChangeAddress = false;
  }

  @action
  handleClick(provider) {
    this.segment.track('cards_renewal_address_chosen', {
      address_type: 'default',
    });

    this.sensitiveActions.runTask
      .perform(this.confirmAddressTask, provider.renewTask)
      .catch(ignoreCancelation);
  }

  confirmAddressTask = task(async renewTask => {
    let { hasSingleCardToRenew, organizationAddress } = this.args.context;

    // default address is company (business) address
    let address = organizationAddress.serialize();
    let shipToBusiness = true;

    set(this.args.context, 'address', address);
    set(this.args.context, 'shipToBusiness', shipToBusiness);

    // in case there is a single expiring card, we go to the next step
    if (hasSingleCardToRenew) return this.args.transitionToNext();

    // errors are handled in the renewTask itself
    let response = await renewTask.perform(address, shipToBusiness);

    set(this.args.context, 'renewalCardsCount', response?.renewalCardsCount);
    set(this.args.context, 'renewalCard', response?.renewalCard);

    if (response?.renewalCardsCount) {
      // Notifies description to go success step
      set(this.args.context, 'isAddressConfirmed', true);
      this.args.transitionToNext();
    }
  });

  @action
  changeAddress() {
    // Notifies description to go to custom address step
    this.args.context.isChangeAddress = true;
    this.args.transitionToNext();
  }
}
