/* import __COLOCATED_TEMPLATE__ from './french-company-details.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';

export default class FrenchCompanyDetailsComponent extends Component {
  @service intl;

  isLegalCapitalShareVisible = this.settings.legalCapitalShare;
  isCommercialRegisterNumberVisible = this.settings.commercialRegisterNumber;

  get settings() {
    return this.args.context.settings;
  }

  @cached
  get paymentConditions() {
    return [
      {
        value: 'on_receipts',
        label: this.intl.t('receivable-invoices.invoice-settings.payment-condition.on_receipts'),
      },
      {
        value: 'compensated_for_sales',
        label: this.intl.t(
          'receivable-invoices.invoice-settings.payment-condition.compensated_for_sales'
        ),
      },
      {
        value: 'exempt',
        label: this.intl.t('receivable-invoices.invoice-settings.payment-condition.exempt'),
      },
    ];
  }

  get selectedPaymentCondition() {
    let option = this.settings?.vatPaymentCondition;

    if (option) {
      return this.paymentConditions.find(type => type.value === option);
    }
  }

  @action
  updatePaymentCondition(condition) {
    this.settings.vatPaymentCondition = condition.value;
  }

  get vatNumberErrorMessage() {
    let errors = this.settings.errors.get('vatNumber');
    if (errors.length === 0) return null;

    if (errors.some(error => error.message === 'invalid')) {
      return this.intl.t(
        'receivable-invoices.invoice-setting.settings-modal.general.company-details.vat-error-message'
      );
    }
  }
}
