/* import __COLOCATED_TEMPLATE__ from './style-picker.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class GenerateLogoStylePickerComponent extends Component {
  @service intl;

  get styles() {
    return [
      {
        id: 'any',
        title: this.intl.t('generated-logo.modal.styles.options.any'),
        svg: '/illustrations/generate-logo/any.svg',
      },
      {
        id: 'minimalist',
        title: this.intl.t('generated-logo.modal.styles.options.minimalistic'),
        svg: '/illustrations/generate-logo/minimalist.svg',
      },
      {
        id: 'retro',
        title: this.intl.t('generated-logo.modal.styles.options.retro'),
        svg: '/illustrations/generate-logo/retro.svg',
      },
      {
        id: 'abstract',
        title: this.intl.t('generated-logo.modal.styles.options.abstract'),
        svg: '/illustrations/generate-logo/abstract.svg',
      },
      {
        id: 'playful',
        title: this.intl.t('generated-logo.modal.styles.options.playful'),
        svg: '/illustrations/generate-logo/playful.svg',
      },
    ];
  }
}
