/* import __COLOCATED_TEMPLATE__ from './confirm-trial.hbs'; */
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { BadgeStatus, Disclaimer } from '@repo/design-system-kit';
import { CheckoutHeader } from '@repo/domain-kit/pricing';
import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { TRACKING_TRIAL_STATE } from 'qonto/constants/subscriptions';

export default class ConfirmTrialComponent extends Component {
  disclaimerBlock = Disclaimer.Block;

  @service subscriptionManager;
  @service segment;
  @service intl;
  @service organizationManager;
  @service toastFlashMessages;
  @service sentry;

  @tracked hasProductChangeNotAvailableError = false;

  checkoutHeader = CheckoutHeader;
  badgeStatus = BadgeStatus;

  get selectedPricePlan() {
    return this.args.context.subscription.product;
  }

  get currentPricePlan() {
    return this.subscriptionManager.currentPricePlan;
  }

  get monthlyPrice() {
    return {
      value:
        this.selectedPricePlan.monthlyPrice.value ||
        this.selectedPricePlan.monthlyPrice.perMonthAmount.value,
      currency:
        this.selectedPricePlan.monthlyPrice.currency ||
        this.selectedPricePlan.monthlyPrice.perMonthAmount.currency,
    };
  }

  get infoBodyText() {
    let { selectedPricePlan, freeTrial, intl } = this;

    let brandName = selectedPricePlan?.get('localName');
    let monthDuration = freeTrial?.monthDuration;
    let itsFeatures = intl.t('upsell.discover.trial.confirm.info-1.features');

    if (monthDuration > 1) {
      return intl.t('upsell.discover.trial.confirm.info-1.body.plural', {
        brandName,
        monthDuration,
        itsFeatures,
      });
    } else {
      return intl.t('upsell.discover.trial.confirm.info-1.body.singular', {
        brandName,
        monthDuration,
        itsFeatures,
      });
    }
  }

  get freeTrial() {
    return this.subscriptionManager.currentSubscription.availableTrials.find(
      ({ productId }) => productId === this.selectedPricePlan.get('id')
    );
  }

  get nextBillingDate() {
    return this.args.context.estimatedPrice.next_billing_date;
  }

  get showDisclaimerForItalianOrganizations() {
    return (
      variation('feature--boolean-pricing-italian-disclaimers') &&
      this.organizationManager.organization.legalCountry === 'IT'
    );
  }

  get italianDisclaimerText() {
    return this.intl.t('subscription.change.bank-of-italy-disclaimer.body', {
      faqUrl: htmlSafe(
        `<a href="https://support-it.qonto.com/hc/it/articles/26999640842513-Restrizioni-in-Italia-e-miglioramento-delle-misure-di-antiriciclaggio" target="_blank" rel="noopener noreferrer"
            data-test-confirm-trial-italian-disclaimer-link>${this.intl.t(
              'subscription.change.bank-of-italy-disclaimer.link'
            )}</a>`
      ),
      htmlSafe: true,
    });
  }

  savePlanTask = dropTask(async () => {
    this.segment.track('plans_change_confirmation_clicked', {
      current_plan: this.currentPricePlan.get('code'),
      target_plan: this.selectedPricePlan.get('code'),
      trial_state: TRACKING_TRIAL_STATE.FREE_TRIAL,
      recurrence: this.args.context.recurrence,
    });

    try {
      await this.args.context.subscription.save();
      await this.subscriptionManager.refresh();
      this.args.transitionToNext();
    } catch (error) {
      if (error.status === 422) {
        this.hasProductChangeNotAvailableError = error.errors.some(
          it => it.detail.code === 'product_change_not_available'
        );

        if (variation('feature--boolean-mp-italy-checkout')) {
          if (this.hasProductChangeNotAvailableError) {
            this.args.context.isItalyError = true;
            this.args.transitionToNext(true);
            return;
          }
        }
      } else if (hasMFAError(error?.errors)) {
        throw error;
      } else {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
        this.sentry.captureException(error);
      }

      return error;
    }
  });
}
