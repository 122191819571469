/* import __COLOCATED_TEMPLATE__ from './italian-tax-regime-selector.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';

import taxRegimes from 'qonto/constants/it-tax-regimes';

export default class EinvoiceActivationFormComponent extends Component {
  get selectedTaxRegimeOption() {
    return this.taxRegimeOptions.find(option => option.key === this.args.taxRegime);
  }

  @cached
  get taxRegimeOptions() {
    return Object.entries(taxRegimes).map(([key, value]) => ({ key, value: `${key} - ${value}` }));
  }

  @action updateTaxRegimeOption({ key }) {
    this.args.onChange(key);
  }
}
