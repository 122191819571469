/* import __COLOCATED_TEMPLATE__ from './select-exercise.hbs'; */
import { action, set } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { PERIOD_TYPES } from 'qonto/constants/budget';

export default class FlowsBudgetsSelectExercise extends Component {
  @service segment;

  periodTypes = PERIOD_TYPES;

  constructor() {
    super(...arguments);
    this.segment.track('edit-budget_select-budget-period_displayed');
  }

  get budget() {
    return this.args.context.budget;
  }

  get currentExercise() {
    if (this.budget?.currentExercise && !this.budget.currentExercise.isNew) {
      return this.budget.currentExercise;
    }
  }

  get nextExercise() {
    if (this.budget?.nextExercise && !this.budget.nextExercise.isNew) {
      return this.budget.nextExercise;
    }
  }

  @action
  nextStep(periodType, exercise) {
    set(this.args.context, 'type', periodType);
    set(this.args.context, 'exerciseId', exercise?.id ?? null);

    this.segment.track(`edit-budget_${periodType}_selected`);

    this.args.transitionToNext();
  }
}
