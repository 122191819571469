export default {
  "form": "if",
  "fieldset": "iK",
  "form-body": "iG",
  "conditional-select": "ir",
  "conditional-text": "ib",
  "group": "iy",
  "add-filter-button": "iU btn btn--tertiary",
  "footer": "ij",
  "btn-icon": "iW",
  "add-icon": "iu",
  "cancel-button": "AS btn",
  "form-header": "Ac",
  "align-start": "Aq",
  "align-right": "AZ",
  "button-wrapper": "AR",
  "wrapper": "AQ",
  "label": "Ae body-2",
  "input-wrapper": "AB",
  "input": "AE input-field body-2",
  "error": "Ad",
  "error-message": "AI"
};
