export default {
  "step-container": "WY",
  "content": "Wh",
  "detail": "Wf",
  "secondary": "WK",
  "reference": "WG",
  "fees-label": "Wr",
  "fees-tooltip": "Wb",
  "separator": "Wy",
  "mt-32": "WU"
};
