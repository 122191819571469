/* import __COLOCATED_TEMPLATE__ from './dummy-data.hbs'; */
import Component from '@glimmer/component';

import { SearchField } from '@repo/design-system-kit';

export default class EmptyStatesSuppliersDummyDataComponent extends Component {
  searchField = SearchField;

  sortBy = 'supplier:asc';
  noop() {}
}
