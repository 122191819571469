export default {
  "container": "yv",
  "disabled-option": "yo",
  "content": "yn",
  "spinner": "yi",
  "subtitle": "yA",
  "details-table": "yY",
  "caption": "yh",
  "tooltip-icon": "yf",
  "tooltip-icon-right": "yK",
  "card-monthly-cost": "yG",
  "card-monthly-discounted-cost": "yr",
  "strikethrough": "yb",
  "purple-text": "yy",
  "budget-label": "yU",
  "card-cost-label": "yj",
  "card-billing-account": "yW"
};
