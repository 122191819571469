export default {
  "header": "cJg",
  "center": "cJD",
  "header-placeholder": "cJV",
  "placeholder": "cJL",
  "name-block": "cJT",
  "block": "cJz",
  "detail-block": "cJa",
  "border-top": "cJH"
};
