export default {
  "card": "cMO",
  "text-container": "cMm",
  "text-title": "cMp title-3",
  "text-subtitle": "cMx body-1",
  "text-cta": "cMw body-1",
  "text-cta-icon": "cMN",
  "image": "cMv",
  "image-illustration": "cMo"
};
