/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class InContextIntegrationsSideDrawerBucketItemComponent extends Component {
  @service theme;

  get icon() {
    let { isCurrentThemeDark } = this.theme;
    let { item } = this.args;

    return isCurrentThemeDark ? item.darkLogo : item.lightLogo;
  }
}
