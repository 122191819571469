/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';
import { APPROVAL_WORKFLOW_REQUEST_TYPES } from '@repo/qonto-mirage/constants/approval-workflows.js';

export default class ApprovalWorkflowsSuccess extends Component {
  lottiePlayer = LottiePlayer;

  @service router;
  @service intl;

  get subtitle() {
    let { workflow } = this.args.context;
    return workflow.requestType === APPROVAL_WORKFLOW_REQUEST_TYPES.SUPPLIER_INVOICE
      ? this.intl.t('approval-workflows.success.subtitle-supplier-invoices')
      : this.intl.t('approval-workflows.success.subtitle');
  }

  @action
  goToWorkflow() {
    let { context } = this.args;
    this.router.replaceWith('settings.approval-workflows', {
      queryParams: { highlight: context.workflow.id },
    });
  }
}
