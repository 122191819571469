/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class InvoiceSubscriptionsTableComponent extends Component {
  @action handleItemKeydown(document, event) {
    if (event.key === 'Enter') {
      this.args.handleShowItem(document);
    }
  }
}
