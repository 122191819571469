/* import __COLOCATED_TEMPLATE__ from './add-tax-beneficiary.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { getNRCFullName, getNRCIban } from 'qonto/utils/nrc';
import {
  copyBeneficiaryIntoTransfer,
  copyBeneficiaryLabelsIntoTransfer,
  copyBeneficiaryVatIntoTransfer,
} from 'qonto/utils/transfers';

export default class FlowsTransfersSepaNewAddTaxAgencyComponent extends Component {
  @service abilities;
  @service beneficiariesManager;
  @service toastFlashMessages;
  @service intl;
  @service organizationManager;

  @tracked taxBeneficiary = null;

  constructor() {
    super(...arguments);
    this._prefillTaxBeneficiary();
  }

  @action
  transitionToNext(taxBeneficiary) {
    this._setTransferBeneficiary(taxBeneficiary);
    this.toastFlashMessages.toastSuccess(this.intl.t('transfers.beneficiaries.add-modal.success'));
    this.args.transitionToNext();
  }

  _createBeneficiaryRecord(prefilledOptions) {
    return this.beneficiariesManager.createSepaBeneficiary(
      this.organizationManager.organization,
      prefilledOptions
    );
  }

  _prefillTaxBeneficiary() {
    let { taxBeneficiaryName } = this.args.context.nrcOptions;

    let iban = getNRCIban(taxBeneficiaryName);
    let name = getNRCFullName(taxBeneficiaryName);

    this.taxBeneficiary = this._createBeneficiaryRecord({
      ...(iban && { iban }),
      ...(name && { name }),
      activityTag: 'tax',
    });
  }

  _setTransferBeneficiary(taxBeneficiary) {
    let { sepaTransfer } = this.args.context;

    sepaTransfer.set('beneficiary', taxBeneficiary);

    copyBeneficiaryIntoTransfer(sepaTransfer, taxBeneficiary, { forceCopy: true });

    copyBeneficiaryLabelsIntoTransfer(sepaTransfer, taxBeneficiary);

    if (this.abilities.can('view vat bookkeeping')) {
      copyBeneficiaryVatIntoTransfer(sepaTransfer, taxBeneficiary);
    }
  }
}
