export default {
  "page-wrapper": "uO",
  "container": "um",
  "content": "up",
  "showElement": "ux",
  "illustration": "uw",
  "fadeIn": "uN",
  "scaleDown": "uv",
  "error-content": "uo",
  "error-illustration": "un"
};
