/* import __COLOCATED_TEMPLATE__ from './consent.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';
import { dropTask, waitForProperty, waitForQueue } from 'ember-concurrency';
import window from 'ember-window-mock';
import { reads } from 'macro-decorators';

import { BANK_CONNECTION_OUTCOME } from 'qonto/constants/connect';
import { apiBaseURL, apiNamespace } from 'qonto/constants/hosts';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import scrollIntoView from 'qonto/utils/scroll-into-view';

export default class FlowsAccountsExternalImportConsentComponent extends Component {
  lottiePlayer = LottiePlayer;

  @service flowLinkManager;
  @service intl;
  @service localeManager;
  @service networkManager;
  @service router;
  @service segment;
  @service sentry;
  @service toastFlashMessages;
  @service organizationManager;

  @reads('organizationManager.organization') organization;
  containerId = `${guidFor(this)}-container`;

  constructor() {
    super(...arguments);
    this.consentSetupTask.perform().catch(ignoreCancelation);
  }

  get shouldRedirectToConnectHub() {
    return this.args.context.origin?.includes('connect-hub');
  }

  consentSetupTask = dropTask(async () => {
    await waitForQueue('afterRender');
    await waitForProperty(this, 'router.currentRoute.queryParams');

    let { outcome } = this.router.currentRoute.queryParams;

    if (Object.values(BANK_CONNECTION_OUTCOME).includes(outcome)) {
      // Redirect to proper outcome page when returning from webview
      this.args.context.outcome = outcome;
      this.args.transitionToNext();
    } else {
      this.scrollIntoView(this.containerId);
    }
  });

  @action
  scrollIntoView(element) {
    scrollIntoView(element, { offsetTop: 32 });
  }

  @action
  onCancel() {
    this.segment.track('accounts_external-consent_cancel', {
      bank: this.args.context.bank_name,
    });

    this.resetContext();

    if (!this.shouldRedirectToConnectHub) {
      this.args.transitionToPrevious();
    }
  }

  resetContext() {
    this.args.context.bank_id = null;
    this.args.context.bank_name = null;
    this.args.context.bank_logo = null;
    this.args.context.bank_slug = null;
  }

  redirectToWebview = dropTask(async () => {
    let { origin, bank_id, bank_name } = this.args.context;

    let name = 'external-account-import';
    let successURL = this.flowLinkManager.urlFor({
      name,
      stepId: 'consent',
      queryParams: {
        outcome: BANK_CONNECTION_OUTCOME.SUCCESS,
        origin,
      },
    });
    let errorURL = this.flowLinkManager.urlFor({
      name,
      stepId: 'consent',
      queryParams: {
        outcome: BANK_CONNECTION_OUTCOME.ERROR,
        origin,
      },
    });
    let data = {
      language: this.localeManager.locale,
      bank_id,
      success_url: `${window.location.origin}${successURL}`,
      error_url: `${window.location.origin}${errorURL}`,
    };

    try {
      let response = await this.networkManager.request(
        `${apiBaseURL}/${apiNamespace}/account_aggregation/accounts/connect`,
        { method: 'POST', data }
      );

      this.segment.track('accounts_external-consent_allow', {
        bank: bank_name,
      });

      window.location.href = response.webview_url;
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });
}
