export default {
  "container": "cku",
  "form-container": "cJS",
  "title": "cJc",
  "title-border": "cJq",
  "tabs-container": "cJZ",
  "preview-container": "cJR",
  "email-preview-container": "cJQ",
  "close-button": "cJe btn btn--icon-only btn--tertiary btn--large"
};
