/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Button } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

export default class FlowsInvoicesClientsImportSuccess extends Component {
  @service intl;
  @service toastFlashMessages;
  @service router;
  @service segment;

  @reads('args.context') context;

  button = Button;

  get importedClientsCount() {
    return this.context.xlsFile.rows.length;
  }

  @action
  goToImportItemsPrimaryCTA() {
    this.segment.track('client-import_success_items-flow');

    this.args.transitionToFlow({
      flowName: 'invoice-products-import',
      stepId: 'upload',
    });
  }

  @action
  goToClientList() {
    this.toastFlashMessages.toastInfo(
      this.intl.t('client-list.toasts.info.clients-import', {
        count: this.importedClientsCount,
      })
    );
    this.args.transitionToNext();
  }
}
