/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';
import { task, timeout } from 'ember-concurrency';

import { FUNDING_DEBOUNCE_MS } from 'qonto/constants/bank-account';

export default class FlowsAccountsRemuneratedFundingSuccess extends Component {
  lottiePlayer = LottiePlayer;

  @service router;

  redirectAccountsTask = task(async () => {
    await timeout(FUNDING_DEBOUNCE_MS);
    this.router.replaceWith(this.args.context.origin);
  });
}
