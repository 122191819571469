/* import __COLOCATED_TEMPLATE__ from './cta.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import {
  ALLOWED_ROUTES,
  ATTEMPTS_LIMIT,
  GENERATED_LOGO_MODEL,
  LOGO_STATUS,
  TRACKING_EVENTS,
} from 'qonto/constants/generated-logo';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class CtaComponent extends Component {
  @service capitalIncreaseManager;
  @service store;
  @service organizationManager;
  @service intl;
  @service modals;
  @service router;
  @service segment;

  @reads('organizationManager.organization') organization;

  @tracked logos;

  constructor() {
    super(...arguments);

    this.router.on('routeDidChange', this, this.#fetchLogos);

    this.#fetchLogos();
  }

  willDestroy() {
    super.willDestroy();
    this.router.off('routeDidChange', this, this.#fetchLogos);
  }

  @action
  openModal() {
    this.modals.open('generate-logo/modal', {
      isFullScreenModal: true,
    });

    this.trackLogoCreationEntryPointEvent();
  }

  doesMatchRoutes() {
    let allowedRoutes = [
      ALLOWED_ROUTES.CAPITAL.CERTIFICATE,
      ALLOWED_ROUTES.CAPITAL.KBIS_INFO,
      ALLOWED_ROUTES.CAPITAL.UPLOAD,
    ];

    let { currentRouteName } = this.args;

    let isDepositRequestSigned =
      currentRouteName === ALLOWED_ROUTES.CAPITAL.REVIEW_INDEX && this.isDepositRequestSigned;

    return isDepositRequestSigned || allowedRoutes.includes(currentRouteName);
  }

  get isDepositRequestSigned() {
    return this.capitalIncreaseManager.isDepositRequestSigned;
  }

  get showGenerateLogoCta() {
    return this.doesMatchRoutes();
  }

  get isCollapsed() {
    let { state } = this.args;
    return state !== 'expanded';
  }

  trackLogoCreationEntryPointEvent() {
    this.segment.track(TRACKING_EVENTS.ENTRY_POINT_CLICKED, {
      step: this.args.currentRouteName,
    });
  }

  fetchLogosTask = dropTask(async () => {
    this.logos = await this.store.findAll(GENERATED_LOGO_MODEL);
  });

  get generatedLogos() {
    return this.logos?.filter(
      logo =>
        !logo.isNew &&
        logo.status === LOGO_STATUS.GENERATED &&
        logo.organization?.id === this.organization.id
    );
  }

  get hasReachedLogoLimit() {
    return this.generatedLogos?.length >= ATTEMPTS_LIMIT;
  }

  get ctaButtonText() {
    if (this.hasReachedLogoLimit) {
      return this.intl.t('generated-logo.entry-point.cta.limit-reached');
    } else {
      return this.intl.t('generated-logo.entry-point.cta.create');
    }
  }

  get ctaTitle() {
    if (this.hasReachedLogoLimit) {
      return this.intl.t('generated-logo.entry-point.title.limit-reached');
    } else {
      return this.intl.t('generated-logo.entry-point.title.create');
    }
  }

  get ctaDescription() {
    if (this.hasReachedLogoLimit) {
      return this.intl.t('generated-logo.entry-point.description.limit-reached');
    } else {
      return this.intl.t('generated-logo.entry-point.description.create');
    }
  }

  #fetchLogos() {
    if (this.showGenerateLogoCta) {
      this.fetchLogosTask.perform().catch(ignoreCancelation);
    }
  }
}
