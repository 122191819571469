/* import __COLOCATED_TEMPLATE__ from './restrictions.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { CARD_LEVELS_TRACKING } from 'qonto/constants/cards';

export default class FlowsCardsRestrictions extends Component {
  @service abilities;
  @service intl;
  @service segment;

  get subtitle() {
    if (this.abilities.can('view restrictive categories card')) {
      return this.intl.t('cards.steps.restrictions.subtitle-days-and-categories');
    }
    return this.intl.t('cards.steps.restrictions.subtitle');
  }

  get trackingParams() {
    let { card, flowTrackingOrigin, isUserCardHolder } = this.args.context;
    let { cardLevel, activeDaysOption, hasCategoryTags } = card;

    return {
      card_holder: isUserCardHolder ? 'self' : 'team_member',
      card_type: CARD_LEVELS_TRACKING[cardLevel],
      days: activeDaysOption,
      categories: hasCategoryTags,
      origin: flowTrackingOrigin,
    };
  }

  @action
  continueToNextStep() {
    this.segment.track('cards_order_advanced_settings_submitted', this.trackingParams);
    this.args.transitionToNext();
  }
}
