export default {
  "step-container": "cZa",
  "subtitle": "cZH",
  "step-container-content": "cZO",
  "continue-button": "cZm",
  "period-select": "cZp",
  "period-dropdown": "cZx",
  "readonly": "cZw",
  "period-error": "cZN",
  "toggle-options-container": "cZv",
  "receipt-code-toggle-input": "cZo"
};
