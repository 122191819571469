/* import __COLOCATED_TEMPLATE__ from './result.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { all, race, rawTimeout, task, waitForEvent } from 'ember-concurrency';

import {
  PAGOPA_EVENT_MIN_WAITING_IN_MS,
  PAGOPA_EVENT_TIMEOUT_IN_MS,
  PAGOPA_PAYMENT_EVENTS,
  PAGOPA_PAYMENT_RESULT_STATUS,
  PAGOPA_PAYMENT_TIMEOUT_REACHED_EVENT,
} from 'qonto/constants/pagopa';
import { PagoPaResultManager } from 'qonto/react/components/flows/pagopa/result/manager';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ResultComponent extends Component {
  @service notifierManager;
  @service segment;

  @tracked status = this.args.context.paymentStatus ?? PAGOPA_PAYMENT_RESULT_STATUS.WAITING;

  RESULT_STATUS = PAGOPA_PAYMENT_RESULT_STATUS;

  constructor() {
    super(...arguments);

    if (this.status === PAGOPA_PAYMENT_RESULT_STATUS.WAITING) {
      this.handlePagoPaEventsTask.perform().catch(ignoreCancelation);
    }
  }

  handlePagoPaEventsTask = task(async () => {
    let paymentId = this.args.context.payment.id;

    let authorizedTaskInstance = this.waitForEventTask.perform(
      PAGOPA_PAYMENT_EVENTS.AUTHORIZED,
      paymentId
    );
    let declinedTaskInstance = this.waitForEventTask.perform(
      PAGOPA_PAYMENT_EVENTS.DECLINED,
      paymentId
    );
    let timeoutTaskInstance = this.rawTimeoutEventTask.perform(
      PAGOPA_PAYMENT_TIMEOUT_REACHED_EVENT,
      PAGOPA_EVENT_TIMEOUT_IN_MS
    );

    let resultTask = race([authorizedTaskInstance, declinedTaskInstance, timeoutTaskInstance]);
    let minDelay = rawTimeout(PAGOPA_EVENT_MIN_WAITING_IN_MS);

    let [eventName] = await all([resultTask, minDelay]);

    if (eventName === PAGOPA_PAYMENT_TIMEOUT_REACHED_EVENT) {
      this.status = PAGOPA_PAYMENT_RESULT_STATUS.PROCESSING;
      this.segment.track('pagopa_payment-flow_processing');
    } else if (eventName === PAGOPA_PAYMENT_EVENTS.AUTHORIZED) {
      this.status = PAGOPA_PAYMENT_RESULT_STATUS.SUCCESS;
      this.segment.track('pagopa_payment-flow_successful');
    } else if (eventName === PAGOPA_PAYMENT_EVENTS.DECLINED) {
      this.status = PAGOPA_PAYMENT_RESULT_STATUS.ERROR;
      this.segment.track('pagopa_payment-flow_failed');
    }
  });

  waitForEventTask = task(async (eventName, paymentId) => {
    let eventIdMatchesSavedPayment = false;
    while (!eventIdMatchesSavedPayment) {
      let payload = await waitForEvent(this.notifierManager, eventName);
      eventIdMatchesSavedPayment = payload?.object?.id === paymentId;
    }
    return eventName;
  });

  rawTimeoutEventTask = task(async (eventName, ms) => {
    await rawTimeout(ms);
    return eventName;
  });

  handleNewPayment = () => {
    this.segment.track('pagopa_payment-flow_success_new-payment-button_clicked');
    this.args.restartFlow();
  };

  pagopaResultManager = PagoPaResultManager;
}
