/* import __COLOCATED_TEMPLATE__ from './empty-state.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class F24EmptyState extends Component {
  @service segment;
  @service intl;
  @service router;
  @service flowLinkManager;

  @action
  ctaCallback() {
    this.segment.track('f24_create-flow_start');

    this.flowLinkManager.transitionTo({
      name: 'f24-manual-declaration',
      stepId: 'form-ordinario',
    });
  }

  get emptyStateOptions() {
    let button = {
      label: this.intl.t('f24.pay-f24-cta'),
      callback: this.ctaCallback,
    };
    return {
      title: this.intl.t('f24.empty-state.title'),
      subtitle: this.intl.t('f24.empty-state.subtitle'),
      lottieSrc: '/lotties/f24/empty-state.json',
      button,
    };
  }
}
