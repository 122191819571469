/* import __COLOCATED_TEMPLATE__ from './mileage-calculation-details.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class MileageModalsMileageCalculationDetails extends Component {
  @service intl;
  @service segment;

  distanceWithKmUnit(distance) {
    return this.intl.t('requests.mileage.steps.request-details.calculation-modal.total-distance', {
      distance,
    });
  }

  metersToKmsConverter(distanceInMeters) {
    let distanceInKm = Math.ceil(distanceInMeters / 1000);
    return distanceInKm;
  }

  slabLabel(rate) {
    if (rate.start_meters === 0) {
      let distance = this.metersToKmsConverter(rate.end_meters);
      return this.intl.t('requests.mileage.steps.request-details.calculation-modal.scale-tier-1', {
        distance,
      });
    }

    if (rate.end_meters) {
      let startDistance = this.metersToKmsConverter(rate.start_meters);
      let endDistance = this.metersToKmsConverter(rate.end_meters);
      return this.intl.t('requests.mileage.steps.request-details.calculation-modal.scale-tier-2', {
        startDistance,
        endDistance,
      });
    }

    // This is done because We receive start_meters for tier 3 as 20000001 meters and it should be displayed as 20 000km in the UI
    // but it would be displayed as 20 001km when converterted to km because of the Math round method
    let tier3Distance = rate.start_meters - 1;
    let distance = this.metersToKmsConverter(tier3Distance);
    return this.intl.t('requests.mileage.steps.request-details.calculation-modal.scale-tier-3', {
      distance,
    });
  }

  get slabsRates() {
    let rates = this.args.data.mileageCalculation.rates.map(rate => ({
      label: this.slabLabel(rate),
      value: this.slabeRateValue(rate),
    }));
    return rates;
  }

  get yearlyDistanceInKms() {
    let { yearly_distance_meters } = this.args.data.mileageCalculation;
    return this.distanceWithKmUnit(this.metersToKmsConverter(yearly_distance_meters));
  }

  get reimbursementDistanceInKms() {
    let { distance_meters } = this.args.data.mileageCalculation;
    return this.distanceWithKmUnit(this.metersToKmsConverter(distance_meters));
  }

  slabeRateValue(slabRate) {
    let distance = this.metersToKmsConverter(slabRate.distance_meters);
    return this.intl.t('requests.mileage.sidebar.calculation.value', {
      distance,
      rate: slabRate.value,
    });
  }

  formatCurrency(amount, currency) {
    return this.intl.formatNumber(amount, {
      currency,
      style: 'currency',
    });
  }

  get electricBonusAmount() {
    let { electric_bonus_amount } = this.args.data.mileageCalculation;

    if (electric_bonus_amount) {
      return this.formatCurrency(electric_bonus_amount.value, electric_bonus_amount.currency);
    }
  }

  get totalAmount() {
    let { amount } = this.args.data;
    return this.formatCurrency(amount.value, amount.currency);
  }
}
