/* import __COLOCATED_TEMPLATE__ from './upsell.hbs'; */
import { action, set } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { reads } from 'macro-decorators';

import {
  CARD_FEES,
  CARD_LEVELS,
  CARD_LEVELS_TRACKING,
  CUSTOMIZABLE_CARDS_LEVELS,
  FORMAT_PRICE_OPTIONS,
  PLUS_CARD_WITHDRAWALS_INCLUDED,
} from 'qonto/constants/cards';
import { ORIGIN } from 'qonto/routes/flows/setup/cards/upsell';
import { getMonthlyCost, getUpsellFreeUntil } from 'qonto/utils/cards/card-costs';
import {
  getAtmMonthlyLimitsMaximum,
  getExtendedPaymentMonthlyLimitsMaximum,
} from 'qonto/utils/cards/card-limits';
import { getCardShortName } from 'qonto/utils/cards/card-names';

export default class FlowsCardsUpsell extends Component {
  @service intl;
  @service segment;

  @tracked selectedUpsellLevel = this.isCurrentCardStandard ? CARD_LEVELS.PLUS : CARD_LEVELS.METAL;

  @reads('args.context.cardsLimits') cardsLimits;
  @reads('args.context.card.cardLevel') currentCardLevel;

  // These benefits depend on only the viewed upsell level (switch shows Plus or X)
  upsellLevelBenefits = {
    plus: [
      this.intl.t('cards.steps.upsell.features.free-withdrawals.plus', {
        number: PLUS_CARD_WITHDRAWALS_INCLUDED,
      }),
      this.intl.t('cards.steps.upsell.features.fx-rates.plus', {
        percentage: this.intl.formatNumber(CARD_FEES.plus.foreignFees, { style: 'percent' }),
      }),
      this.intl.t('cards.steps.upsell.features.print-type.plus'),
      this.intl.t('cards.steps.upsell.features.insurance.plus'),
      this.intl.t('cards.steps.upsell.features.free-delivery'),
    ],
    metal: [
      this.intl.t('cards.steps.upsell.features.free-withdrawals.metal'),
      this.intl.t('cards.steps.upsell.features.fx-rates.metal'),
      this.intl.t('cards.steps.upsell.features.print-type.metal'),
      this.intl.t('cards.steps.upsell.features.insurance.metal'),
      this.intl.t('cards.steps.upsell.features.free-delivery'),
    ],
  };

  get oldCardName() {
    return getCardShortName(this.currentCardLevel, this.intl);
  }

  get paymentBenefit() {
    let { amount, multiple, oldCardName } = this._getBenefitsIntlOptions(true);

    if (this.isCurrentCardStandard) {
      let textContent;
      switch (this.args.context.origin) {
        case ORIGIN.PAYMENT_LIMITS:
          textContent = this.intl.t(
            'cards.steps.upsell.features.payment.metal-or-plus.plus.from-payment-limits',
            { amount, multiple, oldCardName }
          );
          break;
        case ORIGIN.WITHDRAWAL_LIMITS:
          textContent = this.intl.t(
            'cards.steps.upsell.features.payment.metal-or-plus.plus.from-withdrawal-limits',
            { amount, multiple }
          );
          break;
        default:
          textContent = this.intl.t(
            'cards.steps.upsell.features.payment.metal-or-plus.plus.from-promotional',
            { amount, multiple, oldCardName }
          );
          break;
      }
      return {
        plus: textContent,
        metal: this.intl.t('cards.steps.upsell.features.payment.metal-or-plus.metal', { amount }),
      };
    } else {
      let textContent;
      switch (this.args.context.origin) {
        case ORIGIN.PAYMENT_LIMITS:
          textContent = this.intl.t(
            'cards.steps.upsell.features.payment.metal-only.from-payment-limits',
            { amount, multiple, oldCardName }
          );
          break;
        case ORIGIN.WITHDRAWAL_LIMITS:
          textContent = this.intl.t(
            'cards.steps.upsell.features.payment.metal-only.from-withdrawal-limits',
            { amount, multiple }
          );
          break;
        default:
          textContent = this.intl.t(
            'cards.steps.upsell.features.payment.metal-only.from-promotional',
            { amount, multiple, oldCardName }
          );
          break;
      }
      return { metal: textContent };
    }
  }

  get withdrawalBenefit() {
    let { amount, multiple, oldCardName } = this._getBenefitsIntlOptions(false);

    if (this.isCurrentCardStandard) {
      let textContent;
      switch (this.args.context.origin) {
        case ORIGIN.PAYMENT_LIMITS:
          textContent = this.intl.t(
            'cards.steps.upsell.features.withdrawal.metal-or-plus.plus.from-payment-limits',
            { amount, multiple }
          );
          break;
        case ORIGIN.WITHDRAWAL_LIMITS:
          textContent = this.intl.t(
            'cards.steps.upsell.features.withdrawal.metal-or-plus.plus.from-withdrawal-limits',
            { amount, multiple, oldCardName }
          );
          break;
        default:
          textContent = this.intl.t(
            'cards.steps.upsell.features.withdrawal.metal-or-plus.plus.from-promotional',
            { amount, multiple }
          );
          break;
      }
      return {
        plus: textContent,
        metal: this.intl.t('cards.steps.upsell.features.withdrawal.metal-or-plus.metal', {
          amount,
        }),
      };
    } else {
      let textContent;
      switch (this.args.context.origin) {
        case ORIGIN.PAYMENT_LIMITS:
          textContent = this.intl.t(
            'cards.steps.upsell.features.withdrawal.metal-only.from-payment-limits',
            { amount, multiple }
          );
          break;
        case ORIGIN.WITHDRAWAL_LIMITS:
          textContent = this.intl.t(
            'cards.steps.upsell.features.withdrawal.metal-only.from-withdrawal-limits',
            { amount, multiple, oldCardName }
          );
          break;
        default:
          textContent = this.intl.t(
            'cards.steps.upsell.features.withdrawal.metal-only.from-promotional',
            {
              amount,
              multiple,
            }
          );
          break;
      }
      return { metal: textContent };
    }
  }

  get benefits() {
    let limitsBenefits = {
      plus: [this.paymentBenefit.plus, this.withdrawalBenefit.plus],
      metal: [this.paymentBenefit.metal, this.withdrawalBenefit.metal],
    };
    if (this.args.context.origin === ORIGIN.WITHDRAWAL_LIMITS) {
      limitsBenefits.plus.reverse();
      limitsBenefits.metal.reverse();
    }

    return {
      plus: [...limitsBenefits.plus, ...this.upsellLevelBenefits.plus],
      metal: [...limitsBenefits.metal, ...this.upsellLevelBenefits.metal],
    };
  }

  get title() {
    switch (this.args.context.origin) {
      case ORIGIN.PAYMENT_LIMITS:
        return this.isCurrentCardStandard
          ? this.intl.t('cards.steps.upsell.title.metal-or-plus.from-payment-limits')
          : this.intl.t('cards.steps.upsell.title.metal-only.from-payment-limits');
      case ORIGIN.WITHDRAWAL_LIMITS:
        return this.isCurrentCardStandard
          ? this.intl.t('cards.steps.upsell.title.metal-or-plus.from-withdrawal-limits')
          : this.intl.t('cards.steps.upsell.title.metal-only.from-withdrawal-limits');
      default:
        return this.isCurrentCardStandard
          ? this.intl.t('cards.steps.upsell.title.metal-or-plus.from-promotional')
          : this.intl.t('cards.steps.upsell.title.metal-only.from-promotional');
    }
  }

  get subtitle() {
    if (!this.isCurrentCardStandard) {
      return this.intl.t('cards.steps.upsell.subtitle.metal-only');
    }

    switch (this.args.context.origin) {
      case ORIGIN.PAYMENT_LIMITS:
        return this.intl.t('cards.steps.upsell.subtitle.metal-or-plus.from-payment-limits');
      case ORIGIN.WITHDRAWAL_LIMITS:
        return this.intl.t('cards.steps.upsell.subtitle.metal-or-plus.from-withdrawal-limits');
      default:
        return this.intl.t('cards.steps.upsell.subtitle.metal-or-plus.from-promotional');
    }
  }

  get isCurrentCardStandard() {
    return this.currentCardLevel === CARD_LEVELS.STANDARD;
  }

  get monthlyCost() {
    return this._formatPrice(getMonthlyCost(this.selectedUpsellLevel, this.args.context.estimates));
  }

  get freeUntil() {
    return getUpsellFreeUntil(this.selectedUpsellLevel, this.args.context.estimates);
  }

  @action
  confirmUpsellLevel() {
    set(this.args.context, 'cardUpsellLevel', this.selectedUpsellLevel);

    // Customization options for Plus card needs to be reset
    // e.g. when choosing a Plus card, but then going back and choosing a Metal card instead
    set(this.args.context, 'cardUpsellDesign', null);
    set(this.args.context, 'cardUpsellTypeOfPrint', null);

    let shouldShowCardCustomization = CUSTOMIZABLE_CARDS_LEVELS.includes(this.selectedUpsellLevel);
    set(this.args.context, 'showCardCustomization', shouldShowCardCustomization);

    let { origin } = this.args.context;
    this.segment.track('cards_upsell_card_selected', {
      card_level_origin: CARD_LEVELS_TRACKING[this.currentCardLevel],
      card_level_upsell: CARD_LEVELS_TRACKING[this.selectedUpsellLevel],
      trigger: origin === ORIGIN.PROMOTIONAL ? '' : origin,
    });

    this.args.transitionToNext();
  }

  @action
  selectUpsellLevel(value) {
    this.selectedUpsellLevel = value;
  }

  /* "for payment" being a boolean opposed to "for withdrawal" */
  _getBenefitsIntlOptions(forPayment) {
    let currentLevelMonthlyLimit = forPayment
      ? getExtendedPaymentMonthlyLimitsMaximum(this.currentCardLevel, this.cardsLimits)
      : getAtmMonthlyLimitsMaximum(this.currentCardLevel, this.cardsLimits);
    let selectedLevelMonthlyLimit = forPayment
      ? getExtendedPaymentMonthlyLimitsMaximum(this.selectedUpsellLevel, this.cardsLimits)
      : getAtmMonthlyLimitsMaximum(this.selectedUpsellLevel, this.cardsLimits);
    return {
      amount: this._formatPrice(selectedLevelMonthlyLimit),
      multiple: this._nearestHalf(selectedLevelMonthlyLimit / currentLevelMonthlyLimit),
      oldCardName: this.oldCardName,
    };
  }

  _formatPrice(price) {
    return this.intl.formatNumber(price, FORMAT_PRICE_OPTIONS);
  }

  _nearestHalf(num) {
    return Math.round(num * 2) / 2;
  }
}
