export default {
  "wrapper": "cMR",
  "close-button": "cMQ",
  "content-wrapper": "cMe",
  "main": "cMB",
  "main-content": "cME",
  "description": "cMd",
  "mt-26": "cMI",
  "mt-62": "cMt",
  "user-prompt": "cMM",
  "user-prompt-inappropriate-warning": "cMP",
  "logo-preview": "cMl",
  "static-loader": "cMX",
  "loading-state-illustration": "cMC",
  "sticky-panel": "cMk"
};
