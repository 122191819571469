export default {
  "sidebar": "cJO",
  "top-section": "cJm",
  "title": "cJp",
  "primary-action": "cJx",
  "edit-icon": "cJw",
  "close-icon": "cJN",
  "box-wrapper": "cJv",
  "bottom-action": "cJo",
  "related-invoices": "cJn",
  "related-invoices-title": "cJi mb-16 caption-bold",
  "box": "cJA",
  "box-element": "cJY",
  "cancel-text": "cJh",
  "box-header": "cJf",
  "row": "cJK",
  "greyed-out": "cJG",
  "struck-through": "cJr",
  "share-mandate-link": "cJb"
};
