/* import __COLOCATED_TEMPLATE__ from './address.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

export default class InvitationsNewUserAddressComponent extends Component {
  @service intl;

  /** @type string | undefined */
  @tracked fiscalCodeError;

  @reads('args.invite.address.didValidate') isAddressValidationEnabled;

  saveInviteTask = dropTask(async invite => {
    this.args.invite.set('didValidate', false);
    this.args.invite.address.set('didValidate', false);

    let { validations: addressValidation } = await invite.address.validate();

    this.args.invite.set('didValidate', true);
    this.args.invite.address.set('didValidate', true);

    let fiscalCodeValid = !this.needsFiscalCode || invite.italianFiscalCode;
    if (!fiscalCodeValid) {
      this.fiscalCodeError = this.intl.t('validations.errors.blank');
    }
    if (addressValidation.isValid && fiscalCodeValid) {
      try {
        await this.args.saveInvite(invite);
      } catch (error) {
        if (error.errors.some(e => e.detail?.source?.pointer === '/italian_fiscal_code')) {
          this.fiscalCodeError = this.intl.t('validations.errors.codice-fiscale-generic', {
            htmlSafe: true,
          });
        }
      }
    }
  });

  @action
  handleSelectAddress(address, _, event) {
    this.args.invite.address.setProperties({
      firstLine: address.street,
      zipcode: address.postalCode,
      city: address.city,
      country: address.countryCode?.toUpperCase(),
    });

    // Prevent form to be submitted on address select
    // `event` is keyboard event when triggered with keyboard and power-select obj when selected with mouse: https://github.com/cibernox/ember-power-select/pull/1386
    event.preventDefault?.();
  }

  @action
  updateFiscalCode(fiscalCode) {
    this.args.invite.set('italianFiscalCode', fiscalCode);
    this.fiscalCodeError = undefined;
  }

  get needsFiscalCode() {
    return this.args.invite.organizationLegalCountry === 'IT';
  }
}
