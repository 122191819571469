/* import __COLOCATED_TEMPLATE__ from './intro.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

export default class FlowsCheckIntroComponent extends Component {
  disclaimerBlock = Disclaimer.Block;

  @service segment;
  @service store;

  handleNext = dropTask(async () => {
    this.segment.track('checks_deposit_started');

    try {
      await this.store
        .adapterFor('check')
        .getMandate(this.args.context.check.get('organization.id'));

      this.args.context.hasValidMandate = true;
    } catch (error) {
      if (error.status === 404) {
        this.args.context.hasValidMandate = false;
      }
    }

    this.args.transitionToNext();
  });
}
