/* import __COLOCATED_TEMPLATE__ from './flow-in-flow.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

export default class FlowInFlowComponent extends Component {
  @service flow;

  get flowName() {
    return this.flow.currentStep.value.flowName;
  }

  get stepId() {
    return this.flow.currentStep.value.id;
  }

  get stepComponentName() {
    let { componentName } = this.flow.currentStep.value;
    return `flows/${componentName}`;
  }

  handleAbortTask = dropTask(async () => {
    if (this.flow.isPrimaryFlowLastStep) {
      this.flow.complete();
    } else {
      await this.flow.abortTask.unlinked().perform();
    }
  });
}
