/* import __COLOCATED_TEMPLATE__ from './withdrawal-limits.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { CARD_LEVELS_TRACKING } from 'qonto/constants/cards';

export default class FlowsCardsWithdrawalLimits extends Component {
  @service segment;

  @action
  handleNext() {
    let {
      context: { card, flowTrackingOrigin, isUserCardHolder },
      transitionToNext,
    } = this.args;

    this.segment.track('cards_order_withdrawal_limits_set', {
      card_holder: isUserCardHolder ? 'self' : 'team_member',
      card_type: CARD_LEVELS_TRACKING[card.cardLevel],
      daily_withdrawal_limit: card.atmDailyLimitOption,
      withdrawal_limit: card.atmMonthlyLimit,
      origin: flowTrackingOrigin,
    });

    transitionToNext();
  }
}
