/* import __COLOCATED_TEMPLATE__ from './file-preview-modal.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class FilePreviewModalComponent extends Component {
  @action
  handleRemoveFile(model, docType, file) {
    this.args.data.remove(model, docType, file);
    this.args.close('confirm');
  }

  get fileIndex() {
    return this.args.selectedFileIndex || 0;
  }
}
