/* import __COLOCATED_TEMPLATE__ from './confirm-pin.hbs'; */
import { InvalidError } from '@ember-data/adapter/error';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask } from 'ember-concurrency';

import { CARD_LEVELS_TRACKING } from 'qonto/constants/cards';
import { CARDS_INFO } from 'qonto/constants/encryption';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class FlowsCardsConfirmPin extends Component {
  @service cardsManager;
  @service toastFlashMessages;
  @service intl;
  @service segment;
  @service sensitiveActions;
  @service sentry;
  @service organizationManager;

  constructor() {
    super(...arguments);

    this.args.context.card.pinConfirmation = null;
    this.clearInputError();
  }

  @tracked pinConfirmationError = '';

  get isPinInvalid() {
    return this.pinConfirmationError !== '';
  }

  @action
  pinConfirmationChanged(value) {
    let { card } = this.args.context;
    card.pinConfirmation = value;

    this.clearInputError();
    this.validatePin(card);
  }

  validatePin(card) {
    if (!this.isNumber(card.pinConfirmation)) {
      this.pinConfirmationError = this.intl.t('validations.errors.pin_not_a_number');
    } else if (!card.pinConfirmation || card.pinConfirmation.length < 4) {
      this.pinConfirmationError = this.intl.t('validations.errors.pin_invalid_length');
    } else if (card.pinConfirmation !== card.pin) {
      this.pinConfirmationError = this.intl.t('validations.errors.pin_do_not_match');
    }
  }

  submitPinTask = dropTask(async providerSaveCardTask => {
    let { context, transitionToNext } = this.args;
    let { card, flowTrackingOrigin, isCardUnderCreation, isOnboardingFlow, isResetPin } = context;

    this.validatePin(card);

    if (this.isPinInvalid || this.saveCardPinTask.isRunning || this.saveQcpCardPinTask.isRunning)
      return;

    if (isCardUnderCreation) {
      let payload = {
        card_type: CARD_LEVELS_TRACKING[card.cardLevel],
        origin: flowTrackingOrigin,
      };
      let eventPayload = isOnboardingFlow ? { ...payload, card_holder: 'self' } : payload;
      this.segment.track('cards_order_pin_submitted', eventPayload);

      if (isOnboardingFlow) {
        // the error is handled inside providerSaveCardTask
        await providerSaveCardTask.perform(context, transitionToNext);
        this.organizationManager.flagMembershipOnboarded();
      } else {
        transitionToNext();
      }
    } else {
      this.segment.track(
        isResetPin ? 'cards_side_panel_pin_reset_pin_chosen' : 'cards_order_cardholder_pin_chosen'
      );

      try {
        if (card.isQcp) {
          let encryptedPin = await this.cardsManager.encryptPin({
            pin: card.pin,
            plainTextAad: card.id,
            plainTextInfo: CARDS_INFO.SET_PIN,
          });

          await this.sensitiveActions.runTask.perform(this.saveQcpCardPinTask, {
            card,
            encryptedPin,
            isResetPin,
          });
        } else {
          await this.sensitiveActions.runTask.perform(this.saveCardPinTask, { card, isResetPin });
        }
      } catch (error) {
        this.#handlePinError(error);
      }
    }
  });

  saveCardPinTask = dropTask(async ({ card, isResetPin }) => {
    try {
      await card.setPin(card.pinSet);
      this.#handleNext(isResetPin);
    } catch (error) {
      // errors are not thrown above sensitiveActions.runTask.perform()
      this.#handlePinError(error);
    }
  });

  saveQcpCardPinTask = dropTask(async ({ card, encryptedPin, isResetPin }) => {
    try {
      await this.cardsManager.saveEncryptedPinTask.perform({
        card,
        encryptedPin,
        isResetPin,
      });
      this.#handleNext(isResetPin);
    } catch (error) {
      // errors are not thrown above sensitiveActions.runTask.perform()
      this.#handlePinError(error);
    }
  });

  isNumber(pin) {
    return !pin || /^[0-9]+$/.test(pin);
  }

  clearInputError() {
    this.pinConfirmationError = '';
  }

  #handleNext(isResetPin) {
    this.segment.track(
      isResetPin
        ? 'cards_side_panel_pin_reset_pin_submitted'
        : 'cards_order_cardholder_pin_submitted'
    );
    this.args.transitionToNext();
  }

  #handlePinError(error) {
    if (hasMFAError(error?.errors)) {
      throw error;
    }

    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry) {
      this.sentry.captureException(error);
    }

    if (error instanceof InvalidError) {
      this.args.context.pinResetInProgress = true;
      this.args.transitionToNext();
    } else {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  }
}
