export default {
  "step-container": "cZU",
  "flex-table": "cZj",
  "flex-row": "cZW",
  "flex-cell": "cZu",
  "with-border": "cRS",
  "with-border-top": "cRc",
  "secondary": "cRq",
  "flex-info": "cRZ",
  "amount": "cRR",
  "legal-name": "cRQ"
};
