/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { CLAIM_TYPES } from 'qonto/constants/transactions';

export default class FlowsCardChargebackSuccessComponent extends Component {
  @service abilities;
  @service flowLinkManager;
  @service intl;
  @service router;
  @service segment;

  get isFraudClaim() {
    return this.args.context.claimType === CLAIM_TYPES.fraud;
  }

  get title() {
    if (this.isFraudClaim) {
      return this.intl.t('dispute-flow.success.fraud.title');
    }
    return this.intl.t('dispute-flow.success.default.title');
  }

  get subtitle() {
    if (this.isFraudClaim) {
      return this.intl.t('dispute-flow.success.fraud.subtitle');
    }
    return this.intl.t('dispute-flow.success.default.subtitle');
  }

  get shouldShowSecondaryCta() {
    let hasEnoughPermissions =
      this.abilities.can('create card') || this.abilities.can('create card request');

    return this.isFraudClaim && hasEnoughPermissions;
  }

  get secondaryCta() {
    if (this.abilities.can('create card'))
      return this.intl.t('dispute-flow.success.fraud.create-card');

    return this.intl.t('dispute-flow.success.fraud.request-card');
  }

  @action
  onSuccess() {
    this.segment.track('cards-chargeback_dispute-in-review-screen_closed', {
      type: this.args.context.claimType,
    });
    this.args.transitionToNext();
  }

  @action
  onCreateCard() {
    this.args.transitionToNext();

    if (this.abilities.can('create card')) {
      this.segment.track('cards-chargeback_create-new-virtual-card_clicked');

      this.flowLinkManager.transitionTo({
        name: 'card-new-digital',
        stepId: 'choose-card',
      });
    } else {
      this.segment.track('cards-chargeback_request-new-virtual-card_clicked');

      this.router.transitionTo('requests.landing', { queryParams: { origin: 'cards' } });
    }
  }
}
