/* import __COLOCATED_TEMPLATE__ from './validity-period.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { parseDate } from '@internationalized/date';
import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { DatePicker } from '@repo/design-system-kit';
import dayjs from 'dayjs';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';

export default class FlowsRequestsCardsValidityPeriodComponent extends Component {
  @service intl;
  @service segment;

  @tracked periodKey = 'tomorrow';
  @tracked customDate = null;

  datePicker = DatePicker;

  constructor() {
    super(...arguments);

    this.trackRequestCreationValidityDisplayed();
  }

  get options() {
    return [
      {
        value: 'tomorrow',
        label: this.intl.t('requests.cards.steps.validity-period.chip.tomorrow'),
      },
      { value: 'week', label: this.intl.t('requests.cards.steps.validity-period.chip.one-week') },
      {
        value: '2-weeks',
        label: this.intl.t('requests.cards.steps.validity-period.chip.two-weeks'),
      },
      { value: 'month', label: this.intl.t('requests.cards.steps.validity-period.chip.one-month') },
    ];
  }

  get tomorrow() {
    return parseDate(dayjs().add(1, 'day').format(DATE_PICKER_FIELD_FORMAT));
  }

  get week() {
    return parseDate(dayjs().add(1, 'week').format(DATE_PICKER_FIELD_FORMAT));
  }

  get twoWeeks() {
    return parseDate(dayjs().add(2, 'week').format(DATE_PICKER_FIELD_FORMAT));
  }

  get month() {
    return parseDate(dayjs().add(1, 'day').add(1, 'month').format(DATE_PICKER_FIELD_FORMAT));
  }

  get furthestDate() {
    return parseDate(dayjs().add(1, 'years').format(DATE_PICKER_FIELD_FORMAT));
  }

  get selectedPeriodValue() {
    let periods = {
      tomorrow: this.tomorrow,
      week: this.week,
      '2-weeks': this.twoWeeks,
      month: this.month,
    };

    return periods[this.periodKey] || this.customDate;
  }

  @action
  trackRequestCreationValidityDisplayed() {
    let str = `${this.args.context.cardLevel} card`;
    this.segment.track('request_creation_validity_displayed', { request_type: str });
  }

  @action
  setCustomDate(newDate) {
    this.updatePeriodKey(newDate?.toString());
    this.customDate = newDate;
  }

  updatePeriodKey(date) {
    let periodKeys = {
      [this.tomorrow]: 'tomorrow',
      [this.week]: 'week',
      [this.twoWeeks]: '2-weeks',
      [this.month]: 'month',
    };

    this.periodKey = periodKeys[date] || 'custom';
  }

  @action
  continue(event) {
    event.preventDefault();

    this.args.context.entity.preExpiresAt = dayjs(this.selectedPeriodValue.toString())
      .utc(true)
      .toDate();
    this.args.transitionToNext();
  }

  @action
  onChipChange(periodKey) {
    this.periodKey = periodKey;
  }

  customValidationMessages = {
    valueMissing: this.intl.t('validations.errors.presence'),
    rangeOverflow: this.intl.t('cards.steps.validity-period.datepicker.error.maximum-date'),
    rangeUnderflow: this.intl.t('cards.steps.validity-period.datepicker.error.past-date', {
      today: dateToken({
        date: this.tomorrow.toDate(),
        locale: this.intl.primaryLocale,
        token: DATE_FORMAT_TOKENS.COMPACT,
      }),
    }),
  };
}
