/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { F24_STATUS } from 'qonto/constants/f24';

export default class F24V2TableItemComponent extends Component {
  @service intl;

  status = {
    [F24_STATUS.SCHEDULED]: this.intl.t('f24.status.scheduled'),
    [F24_STATUS.COMPLETED]: this.intl.t('f24.status.completed'),
    [F24_STATUS.DECLINED]: this.intl.t('f24.status.declined'),
    [F24_STATUS.CANCELED]: this.intl.t('f24.status.canceled'),
  };

  get isTerminatedPayment() {
    return this.args.item.status === 'declined' || this.args.item.status === 'canceled';
  }
}
