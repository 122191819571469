export default {
  "row": "ht",
  "cell": "hM",
  "cell-content": "hP",
  "supplier": "hl",
  "next-installment": "hX",
  "installment-amount": "hC",
  "total-repayment": "hk",
  "cell-details": "hJ",
  "align-right": "hs",
  "empty": "hF"
};
