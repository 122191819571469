/* import __COLOCATED_TEMPLATE__ from './set-budget.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { EXERCISE_KINDS } from 'qonto/constants/budget';

export default class FlowsBudgetsSetBudget extends Component {
  @service store;
  @service segment;

  constructor() {
    super(...arguments);

    this.budget = this._createBudget(this.args.context.team);
    this.exercise = this.budget.exercises.at(0);
    this.exercise.initPeriods();
    this.segment.track('budget-creation_set-budget_displayed');
  }

  _createBudget(assignedTo) {
    let params = {
      name: assignedTo.name,
      exerciseKind: EXERCISE_KINDS.MONTHLY,
      assignedTo,
    };
    if (this.args.context.supervisors) {
      params.supervisorIds = this.args.context.supervisors;
    }
    let budget = this.store.createRecord('budget', params);
    this.store.createRecord('exercise', {
      budget,
      startDate: this.args.context.selectedStartDate,
      endDate: this.args.context.selectedEndDate,
    });
    return budget;
  }

  submitTask = dropTask(async () => {
    await this.budget.save();
    this.segment.track('budget-creation_budget-confirmation_clicked');
    this.args.context.budget_id = this.budget.id;
    this.args.transitionToNext();
  });
}
