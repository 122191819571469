export default {
  "row": "nw",
  "row-active": "nN",
  "cell": "nv body-2",
  "terminated": "no",
  "amount": "nn body-1",
  "item-name-cell": "ni",
  "status-icon": "nA",
  "item-name-container": "nY",
  "item-info": "nh",
  "name": "nf body-1",
  "status-displayed": "nK caption"
};
