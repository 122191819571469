/* import __COLOCATED_TEMPLATE__ from './confirm-unrevoke-member.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer, LottiePlayer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { PRICE_PLAN_LINEUPS } from 'qonto/constants/price-plan';

export default class ConfirmUnrevokeMemberModal extends Component {
  disclaimerBlock = Disclaimer.Block;

  lottiePlayer = LottiePlayer;

  @service errors;
  @service segment;
  @service flowLinkManager;
  @service sensitiveActions;

  confirmTask = dropTask(async () => {
    let { confirmTask, membership } = this.args.data;

    await confirmTask.linked().perform(membership);
    this.args.close();
  });

  get showUnrevokeManagerDisclaimer() {
    let { membership } = this.args.data;
    let isManager = Boolean(membership.customPermissions);
    if (!isManager) {
      return false;
    }

    return !membership.hasActiveAllowedBankAccounts;
  }

  @action
  confirm() {
    this.sensitiveActions.runTask.perform(this.confirmTask).catch(error => {
      this.errors.handleError(error);
    });
  }

  @action
  reactivateMember() {
    this.segment.track('upsell_reactivate-paid-member_clicked', {
      price_plan: this.args.data.pricePlanCode,
    });

    this.args.close();

    this.flowLinkManager.transitionTo({
      name: 'subscription-change',
      stepId: 'plans',
      queryParams: { lineup: PRICE_PLAN_LINEUPS.TEAMS },
    });
  }
}
