export default {
  "container": "fT",
  "numbering": "fz",
  "sideview": "fa",
  "form": "fH",
  "numbering-fields": "fO",
  "row": "fm",
  "label-tooltip": "fp",
  "tooltip-icon": "fx",
  "legend": "fw",
  "next-invoice-number": "fN",
  "pdf-preview": "fv",
  "de-layout": "fo",
  "de-pdf-preview": "fn"
};
