export default {
  "container": "Kh",
  "container-content": "Kf",
  "content": "KK",
  "underline": "KG",
  "inline-content": "Kr",
  "payment-rate": "Kb",
  "payment-subtitle": "Ky",
  "grey-text": "KU",
  "earnings-header": "Kj",
  "earnings-tooltip": "KW"
};
