/* import __COLOCATED_TEMPLATE__ from './initial.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';

import { NextStep } from 'qonto/react/components/flows/invoices/next-step';

export default class FlowsInvoicesClientsImportInitial extends Component {
  @service intl;
  @service segment;

  @reads('args.context') context;

  nextStepComponent = NextStep;

  goToStep = stepId => {
    this.args.context.nextStepId = stepId;

    if (stepId === 'upload') {
      this.segment.track('client-import_manual-flow_selected');
    } else {
      this.segment.track('client-import_connect-flow_selected');
    }

    this.args.transitionToNext();
  };
}
