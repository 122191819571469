/* import __COLOCATED_TEMPLATE__ from './dummy-data.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class EmptyStatesReimbursementsDummyDataComponent extends Component {
  @service subscriptionManager;
  sortBy = 'created_at:desc';
  noop() {}
  get hasFilter() {
    return this.subscriptionManager.hasFeature('team_management');
  }
}
