/* import __COLOCATED_TEMPLATE__ from './invoice-expired.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class FlowsTransfersSepaPayLaterErrorsInvoiceExpiredComponent extends Component {
  @service payByInvoiceUploadManager;

  @action
  handleRestart() {
    this.payByInvoiceUploadManager.resetState();
    this.args.transitionToFlow({
      flowName: 'pay-later-transfer',
      stepId: 'invoice-upload',
      queryParams: {
        invoiceId: null,
        reset: true,
      },
    });
  }
}
