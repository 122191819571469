/* import __COLOCATED_TEMPLATE__ from './card-selection.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import dayjs from 'dayjs';

import { CARD_LEVELS, CARD_NAMES } from 'qonto/constants/cards';

export default class FlowsCardsCardSelection extends Component {
  @service segment;

  cardNames = CARD_NAMES;

  get cards() {
    let { card, cards } = this.args.context;

    // !context.cards is important because it's the property that allows us to know
    // if context.card was set during the flow set up (only one card available for upsell)
    // or if it was set in this very step then the user did a go back
    if (card && !cards) return [card];

    // sort cards by Plus first
    // then for a same card level: older card first
    return cards.sort((a, b) => {
      if (a.cardLevel !== b.cardLevel) {
        return a.cardLevel === CARD_LEVELS.PLUS ? -1 : 1;
      } else {
        return dayjs(a.createdAt).isBefore(b.createdAt) ? -1 : 1;
      }
    });
  }

  @action
  onCardSelection(card) {
    this.args.context.card = card;
    this.segment.track('cards_upsell_card_selection_complete');
    this.args.transitionToNext();
  }
}
