/* import __COLOCATED_TEMPLATE__ from './confirm-remove.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';

import Component from '@glimmer/component';
import { Button, Disclaimer } from '@repo/design-system-kit';
import { CheckoutHeader, SubscriptionHeader } from '@repo/domain-kit/pricing';

// @ts-ignore
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask } from 'ember-concurrency';
import { Addons } from 'qonto/constants/addons';
import { apiBaseURL, billingNamespace } from 'qonto/constants/hosts';
import type NetworkManager from 'qonto/services/network-manager';
import type OrganizationManager from 'qonto/services/organization-manager';
import type SubscriptionManagerService from 'qonto/services/subscription-manager';
import { type ContextArg } from './dataContext.type';
// @ts-ignore
import { ErrorInfo } from 'qonto/utils/error-info';
// @ts-ignore
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class FlowsAddonChangeConfirmRemoveComponent extends Component<ContextArg> {
  @service intl: any;
  @service subscriptionManager!: SubscriptionManagerService;
  @service organizationManager!: OrganizationManager;
  @service networkManager!: NetworkManager;
  @service sensitiveActions!: any;
  @service sentry!: any;
  @service toastFlashMessages!: any;
  @service segment!: any;

  subscriptionHeader = SubscriptionHeader;
  checkoutHeader = CheckoutHeader;
  button = Button;
  disclaimer = Disclaimer.Block;

  constructor() {
    // @ts-ignore
    super(...arguments);
    this.trackEventOnDisplay();
  }

  get showNoRefundDisclaimer() {
    let { hasArAddonForFree } = this.organizationManager.organization;
    let { code } = this.args.context.targetAddon;
    let { hasInitialTrial } = this.subscriptionManager.currentSubscription;

    if (hasArAddonForFree && code === Addons.AccountsReceivable) {
      return false;
    }

    return !hasInitialTrial;
  }

  trackEventOnDisplay() {
    let { tracking } = this.args.context;
    this.segment.track('checkout_initialized');
    this.subscriptionManager.setTrackingUserProperties();
    this.segment.track('checkout_displayed_success', {
      current_plan: tracking.current_plan,
      target_plan: tracking.target_plan,
      trial_state: tracking.trial_state,
      insufficient_funds: false,
      target_plan_recurrence: null,
      target_addon_code: tracking.addon_code,
      target_addon_recurrence: tracking.addon_recurrence,
    });
  }

  @action
  onConfirm() {
    this.segment.track('checkout_confirmation_cta_clicked', this.args.context.tracking);
    this.sensitiveActions.runTask.perform(this.updateSubscriptionTask).catch(ignoreCancelation);
  }

  updateSubscriptionTask = dropTask(async () => {
    let subscriptionProduct = this.subscriptionManager.getProduct(
      this.args.context.targetAddon.code
    );

    try {
      await this.networkManager.request(
        `${apiBaseURL}/${billingNamespace}/subscriptions/${subscriptionProduct.id}`,
        {
          method: 'PATCH',
          data: {
            active: false,
          },
        }
      );
      await this.subscriptionManager.refresh();
      this.args.transitionToNext();
    } catch (error) {
      if (hasMFAError((error as { errors?: any })?.errors)) {
        throw error;
      }

      this.segment.track('checkout_confirmation_error');

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      let errorMessage = this.intl.t('toasts.errors.generic');
      this.toastFlashMessages.toastError(errorMessage);
    }
  });
}
