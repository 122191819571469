/* import __COLOCATED_TEMPLATE__ from './amount-field.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class AmountField extends Component {
  get updateFunction() {
    // fix to avoid the focusout event to be called without an update function in ember-amount-input
    // as it would still try to call the update function: "this.args.update is not a function"
    return typeof this.args.onUpdate === 'function' ? this.args.onUpdate : this.defaultUpdate;
  }

  @action
  defaultUpdate(value) {
    return value;
  }
}
