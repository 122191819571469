/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Badge } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class FlowsMemberRoleSelectionItemComponent extends Component {
  badgeHighlight = Badge;

  @service memberManager;

  @action onClick() {
    this.onClickTask.perform().catch(ignoreCancelation);
  }

  onClickTask = dropTask(async () => {
    let { context, transitionToNext, role, pushFlow } = this.args;
    await this.memberManager.handleSelectRoleTask
      .unlinked()
      .perform(context, role, transitionToNext, pushFlow);
  });
}
