/* import __COLOCATED_TEMPLATE__ from './request-virtual-card-kyc-intro.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class FlowsRequestCardsVirtualCardKycIntro extends Component {
  @action
  handleTransitionToNext() {
    this.args.pushForwardFlow('management-kyc', 'user-info');
  }
}
