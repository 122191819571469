export default {
  "item": "cgg",
  "selected": "cgD",
  "hover-revised": "cgV",
  "asset-container": "cgL",
  "asset": "cgT",
  "revoked-icon": "cgz",
  "pending-icon": "cga",
  "revoked": "cgH",
  "revised-revoke": "cgO",
  "pending": "cgm",
  "revised-pending": "cgp",
  "invitable": "cgx",
  "info": "cgw",
  "name": "cgN",
  "email": "cgv",
  "actions": "cgo",
  "invitable-member-delete": "cgn",
  "invitable-member-invite": "cgi"
};
