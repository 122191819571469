import { run } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { isTesting, macroCondition } from '@embroider/macros';
import { dropTask, task, waitForProperty, waitForQueue } from 'ember-concurrency';

import ENV from 'qonto/config/environment';
import { ErrorInfo } from 'qonto/utils/error-info';

const CULTURES = {
  fr: 'fr_FR',
  sv: 'sv_SE',
  de: 'de_DE',
  zh: 'zh_CN',
  da: 'da_DK',
  nl: 'nl_NL',
  es: 'es_ES',
  pt: 'pt_BR',
  pl: 'pl_PL',
};

const FOREVER = new Promise(() => {});

export default class HellosignDocument extends Component {
  @service sentry;
  @service intl;
  @service localeManager;
  @service toastFlashMessages;

  async loadHelloSign() {
    try {
      return (await import('hellosign-embedded/umd/embedded.production.min')).default;
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
  }

  @tracked HelloSign;

  constructor() {
    super(...arguments);
    /* eslint-disable ember-concurrency/no-perform-without-catch */
    this.setupTask.perform(); // Errors are handled in the task
    this.openTask.perform(); // Task will never fail and should be close when the component is destroyed
    /* eslint-enable ember-concurrency/no-perform-without-catch */
  }

  setupTask = dropTask(async () => {
    if (macroCondition(isTesting())) {
      class FakeHelloSign {
        constructor() {
          this._listeners = new Map();
        }

        init() {}

        open() {
          setTimeout(() => this._sendMessage('sign'), 0);
        }

        close() {}

        on(type, callback) {
          this._listeners.set(type, callback);
        }

        _sendMessage(event) {
          this._listeners.get(event)();
        }
      }

      this.HelloSign = FakeHelloSign;
    } else {
      this.HelloSign = await this.loadHelloSign();
    }
  });

  openTask = task(async () => {
    await waitForQueue('afterRender');
    await waitForProperty(this, 'args.url');

    // Will place HS container in the div locally and on test ENV once the testing strategy is decided
    // const hsOptions = ['development', 'test'].includes(ENV.environment)
    //   ? {container: document.querySelector('.l-block')}
    //   : {}

    await waitForProperty(this, 'HelloSign');

    let { HelloSign } = this;
    let { url, contractType } = this.args;
    let { clientID, skipDomainVerification, debug } = ENV.helloSign[contractType];

    let locale = CULTURES[this.localeManager.locale] || 'en_US';

    let helloSign = new HelloSign({ clientId: clientID });

    helloSign.open(url, { allowCancel: true, debug, skipDomainVerification, locale });

    helloSign.on('sign', () => {
      helloSign.close();
      run(() => this.args.onSigned());
    });
    helloSign.on('decline', () => run(() => this.args.onDeclined()));
    helloSign.on('cancel', () => run(() => this.args.onCanceled()));
    helloSign.on('error', () => run(() => this.args.onError()));

    try {
      // we will wait "forever" here, which means that the `finally` block
      // gets executed once the component is destroyed.
      await FOREVER;
    } finally {
      helloSign.close();
    }
  });
}
