export default {
  "voucher-wrapper": "cEh",
  "left-side": "cEf",
  "left-side__wrapper": "cEK",
  "voucher-illustration": "cEG",
  "left-side__wrapper-title": "cEr",
  "voucher-list": "cEb",
  "left-side__wrapper-details": "cEy",
  "right-side": "cEU",
  "multi-accounts": "cEj",
  "right-side__wrapper": "cEW"
};
