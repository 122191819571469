/* import __COLOCATED_TEMPLATE__ from './vehicle-details.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Checkbox } from '@repo/design-system-kit';

export default class VehicleDetailsComponent extends Component {
  checkbox = Checkbox;

  @service intl;
  @service segment;
  @service toastFlashMessages;
  @service organizationManager;
  @service abilities;

  @tracked selectedType = this.args.context.requestMileage.vehicle?.type;
  @tracked selectedPower = this.args.context.requestMileage.vehicle?.power;
  @tracked isVehicleElectric = this.args.context.requestMileage.vehicle?.electric;

  get selectedVehiclePowerOptions() {
    return this.args.context.vehicles[this.selectedType]?.powerOptions;
  }

  get shouldDisplayElectricVehicleCheckbox() {
    return this.organizationManager.organization?.isFrench;
  }

  @action
  setVehicleType(value) {
    this.selectedType = value;

    // Reset the power value when switching vehicle type
    this.selectedPower = null;
    this.args.context.requestMileage.vehicle.power = null;

    this.args.context.requestMileage.vehicle ||= {};
    this.args.context.requestMileage.vehicle.type = value;
    if (this.selectedType === 'scooter') {
      // we delete the property `power` here because
      // backend doesn't accept it for scooters
      delete this.args.context.requestMileage.vehicle.power;
    }
  }

  @action
  setFiscalPower(value) {
    this.selectedPower = value;
    this.args.context.requestMileage.vehicle.power = value;
  }

  @action
  toggleIsElectricVehicle() {
    this.isVehicleElectric = !this.isVehicleElectric;
  }

  @action
  transitionToNext() {
    this.args.context.requestMileage.vehicle.electric = this.isVehicleElectric;

    this.segment.track('request_vehicle_selected', {
      origin: 'mileage_request',
    });

    if (this.args.context.shouldGoToVehicleDetailsStep) {
      // We set this to false here because we don't want to go to the `vehicle-details` in this case
      // user should be redirect back to `request-details` step
      this.args.context.shouldGoToVehicleDetailsStep = false;
      this.toastFlashMessages.toastSuccess(
        this.intl.t('requests.mileage.steps.request-details.success.vehicle-edited')
      );
    }

    this.args.transitionToNext();
  }
}
