export default {
  "container": "cCE",
  "header": "cCd",
  "content": "cCI",
  "info-list": "cCt",
  "info-label": "cCM body-2",
  "info-value": "cCP body-2",
  "provider": "cCl",
  "policy-container": "cCX",
  "policy-label": "cCC body-2",
  "policy-number": "cCk body-2",
  "copy-to-clipboard": "cCJ",
  "button": "cCs btn btn--primary btn--stretch btn--large"
};
