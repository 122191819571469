/* import __COLOCATED_TEMPLATE__ from './choose-card.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import {
  CARD_FEES,
  CARD_LEVELS,
  CARD_TYPES,
  CARD_WITHDRAW_FEE,
  PLUS_CARD_WITHDRAWALS_INCLUDED,
} from 'qonto/constants/cards';
import { Recycled } from 'qonto/react/components/badge/recycled';

export default class FlowsCardsChooseCard extends Component {
  Recycled = Recycled;

  @service deviceManager;

  guid = guidFor(this);

  GUIDS = {
    [CARD_LEVELS.STANDARD]: `${this.guid}-${CARD_LEVELS.STANDARD}`,
    [CARD_LEVELS.PLUS]: `${this.guid}-${CARD_LEVELS.PLUS}`,
    [CARD_LEVELS.METAL]: `${this.guid}-${CARD_LEVELS.METAL}`,
  };

  CARD_FEES = CARD_FEES;
  CARD_LEVELS = CARD_LEVELS;
  PLUS_CARD_WITHDRAWALS_INCLUDED = PLUS_CARD_WITHDRAWALS_INCLUDED;
  CARD_WITHDRAW_FEE = CARD_WITHDRAW_FEE;

  @tracked video;
  @tracked isVideoPlaying = null;
  @tracked isStandardVideoPlayingOnError = false;
  @tracked isPlusVideoPlayingOnError = false;
  @tracked isMetalVideoPlayingOnError = false;
  @tracked isStandardLoaded = false;
  @tracked isPlusLoaded = false;
  @tracked isMetalLoaded = false;

  @reads('args.context.estimates') estimates;
  @reads('args.context.cardsLimits') cardsMaxLimits;
  @reads('args.context.cardOptionsPrices') cardOptionsPrices;

  get isReadyToAnimate() {
    // For Safari, videos are not loaded but animations are still played
    if (this.deviceManager.isSafariDesktop) {
      return true;
    }

    return this.isStandardLoaded && this.isPlusLoaded && this.isMetalLoaded;
  }

  get hasAnyCardDiscount() {
    return CARD_TYPES.PHYSICALS.some(cardLevel =>
      this._isDiscountApplied(this.estimates[cardLevel])
    );
  }

  @action
  setIsLoaded(cardLevel) {
    if (cardLevel === CARD_LEVELS.STANDARD) {
      this.isStandardLoaded = true;
    }

    if (cardLevel === CARD_LEVELS.PLUS) {
      this.isPlusLoaded = true;
    }

    if (cardLevel === CARD_LEVELS.METAL) {
      this.isMetalLoaded = true;
    }
  }

  handleSelectorMouseenter = dropTask(async cardLevel => {
    let id = this.GUIDS[cardLevel];
    this.video = document.getElementById(id);

    if (this.video?.paused) {
      try {
        await this.video.play();
        this.isVideoPlaying = true;
      } catch {
        switch (cardLevel) {
          case CARD_LEVELS.STANDARD:
            this.isStandardVideoPlayingOnError = true;
            break;
          case CARD_LEVELS.PLUS:
            this.isPlusVideoPlayingOnError = true;
            break;
          case CARD_LEVELS.METAL:
            this.isMetalVideoPlayingOnError = true;
            break;
        }
      }
    }
  });

  @action
  handleSelectorMouseleave() {
    //!\ We need to ensure the video is playing before pausing it
    // https://developer.chrome.com/blog/play-request-was-interrupted/
    if (this.isVideoPlaying) {
      this.video.pause();
      this.video.currentTime = 0;
      this.isVideoPlaying = false;
    }
  }

  _isDiscountApplied(estimate) {
    return (
      estimate.discount_finish_date && estimate.monthly_cost !== estimate.discounted_monthly_cost
    );
  }
}
