/* import __COLOCATED_TEMPLATE__ from './italian-province-selector.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';

import italianProvinces from 'qonto/constants/it-provinces';

export default class ItalianProvinceSelector extends Component {
  @service intl;

  @cached
  get provinceOptions() {
    return italianProvinces.map(key => ({
      key,
      label: `${key} - ${this.intl.t(`italy.provinces.${key.toLowerCase()}`)}`,
    }));
  }

  get selectedProvinceOption() {
    let { provinceCode } = this.args.eInvoiceActivation;

    return this.provinceOptions.find(({ key }) => key === provinceCode);
  }

  @action
  handleProvinceChange(provinceObject) {
    this.args.eInvoiceActivation.provinceCode = provinceObject?.key;
  }
}
