/* import __COLOCATED_TEMPLATE__ from './trigger.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { LottiePlayer } from '@repo/design-system-kit';
import window from 'ember-window-mock';

import { BUCKET_KEY } from 'qonto/constants/in-context-integrations';

export default class InContextIntegrationsTrigger extends Component {
  lottiePlayer = LottiePlayer;

  @service segment;
  @service abilities;

  @tracked isSideDrawerRendered = Boolean(window.sessionStorage.getItem(BUCKET_KEY));
  @tracked isSideDrawerVisible = Boolean(window.sessionStorage.getItem(BUCKET_KEY));

  get canAccessConnect() {
    return this.abilities.can('navigate connect');
  }

  get tooltipPosition() {
    return this.args.tooltipPosition ?? 'bottom center';
  }

  @action
  hideSideDrawer() {
    this.isSideDrawerVisible = false;
    window.sessionStorage.removeItem(BUCKET_KEY);
    this.segment.track('ICI_sidedrawer_closed', { page: this.args.sideDrawerId });
    this.args.onHide?.();
  }

  @action
  displaySideDrawer() {
    this.isSideDrawerRendered = true;
    setTimeout(() => {
      this.isSideDrawerVisible = true;
      this.segment.track('ICI_trigger_clicked', { page: this.args.sideDrawerId });
    }, 0);
    this.args.onDisplay?.();
  }
}
