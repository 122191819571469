/* import __COLOCATED_TEMPLATE__ from './request-flash-card-kyc-intro.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class FlowsCardsKycFlashCardIntro extends Component {
  @service flowLinkManager;

  @action
  handleTransitionToNext() {
    this.args.pushForwardFlow('management-kyc', 'user-info');
  }
}
