/* import __COLOCATED_TEMPLATE__ from './ics-field.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { FR_CREDITORS_CODE } from 'qonto/constants/mandates';
import { formatIcs, handleFormatIcs } from 'qonto/utils/format-input';

export default class IcsField extends Component {
  @service intl;

  @tracked displayWarning = false;
  @tracked ics = formatIcs(this.args.value, this.args.creditorCode);
  @tracked isFocused = false;

  get messageLevel() {
    if (this.displayHint) {
      return 'hint';
    } else if (this.displayWarning && this.args.warning) {
      return 'warning';
    } else if (this.displayError && this.args.error) {
      return 'error';
    }
  }

  get message() {
    if (this.displayHint) {
      return this.hint;
    } else if (this.displayWarning && this.args.warning) {
      return this.args.warning;
    } else if (this.displayError && this.args.error) {
      return this.args.error;
    }
  }

  get displayHint() {
    return !this.args.readonly && this.isFocused && !this.displayWarning;
  }

  get displayError() {
    return this.args.error && !this.displayHint;
  }

  get placeholder() {
    let { creditorCode } = this.args;
    let { DGFIP, SIE, URSSAF } = FR_CREDITORS_CODE;

    switch (creditorCode) {
      case DGFIP:
        return this.intl.t('placeholders.ics-dgfip');
      case SIE:
      case URSSAF:
        return this.intl.t('placeholders.ics-default');
      default:
        return this.intl.t('placeholders.ics-other');
    }
  }

  get hint() {
    let { creditorCode } = this.args;
    let { DGFIP, SIE, URSSAF } = FR_CREDITORS_CODE;

    switch (creditorCode) {
      case DGFIP:
      case SIE:
      case URSSAF:
        return this.intl.t('mandates.mandate-details.ics-hint.default');
      default:
        return this.intl.t('mandates.mandate-details.ics-hint.other');
    }
  }

  @action
  handleOnFocusOut(event) {
    let { value } = event.target;

    this.isFocused = false;
    this.displayWarning = Boolean(value);

    this.ics = value;
    this.args.onChange(value.replace(/[^A-Z0-9/\-?:().,'+]/g, ''));
  }

  @action
  handleOnInput(creditorCode, event) {
    this.displayWarning = false;
    this.isFocused = true;
    handleFormatIcs(event.target, creditorCode);
  }
}
