/* import __COLOCATED_TEMPLATE__ from './uploader.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { reads } from 'macro-decorators';

import { AiGlow } from 'qonto/react/components/account-receivable/summary';

export default class accountReceivableOnboardingSummaryUploaderComponent extends Component {
  @service intl;
  @service accountReceivableOnboardingUploadManager;
  @reads('accountReceivableOnboardingUploadManager.files') files;

  formattedMaxSize = formatFileSize(
    this.intl,
    this.accountReceivableOnboardingUploadManager.maxSize
  );

  constructor() {
    super(...arguments);
    this.accountReceivableOnboardingUploadManager.shouldToastOnInvalidFile = false;
  }

  get file() {
    return this.files.at(-1);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.accountReceivableOnboardingUploadManager.resetState();
  }

  AiGlow = AiGlow;
}
