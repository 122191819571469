/* import __COLOCATED_TEMPLATE__ from './intro.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

export default class FlowsKYCIntro extends Component {
  lottiePlayer = LottiePlayer;

  @service organizationManager;
  @service segment;
  @service router;

  animationReady = false;
  defaultLottieSrc = '/lotties/fourthline/intro.json';

  get originTrackingParam() {
    switch (this.args.origin) {
      case 'cards':
        return 'card_flow';
      case 'transfers':
        return 'transfer_flow';
      default:
        return null;
    }
  }

  @action
  handleNext() {
    let { personalInfoProvided } = this.organizationManager.membership;

    if (personalInfoProvided) {
      this.router.transitionTo('kyc.start', this.organizationManager.organization.slug, {
        queryParams: {
          trackingEventOrigin: this.originTrackingParam,
        },
      });
    } else {
      this.segment.track('submit_kyc_information_start', {
        origin: this.originTrackingParam,
      });

      this.args.transitionToNext();
    }
  }
}
