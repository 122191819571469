/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { AmountField, Disclaimer, TextAreaField } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class DetailsComponent extends Component {
  disclaimerInline = Disclaimer.Inline;
  textAreaField = TextAreaField;
  amountField = AmountField;

  @service abilities;
  @service organizationManager;
  @service intl;
  @service segment;
  @service toastFlashMessages;
  @service sentry;

  @tracked errors = null;
  @tracked model = this.args.context.requestExpenseReport;
  @tracked isIbanCurrentlyEmpty = !this.model.iban;

  presetIban = this.model?.iban;
  fieldsToValidate = ['amount', 'note', 'iban'];

  get selectedAttachment() {
    let { isProbated, file, probativeAttachment } = this.model.attachments.content?.[0] || {};

    if (isProbated) {
      return { type: probativeAttachment?.fileContentType, url: probativeAttachment?.fileUrl };
    }

    return { type: file?.fileContentType, url: file?.fileUrl };
  }

  async validations() {
    this.errors = [];
    let valid = true;

    this.fieldsToValidate.forEach(field => {
      let fieldContent =
        typeof this.model[field] === 'object' ? this.model[field]?.value : this.model[field];

      if (!fieldContent) {
        this.validationsTracking(field);
        this.errors = { ...this.errors, [field]: this.intl.t('validations.errors.blank') };
        valid = false;
      }
    });

    if (valid && !(await this.saveIbanTask.perform())) {
      valid = false;
    }

    return valid;
  }

  validationsTracking(field) {
    switch (field) {
      case 'amount':
        this.segment.track('reimbursement_amount_not_filled');
        break;
      case 'note':
        this.segment.track('reimbursement_note_not_filled');
        break;
      case 'iban':
        this.segment.track('reimbursement_iban_not_filled');
        break;
      default:
        break;
    }
  }

  saveExpenseReportTask = dropTask(async () => {
    let validForm = await this.validations();

    if (!validForm) return false;

    if (
      this.model.vatRate &&
      this.model.vatAmount?.value &&
      isNaN(parseFloat(this.model.vatAmount.value))
    ) {
      this.model.vatAmount.value = '0';
    }

    try {
      await this.model.save();
      if (this.abilities.can('review expense report request')) {
        this.segment.track('request_created', {
          request_type: 'expense_report',
          role: this.organizationManager.membership.role,
        });
      } else {
        this.segment.track('reimbursement_request_created');
      }

      this.args.transitionToNext();
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry && error.status !== 422) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });

  saveIbanTask = dropTask(async () => {
    let { membership } = this.organizationManager;
    let valid = true;

    try {
      membership.iban = this.model.iban;

      if (!this.presetIban) {
        await membership.createIban();
        this.presetIban = membership.iban;
        this.segment.track('reimbursement_iban-add_confirmed', {
          origin: 'reimbursement_request',
        });
      } else {
        await membership.updateIban();
        this.segment.track('reimbursement_iban-update_confirmed', {
          origin: 'reimbursement_request',
        });
      }
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry && error.status !== 422) {
        this.sentry.captureException(error);
      }

      this._handleIbanError(error);
      valid = false;
    }

    return valid;
  });

  @action
  onAmountChange(amountValue) {
    this.model.setProperties({
      amount: { ...this.model.amount, value: amountValue },
    });

    if (amountValue) {
      this.errors = { ...this.errors, amount: undefined };
    }
  }

  @action
  onNoteChange(noteValue) {
    this.model.set('note', noteValue);

    if (noteValue) {
      this.errors = { ...this.errors, note: undefined };
    }
  }

  @action
  onIbanChange(ibanValue) {
    this.model.iban = ibanValue;
    this.isIbanCurrentlyEmpty = !ibanValue;

    if (ibanValue) {
      this.errors = { ...this.errors, iban: undefined };
    }
  }

  @action
  updateVat(model, { vatRate, vatAmount }) {
    model.vatRate = vatRate;
    model.vatAmount = vatAmount;
  }

  _handleIbanError(error) {
    if (
      Array.isArray(error.errors) &&
      error.errors.some(({ detail }) =>
        ['not_sepa_iban', 'not_valid_iban', 'not_a_string', 'blank'].includes(detail.code)
      )
    ) {
      this.segment.track('reimbursement_iban_incorrect', { origin: 'reimbursement_request' });
      this.errors = {
        ...this.errors,
        iban: this.intl.t('validations.errors.invalid_iban'),
      };
    } else {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  }
}
