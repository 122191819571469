export default {
  "fieldset": "ckN",
  "subscription-period": "ckv",
  "automatic-number-section": "cko",
  "automatic-number-label": "ckn",
  "icon-tooltip": "cki",
  "date-picker": "ckA",
  "label": "ckY",
  "header-text-field": "ckh",
  "header-text-field-container": "ckf",
  "header-text-field-add-button": "ckK btn btn--tertiary",
  "header-text-field-close-button": "ckG btn btn--icon-only btn--tertiary btn--small",
  "read-only-frequency": "ckr",
  "read-only": "ckb"
};
