export default {
  "wrapper": "cqc",
  "promotion-title": "cqq",
  "comparison-table": "cqZ",
  "table-head": "cqR",
  "title-wrapper": "cqQ",
  "illustration-wrapper": "cqe",
  "illustration": "cqB",
  "role-wrapper": "cqE",
  "role-name": "cqd",
  "role-disabled": "cqI",
  "role-access": "cqt",
  "disabled-caption": "cqM",
  "table-head-sticky": "cqP",
  "show-border": "cql",
  "group-row": "cqX",
  "table-body": "cqC",
  "group": "cqk",
  "table-body-row": "cqJ",
  "feature-description": "cqs body-2",
  "feature": "cqF body-2",
  "group-spacer": "cqg"
};
