/* import __COLOCATED_TEMPLATE__ from './landing.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';

export default class FlowsMandateLandingComponent extends Component {
  @service segment;
  @service organizationManager;
  @service modals;

  @reads('organizationManager.organization.legalCountry') legalCountry;

  constructor() {
    super(...arguments);
    this.segment.track('app_sdd_creation_started');
  }

  @action
  handleNext(hasReceivedMandate) {
    this.segment.track('app_sdd_debit_type_chosen', {
      mandate_received: hasReceivedMandate,
    });
    this.args.context.hasReceivedMandate = hasReceivedMandate;
    this.args.transitionToNext();
  }

  @action
  handleOpenSddEditoModal() {
    this.modals.open('sdd-edito-modal', {
      isFullScreenModal: true,
      legalCountry: this.legalCountry,
      organizationSlug: this.organizationManager.organization.slug,
    });
  }
}
