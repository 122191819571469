export default {
  "card": "bR",
  "asset": "bQ",
  "container": "be",
  "subtitle": "bB",
  "header": "bE",
  "header-name": "bd",
  "option-list": "bI",
  "feature": "bt",
  "highlighted": "bM"
};
