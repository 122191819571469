/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { parseDate } from '@internationalized/date';
import { AmountField, DatePicker, Disclaimer } from '@repo/design-system-kit';
import dayjs from 'dayjs';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class FlowsDirectDebitCollectionsSubscriptionDetailsComponent extends Component {
  amountField = AmountField;
  datePicker = DatePicker;
  disclaimerInline = Disclaimer.Inline;

  @service directDebitCollectionsManager;
  @service organizationManager;
  @service store;
  @service sentry;
  @service segment;
  @service intl;

  @tracked isValidationEnabled = false;
  @tracked dateValidationIsInvalid = false;
  @tracked dateValidationMessage = null;
  @tracked mandates = [];

  constructor() {
    super(...arguments);

    this.directDebitCollectionsManager
      .loadMandates(this.subscription.client.id)
      .then(mandates => {
        if (mandates.length) {
          this.mandates = mandates;
        }

        if (this.args.context.useExistingMandate) {
          this.subscription.mandate = this.mandates[0];
        }
      })
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });
  }

  _parseDate(date) {
    let parsedDate = dayjs(date);
    return parsedDate.isValid() ? parseDate(parsedDate.format(DATE_PICKER_FIELD_FORMAT)) : null;
  }

  customDateValidationMessages = {
    valueMissing: this.intl.t('validations.errors.blank'),
    rangeUnderflow: this.intl.t('transfers.multi.datepicker.error.minimum-date', {
      date: this.intl.formatDate(new Date(), {
        dateStyle: 'short',
        locale: this.intl.primaryLocale === 'en' ? 'en-GB' : this.intl.primaryLocale, // ensure DMY format
      }),
    }),
  };

  get dateValue() {
    return this._parseDate(this.subscription.initialCollectionDate);
  }

  get minDate() {
    let tomorrow = new Date().setDate(new Date().getDate() + 1);
    return this._parseDate(tomorrow);
  }

  get subscription() {
    return this.args.context.directDebitSubscription;
  }

  get bankAccounts() {
    return this.organizationManager.organization.bankAccounts;
  }

  @action
  onAmountChange(amountValue) {
    this.subscription.setProperties({
      amount: { ...this.subscription.amount, value: amountValue },
    });
  }

  @action
  onUpdateInitialCollectionDate(initialCollectionDate, isInvalid) {
    this.dateValidationIsInvalid = isInvalid;
    this.subscription.initialCollectionDate = initialCollectionDate;
  }

  @action
  onUpdateFrequency({ scheduleType, scheduleCustomInterval, scheduleCustomUnit }) {
    this.subscription.setProperties({ scheduleType, scheduleCustomInterval, scheduleCustomUnit });
  }

  @action
  onUpdateDuration({ scheduleTotalCollectionCount, isCustomDuration }) {
    this.subscription.setProperties({ scheduleTotalCollectionCount, isCustomDuration });
  }

  @action
  onSelectMandate(mandate) {
    this.subscription.mandate = mandate;
    this.args.context.useExistingMandate = Boolean(mandate);
  }

  @action
  onCTAClick() {
    this.isValidationEnabled = true;

    this.subscription.validate({}, false);

    if (this.subscription.validations.isValid && !this.dateValidationIsInvalid) {
      this.args.transitionToNext();

      let { isOneOff, isRecurring, scheduleType, scheduleTotalCollectionCount } = this.subscription;

      this.segment.track('incoming-direct-debit-flow_collection-details_submitted', {
        collection_type: isOneOff ? 'one_off' : 'recurring',
        ...(isRecurring && { frequency: scheduleType }),
        ...(isRecurring && {
          duration: scheduleTotalCollectionCount ? 'number_payments' : 'no_limit',
        }),
      });
    }
  }
}
