export default {
  "container": "cXD",
  "logo": "cXV",
  "title": "cXL title-4",
  "description": "cXT body-2",
  "metadata": "cXz",
  "risks-container": "cXa",
  "label": "cXH caption",
  "risks": "cXO",
  "tag": "cXm tag"
};
