/* import __COLOCATED_TEMPLATE__ from './upload.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { buildWaiter } from '@ember/test-waiters';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { Button } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';
import { TrackedObject } from 'tracked-built-ins';

import { XlsFile } from 'qonto/lib/xls-file';
import { ErrorInfo } from 'qonto/utils/error-info';

const FILE_MAX_SIZE = 5 * 1e6; // 5MB

let waiter = buildWaiter('upload-waiter');

export default class FlowsInvoicesClientsImportUpload extends Component {
  @service intl;
  @service sentry;
  @service toastFlashMessages;
  @service segment;

  @reads('args.context') context;

  @tracked files = this.context.uploadedFiles ?? [];
  @tracked hideDropzone = true;

  button = Button;
  uploadOptions = {
    callEndpoint: false,
  };

  get dropZoneLabel() {
    return this.intl.t('clients-import.upload.dropzone.label', {
      maxSize: formatFileSize(this.intl, FILE_MAX_SIZE),
    });
  }

  onFileUploadedTask = dropTask(async fileModel => {
    let token = waiter.beginAsync();

    this.segment.track('client-import_upload_started');

    // Normalize file model to match the format expected by the component API
    fileModel.file.filename = fileModel.file.name;
    fileModel.file.filesizeBytes = fileModel.file.size;
    fileModel.file.url = fileModel.file.fileUrl;

    try {
      this.context.xlsFile = await XlsFile.parse(fileModel.file);
      this.context.uploadedFiles = [...this.files, fileModel.file];
      this.args.transitionToNext();
    } catch (error) {
      this.context.uploadedFiles = [];
      this.files = [];
      this.hideDropzone = false;
      this.context.xlsFile = null;
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    } finally {
      waiter.endAsync(token);
    }
  });

  @action
  onRemoveFile() {
    this.context.uploadedFiles = [];
    this.files = [];
    this.hideDropzone = false;
    this.context.xlsFile = null;
    this.context.mapping = new TrackedObject({});
    this.context.status.isMapped = false;
  }
}
