export default {
  "dates-filter": "ia",
  "dates-wrapper": "iH",
  "date-interval": "iO",
  "date-field-wrapper": "im",
  "dates-conditional": "ip",
  "placeholder": "ix",
  "error": "iw",
  "error-message": "iN body-2"
};
