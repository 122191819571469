/* import __COLOCATED_TEMPLATE__ from './dummy-data.hbs'; */
import Component from '@glimmer/component';

import { SearchField } from '@repo/design-system-kit';

import { COUNTERPARTIES_SORT_BYS } from 'qonto/constants/sort';

export default class EmptyStatesSuppliersListDummyDataComponent extends Component {
  searchField = SearchField;

  sortBy = `${COUNTERPARTIES_SORT_BYS.NAME}:asc`;
  noop() {}
}
