/* import __COLOCATED_TEMPLATE__ from './set-pin-intro.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class FlowsCardsKycSetPinIntro extends Component {
  @service segment;

  @action
  handleClick() {
    let { transitionToNext } = this.args;

    this.segment.track('submit_kyc_information_start', {
      origin: 'card_flow',
    });
    transitionToNext();
  }
}
