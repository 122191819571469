export default {
  "details": "cRr",
  "disclaimer": "cRb",
  "data": "cRy",
  "confirm": "cRU",
  "form": "cRj",
  "textarea": "cRW",
  "preview": "cRu",
  "form-title": "cQS title1",
  "form-subtitle": "cQc title2",
  "divider": "cQq",
  "submit": "cQZ",
  "doc": "cQR",
  "amount-input-error": "cQQ"
};
