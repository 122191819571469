/* import __COLOCATED_TEMPLATE__ from './edit.hbs'; */
import { InvalidError } from '@ember-data/adapter/error';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { task } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class MandatesEditComponent extends Component {
  @service toastFlashMessages;
  @service intl;
  @service sentry;
  @service organizationManager;
  @service sensitiveActions;
  @tracked enableMandateValidations = null;

  constructor() {
    super(...arguments);
    let organization = this.organizationManager.organization;
    this.args.mandate.setProperties({
      organization,
    });
  }

  closeModal(reset) {
    if (reset) {
      this.args.mandate.rollbackAttributes();
    }
    this.args.cancel();
  }

  @action handleCloseModal(reset) {
    this.closeModal(reset);
  }

  saveUpdatedMandateTask = task(async mandate => {
    try {
      await mandate.save();

      this.toastFlashMessages.toastSuccess(this.intl.t('toasts.mandate_updated'));

      this.closeModal(false);
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }

      let message = '';
      if (error instanceof InvalidError) {
        let { code, detail } = error.errors[0].detail;
        if (code === 'invalid_format') {
          message = this.intl.t('mandates.errors.creditor-identifier-invalid-format');
        } else if (code === 'umr_taken') {
          message = this.intl.t('mandates.errors.umr-taken');
        } else {
          message = detail;
        }
      } else {
        message = this.intl.t('toasts.mandate_failed_update');
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }

      this.toastFlashMessages.toastError(message);
    }
  });

  validationToConfirmationTask = task(async () => {
    this.enableMandateValidations = false;

    let { validations: mandateValidation } = await this.args.mandate.validate({
      on: ['creditorName', 'creditorIdentifier', 'umr'],
    });
    this.enableMandateValidations = true;

    if (mandateValidation.isValid) {
      await this.saveUpdatedMandateTask.perform(this.args.mandate);
    }
  });

  handleFormSubmitTask = task(async () => {
    await this.sensitiveActions.runTask.perform(this.validationToConfirmationTask);
  });
}
