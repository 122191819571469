/* import __COLOCATED_TEMPLATE__ from './budget.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { AmountField } from '@repo/design-system-kit';

import { CARD_LEVELS } from 'qonto/constants/cards';
import { CARDS_BOTTOM_LIMIT } from 'qonto/constants/requests';

export default class FlowsRequestsCardsBudgetComponent extends Component {
  @service intl;
  @service segment;

  @tracked errorMessage;

  amountField = AmountField;

  constructor() {
    super(...arguments);

    this.segment.track('request_creation_budget_displayed', {
      request_type: `${this.args.context.cardLevel} card`,
    });
  }

  get isFlashCard() {
    return this.args.context.cardLevel === CARD_LEVELS.FLASH;
  }

  get amount() {
    let entity = this.args.context.entity;
    return this.isFlashCard ? entity.paymentLifespanLimit : entity.paymentMonthlyLimit;
  }

  @action
  onAmountChange(amountValue) {
    this.errorMessage = this.validateAmount(amountValue);

    if (this.isFlashCard) {
      this.args.context.entity.paymentLifespanLimit = amountValue;
    } else {
      this.args.context.entity.paymentMonthlyLimit = amountValue;
    }
  }

  @action
  continue(event) {
    event.preventDefault();
    this.errorMessage = this.validateAmount(this.amount);

    if (this.errorMessage) {
      return;
    }

    this.args.transitionToNext();
  }

  validateAmount(amount) {
    let { cardMaxLimit } = this.args.context;

    if (!amount || !amount.trim()) {
      return this.intl.t('validations.errors.presence');
    }

    if (amount > cardMaxLimit) {
      let formattedAmount = this.formatTranslationAmount(cardMaxLimit);
      return this.isFlashCard
        ? this.intl.t('requests.creation.spending-limit.error.too-high', {
            amount: formattedAmount,
          })
        : this.intl.t('requests.creation.monthly-limit.error.too-high', {
            amount: formattedAmount,
          });
    }

    if (amount < CARDS_BOTTOM_LIMIT) {
      let formattedAmount = this.formatTranslationAmount(CARDS_BOTTOM_LIMIT);
      return this.isFlashCard
        ? this.intl.t('requests.creation.spending-limit.error.too-low', {
            amount: formattedAmount,
          })
        : this.intl.t('requests.creation.monthly-limit.error.too-low', {
            amount: formattedAmount,
          });
    }

    return null;
  }

  formatTranslationAmount(amount) {
    return this.intl.formatNumber(amount, {
      minimumFractionDigits: 0,
      currency: 'EUR',
      style: 'currency',
    });
  }
}
