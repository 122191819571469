export default {
  "container": "cDA",
  "close-button": "cDY",
  "content-wrapper": "cDh",
  "main": "cDf",
  "description": "cDK",
  "calculation-item": "cDG",
  "total-km": "cDr",
  "calculation-details": "cDb",
  "btn": "cDy"
};
