export default {
  "container": "csk",
  "mapped": "csJ",
  "header": "css",
  "title": "csF caption",
  "select-option-label": "csg",
  "content": "csD",
  "item": "csV body-2",
  "empty": "csL"
};
