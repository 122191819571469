/* import __COLOCATED_TEMPLATE__ from './dummy-data.hbs'; */
import Component from '@glimmer/component';

import { SearchField } from '@repo/design-system-kit';

export default class EmptyStatesOutgoingDirectDebitsDummyDataComponent extends Component {
  searchField = SearchField;

  noop() {}

  get mandate() {
    return this.args.dummyData.items[0];
  }

  get selectedMandateId() {
    return this.mandate.id;
  }
}
