/* import __COLOCATED_TEMPLATE__ from './review.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { PAGOPA_PAYMENT_RESULT_STATUS } from 'qonto/constants/pagopa';

export default class ReviewComponent extends Component {
  @service intl;
  @service segment;
  @service sentry;

  @reads('args.context.payment') payment;

  get paymentCost() {
    if (!this.payment.feeEstimateAmount || parseFloat(this.payment.feeEstimateAmount.value) === 0) {
      return undefined;
    }

    return this.intl.formatNumber(this.payment.feeEstimateAmount.value, {
      currency: this.payment.feeEstimateAmount.currency,
      style: 'currency',
    });
  }

  get paymentCostCaption() {
    return this.paymentCost
      ? this.intl.t('pagopa.payment-creation.review.payment-cost.not-included')
      : this.intl.t('pagopa.payment-creation.review.payment-cost.included');
  }

  get paymentCostOrPlaceholder() {
    return (
      this.paymentCost ?? this.intl.t('pagopa.payment-creation.review.payment-cost-placeholder')
    );
  }

  get amount() {
    return this.intl.formatNumber(this.payment.amount.value, {
      currency: this.payment.amount.currency,
      style: 'currency',
    });
  }

  get totalAmount() {
    return this.intl.formatNumber(this.payment.totalAmount.value, {
      currency: this.payment.totalAmount.currency,
      style: 'currency',
    });
  }

  handleSubmitTask = dropTask(async () => {
    this.segment.track('pagopa_payment-flow_review_confirm-button_clicked');

    try {
      await this.payment.save();
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }

      if (error.status >= 500) {
        this.sentry.captureException(error);
      }

      this.segment.track('pagopa_payment-flow_failed');
      this.args.context.paymentStatus = PAGOPA_PAYMENT_RESULT_STATUS.ERROR;
    }

    this.args.transitionToNext();
  });
}
