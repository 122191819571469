/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { ApplyCta } from 'qonto/react/components/financing/offer-details/apply-cta';

export default class FinancingOfferDetailsHeaderComponent extends Component {
  ApplyCta = ApplyCta;
  @service router;
}
