export default {
  "color-option": "ctT",
  "color-dot": "ctz",
  "any": "cta",
  "red": "ctH",
  "orange": "ctO",
  "yellow": "ctm",
  "green": "ctp",
  "blue": "ctx",
  "purple": "ctw",
  "pink": "ctN",
  "black": "ctv",
  "grey": "cto",
  "brown": "ctn",
  "color-name": "cti"
};
