/* import __COLOCATED_TEMPLATE__ from './success-retention.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { LottiePlayer } from '@repo/design-system-kit';

export default class SubscriptionRetentionCloseStepsReasonSuccessComponent extends Component {
  lottiePlayer = LottiePlayer;
  @service flow;
  @service segment;
  @service router;
  @service sentry;
  @service subscriptionManager;

  @tracked animationReady = false;

  constructor() {
    super(...arguments);
    this.flow.dataContext.continueClosure = false;
    this.segment.track('account-closure_voucher-offer_completed', {
      current_plan: this.currentPlan,
    });
  }

  @action redirectToBilling() {
    this.router.transitionTo('settings.price-plan.discounts');
  }

  get currentPlan() {
    return this.subscriptionManager.currentPricePlan?.localName;
  }

  @action displayLottie() {
    // see https://github.com/emberjs/ember.js/issues/17202#issuecomment-437927226
    this.animationReady = true;
  }
}
