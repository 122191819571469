export default {
  "filter": "iM",
  "expression": "iP",
  "conditional-wrapper": "il mr-8",
  "dropdown-property-select": "iX",
  "is-advanced-filters-mode": "iC",
  "dropdown-operator-select": "ik",
  "conditional-text": "iJ",
  "property-select": "is",
  "operator-select": "iF",
  "error-message": "ig",
  "values-field": "iD",
  "input-placeholder": "iV",
  "filter-actions": "iL",
  "label-color": "iT",
  "button-container": "iz"
};
