/* import __COLOCATED_TEMPLATE__ from './success-onboarding.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { CARD_LEVELS_TRACKING } from 'qonto/constants/cards';

export default class FlowsCardsSuccessOnboarding extends Component {
  @service abilities;
  @service organizationManager;
  @service intl;
  @service router;
  @service segment;
  @service homePage;

  constructor() {
    super(...arguments);

    let { card, flowTrackingOrigin } = this.args.context;

    this.segment.track('cards_order_completed', {
      card_holder: 'self',
      card_type: CARD_LEVELS_TRACKING[card.cardLevel],
      origin: flowTrackingOrigin,
    });
  }

  get hasTopUpFeature() {
    return this.organizationManager.organization.hasTopUpFeature;
  }

  get shouldSubmitKyc() {
    return this.args.context.shouldSubmitKyc;
  }

  get title() {
    if (this.shouldSubmitKyc) {
      return this.intl.t('cards.steps.success-onboarding.kyc-unsubmitted.title');
    }
    if (this.hasTopUpFeature) {
      return this.intl.t('cards.steps.success-onboarding.title-top-up');
    }
    return this.intl.t('cards.steps.success-onboarding.title');
  }

  get subtitle() {
    if (this.shouldSubmitKyc) {
      return this.intl.t('cards.steps.success-onboarding.kyc-unsubmitted.subtitle');
    }
    if (this.hasTopUpFeature) {
      return this.intl.t('cards.steps.success-onboarding.subtitle-top-up');
    }
    return this.intl.t('cards.steps.success-onboarding.subtitle');
  }

  get cta() {
    if (this.shouldSubmitKyc) {
      return this.intl.t('btn.start-verification');
    }
    if (this.hasTopUpFeature) {
      return this.intl.t('cards.steps.success-onboarding.continue-to-top-up');
    }
    return this.intl.t('cards.steps.success-onboarding.cta');
  }

  @action
  handleAbortTask() {
    return this.homePage.visitDefaultPage();
  }

  @action
  onSuccess() {
    let organization = this.organizationManager.organization;

    if (this.shouldSubmitKyc) {
      this.router.transitionTo('kyc.start', organization, {
        queryParams: { trackingEventOrigin: 'onboarding_card_flow' },
      });
      return;
    }

    if (this.hasTopUpFeature) {
      this.segment.track('onboarding_top_up_clicked');
      this.router.transitionTo('onboarding.topup.amount', organization);
      return;
    }

    let { card, flowTrackingOrigin } = this.args.context;
    this.segment.track('cards_order_success_complete', {
      origin: flowTrackingOrigin,
      card_type: CARD_LEVELS_TRACKING[card.cardLevel],
    });
    if (this.abilities.can('navigate overview')) {
      this.router.transitionTo('overview.index', organization);
    } else {
      this.router.transitionTo('transactions.index', organization);
    }
  }
}
