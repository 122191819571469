export default {
  "label-wrapper": "Uv body-1",
  "avatar": "Uo",
  "container": "Un",
  "transaction-info": "Ui",
  "secondary": "UA",
  "amount-container": "UY",
  "amount": "Uh",
  "disabled": "Uf",
  "checkbox": "UK",
  "disclaimer": "UG"
};
