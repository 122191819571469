/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { variation } from 'ember-launch-darkly';

export default class FlowsAccountsExternalImportConfirmationSuccessComponent extends Component {
  @service segment;
  @service router;

  get isComingFromDirectDebitCollections() {
    return this.args.context?.origin?.includes('direct-debit-collections');
  }

  @action
  restartFlow() {
    let { context, restartFlow } = this.args;

    this.segment.track('accounts_external-success_connect-another-account', {
      bank: context?.bank?.name || 'unknown',
    });

    restartFlow();
  }

  @action
  onPrimaryButtonClick() {
    let { context, completeFlow } = this.args;

    if (
      variation('feature-boolean-sdd-increase-collection-limit') &&
      this.isComingFromDirectDebitCollections
    ) {
      this.router.transitionTo(context.origin);
    } else {
      this.segment.track('accounts_external-success_go-to-connected-accounts', {
        bank: context?.bank?.name || 'unknown',
      });

      completeFlow();
    }
  }
}
