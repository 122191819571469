/* import __COLOCATED_TEMPLATE__ from './hear-from-you.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { apiBaseURL } from 'qonto/constants/hosts';
import { HearFromYouScreen } from 'qonto/react/components/flows/subscription/hear-from-you/index.ts';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class SubscriptionHearFromYouComponent extends Component {
  @service flow;
  @service networkManager;
  @service organizationManager;
  @service toastFlashMessages;
  @service subscriptionManager;
  @service intl;
  @service segment;
  @service zendeskWidget;

  hearFromYouScreen = HearFromYouScreen;

  constructor() {
    super(...arguments);
    this.zendeskWidget.hide();
    this.segment.track('account-closing_cs-callback_displayed', {
      pricePlan: this.subscriptionManager.currentPricePlan?.code,
      closingReasonCategory: this.flow.dataContext.closingReasonCategory?.key,
      entry:
        this.flow.dataContext.closingReasonCategory?.key === 'service_quality'
          ? 'screen'
          : 'banner',
    });
  }

  @action onClose() {
    this.flow.dataContext.bookCall = false;
    this.segment.track('account-closing_cs-callback_continue-closure', {
      pricePlan: this.subscriptionManager.currentPricePlan?.code,
      closingReasonCategory: this.flow.dataContext.closingReasonCategory?.key,
      entry:
        this.flow.dataContext.closingReasonCategory?.key === 'service_quality'
          ? 'screen'
          : 'banner',
    });
    this.flow.next();
  }

  @action onBookCall({ slotPreference }) {
    this.flow.dataContext.bookCall = true;
    this.segment.track('account-closing_cs-callback_call-requested', {
      pricePlan: this.subscriptionManager.currentPricePlan.code,
      closingReasonCategory: this.flow.dataContext.closingReasonCategory?.key,
      entry:
        this.flow.dataContext.closingReasonCategory?.key === 'service_quality'
          ? 'screen'
          : 'banner',
    });
    this.onBookCallTask
      .perform({ slotPreference })
      .then(() => {
        this.flow.next();
      })
      .catch(error => {
        let errorInfo = ErrorInfo.for(error);
        let status = errorInfo.httpStatus;
        if (status === 409) {
          this.toastFlashMessages.toastError(
            this.intl.t('subscription.account-closing.book-a-call.error-toast2')
          );
        } else {
          this.toastFlashMessages.toastError(
            this.intl.t('subscription.account-closing.book-a-call.error-toast')
          );
        }
      });
  }

  onBookCallTask = dropTask(this, async ({ slotPreference }) => {
    let { organization } = this.organizationManager;
    let {
      closingReasonCategory,
      closingReasonSubCategory,
      closingReasonDetails,
      closingReasonDetailsOther,
    } = this.flow.dataContext;
    return await this.networkManager.request(
      `${apiBaseURL}/v3/organizations/${organization.id}/deactivation/cs_callback`,
      {
        method: 'POST',
        body: JSON.stringify({
          deactivation_reason: {
            closing_reason_category: closingReasonCategory?.key,
            closing_reason_sub_category: closingReasonSubCategory?.key,
            closing_reason_details: closingReasonDetails?.key,
            closing_reason_details_other: closingReasonDetailsOther ?? undefined,
          },
          slot_preference: slotPreference,
        }),
      }
    );
  });
}
