/* import __COLOCATED_TEMPLATE__ from './introduction.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

export default class SubscriptionCloseStepsIntroductionComponent extends Component {
  @service intl;
  @service organizationManager;
  @service subscriptionManager;
  @service toastFlashMessages;
  @service flow;
  @tracked isEligibleForVoucher = false;

  constructor() {
    super(...arguments);
    if (variation('feature--improve-retention-vouchers-ab-test')) {
      this.checkVoucherEligibilityTask.perform().catch(() => {});
    }
  }

  checkVoucherEligibilityTask = dropTask(async () => {
    try {
      if (variation('feature--improve-retention-vouchers-ab-test')) {
        let response = await this.organizationManager.organization.checkVoucherEligibility();
        this.isEligibleForVoucher = response.isEligible !== undefined ? response.isEligible : null;
        this.finishOn = response.finishOn !== undefined ? response.finishOn : null;
        this.flow.dataContext.finishOn = this.finishOn;
        this.flow.dataContext.isEligibleForVoucher = this.isEligibleForVoucher;
      }
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });

  get organizationName() {
    return this.organizationManager.organization.name;
  }

  get planName() {
    return this.subscriptionManager.currentPricePlan.localName;
  }

  get currentPricePlanRecurrence() {
    return this.subscriptionManager.currentSubscription.recurrence;
  }

  get closingDate() {
    let endOfMonth = dayjs().endOf('month').toDate();

    if (endOfMonth.getDate() === dayjs().toDate().getDate()) {
      let endOfNextMonth = dayjs().add(1, 'month').endOf('month').toDate();
      return this.intl.formatDate(endOfNextMonth, { format: 'long' });
    }

    return this.intl.formatDate(endOfMonth, { format: 'long' });
  }

  @action onSubmit(e) {
    e.preventDefault();
    this.args.transitionToNext();
  }
}
