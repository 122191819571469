/* import __COLOCATED_TEMPLATE__ from './color-picker.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class GenerateLogoColorPickerComponent extends Component {
  @service intl;

  get colors() {
    return [
      { id: 'any', title: this.intl.t('generated-logo.modal.color.options.any') },
      { id: 'red', title: this.intl.t('generated-logo.modal.color.options.red') },
      { id: 'orange', title: this.intl.t('generated-logo.modal.color.options.orange') },
      { id: 'yellow', title: this.intl.t('generated-logo.modal.color.options.yellow') },
      { id: 'green', title: this.intl.t('generated-logo.modal.color.options.green') },
      { id: 'blue', title: this.intl.t('generated-logo.modal.color.options.blue') },
      { id: 'purple', title: this.intl.t('generated-logo.modal.color.options.purple') },
      { id: 'pink', title: this.intl.t('generated-logo.modal.color.options.pink') },
      { id: 'brown', title: this.intl.t('generated-logo.modal.color.options.brown') },
      { id: 'grey', title: this.intl.t('generated-logo.modal.color.options.grey') },
      { id: 'black', title: this.intl.t('generated-logo.modal.color.options.black') },
    ];
  }
}
