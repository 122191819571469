/* import __COLOCATED_TEMPLATE__ from './related-invoice.hbs'; */
import Component from '@glimmer/component';

import { Tag } from '@repo/design-system-kit';

import { INVOICING_STATUS_COLORS } from 'qonto/constants/receivable-invoice';

export default class RelatedInvoiceComponent extends Component {
  tag = Tag;

  get status() {
    let { status, displayedStatus } = this.args.document;
    let statusColors = INVOICING_STATUS_COLORS;

    return {
      displayedStatus,
      color: statusColors[status],
    };
  }
}
