/* import __COLOCATED_TEMPLATE__ from './payment-lifespan-limit.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class FlowsCardsPaymentLifespanLimit extends Component {
  @service segment;

  @action
  handleNext() {
    let {
      context: { card, flowTrackingOrigin },
      transitionToNext,
    } = this.args;

    this.segment.track('cards_flash_budget_set', {
      origin: flowTrackingOrigin,
      payment_lifespan_limit: card.paymentLifespanLimit,
    });

    transitionToNext();
  }
}
