/* import __COLOCATED_TEMPLATE__ from './horizontal-scroller.hbs'; */
import Component from '@glimmer/component';

export default class HorizontalScrollerComponent extends Component {
  constructor(...props) {
    super(...props);

    document.addEventListener('wheel', handleScroll);
    document.body.classList.add('disable-overscroll');
  }

  willDestroy(...props) {
    super.willDestroy(...props);
    document.removeEventListener('wheel', handleScroll);
    document.body.classList.remove('disable-overscroll');
  }

  isDown = false;
  startX;
  scrollLeft;

  startSlider = e => {
    this.isDown = true;
    this.startX = e.pageX - this.columnsElm.offsetLeft;
    this.scrollLeft = this.columnsElm.scrollLeft;
  };

  stopSlider = () => {
    this.isDown = false;
  };

  moveSlider = e => {
    if (!this.isDown) return;
    e.preventDefault();
    let delta = e.pageX - this.startX;
    this.columnsElm.scrollLeft = this.scrollLeft - delta;
  };
}

function handleScroll(e) {
  let { deltaX, deltaY, deltaZ, target } = e;

  let dropdown = document.getElementById('ember-basic-dropdown-wormhole');
  if (dropdown && dropdown.contains(target)) {
    return;
  }

  let columnsElm = document.querySelector('[data-horizontal-scroller-slider-element]');

  if (!columnsElm) {
    return;
  }

  if (deltaZ === 0 && Math.abs(deltaY) > Math.abs(deltaX)) {
    columnsElm.scrollLeft = columnsElm.scrollLeft + deltaY;
  }

  if (deltaZ === 0 && Math.abs(deltaX) > Math.abs(deltaY)) {
    columnsElm.scrollLeft = columnsElm.scrollLeft + deltaX;
  }
}
