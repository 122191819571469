/* import __COLOCATED_TEMPLATE__ from './set-pin-kyc-intro.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class FlowsCardsKycSetPinKycIntro extends Component {
  @action
  handleTransitionToNext() {
    this.args.pushForwardFlow('management-kyc', 'user-info');
  }
}
