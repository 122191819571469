/* import __COLOCATED_TEMPLATE__ from './terms-and-conditions.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { apiBaseURL } from 'qonto/constants/hosts';
import { TermsAndConditions } from 'qonto/react/components/flows/chargeback-claim/terms-and-conditions';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class FlowsChargebackClaimTermsAndConditionsComponent extends Component {
  @service intl;
  @service networkManager;
  @service toastFlashMessages;
  @service segment;
  @service sentry;

  termsAndConditions = TermsAndConditions;

  submitTask = dropTask(async () => {
    try {
      let { claimType, selectedTransactions, files, description, hadCardDuringFraud } =
        this.args.context;

      let payload = {
        data: {
          type: 'claims',
          attributes: {
            type: claimType,
            description,
            user_had_card_during_fraud: hadCardDuringFraud,
            user_accepted_tos: true,
          },
          relationships: {
            transactions: {
              data: selectedTransactions.map(transaction => ({
                type: 'transactions',
                id: transaction.id,
              })),
            },
            documents: {
              data: files.flat().map(file => ({ id: file.id, type: 'documents' })),
            },
          },
        },
      };

      this.segment.track('cards-chargeback_submit-dispute-cta_clicked');

      await this.networkManager.request(`${apiBaseURL}/v1/claims`, {
        method: 'POST',
        data: payload,
      });

      this.args.transitionToNext();
    } catch (error) {
      let errorMessage = this.intl.t('toasts.errors.server_error');
      this.toastFlashMessages.toastError(errorMessage);

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });
}
