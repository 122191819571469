export default {
  "checks": "jS",
  "content": "jc",
  "form": "jq",
  "title": "jZ",
  "description": "jR",
  "wrapper": "jQ",
  "aside": "je",
  "amount-field": "jB"
};
