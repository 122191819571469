export default {
  "wrapper": "ceA",
  "wrapper-revamp": "ceY",
  "container": "ceh",
  "container-revamp": "cef",
  "box-wrapper": "ceK",
  "warning-label": "ceG",
  "subtitle-container": "cer",
  "info-container": "ceb",
  "card": "cey",
  "disclaimer": "ceU",
  "list": "cej",
  "list-item": "ceW body-2",
  "total": "ceu"
};
