/* import __COLOCATED_TEMPLATE__ from './document-selection.hbs'; */
import { action, set } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';
import { TrackedArray } from 'tracked-built-ins';

export default class FlowsKYCDocumentSelection extends Component {
  @service segment;
  @service organizationManager;
  @reads('organizationManager.organization.legalCountry') legalCountry;

  @action
  nextStep(documentType) {
    if (this.args.context.documentType !== documentType) {
      set(this.args.context, 'documents', new TrackedArray());
    }
    set(this.args.context, 'documentType', documentType);
    this.segment.track('submit_kyc_information_poi_type_chosen', {
      poi_type: documentType,
    });
    this.args.transitionToNext();
  }
}
