export default {
  "container": "cth",
  "container--collapsed": "ctf",
  "spin": "ctK",
  "cta-tooltip": "ctG",
  "cta--collapsed": "ctr",
  "cta--expanded": "ctb",
  "collapsed-svg-icon": "cty",
  "expanded-svg-icon": "ctU",
  "generate-logo-cta": "ctj",
  "badge": "ctW",
  "scaleDown": "ctu",
  "fadeIn": "cMS",
  "textRiseButton": "cMc",
  "cta-description": "cMq",
  "textRiseDescription": "cMZ"
};
