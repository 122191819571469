/* import __COLOCATED_TEMPLATE__ from './time-frame.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';

export default class FlowsBudgetsTimeFrame extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service toastFlashMessages;
  @service store;
  @service segment;
  @service intl;
  @service flow;
  @service modals;

  get exercise() {
    if (this.args.context.exerciseId) {
      return this.store.peekRecord('exercise', this.args.context.exerciseId);
    }
  }

  get isNew() {
    return !this.exercise;
  }

  get budget() {
    return this.args.context.budget;
  }

  get startDate() {
    if (this.exercise?.startDate) {
      return this.exercise.startDate;
    } else if (this.budget.exercisesEndingInTheFuture.at(-1)) {
      return dayjs(this.budget.exercisesEndingInTheFuture.at(-1).endDate)
        .add(1, 'day')
        .format(DATE_PICKER_FIELD_FORMAT); // next available date
    } else {
      return new dayjs().format(DATE_PICKER_FIELD_FORMAT);
    }
  }

  @tracked error;
  @tracked selectedDates = {
    endDate:
      this.args.context.selectedEndDate ??
      this.exercise?.endDate ??
      dayjs(this.startDate).add(11, 'month').endOf('month').format(DATE_PICKER_FIELD_FORMAT),
    startDate:
      this.args.context.selectedStartDate ??
      dayjs(this.startDate).startOf('month').format(DATE_PICKER_FIELD_FORMAT),
  };

  @action handleDatesSelected(selectedDates) {
    this.selectedDates = selectedDates;
    this.error = null;
  }

  @action submit() {
    this.segment.track('budget-creation_budget-period-definition_clicked');
    if (!this.selectedDates.endDate) {
      this.error = this.intl.t('validations.errors.presence');
    } else {
      this.args.context.selectedEndDate = this.selectedDates.endDate;
      this.args.context.selectedStartDate = this.selectedDates.startDate;
      this.args.transitionToNext();
    }
  }

  deleteTask = dropTask(async () => {
    this.segment.track('edit-budget_delete-period_clicked');

    let result = await this.modals.open('popup/destructive', {
      title: this.intl.t('team-budgets.edit.edit-exercise.delete-period.modal.title'),
      description: this.intl.t('team-budgets.edit.edit-exercise.delete-period.modal.subtitle'),
      cancel: this.intl.t('btn.back'),
      confirm: this.intl.t('team-budgets.edit.edit-exercise.delete-period.modal.button'),
    });

    if (result === 'confirm') {
      this.segment.track('edit-budget_delete-period-confirmation_clicked');
      await this.exercise.deleteRecord();
      await this.exercise.save();
      this.toastFlashMessages.toastSuccess(
        this.intl.t('team-budgets.edit.customize.toast.budget.success')
      );
      this.flow.complete();
    } else {
      this.segment.track('edit-budget_delete-period-confirmation-back_clicked');
    }
  });
}
