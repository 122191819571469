/* import __COLOCATED_TEMPLATE__ from './accordion.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class InContextIntegrationsSideDrawerIntegrationQualityAccordionComponent extends Component {
  @tracked isAccordionOpen = false;

  @action
  toggleAccordion() {
    this.isAccordionOpen = !this.isAccordionOpen;
    this.args.trackEvent('ICI_integration_quality_clicked');
  }

  get uniqueQualities() {
    let { inboundItems, outboundItems } = this.args;

    let inboundQualities = inboundItems.flatMap(item => item.qualities);
    let outboundQualities = outboundItems.flatMap(item => item.qualities);

    return inboundQualities.length + outboundQualities.length;
  }
}
