/* import __COLOCATED_TEMPLATE__ from './intro.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { importAsset } from 'qonto/helpers/import-asset';

const VIDEO_PATHS = {
  de: '/videos/budgets/value-prop-de.webm',
  en: '/videos/budgets/value-prop-en.webm',
  es: '/videos/budgets/value-prop-es.webm',
  fr: '/videos/budgets/value-prop-fr.webm',
  it: '/videos/budgets/value-prop-it.webm',
};

const IMAGE_PATHS = {
  de: '/illustrations/budgets/value-prop-de.webp',
  en: '/illustrations/budgets/value-prop-en.webp',
  es: '/illustrations/budgets/value-prop-es.webp',
  fr: '/illustrations/budgets/value-prop-fr.webp',
  it: '/illustrations/budgets/value-prop-it.webp',
};

export default class FlowsCreateBudgetIntroComponent extends Component {
  @service localeManager;
  @service segment;

  get videoPath() {
    return importAsset([VIDEO_PATHS[this.localeManager.locale]]);
  }

  get imagePath() {
    return importAsset([IMAGE_PATHS[this.localeManager.locale]]);
  }

  @action next() {
    this.segment.track('budget-creation_value-proposition-button_clicked');
    this.args.transitionToNext();
  }
}
