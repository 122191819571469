/* import __COLOCATED_TEMPLATE__ from './success-new-digital.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { CARD_LEVELS, CARD_LEVELS_TRACKING } from 'qonto/constants/cards';

export default class FlowsCardsSuccessNewDigital extends Component {
  @service intl;
  @service segment;

  constructor() {
    super(...arguments);
    let { flowTrackingOrigin, isUserCardHolder } = this.args.context;

    this.segment.track('cards_order_completed', {
      card_holder: isUserCardHolder ? 'self' : 'team_member',
      card_type: this.trackingCardLevel,
      origin: flowTrackingOrigin,
    });
  }

  get title() {
    let {
      card: { cardLevel },
      isUserCardHolder,
    } = this.args.context;

    switch (cardLevel) {
      case CARD_LEVELS.ADVERTISING:
        return isUserCardHolder
          ? this.intl.t('cards.steps.success.title.advertising.holder')
          : this.intl.t('cards.steps.success.title.advertising.non-holder');
      case CARD_LEVELS.FLASH:
        return isUserCardHolder
          ? this.intl.t('cards.steps.success.title.flash.holder')
          : this.intl.t('cards.steps.success.title.flash.non-holder');
      case CARD_LEVELS.VIRTUAL:
        return isUserCardHolder
          ? this.intl.t('cards.steps.success.title.virtual.holder')
          : this.intl.t('cards.steps.success.title.virtual.non-holder');
      default:
        return null;
    }
  }

  get subtitle() {
    let { card, isUserCardHolder } = this.args.context;

    switch (card.cardLevel) {
      case CARD_LEVELS.ADVERTISING:
        return isUserCardHolder
          ? this.intl.t('cards.steps.success.subtitle.advertising.holder')
          : this.intl.t('cards.steps.success.subtitle.advertising.non-holder', {
              cardHolderName: card.holder.get('fullName'),
            });
      case CARD_LEVELS.FLASH:
        return isUserCardHolder
          ? this.intl.t('cards.steps.success.subtitle.flash.holder', { htmlSafe: true })
          : this.intl.t('cards.steps.success.subtitle.flash.non-holder', {
              cardholder_name: card.holder.get('fullName'),
            });
      case CARD_LEVELS.VIRTUAL:
        return this.intl.t('cards.steps.success.subtitle.virtual');
      default:
        return null;
    }
  }

  get mainCtaText() {
    let { card, isUserCardHolder } = this.args.context;

    switch (card.cardLevel) {
      case CARD_LEVELS.ADVERTISING:
        return isUserCardHolder
          ? this.intl.t('cards.steps.success.cta.advertising.holder')
          : this.intl.t('cards.steps.success.cta.non-holder');
      case CARD_LEVELS.FLASH:
        return this.intl.t('cards.steps.success.cta.non-holder');
      case CARD_LEVELS.VIRTUAL:
        return isUserCardHolder
          ? this.intl.t('cards.steps.success.cta.holder')
          : this.intl.t('cards.steps.success.cta.non-holder');
      default:
        return null;
    }
  }

  get trackingCardLevel() {
    let { cardLevel } = this.args.context.card;
    return CARD_LEVELS_TRACKING[cardLevel];
  }

  @action
  handleNext() {
    let { flowTrackingOrigin } = this.args.context;
    this.segment.track('cards_order_success_complete', {
      card_type: this.trackingCardLevel,
      origin: flowTrackingOrigin,
    });
    this.args.transitionToNext();
  }
}
