export default {
  "wrapper": "cBS",
  "confirm": "cBc",
  "plan-info": "cBq",
  "plan-info-left": "cBZ",
  "info-title": "cBR body-1",
  "initial-trial-content": "cBQ",
  "extra-fees-item": "cBe body-2",
  "extra-fees-container": "cBB",
  "bottom-wrapper": "cBE",
  "total-price-container": "cBd",
  "extra-fees-element": "cBI body-1",
  "border": "cBt",
  "subtitle": "cBM body-2",
  "group-item": "cBP",
  "amount": "cBl",
  "subscription-billing-summary": "cBX",
  "summary": "cBC",
  "summary-list": "cBk",
  "disclaimer": "cBJ",
  "disclaimer-revamp": "cBs",
  "error": "cBF",
  "error-link": "cBg",
  "warning-wrapper": "cBD",
  "warning-label": "cBV body-1"
};
