/* import __COLOCATED_TEMPLATE__ from './confirm-revoke-member.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

export default class ConfirmRrevokeMemberModal extends Component {
  @service errors;

  confirmTask = dropTask(async (...args) => {
    try {
      await this.args.data.confirmTask.linked().perform(...args);
    } catch (error) {
      this.errors.handleError(error);
    }
  });
}
