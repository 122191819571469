export default {
  "container": "ced",
  "form-core": "ceI",
  "label": "cet",
  "request-description": "ceM",
  "radio-option": "ceP",
  "radio-button": "cel",
  "inline-inputs": "ceX",
  "placeholder-label": "ceC",
  "placeholder-input": "cek",
  "placeholder-checkbox": "ceJ"
};
