/* import __COLOCATED_TEMPLATE__ from './dummy-data.hbs'; */
import Component from '@glimmer/component';

import { SearchFieldWithDebounce } from '@repo/design-system-kit';

import { CODES } from 'qonto/constants/empty-states/system';
import { BASE_INSIGHTS } from 'qonto/constants/supplier-invoice';
import { SupplierInvoicesTabs } from 'qonto/react/components/supplier-invoices/tabs';

export default class EmptyStatesSupplierInvoicesDummyDataComponent extends Component {
  SupplierInvoicesTabs = SupplierInvoicesTabs;
  SearchBar = SearchFieldWithDebounce;
  sortBy = 'due_date:asc';
  noop() {}

  fakeSuppliers = [{ id: 'id' }];

  get enableAllTabs() {
    return [CODES.ES_U1, CODES.ES_T1].includes(this.args.emptyStateCode);
  }

  get BASE_INSIGHTS() {
    return BASE_INSIGHTS;
  }
}
