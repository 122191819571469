/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

import { ACCOUNT_ID_LOCAL_STORAGE } from 'qonto/constants/bank-account';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export default class FlowsAccountsRemuneratedCreationSuccess extends Component {
  lottiePlayer = LottiePlayer;

  @service segment;

  @action
  handleNext() {
    safeLocalStorage.setItem(
      ACCOUNT_ID_LOCAL_STORAGE,
      JSON.stringify(this.args.context.account.id)
    );
    this.segment.track('remunerated-account_fund_clicked', { origin: 'success_page' });

    this.args.transitionToFlow({
      flowName: 'remunerated-account-funding',
      stepId: 'add-funds',
      queryParams: {
        origin: this.args.context.origin,
      },
    });
  }
}
