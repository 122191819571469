/* import __COLOCATED_TEMPLATE__ from './landing.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import window from 'ember-window-mock';

import { REDIRECTION_OPTIONS } from 'qonto/routes/flows/setup/accounting-discovery';

export const PARTNER_REPOSITORY_URL = 'https://qonto.com/fr/accountants-partners';
export default class FlowsAccountingDiscoveryLanding extends Component {
  @service segment;

  constructor() {
    super(...arguments);
    this.segment.track('accounting-discovery-flow_landing-page_displayed');
  }

  @action
  handleInvitationClick() {
    this.args.context.selectedOption = REDIRECTION_OPTIONS.INVITATION;
    this.args.pushForwardFlow('member-invite', 'personal-info');
  }

  @action
  handleIntegrationsClick() {
    this.args.context.selectedOption = REDIRECTION_OPTIONS.INTEGRATIONS;
    this.args.completeFlow();
  }

  @action
  handlePartnerRepositoryClick() {
    window.open(PARTNER_REPOSITORY_URL, '_blank');
  }
}
