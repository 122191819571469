/* import __COLOCATED_TEMPLATE__ from './row.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';

export default class FinancingPayLaterCockpitInProgressTableRowComponent extends Component {
  @service intl;
  get warning() {
    let { bankAccountName, nextInstallmentAt } = this.args.financing;
    let formattedDate = dateToken({
      date: nextInstallmentAt,
      token: DATE_FORMAT_TOKENS.DATE_S,
      locale: this.intl.primaryLocale,
    });

    switch (this.args.financing.repaymentStatus) {
      case 'at_risk':
        return {
          tooltipMessage: this.intl.t(
            'financing.pay-later.cockpit.table.row.installment-amount.at-risk.tooltip',
            { account_name: bankAccountName, date: formattedDate }
          ),
          icon: 'icon_warning_sign_filled',
          text: this.intl.t(
            'financing.pay-later.cockpit.table.row.installment-amount.at-risk.disclaimer'
          ),
        };
      case 'late':
        return {
          tooltipMessage: this.intl.t(
            'financing.pay-later.cockpit.table.row.installment-amount.overdue.tooltip',
            { account_name: bankAccountName }
          ),
          icon: 'icon_error_filled',
          text: this.intl.t(
            'financing.pay-later.cockpit.table.row.installment-amount.overdue.disclaimer'
          ),
        };
      default:
        return null;
    }
  }

  get isProcessing() {
    return this.args.financing.isProcessing;
  }
}
