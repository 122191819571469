/* import __COLOCATED_TEMPLATE__ from './holder.hbs'; */
/* eslint-disable @qonto/no-async-action, @qonto/no-import-roles-constants */
import { action, set } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { LottiePlayer, SearchFieldWithDebounce } from '@repo/design-system-kit';
import { restartableTask, task } from 'ember-concurrency';

import { MEMBER_STATUS, ROLES } from 'qonto/constants/membership';
import { ErrorInfo } from 'qonto/utils/error-info';

const USER_ROLES = [ROLES.OWNER, ROLES.ADMIN, ROLES.MANAGER, ROLES.EMPLOYEE];
const USER_STATUS = [MEMBER_STATUS.ACTIVE, MEMBER_STATUS.INVITED];

export default class FlowsCardsCardHolder extends Component {
  lottiePlayer = LottiePlayer;
  searchField = SearchFieldWithDebounce;

  @service cardsManager;
  @service errors;
  @service toastFlashMessages;
  @service intl;
  @service organizationManager;
  @service segment;
  @service sentry;
  @service store;
  @service abilities;

  @tracked members;
  @tracked membersCount = null;
  @tracked memberWithCardLimit = null;
  @tracked searchQuery = null;

  constructor() {
    super(...arguments);
    // Disabling the rule since the following task is already handling errors
    // eslint-disable-next-line ember-concurrency/no-perform-without-catch
    this.loadMembersTask.perform();
  }

  get currentMembershipId() {
    return this.organizationManager.membership.id;
  }

  get localizedRole() {
    return {
      [ROLES.OWNER]: this.intl.t('roles.owner'),
      [ROLES.ADMIN]: this.intl.t('roles.admin'),
      [ROLES.MANAGER]: this.intl.t('roles.manager'),
      [ROLES.EMPLOYEE]: this.intl.t('roles.employee'),
    };
  }

  loadMembersTask = task(async () => {
    this.members = await this.findMembers();
    this.membersCount = this.members?.length;
  });

  searchUserTask = restartableTask(async searchQuery => {
    this.searchQuery = searchQuery;
    this.memberWithCardLimit = null;
    this.members = await this.findMembers(searchQuery);
  });

  @action
  async handleNext(member) {
    let { card } = this.args.context;

    let isSameHolderReselected = member.id === card.holder?.id;

    this.memberWithCardLimit = null;
    set(this.args.context, 'card.holder', member);

    if (member.physicalCardsLimitExceeded && card.isPhysical) {
      this.memberWithCardLimit = member;
      set(this.args.context, 'card.holder', null);
    } else {
      let isUserCardHolder = member.id === this.currentMembershipId;
      set(this.args.context, 'isUserCardHolder', isUserCardHolder);

      // Options must be reset if user goes back in flow and re-selects another holder
      if (card.isPhysical && !isSameHolderReselected) {
        set(card, 'atmOption', Boolean(isUserCardHolder));
        set(card, 'nfcOption', Boolean(isUserCardHolder));
        set(card, 'foreignOption', true);
        set(card, 'onlineOption', true);
      }

      if (this.abilities.can('read budget')) {
        try {
          let results = await this.store.adapterFor('budget').search({
            initiatorId: member.id,
            scheduledDate: new Date(),
          });
          if (results.length) {
            this.args.context.budgetName = results[0].budget.name;
          }
        } catch (error) {
          if (ErrorInfo.for(error).shouldSendToSentry) {
            this.sentry.captureException(error);
          }
        }
      }

      try {
        let { flowTrackingOrigin } = this.args.context;
        await this.fetchNewLimits();

        this.segment.track('cards_order_cardholder_selected', {
          cardHolder: isUserCardHolder ? 'self' : 'team_member',
          origin: flowTrackingOrigin,
        });

        this.args.transitionToNext();
      } catch (error) {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }
        if (this.errors.shouldFlash(error)) {
          this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
        }
      }
    }
  }

  async fetchNewLimits() {
    let { card } = this.args.context;
    if (!card.isPhysical && !card.isAdvertising) return;

    let cardsLimits = await this.cardsManager.fetchCardsMaxLimitsTask.perform(
      card.holder.get('id')
    );
    set(this.args.context, 'cardsLimits', cardsLimits);

    let { payment_monthly_limit_maximum } = this.args.context.cardsLimits[card.cardLevel];
    card.set('paymentMonthlyLimit', payment_monthly_limit_maximum);

    if (card.isPhysical) {
      let { atm_monthly_limit_maximum } = this.args.context.cardsLimits[card.cardLevel];
      card.set('atmMonthlyLimit', atm_monthly_limit_maximum);
    }
  }

  async findMembers(query) {
    let filters = {};
    filters['roles'] = USER_ROLES;
    filters['status'] = USER_STATUS;
    let members;
    let sortedMembers;

    try {
      members = await this.store.query('membership', {
        organization_id: this.args.context.card.get('organization.id'),
        query,
        filters,
        per_page: 250,
      });

      let currentMember = members.find(m => m.id === this.currentMembershipId);

      if (currentMember) {
        sortedMembers = [currentMember, ...members.filter(member => member !== currentMember)];
      }
    } catch (error) {
      if (this.errors.shouldFlash(error)) {
        this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
      }

      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }

    return sortedMembers || members;
  }
}
