/* import __COLOCATED_TEMPLATE__ from './percentage-input.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { setCaretPosition } from 'qonto/utils/format-input';

export default class PercentageInput extends Component {
  argOrDefault(arg, defaultValue) {
    if (Object.keys(this.args).includes(arg)) {
      return this.args[arg];
    }
    return defaultValue;
  }

  get numberOfDecimal() {
    return this.argOrDefault('numberOfDecimal', 2);
  }

  get placeholder() {
    return this.argOrDefault('placeholder', '0');
  }

  @action
  onInput(e) {
    let { value } = e.target;
    let newValue = value.replace(/[^0-9.,]/g, '');
    setCaretPosition(e.target, value, newValue);
    this.args.onInput?.(newValue);
  }

  @action
  onChange(e) {
    let { value } = e.target;
    if (value) {
      let cappedValue = this._validateBoundaries(value.replace(/[,.]/g, '.'));
      let precisionFactor = Math.pow(10, this.numberOfDecimal);
      let roundedValue =
        Math.round(parseFloat(cappedValue) * precisionFactor * (1 + Number.EPSILON)) /
        precisionFactor;

      let normalizedValue = (roundedValue || 0).toFixed(this.numberOfDecimal);

      // for invoicing document the vat will display decimal only if different than 0
      if (this.args.origin === 'invoicing-form') {
        normalizedValue = String(roundedValue || 0);
      }

      setCaretPosition(e.target, value, normalizedValue);
      this.args.onChange?.(normalizedValue);
    }
  }

  _validateBoundaries(value) {
    if (this.args.max && value > this.args.max) {
      return this.args.max;
    }
    if (this.args.min && value < this.args.min) {
      return this.args.min;
    }

    return value;
  }
}
