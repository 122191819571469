/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { SuccessScreen } from 'qonto/react/components/flows/subscription/hear-from-you';

export default class SubscriptionHearFromYouSuccessComponent extends Component {
  @service flow;
  @service segment;
  @service subscriptionManager;
  @service zendeskWidget;

  successScreen = SuccessScreen;

  @action onClose() {
    this.segment.track('account-closing_cs-callback_call-request-completed', {
      entry:
        this.flow.dataContext.closingReasonCategory?.key === 'service_quality'
          ? 'screen'
          : 'banner',
      pricePlan: this.subscriptionManager.currentPricePlan.code,
    });
    this.zendeskWidget.show();
    this.flow.complete();
  }
}
