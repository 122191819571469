/* import __COLOCATED_TEMPLATE__ from './insurance-links.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { CARD_LEVELS } from 'qonto/constants/cards';

export default class InsuranceLinksComponent extends Component {
  @service intl;

  get insuranceNotice() {
    switch (this.args.cardLevel) {
      case CARD_LEVELS.METAL:
        return {
          cta: this.intl.t('insurance.notice.metal.download'),
          link: this.intl.t('insurance.notice.metal.download-link'),
        };
      case CARD_LEVELS.PLUS:
        return {
          cta: this.intl.t('insurance.notice.plus.download'),
          link: this.intl.t('insurance.notice.plus.download-link'),
        };
      default:
        return {
          cta: this.intl.t('insurance.notice.standard.download'),
          link: this.intl.t('insurance.notice.standard.download-link'),
        };
    }
  }
}
