export default {
  "wrapper": "og",
  "legend": "oD",
  "operation-code": "oV",
  "table": "oL",
  "footer": "oT",
  "footer-detraction": "oz",
  "footer-total-g": "oa",
  "footer-total-h": "oH",
  "footer-net-g-h": "oO"
};
