/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { task } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class F24ManualDeclarationOrdinarioFormComponent extends Component {
  @service segment;
  @service errors;
  @service intl;
  @service toastFlashMessages;
  @service sentry;

  validateOrderTask = task(async () => {
    let { context, transitionToNext } = this.args;
    let { f24Order } = context;

    try {
      f24Order.documentAmount = String(f24Order.totalAmount);
      await f24Order.validate();
      this.segment.track('f24_create-flow_step1-form-submitted');
      transitionToNext();
    } catch (error) {
      let isInvalidError = f24Order.isInvalidErrorWithPointers(error);
      let isServiceUnavailableError = error.status === 503;

      if (!isInvalidError && ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      let genericMessage = this.errors.messageForStatus(error);
      if (genericMessage) {
        this.toastFlashMessages.toastError(genericMessage);
      } else if (isServiceUnavailableError) {
        this.toastFlashMessages.toastError(
          this.intl.t('toasts.errors.server_error', { htmlSafe: true })
        );
      } else {
        this.toastFlashMessages.toastError(
          this.intl.t('f24.manual-declaration.module.generic-error')
        );
      }
    }
  });
}
