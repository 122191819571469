/* import __COLOCATED_TEMPLATE__ from './dropdown.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class ItemCardDropdownComponent extends Component {
  @action
  toggleActionsDropdown(showActionsMenu) {
    if (showActionsMenu) {
      this.args.onOpen?.();
    }
  }
}
