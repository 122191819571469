export default {
  "step-container": "uM",
  "content": "uP",
  "section": "ul",
  "section-content": "uX",
  "section-content-text": "uC",
  "section-illustration": "uk",
  "close-form-cta": "uJ",
  "bank-account-select": "us"
};
