/* import __COLOCATED_TEMPLATE__ from './name.hbs'; */
import { ForbiddenError } from '@ember-data/adapter/error';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { TEAM_NAME_MIN_LENGTH } from 'qonto/constants/teams';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class TeamsCreateComponent extends Component {
  @service intl;
  @service toastFlashMessages;
  @service segment;
  @service organizationManager;
  @service sentry;

  @tracked value = null;
  @tracked error = null;

  createTask = dropTask(async () => {
    if (!this.value || this.value.length < TEAM_NAME_MIN_LENGTH) {
      this.error = this.intl.t('validations.errors.tooShort', { min: TEAM_NAME_MIN_LENGTH });

      return;
    }

    try {
      let { team } = this.args.context;

      team.name = this.value;

      await team.save();
      await this.organizationManager.organization.loadTeams();

      this.segment.track('teams_new_team_created');

      this.args.transitionToNext();
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      if (error instanceof ForbiddenError) {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.forbidden_error'));
      } else {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    }
  });

  @action
  updateField(value) {
    this.value = value;
    this.error = null;
  }
}
