/* import __COLOCATED_TEMPLATE__ from './bank-details.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';
import { DEFAULT_LOCALE } from '@repo/shared-config/constants/locales';

export default class BankDetailsComponent extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service organizationManager;
  @service userManager;
  @service intl;

  get recommendedPrice() {
    return this.args.context.recommendedPrice;
  }

  get bankAccount() {
    return this.organizationManager.organization.mainAccount;
  }

  get currentUserLocale() {
    return (
      this.intl.getLocaleCodes.find(
        code => code === this.userManager.currentUser.localeObject.code
      ) || DEFAULT_LOCALE
    );
  }
}
