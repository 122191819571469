/* import __COLOCATED_TEMPLATE__ from './success-reorder.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class FlowsCardsSuccessReorder extends Component {
  @service intl;

  get title() {
    return this.args.context.isUserCardHolder
      ? this.intl.t('cards.steps.success-reorder.title.holder')
      : this.intl.t('cards.steps.success-reorder.title.non-holder');
  }

  get subtitle() {
    return this.args.context.isUserCardHolder
      ? this.intl.t('cards.steps.success-reorder.subtitle.holder')
      : this.intl.t('cards.steps.success-reorder.subtitle.non-holder');
  }
}
