/* import __COLOCATED_TEMPLATE__ from './customization-logo.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import TEMPLATE_VALUES from 'qonto/constants/env-helper';
import { ErrorInfo } from 'qonto/utils/error-info';

const SENTRY_IGNORE_ERROR_STATUSES = [422];

export default class FlowsAccountReceivableOnboardingCustomizationLogo extends Component {
  @service sentry;
  @service intl;
  @service organizationManager;
  @service flow;
  @service toastFlashMessages;

  @reads('organizationManager.organization') organization;
  @tracked formattedMaxSize = formatFileSize(this.intl, TEMPLATE_VALUES.avatarMaxSize);

  avatarRollbackAttributes;

  constructor() {
    super(...arguments);

    let { isDefaultAvatar, avatar } = this.organization;
    this.avatarRollbackAttributes = { isDefaultAvatar, avatar };
  }

  willDestroy() {
    super.willDestroy(...arguments);

    if (this.organization.isDestroying || this.organization.isDestroyed) return;

    this.rollbackAttributesOnClose();
  }

  get hasDefaultAvatar() {
    return this.organization.isDefaultAvatar ?? true;
  }

  get logo() {
    return this.organization.get('isDefaultAvatar') === false ? this.organization.picture : null;
  }

  @action
  handleAvatarUpload(file) {
    this.organization.reload();
    this.organization.set('avatar', file);
    this.organization.set('isDefaultAvatar', false);
  }

  @action
  handleDropAvatar() {
    this.organization.reload();
    this.organization.set('avatar', null);
    this.organization.set('isDefaultAvatar', true);
  }

  @action
  rollbackAttributesOnClose() {
    this.organization.set('isDefaultAvatar', this.avatarRollbackAttributes.isDefaultAvatar);
    this.organization.set('avatar', this.avatarRollbackAttributes.avatar);
  }

  @action
  handleDoItLater() {
    this.rollbackAttributesOnClose();
    this.args.transitionToNext();
  }

  handleConfirmTask = dropTask(async () => {
    try {
      await this.organization.save();

      this.flow.dataContext.onboardingState.substepStatusLogo = 'confirmed';
      this.flow.dataContext.onboardingState.stepStatusCustomization = 'confirmed';
      await this.flow.dataContext.onboardingState.save();

      this.organization.getAvatar();

      let { isDefaultAvatar, avatar } = this.organization;
      this.avatarRollbackAttributes = { isDefaultAvatar, avatar };

      this.args.transitionToNext();
    } catch (error) {
      if (
        ErrorInfo.for(error).shouldSendToSentry &&
        !SENTRY_IGNORE_ERROR_STATUSES.includes(error.status)
      ) {
        this.sentry.captureException(error);
      }
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });
}
