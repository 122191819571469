/* import __COLOCATED_TEMPLATE__ from './link-to-flow.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class LinkToFlowComponent extends Component {
  @service flow;
  @service flowLinkManager;
  @service organizationManager;

  get initialStepId() {
    let { name, stepId } = this.args;
    return stepId ?? this.flowLinkManager.initialStepIdFor(name);
  }

  get organizationSlug() {
    return this.organizationManager.organization.slug;
  }

  get query() {
    return this.args.query ?? {};
  }

  @action
  clearSessionStorage() {
    this.flow.clearStorage();
  }
}
