/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { ProviderLogo } from 'qonto/react/components/insurance-hub/provider-logo';

export default class InsuranceHubProductSidebarComponent extends Component {
  @service intl;
  @service localeManager;
  @service organizationManager;

  providerLogo = ProviderLogo;

  get shouldDisplayDisclaimer() {
    return !this.args.hasActiveContract;
  }

  get legalArticle() {
    let { organization } = this.organizationManager;

    if (organization.isFrench)
      return this.localeManager.locale === 'fr'
        ? '#information-prealable-a-la-conclusion-d-un-contrat-d-assurance-fr'
        : '#pre-contractual-information-for-the-conclusion-of-an-insurance-contract-en';

    if (organization.isGerman)
      return this.localeManager.locale === 'de' ? '#erstinformation-de' : '#erstinformation-en';
  }

  get disclaimer() {
    let { organization } = this.organizationManager;
    let translationParams = {
      link: this.intl.t('insurance-hub.details.sidebar.link-text.legal-notice'),
    };

    return organization.isFrench
      ? {
          text: this.intl.t('insurance-hub.details.sidebar.legal-notice-fr', translationParams),
          key: 'insurance-hub.details.sidebar.legal-notice-fr',
        }
      : {
          text: this.intl.t('insurance-hub.details.sidebar.legal-notice-de', translationParams),
          key: 'insurance-hub.details.sidebar.legal-notice-de',
        };
  }
}
