/* import __COLOCATED_TEMPLATE__ from './type-selection.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES } from 'qonto/constants/direct-debit-subscriptions';
import { apiBaseURL, billingNamespace } from 'qonto/constants/hosts';
import { TypeCard } from 'qonto/react/components/direct-debit-collections/type-card';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class FlowsDirectDebitCollectionsNewTypeSelectionComponent extends Component {
  @service sentry;
  @service intl;
  @service segment;
  @service subscriptionManager;
  @service toastFlashMessages;
  @service router;
  @service networkManager;

  TypeCard = TypeCard;

  get hasRecurringInvoiceEnabled() {
    return this.subscriptionManager.hasFeature('recurring_invoices');
  }

  @action
  onTypeSelect(type) {
    this.args.context.directDebitSubscription.scheduleType =
      type === 'one-off'
        ? DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.ONE_OFF
        : DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.MONTHLY;

    this.segment.track('incoming-direct-debit-flow_collection-type_selected', {
      collection_type: type,
    });

    this.args.transitionToNext();
  }

  onRecurringInvoiceSelect = dropTask(async () => {
    this.segment.track('incoming-direct-debit-flow_collection-type_selected', {
      collection_type: 'recurring-invoices',
    });

    if (this.hasRecurringInvoiceEnabled) {
      this.router.transitionTo('invoice-subscriptions.new');
      return;
    }

    try {
      let {
        recommended_recurrence: recurrence,
        recommended_product: { code },
      } = await this.networkManager.request(
        `${apiBaseURL}/${billingNamespace}/subscriptions/upgrade_recommendation?feature_code=recurring_invoices`
      );

      this.args.context.recurrence = recurrence;
      this.args.context.addonCode = code;

      this.args.pushFlow('addon-change', 'addons');
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });
}
