/* import __COLOCATED_TEMPLATE__ from './cancel-confirmation-modal.hbs'; */
import { InvalidError } from '@ember-data/adapter/error';
import { getOwner } from '@ember/owner';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class CancelConfirmationModal extends Component {
  @service intl;
  @service toastFlashMessages;
  @service sentry;

  getErrorToastTranslation(isInvalid) {
    if (isInvalid) {
      return this.intl.t('f24.archive.cancel.error.cutoff');
    }

    return this.intl.t('f24.archive.cancel.error.generic');
  }

  f24PaymentCancelTask = dropTask(async () => {
    try {
      let { f24Order, closeSidebar } = this.args.data;
      await f24Order.cancelF24();
      let route = getOwner(this).lookup('route:f24.list.upcoming');
      await route.refresh();
      closeSidebar();
      this.args.close();
      this.toastFlashMessages.toastSuccess(this.intl.t('f24.archive.cancel.success'));
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      let errorTranslation = this.getErrorToastTranslation(error instanceof InvalidError);
      this.toastFlashMessages.toastError(errorTranslation);
      this.args.close();
    }
  });
}
