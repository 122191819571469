/* import __COLOCATED_TEMPLATE__ from './inail.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { ERROR_KEYS } from 'qonto/constants/f24';

const MAX_ITEMS_COUNT = 3;

const FIELD_NAME_KEYS = {
  branchCode: 'f24.manual-declaration.module.inail.field1',
  companyCode: 'f24.manual-declaration.module.inail.field2',
  checkCode: 'f24.manual-declaration.module.inail.field3',
  referenceNumber: 'f24.manual-declaration.module.inail.field4',
  taxType: 'f24.manual-declaration.module.inail.field5',
  taxAmount: 'f24.manual-declaration.module.inail.field6',
  compensationAmount: 'f24.manual-declaration.module.inail.field7',
};

export default class F24FormErario extends Component {
  fieldNameKeys = FIELD_NAME_KEYS;

  @action
  getErrorKey(code) {
    return ERROR_KEYS[code] ?? ERROR_KEYS.generic_error;
  }

  get isAddItemDisabled() {
    return this.args.taxInformation.inailTaxList.length >= MAX_ITEMS_COUNT;
  }

  get totalTax() {
    return this.sumUp('taxAmount');
  }

  get totalCompensation() {
    return this.sumUp('compensationAmount');
  }

  get netTotal() {
    return Number(this.totalTax - this.totalCompensation).toFixed(2);
  }

  sumUp(attributeName) {
    return this.args.taxInformation.inailTaxList
      .reduce((acc, item) => {
        let value = item[attributeName] || 0;
        return acc + Number(value);
      }, 0)
      .toFixed(2);
  }
}
