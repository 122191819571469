export default {
  "body": "cgA",
  "members": "cgY",
  "members-results": "cgh",
  "members-list": "cgf",
  "members-title": "cgK caption-bold",
  "member": "cgG",
  "empty-illustration": "cgr",
  "empty-title": "cgb title-3",
  "empty-description": "cgy body-2",
  "invitable-members": "cgU",
  "details": "cgj",
  "pagination-footer": "cgW"
};
