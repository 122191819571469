/* import __COLOCATED_TEMPLATE__ from './pagopa-notice-number-field.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { isHTMLSafe } from '@ember/template';
import Component from '@glimmer/component';

import { formatPagopaNoticeNumber, handleFormatPagopaNoticeNumber } from 'qonto/utils/format-input';

export default class PagopaNoticeNumberField extends Component {
  elementId = guidFor(this);
  inputId = `${this.elementId}-input`;
  messageId = `${this.elementId}-msg`;

  get noticeInfoValue() {
    let { value } = this.args;
    return formatPagopaNoticeNumber(value);
  }

  get hasError() {
    let { message, messageLevel } = this.args;
    // @message can potentially be a SafeString object when using the `htmlSafe=true` option in `intl.t()`
    // eg: `@message={{t "my.key" htmlSafe=true}}`
    return (
      messageLevel === 'error' &&
      (message?.length > 0 || // Native string
        (message && isHTMLSafe(message) && message.toString?.().length > 0)) // The SafeString object exposes a `toString` method
    );
  }

  @action
  handleInput({ target }) {
    handleFormatPagopaNoticeNumber(target);
    this.args.update(target.value.replace(/[^a-zA-Z0-9]/g, ''));
  }
}
