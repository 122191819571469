/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

export default class SuccessComponent extends Component {
  lottiePlayer = LottiePlayer;

  @service intl;
  @service organizationManager;
  @service router;
  @service segment;
  @reads('args.context.activation') activation;
  @reads('args.context.origin') origin;

  constructor() {
    super(...arguments);
    this.segment.track('incoming-direct-debits-activation_success-viewed');
  }

  get isFrenchOrganization() {
    return this.organizationManager.organization.legalCountry === 'FR';
  }

  get translationStrings() {
    if (this.isFrenchOrganization) {
      if (this.activation.cidRequested) {
        let translations = {
          title: this.intl.t('sdd-collections.activation-request.title'),
          subtitle: this.intl.t('sdd-collections.activation-request.subtitle'),
          cta: this.intl.t('sdd-collections.activation-request.cta'),
        };

        if (['invoice-subscriptions.edit', 'invoice-subscriptions.new'].includes(this.origin)) {
          translations.subtitle = this.intl.t(
            'sdd-collections.activation-request.recurring-invoices.new.pending-subtitle'
          );
          translations.cta = this.intl.t(
            'sdd-collections.activation-request.recurring-invoices.new.cta'
          );
        }

        if (this.origin === 'invoice-subscriptions.index') {
          translations.cta = this.intl.t(
            'sdd-collections.activation-request.recurring-invoices.list.cta'
          );
        }

        return translations;
      }

      let translations = {
        title: this.intl.t('sdd-collections.activation-verification.title'),
        subtitle: this.intl.t('sdd-collections.activation-verification.subtitle'),
        cta: this.intl.t('sdd-collections.activation-verification.cta'),
      };

      if (this.origin === 'invoice-subscriptions.index') {
        translations.cta = this.intl.t(
          'sdd-collections.activation-request.recurring-invoices.list.cta'
        );
      }

      if (['invoice-subscriptions.edit', 'invoice-subscriptions.new'].includes(this.origin)) {
        translations.subtitle = this.intl.t(
          'sdd-collections.activation-request.recurring-invoices.new.pending-subtitle'
        );
        translations.cta = this.intl.t(
          'sdd-collections.activation-request.recurring-invoices.new.cta'
        );
      }
      return translations;
    }

    let translations = {
      title: this.intl.t('sdd-collections.activation-success.title'),
      subtitle: this.intl.t('sdd-collections.activation-success.subtitle'),
      cta: this.intl.t('sdd-collections.activation-success.cta'),
    };

    if (this.origin === 'invoice-subscriptions.index') {
      translations.cta = this.intl.t(
        'sdd-collections.activation-request.recurring-invoices.list.setup-cta'
      );
      translations.subtitle = this.intl.t(
        'sdd-collections.activation-request.recurring-invoices.list.subtitle'
      );
    }
    if (['invoice-subscriptions.edit', 'invoice-subscriptions.new'].includes(this.origin)) {
      translations.subtitle = this.intl.t(
        'sdd-collections.activation-request.recurring-invoices.new.subtitle'
      );
      translations.cta = this.intl.t(
        'sdd-collections.activation-request.recurring-invoices.new.cta'
      );
    }

    return translations;
  }

  @action
  handleCTAClick() {
    this.router.transitionTo(this.origin || 'direct-debit-collections');
  }
}
