/* import __COLOCATED_TEMPLATE__ from './time-frame.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import dayjs from 'dayjs';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';

export default class FlowsBudgetsTimeFrame extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service segment;
  @service intl;

  startDate = dayjs().format(DATE_PICKER_FIELD_FORMAT);

  @tracked error;
  @tracked selectedDates = {
    endDate:
      this.args.context.selectedEndDate ??
      dayjs(this.startDate).add(11, 'month').endOf('month').format(DATE_PICKER_FIELD_FORMAT),
    startDate:
      this.args.context.selectedStartDate ??
      dayjs(this.startDate).startOf('month').format(DATE_PICKER_FIELD_FORMAT),
  };

  @action handleDatesSelected(selectedDates) {
    this.selectedDates = selectedDates;
    this.error = null;
  }

  @action submit() {
    this.segment.track('budget-creation_budget-period-definition_clicked');
    if (!this.selectedDates.endDate) {
      this.error = this.intl.t('validations.errors.presence');
    } else {
      this.args.context.selectedEndDate = this.selectedDates.endDate;
      this.args.context.selectedStartDate = this.selectedDates.startDate;
      this.args.transitionToNext();
    }
  }
}
