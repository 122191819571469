/* import __COLOCATED_TEMPLATE__ from './digital-card-kyc-intro.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class FlowsCardsKycDigitalCardKycIntro extends Component {
  @service segment;

  constructor() {
    super(...arguments);

    this.segment.track('submit_kyc_information_viewed', { origin: 'card_flow' });
  }
}
