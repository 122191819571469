/* import __COLOCATED_TEMPLATE__ from './insurance-level-tile.hbs'; */
import Component from '@glimmer/component';

import { BadgeStatus } from '@repo/design-system-kit';

export default class InsuranceLevelTileComponent extends Component {
  badgeStatus = BadgeStatus;

  get iconUri() {
    return `/illustrations/cards/card-${this.args.cardLevel}.svg`;
  }
}
