/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { restartableTask } from 'ember-concurrency';
import window from 'ember-window-mock';

import { apiBaseURL, insuranceHubNamespace } from 'qonto/constants/hosts';
import { PROVIDERS_MAP } from 'qonto/constants/insurance-hub';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class InsuranceHubSidebarComponent extends Component {
  @service modals;
  @service networkManager;
  @service organizationManager;
  @service segment;
  @service sentry;

  @action
  onPreviewFile(attachment) {
    let {
      insuranceContract: { id, contractId, type, status },
    } = this.args;
    let country = this.organizationManager.organization.legalCountry;

    this.fetchAttachmentTask
      .perform(id, attachment.id)
      .then(response => {
        let { redirect_to: downloadUrl } = response;

        this.segment.track('insurance-hub_my-policies_policy_document_clicked', {
          policy_number: contractId,
          document_name: attachment.filename,
          product: type,
          policy_status: status,
          partner: PROVIDERS_MAP[country],
        });

        window.open(downloadUrl, '_blank');
      })
      .catch(ignoreCancelation);
  }

  fetchAttachmentTask = restartableTask(async (id, attachmentId) => {
    try {
      return await this.networkManager.request(
        `${apiBaseURL}/${insuranceHubNamespace}/insurances/insurance_contracts/${id}/attachments/${attachmentId}`
      );
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });
}
