/* import __COLOCATED_TEMPLATE__ from './einvoice-activation.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class EInvoiceActivationController extends Component {
  @service toastFlashMessages;
  @service intl;
  @service organizationManager;
  @service sentry;
  @service store;
  @service segment;

  @tracked eInvoiceActivation = null;

  constructor() {
    super(...arguments);
    this.fetchDataTask.perform().catch(ignoreCancelation);
  }

  fetchDataTask = dropTask(async () => {
    this.eInvoiceActivation = await this.store.findRecord(
      'e-invoice-activation',
      this.organizationManager.organization.id
    );
  });

  confirmTask = dropTask(async () => {
    try {
      await this.eInvoiceActivation.save();
      await this.organizationManager.organization.reload();

      this.segment.track('invoice_einvoicing-activation_continue');
      this.args.completeFlow();
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });
}
