export default {
  "container": "cBL",
  "has-background": "cBT",
  "wrapper": "cBz",
  "error-wrapper": "cBa",
  "bottom-btns": "cBH",
  "bottom-wrapper": "cBO",
  "tab-nav": "cBm",
  "tab-nav-small": "cBp",
  "btn-clear": "cBx",
  "error-illustration": "cBw",
  "error-title": "cBN title-1",
  "disclaimer-wrapper": "cBv",
  "disclaimer": "cBo",
  "error-description": "cBn body-2",
  "table-wrapper": "cBi",
  "title": "cBA title-1",
  "lineup-toggle": "cBY",
  "description": "cBh body-2",
  "loading-wrapper": "cBf",
  "card": "cBK",
  "plan": "cBG",
  "placeholder-header": "cBr",
  "disclaimer-info": "cBb",
  "spinner": "cBy"
};
