/* import __COLOCATED_TEMPLATE__ from './context.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import dayjs from 'dayjs';

import { SUBSCRIPTION_RECURRENCES } from 'qonto/constants/subscriptions';

export default class ContextComponent extends Component {
  @service intl;
  @service subscriptionManager;
  @service segment;

  get planPriceWithVAT() {
    return this.subscriptionManager.planPriceWithVAT.value;
  }

  get isProRatedAmount() {
    let nextSubscriptionBillingDate = dayjs(
      this.subscriptionManager.currentSubscription?.nextSubscriptionBillingDate
    );
    if (nextSubscriptionBillingDate.isSame(nextSubscriptionBillingDate.startOf('month'))) {
      return false;
    }
    return true;
  }

  get nextBillingDate() {
    return dateToken({
      locale: this.intl.primaryLocale,
      token: DATE_FORMAT_TOKENS.COMPACT,
      date: this.subscriptionManager.currentSubscription?.nextSubscriptionBillingDate,
    });
  }

  get recurrence() {
    if (
      this.subscriptionManager.currentSubscription.recurrence === SUBSCRIPTION_RECURRENCES.ANNUAL
    ) {
      return this.intl.t('payment-activation-top-up.context.recurrence-annual');
    }
    return this.intl.t('payment-activation-top-up.context.recurrence-monthly');
  }

  get planName() {
    return this.subscriptionManager.currentPricePlan.localName;
  }

  @action
  handleClick() {
    let { transitionToNext } = this.args;

    this.segment.track('interstitial_top_up_clicked');

    transitionToNext();
  }
}
