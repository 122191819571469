/* import __COLOCATED_TEMPLATE__ from './label-list-icon.hbs'; */
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';

export default class LabelListIconComponent extends Component {
  @reads('args.labelList.name') name;

  get style() {
    let color = this.args.labelList.color;

    return htmlSafe(`background-color: ${color}`);
  }
}
