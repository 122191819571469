export default {
  "sidebar-section": "cXK body-2",
  "sidebar-section-header": "cXG body-1 mb-4",
  "providers-logos": "cXr",
  "risks": "cXb",
  "tag": "cXy tag",
  "external-link-icon": "cXU",
  "disclaimer": "cXj",
  "divider": "cXW"
};
