/* import __COLOCATED_TEMPLATE__ from './members.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { compare } from '@ember/utils';
import Component from '@glimmer/component';

import { NUM_OF_NAMES_TO_DISPLAY } from 'qonto/constants/budget';
import { ROLES } from 'qonto/constants/membership';

export default class FlowsBudgetsCreateBudgetTeamSelectionMembers extends Component {
  @service intl;

  get teamMembersList() {
    let { team, memberships } = this.args.teamInfos;

    let membersCount = this.intl.t('team-budgets.creation.team-selection.team.members', {
      count: team.activeMembershipsCount ?? 0,
    });

    if (!team.activeMembershipsCount) {
      return membersCount;
    }

    let teamManagersCount = team.hasMany('teamManagers').ids().length;
    let managers = memberships
      .filter(({ role }) => role === ROLES.MANAGER)
      .sort((a, b) => compare(a.lastName, b.lastName));

    let managersList = this.intl.t('team-budgets.creation.team-selection.team.managers', {
      count: teamManagersCount,
      managerNameOne: managers[0]?.fullName,
      managerNameTwo: managers[1]?.fullName,
      remaining: teamManagersCount - NUM_OF_NAMES_TO_DISPLAY,
    });

    return htmlSafe(`${membersCount} &middot; ${managersList}`);
  }
}
