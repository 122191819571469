import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

export default class ModalComponent extends Component {
  @service modals;

  constructor() {
    super(...arguments);
    // No need to catch here, the task should be canceled when the component is destroyed
    // eslint-disable-next-line ember-concurrency/no-perform-without-catch
    this.openModalTask.perform();
  }

  willDestroy() {
    this.openModalTask.cancelAll();
    super.willDestroy(...arguments);
  }

  openModalTask = dropTask(async () => {
    let { modalComponent, disableClickOutside, ...data } = this.args;
    let modal;
    let result;
    let focusTrapOptions = { clickOutsideDeactivates: !disableClickOutside };
    try {
      modal = this.modals.open(modalComponent, data, { focusTrapOptions });
      result = await modal;
    } finally {
      modal.close();
      data.onClose(result);
    }
  });
}
