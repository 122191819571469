/* import __COLOCATED_TEMPLATE__ from './mandate.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Checkbox } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

export default class FlowsCheckMandateComponent extends Component {
  checkbox = Checkbox;

  @service toastFlashMessages;
  @service intl;
  @service store;

  @tracked isFNCIAccepted = false;
  @tracked showFNCIerror = false;

  constructor() {
    super(...arguments);

    if (this.args.context.hasValidMandate) {
      this.isFNCIAccepted = true;
    }
  }

  handleNext = dropTask(async () => {
    if (this.isFNCIAccepted) {
      try {
        await this.store
          .adapterFor('check')
          .createMandate(this.args.context.check.get('organization.id'));

        this.args.context.hasValidMandate = true;
        this.args.transitionToNext();
      } catch {
        this.toastFlashMessages.toastError(this.intl.t('checks.errors.general'));
      }
    } else {
      this.showFNCIerror = true;
    }
  });

  @action
  onFNCIAccept() {
    this.isFNCIAccepted = !this.isFNCIAccepted;
    this.showFNCIerror = false;
  }
}
