/* import __COLOCATED_TEMPLATE__ from './set-pin-transition.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class FlowsCardsKycSetPinTransition extends Component {
  @service segment;

  @action
  handleClick() {
    let { transitionToNext } = this.args;

    this.segment.track('physical_card_set_pin_start');
    transitionToNext();
  }
}
