export default {
  "animated": "rO",
  "container": "rm",
  "cards-selectors": "rp",
  "card-selector": "rx",
  "selector": "rw",
  "header": "rN",
  "footer": "rv",
  "skip-cta": "ro",
  "pricing-caption": "rn",
  "show": "ri",
  "selectorFadeIn": "rA",
  "fadeIn": "rY",
  "asset-one": "rh",
  "onePlusAssetFadeIn": "rf",
  "assetMoveUp": "rK",
  "asset-plus": "rG",
  "asset-x": "rr",
  "xAssetFadein": "rb",
  "feature": "ry",
  "highlighted": "rU"
};
