/* import __COLOCATED_TEMPLATE__ from './choose-alert.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class QontoHubNotificationsCreateStepsChooseAlertComponent extends Component {
  @tracked hasBalanceAlert;
  @tracked hasTransactionAlert;

  constructor() {
    super(...arguments);
    this.hasBalanceAlert = this.findRuleByTrigger('balance');
    this.hasTransactionAlert = this.findRuleByTrigger('transaction');
  }

  findRuleByTrigger(trigger) {
    let { rules } = this.args.context.hubConnection;
    return rules.find(rule => !rule.isNew && rule.trigger === trigger);
  }

  get channelUrl() {
    let reg = /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/?\n]+)/;
    let { configuration_url, channel } = this.args.context.hubConnection.params;

    let domainName = reg.exec(configuration_url)[0];
    let channelName = channel.replace('#', '');

    return `${domainName}/app_redirect?channel=${channelName}`;
  }

  @action
  handleNext(trigger) {
    let isBalance = trigger === 'balance';

    this.args.context.hubNotificationRule.setProperties({
      trigger,
      field: isBalance ? 'authorized_balance_cents' : 'amount_cents',
      filter: isBalance ? 'lte' : 'gte',
    });
    this.args.transitionToNext();
  }
}
