/* import __COLOCATED_TEMPLATE__ from './label-select.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { sortByKey } from 'qonto/utils/sort-by-keys';

export default class LabelSelectComponent extends Component {
  placeholder = this.args.placeholder || 'placeholders.all';

  get sortedListLabels() {
    return [...this.args.labelList.labels].sort(sortByKey('name'));
  }

  @action handleUpdateLabel(label) {
    return this.args.saveLabel(this.args.labelList, label);
  }
}
