/* import __COLOCATED_TEMPLATE__ from './integrations.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';

export default class FlowsInvoicesClientsImportIntegrations extends Component {
  @service organizationManager;
  @service connectManager;

  @reads('args.context') context;
  @reads('args.context.integrations') integrations;
  @reads('organizationManager.organization') organization;
}
