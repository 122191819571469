export default {
  "wrapper": "cXv",
  "content": "cXo",
  "back": "cXn btn btn--tertiary",
  "heading": "cXi",
  "heading-content": "cXA",
  "logo": "cXY",
  "description": "cXh body-2",
  "cta": "cXf"
};
