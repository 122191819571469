/* import __COLOCATED_TEMPLATE__ from './preset.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';

import { Spinner } from '@repo/design-system-kit';

export default class FiltersPresetComponent extends Component {
  spinner = Spinner;

  elementId = guidFor(this);
  presetButtonId = `${this.elementId}-preset-button`;

  get formData() {
    if (this.args.cachedFilterGroup) {
      let formData = {
        id: this.elementId,
        query: {
          filter_group: {
            conditional: this.args.cachedFilterGroup.conditional,
            expressions: this.args.cachedFilterGroup.expressions,
          },
        },
      };
      return formData;
    } else {
      return this.args.cachedFormData.find(({ id }) => id === this.elementId);
    }
  }

  get isActive() {
    return (
      (this.elementId === this.args.activeId || this.args.cachedFilterGroup) &&
      !this.args.selectedPreset
    );
  }

  get isLoading() {
    return this.args.isFetchingTransactions && this.isActive;
  }

  get showCounter() {
    return !isNaN(this.count) && this.isActive;
  }

  get count() {
    return this.isActive ? this.args.count : this.args.presetCount;
  }

  @action
  togglePresetDropdown(showMenu) {
    if (this.args.queryParamsFilters && showMenu) {
      let filterGroup = this.args.queryParamsFilters;
      let formData = {
        id: this.elementId,
        query: { ...this.args.queryOptions, filter_group: filterGroup },
      };

      this.args.updateActiveFilterId(this.elementId);
      this.args.refreshCache(formData, true);
    }
  }

  @action
  clearFilters(toggle) {
    this.args.clearFilters();
    toggle();
  }

  @action
  onSubmit(toggle, filterGroup) {
    let query = { ...this.args.queryOptions, filter_group: filterGroup };

    if (query.pagination?.page) {
      query.pagination.page = 1;
    }

    this.args.applyFilters(query, this.elementId);
    toggle();
  }

  @action
  refreshCache(filterGroup) {
    let formData = {
      id: this.elementId,
      query: { ...this.args.queryOptions, filter_group: filterGroup },
    };

    this.args.refreshCache(formData, true);
  }
}
