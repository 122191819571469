/* import __COLOCATED_TEMPLATE__ from './image-zoomer.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class ImageZoomerComponent extends Component {
  @tracked isLoading = true;

  @action
  onImageLoaded() {
    this.isLoading = false;
  }
}
