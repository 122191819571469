export default {
  "container": "As",
  "header-wrapper": "AF mb-16",
  "header": "Ag",
  "logo": "AD mr-16",
  "title": "AV title-4",
  "description": "AL body-2",
  "conditions": "AT",
  "label": "Az caption mb-4",
  "tags": "Aa"
};
