export default {
  "card": "csG",
  "focus": "csr",
  "title-format": "csb",
  "header": "csy",
  "headings": "csU",
  "title": "csj title-4",
  "main": "csW title-3",
  "footer": "csu"
};
