/* import __COLOCATED_TEMPLATE__ from './review.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class F24ManualDeclarationConfirmComponent extends Component {
  disclaimerBlock = Disclaimer.Block;

  @service errors;
  @service intl;
  @service toastFlashMessages;
  @service segment;
  @service sentry;

  handleSubmitTask = dropTask(async () => {
    let { f24Order } = this.args.context;

    try {
      await f24Order.create();
      this.segment.track('f24_create-flow_step5-info-reviewed');
      this.args.transitionToNext();
    } catch (error) {
      if (hasMFAError(error?.errors) || !error.isAdapterError) {
        throw error;
      }

      let isInvalidError = f24Order.isInvalidErrorWithPointers(error);
      if (!isInvalidError && ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      let genericMessage = this.errors.messageForStatus(error);
      if (genericMessage) {
        this.toastFlashMessages.toastError(genericMessage);
      } else {
        this.toastFlashMessages.toastError(
          this.intl.t('f24.manual-declaration.review.generic-error')
        );
      }
    }
  });
}
