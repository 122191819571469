/* import __COLOCATED_TEMPLATE__ from './next-installments.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { BadgeStatus } from '@repo/design-system-kit';

import { REPAYMENT_STATUS } from 'qonto/constants/financing';

const REPAYMENT_INSIGHT_STATES = {
  [REPAYMENT_STATUS.FROZEN]: 'error',
};

export default class FinancingPayLaterCockpitInsightsTilesNextInstallmentsComponent extends Component {
  badgeStatus = BadgeStatus;

  @service intl;

  get tileStatus() {
    return REPAYMENT_INSIGHT_STATES[this.args.repaymentStatus];
  }

  createTileDetails(level, text, tooltipKey) {
    let badge = {
      level,
      text,
    };
    let tooltip = tooltipKey ? tooltipKey : null;
    return { badge, tooltip };
  }

  get tileDetails() {
    switch (this.args.repaymentStatus) {
      case REPAYMENT_STATUS.AT_RISK:
        return this.createTileDetails(
          'warning',
          this.intl.t('financing.pay-later.cockpit.insights.next-installments.at-risk.badge-text'),
          this.intl.t('financing.pay-later.cockpit.insights.next-installments.at-risk.tooltip', {
            count: this.args.installmentsCount,
          })
        );
      case REPAYMENT_STATUS.LATE:
        return this.createTileDetails(
          'error',
          this.intl.t('financing.pay-later.cockpit.insights.next-installments.overdue.badge-text'),
          this.intl.t('financing.pay-later.cockpit.insights.next-installments.late.tooltip', {
            count: this.args.installmentsCount,
          })
        );
      default:
        return null;
    }
  }
}
