/* import __COLOCATED_TEMPLATE__ from './text-field.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';

export default class F24TextField extends Component {
  elementId = guidFor(this);
  inputId = `${this.elementId}-input`;
  messageId = `${this.elementId}-msg`;

  @action
  handleInput(event) {
    let { value } = event.target;
    this.args.onUpdate(value);
  }
}
