export default {
  "upsell-cta": "cZP",
  "discover-badge": "cZl",
  "role-selection-item": "cZX",
  "unavailable": "cZC",
  "role-headline": "cZk",
  "illustration": "cZJ",
  "role-description": "cZs",
  "description-item": "cZF",
  "description-icon": "cZg",
  "description-text": "cZD",
  "current-role": "cZV"
};
