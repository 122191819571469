/* import __COLOCATED_TEMPLATE__ from './bic-field.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { formatBIC, handleFormatBIC } from 'qonto/utils/format-input';

export default class BICField extends Component {
  get bic() {
    let { value } = this.args;
    return formatBIC(value);
  }

  @action
  handleOnInput(event) {
    let { target } = event;
    handleFormatBIC(target);
    this.args.update(target.value);
  }
}
