/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';
import { SubscriptionSuccess } from '@repo/domain-kit/pricing';

import { TRACKING_TRIAL_STATE } from 'qonto/constants/subscriptions';

export default class SubscriptionChangeSuccessComponent extends Component {
  lottiePlayer = LottiePlayer;
  subscriptionSuccess = SubscriptionSuccess;

  @service intl;
  @service segment;
  @service subscriptionManager;
  @service organizationManager;

  constructor() {
    super(...arguments);
    this.sendTrackingEventOnLoad();
  }

  sendTrackingEventOnLoad() {
    let context = this.args.context;
    let changeType = context.changeType;
    let currentPlan = context.currentPlan;

    if (changeType === 'change-recurrence') {
      this.segment.track('account-closing_change-recurrence_completed', {
        current_plan: currentPlan,
      });
    } else if (changeType === 'switch-plan') {
      this.segment.track('account-closing_switch-plan_completed', {
        current_plan: currentPlan,
      });
    } else if (changeType === 'annual-switch-plan') {
      this.segment.track('account-closing_annual-switch-plan_completed', {
        current_plan: currentPlan,
      });
    }

    this.segment.track('plans_change_confirmation_success', {
      recurrence: context.recurrence,
      trial_state: this.trackingTrialState,
      target_plan: context.subscription.product?.get('code'),
      current_plan: context.currentPricePlanCode,
    });
  }

  get hasInitialTrial() {
    return this.subscriptionManager.currentSubscription?.hasInitialTrial;
  }

  get trackingTrialState() {
    let trialState = TRACKING_TRIAL_STATE.NONE;

    if (this.args.context.isFreeTrial) {
      trialState = TRACKING_TRIAL_STATE.FREE_TRIAL;
    }

    if (this.hasInitialTrial) {
      trialState = TRACKING_TRIAL_STATE.INITIAL_TRIAL;
    }

    return trialState;
  }

  get CTAText() {
    if (this.isConnectUpsell) {
      return this.intl.t('subscription.change.success.connect-cta');
    }
    return this.intl.t('btn.close');
  }

  get subtitle() {
    if (this.isConnectUpsell) {
      return this.intl.t('subscription.change.success.connect-subtitle');
    }
    return this.intl.t('subscription.change.success.subtitle');
  }

  get isConnectUpsell() {
    return this.args.context?.refererPage?.includes('connect');
  }
}
