/* import __COLOCATED_TEMPLATE__ from './upload-documents.hbs'; */
import { action, set } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import LocalFile from 'qonto/objects/local-file';
import { addDocumentFileToModel, removeDocumentFileFromModel } from 'qonto/utils/handle-documents';

export const DOCUMENT_TYPES = {
  passport: 'eu-passport',
  idCard: 'eu-id-card',
  residentPermit: 'eu-resident-permit',
};

export const FRONT_DOCUMENT = 'front';
export const BACK_DOCUMENT = 'back';

export default class FlowsKYCUploadDocuments extends Component {
  @service intl;
  @service segment;
  @service modals;
  @service store;
  @service organizationManager;

  @tracked showError = false;

  @tracked singleDocumentLocalFile;
  @tracked frontDocumentLocalFile;
  @tracked backDocumentLocalFile;
  @reads('organizationManager.organization.legalCountry') legalCountry;

  @action
  formatFileSize(val) {
    return formatFileSize(this.intl, val);
  }

  get title() {
    let { idCard, residentPermit, passport } = DOCUMENT_TYPES;

    switch (this.documentType) {
      case idCard: {
        return this.intl.t('kyc.on-demand.document-upload.eu-id-card.title', {
          legalCountry: this.legalCountry,
        });
      }
      case passport: {
        return this.intl.t('kyc.on-demand.document-upload.eu-passport.title');
      }
      case residentPermit: {
        return this.intl.t('kyc.on-demand.document-upload.eu-resident-permit.title');
      }
    }
  }

  get documents() {
    return this.args.documents;
  }

  get documentType() {
    return this.args.documentType;
  }

  get invite() {
    return this.args.invite;
  }

  get uploaderLabel() {
    return this.intl.t('kyc.on-demand.document-upload.eu-passport.uploader-text');
  }

  get uploaderLabelFront() {
    let { idCard, residentPermit } = DOCUMENT_TYPES;

    switch (this.documentType) {
      case idCard: {
        return this.intl.t('invitation-flow.mobile.card.front');
      }
      case residentPermit: {
        return this.intl.t('invitation-flow.mobile.permit.front');
      }
    }
  }

  get uploaderLabelBack() {
    let { idCard, residentPermit } = DOCUMENT_TYPES;

    switch (this.documentType) {
      case idCard: {
        return this.intl.t('invitation-flow.mobile.card.revers');
      }
      case residentPermit: {
        return this.intl.t('invitation-flow.mobile.permit.revers');
      }
    }
  }

  get documentsDropzoneText() {
    return this.intl.t('join-team.mobile.about-you.add-poi.drop-zone.label');
  }

  get isDoubleSidePhotoNeeded() {
    let { idCard, residentPermit } = DOCUMENT_TYPES;
    return [idCard, residentPermit].includes(this.documentType);
  }

  get singleDocument() {
    return this.documents[0];
  }

  get frontDocument() {
    return this.documents.find(({ type }) => type === FRONT_DOCUMENT);
  }

  get backDocument() {
    return this.documents.find(({ type }) => type === BACK_DOCUMENT);
  }

  resetError() {
    this.showError = false;
  }

  createFileEntityTask = dropTask(async (file, type) => {
    let fileEntity = {
      fileName: file.name,
      fileSize: file.size,
      fileContentType: file.type,
      fileUrl: file.url,
    };

    if (type) {
      fileEntity.type = type;
    }

    let data = await file.readAsDataURL();
    set(fileEntity, 'fileUrl', data);

    file.queue?.remove(file);

    return fileEntity;
  });

  handleAddDocument = dropTask(async (file, type) => {
    this.resetError();

    let fileEntity = await this.createFileEntityTask.linked().perform(file, type);
    this.documents.push(fileEntity);
    if (this.invite) {
      this.addDocumentToInvite(fileEntity, type);
    }
  });

  handleAddFrontDocumentTask = dropTask(async file => {
    await this.handleAddDocument.linked().perform(file, FRONT_DOCUMENT);
  });

  handleAddDocumentBackTask = dropTask(async file => {
    await this.handleAddDocument.linked().perform(file, BACK_DOCUMENT);
  });

  addDocumentToInvite(fileEntity, type) {
    let localFile = LocalFile.create({ ...fileEntity });
    switch (type) {
      case FRONT_DOCUMENT: {
        this.frontDocumentLocalFile = localFile;
        break;
      }
      case BACK_DOCUMENT: {
        this.backDocumentLocalFile = localFile;
        break;
      }
      default: {
        this.singleDocumentLocalFile = localFile;
        break;
      }
    }
    addDocumentFileToModel(this.store, this.invite, 'poi', localFile);
  }

  @action
  handleRemoveFileFromDocument(file, type) {
    let index = this.documents.indexOf(file);
    this.documents.splice(index, 1);
    if (this.invite) {
      this.removeDocumentFromInvite(type);
    }
  }

  removeDocumentFromInvite(type) {
    let file = null;
    switch (type) {
      case FRONT_DOCUMENT: {
        file = this.frontDocumentLocalFile;
        this.frontDocumentLocalFile = null;
        break;
      }
      case BACK_DOCUMENT: {
        file = this.backDocumentLocalFile;
        this.backDocumentLocalFile = null;
        break;
      }
      default: {
        file = this.singleDocumentLocalFile;
        this.singleDocumentLocalFile = null;
        break;
      }
    }
    removeDocumentFileFromModel(this.store, this.invite, 'poi', file);
  }

  @action
  handleRemoveDocument() {
    this.handleRemoveFileFromDocument(this.singleDocument);
  }

  @action
  handleRemoveFrontDocument() {
    this.handleRemoveFileFromDocument(this.frontDocument, FRONT_DOCUMENT);
  }

  @action
  handleRemoveBackDocument() {
    this.handleRemoveFileFromDocument(this.backDocument, BACK_DOCUMENT);
  }

  get error() {
    return this.intl.t('validations.errors.upload_id_error');
  }

  @action
  confirmTask() {
    if (this.documents.length === 0) {
      this.showError = true;
      this.segment.track('join_team_information_poi_upload_failed');
      return;
    }

    this.segment.track('join_team_information_poi_upload_confirmed');
    this.args.onUpload();
  }

  @action
  handleOpenFilePreviewModal(file) {
    this.modals.open('file-preview-modal', {
      isFullScreenModal: true,
      deletable: false,
      selectedFile: file,
    });
  }
}
