/* import __COLOCATED_TEMPLATE__ from './de-freelancers-form.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { TrackedArray } from 'tracked-built-ins';

import {
  EINZELUNTERNEHMER_LEGAL_CODE,
  FREIBERUFLER_LEGAL_CODE,
} from 'qonto/constants/company-types';

const DEFAULT_FILES_LIMIT = 1;

export default class FlexKybDeFreelancersFormComponent extends Component {
  disclaimerBlock = Disclaimer.Block;

  @service intl;

  fileMaxFileSize = 30 * 1e6; // 30MB
  filesLimit = DEFAULT_FILES_LIMIT;

  files = new TrackedArray([]);

  @tracked legalNumber = null;
  @tracked legalNumberRequiredError = null;
  @tracked showFileRequiredError = false;

  constructor() {
    super(...arguments);

    let { legalNumber } = this.args;
    if (legalNumber) {
      this.legalNumber = legalNumber;
    }
  }

  get fileExtensions() {
    return '.pdf,.jpg,.jpeg,.png';
  }

  get fileDropzoneLabel() {
    return this.intl.t('labels.upload-single-file-message', {
      maxSize: formatFileSize(this.intl, this.fileMaxFileSize),
    });
  }

  get fileLabel() {
    let { legalCode } = this.args;
    if (legalCode === EINZELUNTERNEHMER_LEGAL_CODE) {
      return this.intl.t(
        'flex-kyb.de-freelancers-form.registration-certificate.einzelunternehmer.label',
        {
          htmlSafe: true,
        }
      );
    } else if (legalCode === FREIBERUFLER_LEGAL_CODE) {
      return this.intl.t(
        'flex-kyb.de-freelancers-form.registration-certificate.freiberufler.label',
        {
          htmlSafe: true,
        }
      );
    }
  }

  @action
  handleLegalNumberUpdate(legalNumber) {
    this.legalNumberRequiredError = null;
    this.legalNumber = legalNumber;
    this.handleFormChange();
  }

  @action
  handleFileUpload(file) {
    this.showFileRequiredError = false;
    this.files.push(file);
    this.handleFormChange();
  }

  @action
  handleDeleteFile(file) {
    file.deleteRecord();
    this.files = this.files.filter(f => f !== file);
    this.handleFormChange();
  }

  @action
  handleFormChange() {
    this.args.onChange?.({ legalNumber: this.legalNumber, files: this.files });
  }

  submitTask = dropTask(async () => {
    if (!this.legalNumber) {
      this.legalNumberRequiredError = this.intl.t(
        'flex-kyb.de-freelancers-form.legal-number.required-error-message'
      );
    }
    if (!this.files.length) {
      this.showFileRequiredError = true;
    }

    if (!this.legalNumber || !this.files.length) {
      return;
    }

    let { onFormSubmitTask } = this.args;
    await onFormSubmitTask.perform({
      legalNumber: this.legalNumber,
      fileId: this.files[0].id,
    });
  });
}
