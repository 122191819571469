export default {
  "wrapper": "GI",
  "wrapper-new": "Gt",
  "icon-list": "GM",
  "list-item": "GP",
  "total": "Gl",
  "box-wrapper": "GX",
  "panel": "GC",
  "panel-top": "Gk",
  "list": "GJ",
  "container": "Gs",
  "column": "GF",
  "icon": "Gg",
  "column-right": "GD"
};
