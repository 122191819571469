/* import __COLOCATED_TEMPLATE__ from './edit-exercise.hbs'; */
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

export default class FlowsBudgetsEditExercise extends Component {
  @service intl;
  @service store;
  @service segment;
  @service modals;

  @tracked exercise;

  constructor() {
    super(...arguments);
    next(() => this.#initExercise());
  }

  #initExercise() {
    let { exerciseId, budget } = this.args.context;

    if (exerciseId) {
      this.exercise = this.#findAndUpdateExercise(budget, exerciseId);
    } else {
      this.exercise = this.#createExercise(budget);
    }
    this.segment.track('edit-budget_choose-amounts_displayed');
  }

  #findAndUpdateExercise(budget, exerciseId) {
    let exercise = budget.exercises.find(({ id }) => id === exerciseId);
    let startDate = this.args.context.selectedStartDate;
    let endDate = this.args.context.selectedEndDate;
    exercise.updateStartAndEndDates({
      startDate,
      endDate,
    });
    return exercise;
  }

  #createExercise(budget) {
    let exercise = this.store.createRecord('exercise', {
      budget,
      startDate: this.args.context.selectedStartDate,
      endDate: this.args.context.selectedEndDate,
    });
    exercise.initPeriods();
    return exercise;
  }

  submitTask = dropTask(async () => {
    if (
      (this.exercise.hasDirtyAttributes && !this.exercise.isStarted) ||
      this.exercise.periods.some(period => period.hasDirtyAttributes)
    ) {
      await this.exercise.save();
    }
    this.segment.track('edit-budget_save_clicked');
    this.args.transitionToNext();
  });

  deleteTask = dropTask(async () => {
    this.segment.track('edit-budget_delete-period_clicked');

    let result = await this.modals.open('popup/destructive', {
      title: this.intl.t('team-budgets.edit.edit-exercise.delete-period.modal.title'),
      description: this.intl.t('team-budgets.edit.edit-exercise.delete-period.modal.subtitle'),
      cancel: this.intl.t('btn.back'),
      confirm: this.intl.t('team-budgets.edit.edit-exercise.delete-period.modal.button'),
    });

    if (result === 'confirm') {
      this.segment.track('edit-budget_delete-period-confirmation_clicked');
      await this.exercise.deleteRecord();
      await this.exercise.save();
      this.args.transitionToNext();
    } else {
      this.segment.track('edit-budget_delete-period-confirmation-back_clicked');
    }
  });
}
