/* import __COLOCATED_TEMPLATE__ from './tabs.hbs'; */
import Component from '@glimmer/component';

import { STATUS } from 'qonto/constants/invoice-subscriptions';

export default class InvoiceSubscriptionsTabs extends Component {
  get activeTab() {
    let { status } = this.args;
    let statuses = (status || '').split(',');
    let isCompleted = [STATUS.FINISHED, STATUS.CANCELED].includes(statuses[0]);
    return isCompleted ? 'completed' : 'processing';
  }

  get totalCount() {
    let stats = this.args.stats?.lastSuccessful?.value?.created;

    if (!stats) {
      return {
        processing: 0,
        completed: 0,
      };
    }

    let { active, scheduled, suspended, total } = stats;

    let processing = active + scheduled + suspended;

    return {
      processing,
      completed: total - processing,
    };
  }
}
