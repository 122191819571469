/* import __COLOCATED_TEMPLATE__ from './beneficiaries.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { SearchFieldWithDebounce, Spinner } from '@repo/design-system-kit';
import { dropTask, restartableTask, task } from 'ember-concurrency';

import { isIbanFromSepaZone } from 'qonto/utils/beneficiaries';
import { getCurrentParisDateString } from 'qonto/utils/date';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import {
  copyBeneficiaryIntoTransfer,
  copyBeneficiaryLabelsIntoTransfer,
  copyBeneficiaryVatIntoTransfer,
} from 'qonto/utils/transfers';

import styles from './beneficiaries.strict-module.css';
export default class FlowsTransfersSepaNewBeneficiariesComponent extends Component {
  searchField = SearchFieldWithDebounce;
  styles = styles;
  spinner = Spinner;

  @service abilities;
  @service beneficiariesManager;
  @service errors;
  @service toastFlashMessages;
  @service intl;
  @service modals;
  @service segment;
  @service sensitiveActions;

  @tracked beneficiaries = [];
  @tracked hasServerError = false;
  @tracked searchQuery = null;

  constructor() {
    super(...arguments);
    this.loadBeneficiariesTask.perform().catch(ignoreCancelation);
  }

  get shouldDisplayNewButton() {
    return this.abilities.can('create beneficiary') && !this.searchBeneficiaryTask.isRunning;
  }

  get shouldDisplaySearchEmptyState() {
    return this.beneficiaries.length === 0 && this.searchQuery && !this.hasServerError;
  }

  get shouldDisplaySearchInput() {
    let isNoBeneficiaries =
      this.beneficiaries.length === 0 && !this.searchQuery && !this.searchBeneficiaryTask.isRunning;

    return !isNoBeneficiaries || this.hasServerError;
  }

  @action
  addBeneficiary() {
    let { context, pushForwardFlow } = this.args;
    let { origin } = context;
    this.segment.track('transfer-sepa_new-beneficiary_clicked', {
      ...(origin && { origin }),
    });
    // We reset the `beneficiaryToEdit` property in case we have already visited the beneficiary edition flow
    context.beneficiaryToEdit = null;
    pushForwardFlow('sepa-transfer-beneficiary', 'add-beneficiary');
  }

  @action
  editBeneficiary(beneficiary) {
    let { context, pushForwardFlow } = this.args;
    let { origin } = context;
    this.segment.track('transfer-sepa_edit-beneficiary_clicked', {
      ...(origin && { origin }),
    });
    context.beneficiaryToEdit = beneficiary;
    pushForwardFlow('sepa-transfer-beneficiary', 'edit-beneficiary');
  }

  @action
  deleteBeneficiary(beneficiary) {
    this.modals.open('popup/destructive', {
      title: this.intl.t('transfers.modals.delete-beneficiary.title'),
      description: this.intl.t('transfers.modals.delete-beneficiary.desc'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('transfers.modals.delete-beneficiary.confirm'),
      confirmTask: this.deleteBeneficiaryTask,
      beneficiary,
    });
  }

  @action
  markBeneficiaryAsTrusted(beneficiary) {
    this.modals.open('beneficiary-trust-popup', {
      title: this.intl.t('transfers.beneficiaries.trust-popup.trust.title'),
      beneficiary,
      confirmTask: this.confirmMarkAsTrustedTask,
    });
  }

  @action
  removeTrustedMark(beneficiary) {
    this.modals.open('beneficiary-trust-popup', {
      title: this.intl.t('transfers.beneficiaries.trust-popup.untrust.title'),
      beneficiary,
      confirmTask: this.confirmRemovingTrustedMark,
    });
  }

  confirmMarkAsTrustedTask = dropTask(async (close, { beneficiary }) => {
    try {
      await this.sensitiveActions.runTask.perform(this.markAsTrustedTask, beneficiary);
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    } finally {
      close();
    }
  });

  confirmRemovingTrustedMark = task(async (close, { beneficiary }) => {
    try {
      await beneficiary.markAsUntrusted();
      this.toastFlashMessages.toastInfo(
        this.intl.t('transfers.beneficiaries.trust-popup.untrust.success')
      );
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    } finally {
      close();
    }
  });

  deleteBeneficiaryTask = task(async (close, { beneficiary }) => {
    try {
      await beneficiary.destroyRecord();
      this.toastFlashMessages.toastInfo(
        this.intl.t('transfers.beneficiaries.delete.success-message')
      );
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    } finally {
      close();
    }
  });

  loadBeneficiariesTask = task(async () => {
    try {
      this.beneficiaries = await this._loadBeneficiaries();
    } catch {
      this.hasServerError = true;
    }
  });

  markAsTrustedTask = task(async beneficiary => {
    try {
      await beneficiary.markAsTrusted();
      let message = this.intl.t('transfers.beneficiaries.trust-popup.trust.success');
      this.toastFlashMessages.toastInfo(message);
    } catch (error) {
      if (error?.errors[0].code === 'declined') {
        this.toastFlashMessages.toastError(
          this.intl.t('transfers.beneficiaries.trust-popup.trust.declined-error')
        );
      }

      if (hasMFAError(error?.errors)) {
        throw error;
      }

      this.errors.handleError(error);
    }
  });

  searchBeneficiaryTask = restartableTask(async searchQuery => {
    try {
      this.searchQuery = searchQuery;
      this.beneficiaries = await this._loadBeneficiaries(this.searchQuery);
    } catch {
      this.hasServerError = true;
    }
  });

  selectBeneficiary = dropTask(async beneficiary => {
    let { context, transitionToNext } = this.args;
    let { origin, sepaTransfer } = context;

    if (!isIbanFromSepaZone(beneficiary.iban)) {
      this.segment.track('transfer-sepa_out_of_SEPA_modal_displayed');
      return await this.modals.open('transfers/sepa/not-sepa-modal', {
        onCancel: () => this.segment.track('transfer-sepa_out_of_SEPA_cancel_clicked'),
        onConfirm: () => {
          this.segment.track('transfer-sepa_out_of_SEPA_edit_clicked');
          this.editBeneficiary(beneficiary);
        },
      });
    }

    let currentBeneficiaryId = sepaTransfer.get('beneficiary.id');

    // If the currently selected beneficiary is different from
    // the one we want to select, we reset the transfer details
    if (beneficiary.id !== currentBeneficiaryId) {
      sepaTransfer.setProperties({
        amount: null,
        notifyByEmail: false,
        operationType: 'scheduled',
        reference: null,
        scheduledDate: getCurrentParisDateString(),
        standingEndingDate: null,
        standingOn: null,
      });
    }

    sepaTransfer.set('beneficiary', beneficiary);

    copyBeneficiaryIntoTransfer(sepaTransfer, beneficiary, { forceCopy: true });

    copyBeneficiaryLabelsIntoTransfer(sepaTransfer, beneficiary);

    if (this.abilities.can('view vat bookkeeping')) {
      copyBeneficiaryVatIntoTransfer(sepaTransfer, beneficiary);
    }

    context.beneficiary = beneficiary;

    this.segment.track('transfer-sepa_beneficiary_selected', {
      ...(origin && { origin }),
    });

    transitionToNext();
  });

  _loadBeneficiaries(searchQuery) {
    this.hasServerError = false;
    let organizationId = this.args.context.sepaTransfer.get('organization.id');
    return this.beneficiariesManager.loadSepaBeneficiaries(organizationId, searchQuery);
  }
}
