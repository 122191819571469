/* import __COLOCATED_TEMPLATE__ from './date-field.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { parseDate } from '@internationalized/date';
import { DateField } from '@repo/design-system-kit';

export default class F24DateField extends Component {
  dateField = DateField;

  get birthdate() {
    let birthdate = this.args.value;
    return birthdate ? parseDate(birthdate) : birthdate;
  }

  get localClass() {
    if (this.args.hasError) {
      return 'field error';
    }
    return 'field';
  }

  @action
  updateBirthDate(value) {
    if (value) {
      this.args.onUpdate(value.toString());
    }
  }
}
