/* import __COLOCATED_TEMPLATE__ from './set-name.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class F24ManualDeclarationSetNameComponent extends Component {
  @service segment;

  @tracked showError = false;

  @action setName(value) {
    this.showError = false;
    this.args.context.f24Order.name = value;
  }

  @action onSubmit() {
    let { context, transitionToNext } = this.args;
    let { f24Order } = context;

    if (isBlank(f24Order.name)) {
      this.showError = true;
    } else {
      this.segment.track('f24_create-flow_step3-name-assigned');
      transitionToNext();
    }
  }
}
