export default {
  "container": "cdM",
  "content": "cdP",
  "quote-information-item": "cdl",
  "placeholder": "cdX",
  "divider": "cdC",
  "tooltip": "cdk",
  "amount": "cdJ title-4",
  "align-left": "cds",
  "sticky-panel": "cdF",
  "color-accent": "cdg",
  "color-secondary": "cdD"
};
