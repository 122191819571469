export default {
  "wrapper": "oI",
  "subtitle": "ot body-2",
  "legend": "oM",
  "table": "oP",
  "footer": "ol",
  "footer-codice-1": "oX",
  "footer-codice-2": "oC",
  "footer-total-a": "ok",
  "footer-total-b": "oJ",
  "footer-balance": "os",
  "errors": "oF"
};
