export default {
  "container": "bo",
  "container-metal": "bn",
  "content": "bi",
  "button": "bA",
  "embossed-selector": "bY",
  "carousel-container": "bh",
  "carousel": "bf",
  "asset": "bK",
  "poster": "bG",
  "video-embossed": "br",
  "video": "bb",
  "active": "by",
  "embossed": "bU",
  "safari": "bj",
  "poster-open": "bW"
};
