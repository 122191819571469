/* import __COLOCATED_TEMPLATE__ from './other-transaction.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { parseDate } from '@internationalized/date';
import { DatePicker } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { task } from 'ember-concurrency';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';

export default class MatchInvoiceFlowMatchingOtherTransactionComponent extends Component {
  @service modals;
  @service intl;
  @service segment;

  datePicker = DatePicker;

  @tracked datePickerValue = null;
  @tracked error;

  isDirty = false;

  get isValid() {
    return !this.error;
  }

  get dateString() {
    return this.datePickerValue ? parseDate(this.datePickerValue) : null;
  }

  get maxDateString() {
    return parseDate(dayjs().format(DATE_PICKER_FIELD_FORMAT));
  }

  get invoice() {
    return this.args.context.invoice;
  }

  @action
  updatePaidAtDate(paidAt) {
    if (this.datePickerValue?.toString() === paidAt?.toString()) {
      return;
    }

    this.segment.track('receivable_invoice_paid_on_edited');
    this.datePickerValue = paidAt?.toString();
    this.isDirty = true;
    this._validateInput();
  }

  markAsPaidWithoutAttachmentTask = task(this, { drop: true }, async () => {
    this._validateInput();

    if (this.isValid) {
      try {
        await this.invoice.markAsPaid(this.datePickerValue);
        this.segment.track('receivable_invoice_paid_on_confirmed');
        this.args.context.hasMarkedWithoutTransaction = true;
        this.args.transitionToNext();
      } catch {
        this._handleError();
      }
    }
  });

  onCloseTask = task(this, { drop: true }, async () => {
    this.segment.track('receivable_invoice_transaction_list_dropped', {
      origin: 'payment_date_cancel_btn',
    });
    if (this.isDirty) {
      let result = await this.modals.open('popup/confirmation', {
        title: this.intl.t(
          'receivable-invoices.mark-as-paid.without-transaction-step.exit-modal.title'
        ),
        description: this.intl.t(
          'receivable-invoices.mark-as-paid.without-transaction-step.exit-modal.description'
        ),
        confirm: this.intl.t(
          'receivable-invoices.mark-as-paid.without-transaction-step.exit-modal.cta.leave'
        ),
        cancel: this.intl.t('btn.back'),
        confirmTask: this.modalConfirmTask,
      });

      if (result === 'cancel' || result === 'close') {
        this.segment.track('receivable_invoice_transaction_list_stay');
      }
    } else {
      this.args.abort();
    }
  });

  modalConfirmTask = task(this, { drop: true }, async close => {
    this.args.context.abortOrigin = 'confirmation_modal';
    this.args.abort();
    await close();
  });

  _validateInput() {
    if (!this.datePickerValue) {
      this.error = this.intl.t(
        'receivable-invoices.mark-as-paid.without-transaction-step.payment-date-field.errors.required'
      );
      return;
    }

    this.error = null;
  }

  _handleError() {
    this.args.context.abortOrigin = 'error';
    this.args.abort();
  }
}
