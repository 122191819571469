/* import __COLOCATED_TEMPLATE__ from './result.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { all, race, rawTimeout, task, waitForEvent } from 'ember-concurrency';

import {
  NRC_EVENT_MIN_WAITING_IN_MS,
  NRC_EVENT_NAMES,
  NRC_EVENT_TIMEOUT_IN_MS,
  NRC_EVENT_TIMEOUT_REACHED,
  NRC_RESULT_STATUS,
} from 'qonto/constants/nrc-payments';
import { ErrorScreen } from 'qonto/react/components/nrc-payments/result/error-screen';
import { ProcessingScreen } from 'qonto/react/components/nrc-payments/result/processing-screen';
import { SuccessScreen } from 'qonto/react/components/nrc-payments/result/success-screen';
import { TimeoutScreen } from 'qonto/react/components/nrc-payments/result/timeout-screen';

export default class NrcCreationResultComponent extends Component {
  @service notifierManager;
  @service router;

  @tracked status = this.args.context.showErrorScreen
    ? NRC_RESULT_STATUS.ERROR
    : NRC_RESULT_STATUS.PROCESSING;

  RESULT_STATUS = NRC_RESULT_STATUS;

  constructor() {
    super(...arguments);

    if (this.status === NRC_RESULT_STATUS.PROCESSING) {
      this.handleNrcEventsTask.perform().catch(() => {});
    }
  }

  handleNrcEventsTask = task(async () => {
    let paymentId = this.args.context.requestId;

    let successTaskInstance = this.waitForEventTask.perform(NRC_EVENT_NAMES.SUCCESS, paymentId);
    let errorTaskInstance = this.waitForEventTask.perform(NRC_EVENT_NAMES.ERROR, paymentId);
    let timeoutTaskInstance = this.rawTimeoutEventTask.perform(
      NRC_EVENT_TIMEOUT_REACHED,
      NRC_EVENT_TIMEOUT_IN_MS
    );

    let resultTask = race([successTaskInstance, errorTaskInstance, timeoutTaskInstance]);
    let minDelay = rawTimeout(NRC_EVENT_MIN_WAITING_IN_MS); // Minimum delay for UI smoothness

    let [eventName] = await all([resultTask, minDelay]);

    if (eventName === NRC_EVENT_NAMES.SUCCESS) {
      this.status = NRC_RESULT_STATUS.SUCCESS;
    } else if (eventName === NRC_EVENT_NAMES.ERROR) {
      this.status = NRC_RESULT_STATUS.ERROR;
    } else if (eventName === NRC_EVENT_TIMEOUT_REACHED) {
      this.status = NRC_RESULT_STATUS.TIMEOUT;
    }
  });

  waitForEventTask = task(async (eventName, paymentId) => {
    let eventIdMatchesSavedPayment = false;
    while (!eventIdMatchesSavedPayment) {
      let payload = await waitForEvent(this.notifierManager, eventName);
      eventIdMatchesSavedPayment = payload?.object?.request_id === paymentId;
    }
    return eventName;
  });

  rawTimeoutEventTask = task(async (eventName, ms) => {
    await rawTimeout(ms);
    return eventName;
  });

  onCtaClick = () => {
    if (this.status === NRC_RESULT_STATUS.SUCCESS) {
      this.router.transitionTo('agencia-tributaria.list.completed');
    } else {
      this.router.transitionTo('agencia-tributaria.list.processing');
    }
  };

  successScreen = SuccessScreen;
  processingScreen = ProcessingScreen;
  errorScreen = ErrorScreen;
  timeoutScreen = TimeoutScreen;
}
