/* import __COLOCATED_TEMPLATE__ from './fm-discovery.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class FmDiscoveryComponent extends Component {
  @service segment;

  @action
  handleSubmit() {
    this.segment.track('interstitial_team_invite_clicked');
    this.args.transitionToNext();
  }
}
