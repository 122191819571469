/* import __COLOCATED_TEMPLATE__ from './block-card.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { CARD_LOCK_REASONS } from 'qonto/constants/cards';
import { CardDescriptionBox } from 'qonto/react/components/flows/chargeback-claim/card-description-box';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class FlowsCardChargebackBlockCardComponent extends Component {
  disclaimerBlock = Disclaimer.Block;

  @service intl;
  @service segment;
  @service toastFlashMessages;

  CardDescriptionBox = CardDescriptionBox;

  get title() {
    if (this.isPausedCard) {
      return this.intl.t('dispute-flow.block-card.title.blocked');
    }

    return this.intl.t('dispute-flow.block-card.title.unblocked');
  }

  get subtitle() {
    if (this.isPausedCard) {
      return this.intl.t('dispute-flow.block-card.subtitle.blocked');
    }

    return this.intl.t('dispute-flow.block-card.subtitle.unblocked');
  }

  get isPausedCard() {
    return this.args.context?.card?.paused;
  }

  _blockCardTask = dropTask(async card => {
    if (!this.isPausedCard) {
      try {
        await card.lock(CARD_LOCK_REASONS.LOCK);
        this.toastFlashMessages.toastSuccess(this.intl.t('dispute-flow.block-card.toast'));
      } catch {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
        return;
      }
    }

    this.args.transitionToNext();
  });

  @action
  onBlockCard() {
    let { card } = this.args.context;

    this.segment.track('cards-chargeback_block-card-continue-cta_clicked');
    this._blockCardTask.perform(card).catch(ignoreCancelation);
  }
}
