/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Button, LottiePlayer } from '@repo/design-system-kit';

import { EVENTS } from 'qonto/constants/international-out/tracking';

export default class FlowsTransfersInternationalOutNewSuccessComponent extends Component {
  button = Button;
  lottiePlayer = LottiePlayer;

  @service internationalOutManager;
  @service router;
  @service segment;

  constructor() {
    super(...arguments);
    this.segment.track(EVENTS.TRANSFER_CREATED);
  }

  @action
  makeAnotherTransfer() {
    this.router.transitionTo('transfers.landing');
  }

  @action
  viewTransfer() {
    this.router.transitionTo('transfers.pending', {
      queryParams: { highlight: this.internationalOutManager.getIdempotencyKey() },
    });
  }
}
