/* import __COLOCATED_TEMPLATE__ from './transactions-list.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';
import { task } from 'ember-concurrency';

import { CLAIM_TYPES, DEFAULT_SORT_BY } from 'qonto/constants/transactions';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class FlowsCardChargebackTransactionsBulkSelectionComponent extends Component {
  @service intl;
  @service store;
  @service sentry;
  @service organizationManager;

  @tracked isError = false;

  constructor() {
    super(...arguments);
    this.fetchDataTask.perform().catch(ignoreCancelation);
  }

  get disclaimerBody() {
    let { claimType } = this.args.context;
    // A special case for Italian fraud/atm claims
    if (
      (claimType === CLAIM_TYPES.fraud || claimType === CLAIM_TYPES.atm) &&
      this.organizationManager.organization.isItalian
    ) {
      return this.intl.t('dispute-flow.transactions-selection.bulk-selection.disclaimer.body', {
        learnMoreLink: this.intl.t('dispute-flow.transactions-selection.bulk-selection.learn-more'),
        validityPeriod: this.intl.t(
          'dispute-flow.transactions-selection.bulk-selection.disclaimer.italian-validity-days'
        ),
      });
    }
    return this.intl.t('dispute-flow.transactions-selection.bulk-selection.disclaimer.body', {
      learnMoreLink: this.intl.t('dispute-flow.transactions-selection.bulk-selection.learn-more'),
      validityPeriod:
        claimType === CLAIM_TYPES.fraud
          ? this.intl.t(
              'dispute-flow.transactions-selection.bulk-selection.disclaimer.validity-weeks'
            )
          : this.intl.t(
              'dispute-flow.transactions-selection.bulk-selection.disclaimer.validity-days'
            ),
    });
  }

  get transactions() {
    return this.fetchDataTask.last.value?.transactions;
  }

  get hasOldTransactions() {
    return this.fetchDataTask.last.value?.hasOldTransactions;
  }

  get isLoading() {
    return this.fetchDataTask.isRunning;
  }

  fetchDataTask = task(async () => {
    let { setShouldShowFooter } = this.args;

    try {
      let { card, claimType, transaction } = this.args.context;
      let organizationId = this.organizationManager.organization.id;
      let [property, direction] = DEFAULT_SORT_BY.split(':');

      let { transactions } = await this.store.adapterFor('transaction').search({
        organization_id: organizationId,
        search: '',
        sort: { property, direction },
        pagination: { page: 1, per_page: 500 },
        filter_group: {
          conditional: 'and',
          expressions: [
            {
              property: 'card_id',
              values: [card.id],
              operator: 'in',
            },
          ],
        },
      });

      this.isError = false;
      setShouldShowFooter?.(true);

      let filteredTransactions = transactions;

      if (claimType === CLAIM_TYPES.commercial) {
        filteredTransactions = transactions.filter(
          t => t.counterpartyName === transaction.counterpartyName
        );
      } else if (claimType === CLAIM_TYPES.atm) {
        filteredTransactions = transactions.filter(t => t.activityTag === 'atm');
      }

      let relevantTransactions = filteredTransactions.filter(ft => {
        let displayedDay = dayjs(ft?.displayAt);
        return dayjs().diff(displayedDay, 'day') <= (claimType === CLAIM_TYPES.fraud ? 56 : 90);
      });

      let hasOldTransactions = relevantTransactions.length < filteredTransactions.length;

      return { transactions: relevantTransactions, hasOldTransactions };
    } catch {
      this.isError = true;
      setShouldShowFooter?.(false);
    }
  });
}
