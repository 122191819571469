/* import __COLOCATED_TEMPLATE__ from './limit-input.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { AmountField } from '@repo/design-system-kit';

/**
 * @typedef expensePermissionLimitPreDefType
 * @property {string} label
 * @property {number|null} value
 */
/**
 * @param {string|null} amount
 * @param {EmberConcurrencyTask} updateAmountTask
 * @param {string} title
 * @param {expensePermissionLimitPreDefType[]} preDefValues
 */
export default class MemberInviteExpensePermissionLimitInputComponent extends Component {
  @service intl;

  amountField = AmountField;

  @tracked isFocused = false;

  get isUnlimited() {
    return this.amount === null;
  }

  get amount() {
    return this.args.amount ?? null;
  }

  @action
  generateLabel(value) {
    return value
      ? `€${this.intl.formatNumber(value)}`
      : this.intl.t('invitation-flow.expense-permissions.transfers.limits.none-chip');
  }

  @action
  onFocusChange(isFocused) {
    this.isFocused = isFocused;
  }
}
