/* import __COLOCATED_TEMPLATE__ from './mandate-type-select.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class MandateTypeSelectComponent extends Component {
  @service intl;

  mandateTypes = [
    { code: false, label: this.intl.t('mandates.new.type.single') },
    { code: true, label: this.intl.t('mandates.new.type.recurrent') },
  ];

  get selectedType() {
    return this.mandateTypes.find(({ code }) => code === this.args.mandate.recurrent);
  }

  @action handleOnSelect({ code }) {
    this.args.onChange(code);
  }
}
