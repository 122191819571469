/* import __COLOCATED_TEMPLATE__ from './kyc-success.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class FlowsRequestsCardsKycSuccessComponent extends Component {
  @service segment;

  @action
  handleClick() {
    let { transitionToNext } = this.args;

    this.segment.track('request_creation_virtual_card_started');

    transitionToNext();
  }
}
