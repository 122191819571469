export default {
  "details": "cgu",
  "details--ff": "cDS",
  "header-container": "cDc",
  "icon": "cDq",
  "line-container": "cDZ",
  "info-line": "cDR",
  "body-details": "cDQ",
  "body-details-header": "cDe",
  "body-details-1": "cDB",
  "body-details-2": "cDE",
  "body-details-3": "cDd",
  "body-details-4": "cDI"
};
