/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Button } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

export default class FlowsInvoicesProductsImportSuccess extends Component {
  @service intl;
  @service toastFlashMessages;
  @service router;
  @service segment;

  @reads('args.context') context;

  button = Button;

  get importedProductsCount() {
    return this.context.xlsFile.rows.length;
  }

  @action
  goToImportClientsPrimaryCTA() {
    this.segment.track('items-import_success_clients-flow');

    this.args.transitionToFlow({
      flowName: 'invoice-clients-import',
      stepId: 'initial',
    });
  }

  @action
  goToProductList() {
    this.toastFlashMessages.toastInfo(
      this.intl.t('products.list.toasts.info.products-import', {
        count: this.importedProductsCount,
      })
    );
    this.args.transitionToNext();
  }
}
