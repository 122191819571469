/* import __COLOCATED_TEMPLATE__ from './eligibility.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

export default class FlowsFinancingPayLaterApplicationEligibilityComponent extends Component {
  lottiePlayer = LottiePlayer;

  @service segment;

  @action
  goToValueProp() {
    this.segment.track('pay-later-eligibility_activation_clicked');
    this.args.transitionToNext();
  }
}
