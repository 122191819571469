/* import __COLOCATED_TEMPLATE__ from './edit-client.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class FlowsDirectDebitCollectionsEditClientComponent extends Component {
  @service sentry;
  @service intl;
  @service segment;
  @service toastFlashMessages;

  @tracked isValidationEnabled = false;

  showMissingEmailDisclaimer = false;

  constructor() {
    super(...arguments);

    if (!this.client.email) {
      this.showMissingEmailDisclaimer = true;
    }
  }

  get client() {
    return this.args.context.clientToEdit;
  }

  willDestroy() {
    super.willDestroy(...arguments);

    if (!this.client || this.client.isDestroyed || this.client.isDestroying) return;

    this.client.rollbackAttributes();
  }

  onSaveClientTask = dropTask(async editedClient => {
    this.isValidationEnabled = true;

    editedClient.validate({}, false);

    if (editedClient.validations.isValid) {
      try {
        await editedClient.save({
          adapterOptions: {
            context: 'sdd',
          },
        });
      } catch (error) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.try-later'));

        return;
      }

      this.args.context.directDebitSubscription.client = editedClient;

      this.args.transitionToNext();

      this.segment.track('incoming-direct-debit-flow_client_selected', {
        client_address: Boolean(editedClient.address),
        client_selection: 'edited',
      });
    }
  });
}
