export default {
  "content": "cEC",
  "error-message": "cEk",
  "wrapper": "cEJ",
  "banner": "cEs",
  "banner-content": "cEF",
  "banner-text": "cEg",
  "banner-image": "cED",
  "banner-title": "cEV",
  "banner-description": "cEL body-2",
  "link": "cET",
  "plans-comparison": "cEz",
  "plan-box": "cEa",
  "arrow": "cEH",
  "arrow-icon": "cEO",
  "link-downgrade": "cEm",
  "stroke-text": "cEp"
};
