/* import __COLOCATED_TEMPLATE__ from './confirm.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { Disclaimer } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';

export default class SubscriptionCloseStepsConfirmComponent extends Component {
  disclaimerBlock = Disclaimer.Block;

  @service intl;
  @service organizationManager;
  @service toastFlashMessages;
  @service segment;

  get nextBillingDateFormatted() {
    return dateToken({
      date: this.args.context.nextBillingDate,
      locale: this.intl.primaryLocale,
      token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
    });
  }

  createDeactivationTask = dropTask(async () => {
    try {
      let {
        closingReasonCategory,
        closingReasonSubCategory,
        closingReasonDetails,
        closingReasonDetailsOther,
      } = this.args.context;
      let { organization } = this.organizationManager;
      let { due_date } = await organization.createDeactivation({
        closing_reason_category: closingReasonCategory?.key,
        closing_reason_sub_category: closingReasonSubCategory?.key || '',
        closing_reason_details: closingReasonDetails?.key || '',
        closing_reason_details_other: closingReasonDetailsOther || '',
      });

      this.args.context.deactivationDueDate = dayjs(due_date).toDate();
      this.segment.track('account_closing_requirements_clicked');
      this.args.transitionToNext();
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }
      let message = this.intl.t('toasts.errors.server_error');
      this.toastFlashMessages.toastError(message);
    }
  });
}
