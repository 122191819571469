export default {
  "bucket-item": "clq",
  "button": "clZ",
  "content": "clR",
  "bucket-icon": "clQ",
  "bucket-title-container": "cle",
  "bucket-new-badge": "clB",
  "bucket-title": "clE",
  "arrow-icon": "cld"
};
