/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS } from '@qonto/ui-kit/utils/date-token';
import { Disclaimer } from '@repo/design-system-kit';
import window from 'ember-window-mock';

import { F24_STATUS } from 'qonto/constants/f24';

export default class F24SidebarComponent extends Component {
  disclaimerBlock = Disclaimer.Block;

  @service intl;
  @service modals;
  @service segment;

  get titleTranslationKey() {
    let { order } = this.args;
    let statusToTranslationKey = {
      [F24_STATUS.SCHEDULED]: this.intl.t('f24.archive.sidepanel.header.scheduled'),
      [F24_STATUS.COMPLETED]: this.intl.t('f24.archive.sidepanel.header.completed'),
      [F24_STATUS.DECLINED]: this.intl.t('f24.archive.sidepanel.header.declined'),
      [F24_STATUS.CANCELED]: this.intl.t('f24.archive.sidepanel.header.canceled'),
    };
    return statusToTranslationKey[order.status];
  }

  get headerDateToken() {
    let { order } = this.args;
    let statusToDate = {
      [F24_STATUS.SCHEDULED]: { date: order.createdAt, token: DATE_FORMAT_TOKENS.DATE_TIME_L },
      [F24_STATUS.COMPLETED]: {
        date: order.expirationDate,
        token: DATE_FORMAT_TOKENS.DATE_YEAR_L,
      },
      [F24_STATUS.DECLINED]: {
        date: order.expirationDate,
        token: DATE_FORMAT_TOKENS.DATE_YEAR_L,
      },
      [F24_STATUS.CANCELED]: { date: order.canceledAt, token: DATE_FORMAT_TOKENS.DATE_TIME_L },
    };

    return statusToDate[order.status];
  }

  get isDeclinedForInsufficientFunds() {
    let { order } = this.args;
    return order.status === F24_STATUS.DECLINED && order.declinedReason === 'insufficient_funds';
  }

  get shouldDisplayFooter() {
    return (
      (this.args.order.status === F24_STATUS.COMPLETED && this.args.order.receiptUrl) ||
      (this.args.order.status === F24_STATUS.SCHEDULED && this.args.order.isCancelable)
    );
  }

  @action cancelPayment() {
    let f24Order = this.args.order;

    this.modals.open('f24/cancel-confirmation-modal', {
      f24Order,
      closeSidebar: this.args.closeSidebar,
    });
  }

  @action handleDownload() {
    window.open(this.args.order.receiptUrl);
    this.segment.track('f24_details_receipt-downloaded');
  }
}
