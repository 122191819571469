/* import __COLOCATED_TEMPLATE__ from './slider.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { BadgeHighlight } from '@repo/design-system-kit';
import { task } from 'ember-concurrency';

export default class GenerateLogoSliderComponent extends Component {
  badgeHighlight = BadgeHighlight;

  @service segment;

  get totalImages() {
    return this.args.images.length;
  }

  get currentImage() {
    return this.args.images[this.args.currentIndex];
  }

  get currentImageUrl() {
    return this.currentImage.url;
  }

  get currentImageAltText() {
    return this.currentImage.prompt;
  }

  get currentImagePosition() {
    return `${this.args.currentIndex + 1} / ${this.totalImages}`;
  }

  // eslint-disable-next-line require-await
  showNextImageTask = task(async () => {
    let newIndex = this.args.currentIndex < this.totalImages - 1 ? this.args.currentIndex + 1 : 0;
    this.args.onIndexChange(newIndex);
  });

  // eslint-disable-next-line require-await
  showPreviousImageTask = task(async () => {
    let newIndex = this.args.currentIndex > 0 ? this.args.currentIndex - 1 : this.totalImages - 1;
    this.args.onIndexChange(newIndex);
  });

  @action
  trackDownloadEvent() {
    this.args.onDownloadClicked?.();
  }
}
