/* import __COLOCATED_TEMPLATE__ from './erario.hbs'; */
import { action, get } from '@ember/object';
import Component from '@glimmer/component';

import { ERROR_KEYS } from 'qonto/constants/f24';

const MAX_ITEMS_COUNT = 6;

const FIELD_NAME_KEYS = {
  taxCode: 'f24.manual-declaration.module.erario.field1',
  referenceMonth: 'f24.manual-declaration.module.erario.field2',
  referenceYear: 'f24.manual-declaration.module.erario.field3',
  taxAmount: 'f24.manual-declaration.module.erario.field4',
  compensationAmount: 'f24.manual-declaration.module.erario.field5',
  officeCode: 'f24.manual-declaration.module.erario.field6',
  deedCode: 'f24.manual-declaration.module.erario.field7',
};

export default class F24FormErario extends Component {
  fieldNameKeys = FIELD_NAME_KEYS;

  @action
  getErrorKey(code) {
    return ERROR_KEYS[code] ?? ERROR_KEYS.generic_error;
  }

  get hasOfficeCodeOrDeedCodeErrors() {
    let { erario } = this.args;
    return erario.errors.get('officeCode')?.length || erario.errors.get('deedCode')?.length;
  }

  get isAddItemDisabled() {
    return this.args.erario.taxList.length >= MAX_ITEMS_COUNT;
  }

  get totalTax() {
    return this.sumUp('taxAmount');
  }

  get totalCompensation() {
    return this.sumUp('compensationAmount');
  }

  get netTotal() {
    return Number(this.totalTax - this.totalCompensation).toFixed(2);
  }

  sumUp(attributeName) {
    return this.args.erario?.taxList
      .map(item => get(item, attributeName))
      .filter(value => value)
      .reduce((acc, value) => acc + Number(value), 0)
      .toFixed(2);
  }
}
