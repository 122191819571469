/* import __COLOCATED_TEMPLATE__ from './french-orga-details.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';

import { AmountField } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

const CAPITAL_SHARE_LIMIT = 9999999999.99;

export default class FrenchOrgaDetailsComponent extends Component {
  amountField = AmountField;
  maxCapitalShare = CAPITAL_SHARE_LIMIT;

  @service intl;
  @service segment;

  @reads('args.settings') settings;

  @cached
  get transactionOptions() {
    return [
      {
        value: 'goods',
        label: this.intl.t('receivable-invoices.invoice-settings.transaction-type.goods'),
      },
      {
        value: 'services',
        label: this.intl.t('receivable-invoices.invoice-settings.transaction-type.services'),
      },
      {
        value: 'goods_and_services',
        label: this.intl.t(
          'receivable-invoices.invoice-settings.transaction-type.goods_and_services'
        ),
      },
    ];
  }

  get selectedTransactionOption() {
    let option = this.settings?.transactionType;

    if (option) {
      return this.transactionOptions.find(type => type.value === option);
    }
  }

  @action
  updateTransactionType(type) {
    this.settings.transactionType = type?.value;
    this.segment.track('invoice-creation_additional-details_transaction-type_edited');
  }

  @cached
  get paymentConditions() {
    return [
      {
        value: 'on_receipts',
        label: this.intl.t('receivable-invoices.invoice-settings.payment-condition.on_receipts'),
      },
      {
        value: 'compensated_for_sales',
        label: this.intl.t(
          'receivable-invoices.invoice-settings.payment-condition.compensated_for_sales'
        ),
      },
      {
        value: 'exempt',
        label: this.intl.t('receivable-invoices.invoice-settings.payment-condition.exempt'),
      },
    ];
  }

  get selectedPaymentCondition() {
    let option = this.settings?.vatPaymentCondition;

    if (option) {
      return this.paymentConditions.find(type => type.value === option);
    }
  }

  @action
  updatePaymentCondition(condition) {
    this.settings.vatPaymentCondition = condition?.value;
    this.segment.track('invoice-creation_additional-details_vat-payment-condition_edited');
  }

  @action
  handleRscNumberUpdate(rsc) {
    this.settings.commercialRegisterNumber = rsc;
    this.segment.track('invoice-creation_additional-details_rcs-number_edited');
  }

  get capitalShareValue() {
    return this.settings.legalCapitalShare;
  }

  @action
  updateCapitalShareValue(value) {
    this.settings.legalCapitalShare = parseInt(value, 10) === 0 ? null : value;
    this.segment.track('invoice-creation_additional-details_capital-share_edited');
  }
}
