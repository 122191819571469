/* import __COLOCATED_TEMPLATE__ from './summary.hbs'; */
import { InvalidError } from '@ember-data/adapter/error';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class FlowsCheckSummaryComponent extends Component {
  disclaimerBlock = Disclaimer.Block;

  @service sentry;
  @service toastFlashMessages;
  @service intl;
  @service organizationManager;
  @service segment;
  @service store;
  @service router;
  @service subscriptionManager;

  @tracked fnciResult = {};
  @tracked fnciErrorMessage;

  constructor() {
    super(...arguments);

    this.preRegisterCheckTask.perform().catch(ignoreCancelation);
  }

  @reads('organizationManager.organization.hasMultipleActiveAccounts') shouldDisplayAccountLabel;

  get isFNCIresultRedOrOrange() {
    return this.fnciResult.color === 'red' || this.fnciResult.color === 'orange';
  }

  get feeText() {
    let checkLimit = this.subscriptionManager.getLimitByFeatureCode('cheque');
    let {
      cheque: { value, currency },
    } = this.subscriptionManager.options;

    let feeAmount = this.intl.formatNumber(value, {
      currency,
      style: 'currency',
    });

    return checkLimit > 0
      ? this.intl.t('checks.confirm.plan_fee_info', {
          checkLimit,
          feeAmount,
        })
      : this.intl.t('checks.confirm.basic_fee_info', {
          checkLimit,
          feeAmount,
        });
  }

  get errorMessages() {
    return {
      already_processing: this.intl.t('checks.errors.already-processing'),
      fnci_invalid: this.intl.t('checks.errors.fnci-invalid'),
      invalid_numbers: this.intl.t('checks.errors.invalid-numbers'),
      irregular_check: this.intl.t('checks.errors.irregular-check'),
      other: this.intl.t('checks.errors.contact-support'),
      rejected: this.intl.t('checks.errors.rejected'),
    };
  }

  @action
  abandonCheck() {
    this.router.transitionTo('checks');
  }

  @action
  redirectToEmitterStep() {
    this.args.backToStep('emitter');
  }

  createCheckTask = dropTask(async () => {
    let { check } = this.args.context;
    try {
      await check.save();
    } catch (error) {
      this.sentry.captureException(error);
      let message = this.getMessageFromError(error);
      this.toastFlashMessages.toastError(message);
      return;
    }

    this.segment.track('checks_deposit_review_confirmed');
    this.args.transitionToNext();
  });

  getMessageFromError(error) {
    let { code } = error;

    let isInvalidError = error instanceof InvalidError;
    let message = this.errorMessages[code];
    if (!(isInvalidError && message)) {
      message = this.intl.t('errors.unexpected_error_occured');
    }

    return message;
  }

  preRegisterCheckTask = dropTask(async () => {
    let { check } = this.args.context;
    this.args.context.isFNCIgenericError = false;

    try {
      let { fnci_result } = await this.store.adapterFor('check').preRegister(check);

      this.fnciResult = fnci_result;
    } catch ({ errors }) {
      switch (errors[0]?.code) {
        case 'invalid_cheque_number':
          this.fnciErrorMessage = this.intl.t('checks.errors.check-number');
          break;
        case 'invalid_cheque_status':
        case 'invalid_mandate':
          this.fnciErrorMessage = this.intl.t('checks.errors.general-support');
          break;
        case 'duplicate_cheque':
          this.fnciErrorMessage = this.intl.t('checks.errors.duplication');
          break;
        case 'fnci_white_error':
          this.fnciErrorMessage = this.intl.t('checks.fnci.white.disclaimer');
          break;
        default:
          this.args.context.isFNCIgenericError = true;
          this.args.transitionToNext();
      }
    }
  });
}
