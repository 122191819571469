export default {
  "invite-review": "cZS",
  "invite-review-info": "cZc",
  "invite-review-info--invitee": "cZq",
  "disclaimer-button": "cZZ",
  "info-block": "cZR",
  "ev": "cZQ",
  "tooltip": "cZe",
  "description": "cZB"
};
