/* import __COLOCATED_TEMPLATE__ from './invite.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { CARD_FLOWS_TRACKING_ORIGINS } from 'qonto/constants/cards';
import { INVITATION_TYPES } from 'qonto/constants/membership';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class FlowsInviteSuccessInviteComponent extends Component {
  @service intl;
  @service subscriptionManager;
  @service flowLinkManager;
  @service segment;
  @service flow;

  get isGSAInviteTeamMember() {
    return this.args.context.isGSAInviteTeamMember;
  }

  get isGuest() {
    return this.args.context.invitationType === INVITATION_TYPES.GUEST;
  }

  get additionalActionText() {
    if (this.isGSAInviteTeamMember) {
      return this.intl.t('members.invite.success.secondary-cta-get-team-started');
    }

    if (this.isGuest) {
      return this.intl.t('accountant-access.invite.success.cta.invite-new');
    }

    return this.intl.t('members.invite.success.secondary-cta');
  }

  @action primaryAction() {
    if (this.isGSAInviteTeamMember) {
      this.segment.track('team_invite_selected', {
        origin: CARD_FLOWS_TRACKING_ORIGINS.USER_INVITATION_SUCCESS_GET_STARTED,
      });
      this.args.restartFlow({ initialStepId: 'role' });
    } else {
      this.args.context.primaryActionTriggered = true;
      this.args.transitionToNext();
    }
  }

  @action
  secondaryAction() {
    if (this.isGuest) {
      let stepId = this.subscriptionManager.hasReachedAccountantLimit
        ? 'plan-upgrade'
        : 'personal-info';

      if (this.flow.dataContext.isAccountingDiscoveryFlow) {
        this.restartAccountingDiscoveryFlowTask.perform(stepId).catch(ignoreCancelation);
      } else {
        this.args.restartFlow();
        this.flowLinkManager.transitionTo({
          name: 'member-invite',
          stepId,
          queryParams: { invitationType: INVITATION_TYPES.GUEST },
        });
      }
    } else if (this.isGSAInviteTeamMember) {
      this.args.transitionToNext();
    } else {
      this.args.restartFlow();
    }
  }

  restartAccountingDiscoveryFlowTask = dropTask(async stepId => {
    await this.args.restartFlow();
    await this.args.pushForwardFlow('member-invite', stepId);
  });
}
