/* import __COLOCATED_TEMPLATE__ from './receipt.hbs'; */
import { action } from '@ember/object';
import { schedule } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { isTesting, macroCondition } from '@embroider/macros';
import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { dropTask, rawTimeout } from 'ember-concurrency';

import { apiBaseURL } from 'qonto/constants/hosts';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export const WAIT_FOR_OCR_SCAN = macroCondition(isTesting()) ? 500 : 6000;
export const OCR_EVENT_NAME = 'expense_reports_receipt.analyzed';
const RECEIPT_MAX_SIZE = 15 * 1e6; // 15MB

export default class ReceiptComponent extends Component {
  @service intl;
  @service organizationManager;
  @service localeManager;
  @service toastFlashMessages;
  @service notifierManager;
  @service networkManager;
  @service segment;
  @service sentry;

  fileMaxFileSize = RECEIPT_MAX_SIZE;

  @tracked files = [];
  @tracked processing = false;
  @tracked model = this.args.context.requestExpenseReport;

  constructor() {
    super(...arguments);

    schedule('afterRender', () => (this.model.attachments = []));
    this.notifierManager.on(OCR_EVENT_NAME, this, 'handleVATandAmount');
  }

  willDestroy() {
    this.notifierManager.off(OCR_EVENT_NAME, this, 'handleVATandAmount');
    this.triggerReceiptOCRTask.cancelAll();
    this.timeoutEventTask.cancelAll();

    super.willDestroy();
  }

  get dropZoneLabel() {
    return this.intl.t('labels.upload-message', {
      maxSize: formatFileSize(this.intl, RECEIPT_MAX_SIZE),
    });
  }

  get uploadOptions() {
    let { organization, currentAccount } = this.organizationManager;

    return {
      payload: {
        fileKey: 'attachment[file]',
        data: {
          'attachment[organization_id]': organization.id,
          'attachment[bank_account_id]': currentAccount,
        },
      },
      url: 'v3/attachments',
      model: 'attachment',
    };
  }

  get amount() {
    return { value: null, currency: 'EUR' };
  }

  @action
  handleCancelFile() {
    this.segment.track('reimbursement_request_inprogress_cancel_started');
    this.files = [];
  }

  @action
  handleFileUploaded(fileModel, attachment) {
    if (fileModel) {
      let { id, file } = attachment;

      fileModel.file.filename = fileModel.file.name;
      fileModel.file.filesizeBytes = fileModel.file.size;
      fileModel.file.url = file.fileUrl;

      this.files = [...this.files, fileModel.file];

      this.model.attachments = [attachment];

      this.triggerReceiptOCRTask.perform(id).catch(ignoreCancelation);
      this.timeoutEventTask.perform().catch(ignoreCancelation);

      this.segment.track('reimbursement_receipt_upload_confirmed');
    }
  }

  @action
  handleFileUploadedError(error) {
    if (error) {
      this.segment.track('reimbursement_receipt_upload_failed');
      return this.toastFlashMessages.toastError(
        this.intl.t('requests.reimbursement.steps.loading.failed')
      );
    }
  }

  handleVATandAmount({ organization_id, object }) {
    let { organization } = this.organizationManager;

    if (organization_id === organization.id) {
      let { amount, vat_amount, vat_rate } = object.expense_reports_receipt;

      this.model.amount = amount ?? this.amount;
      this.model.vatAmount = vat_amount;
      this.model.vatRate = parseFloat(vat_rate);
      this.processing = false;

      this._checkVATAndAmount(this.model.amount, this.model.vatRate);
      this.args.transitionToNext();
    }
  }

  triggerReceiptOCRTask = dropTask(async attachmentId => {
    this.processing = true;
    this.segment.track('reimbursement_receipt_upload_filepicker');

    let organizationId = this.organizationManager.organization.id;

    let url = `${apiBaseURL}/v3/expense_reports_receipts`;
    let data = {
      expense_reports_receipt: { attachment_id: attachmentId, organization_id: organizationId },
    };

    try {
      await this.networkManager.request(url, {
        method: 'POST',
        data: JSON.stringify(data),
      });
    } catch (error) {
      if (error?.status === 422) {
        this.sentry.captureException(error);
      }
    }
  });

  timeoutEventTask = dropTask(async () => {
    await rawTimeout(WAIT_FOR_OCR_SCAN);
    this.model.amount = this.amount;
    this.model.vatAmount = null;
    this.model.vatRate = null;
    this.processing = false;

    this._checkVATAndAmount(this.model.amount, this.model.vatRate);
    this.args.transitionToNext();
  });

  _checkVATAndAmount(amount, vatRate) {
    if (!amount?.value && !vatRate) {
      this.segment.track('reimbursement_OCR_scan_failed');
      this.toastFlashMessages.toastInfo(
        this.intl.t('requests.reimbursement.steps.bank-details.toast'),
        'icon_info_rounded_filled'
      );
    }
  }
}
