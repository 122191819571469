export default {
  "wrapper": "or",
  "legend": "ob",
  "input": "oy",
  "table": "oU",
  "footer": "oj",
  "footer-total-m": "oW",
  "footer-total-n": "ou",
  "footer-balance": "nS"
};
