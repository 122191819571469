/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import Component from '@glimmer/component';

import { Checkbox } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

export default class InvoicesTableHeaderComponent extends Component {
  checkbox = Checkbox;

  onExportTask = dropTask(async () => {
    await this.args.exportSelectedInvoicesTask.perform(this.args.selectedItemIds);
  });
}
