export default {
  "mandate-new-no-mandate": "ccc",
  "left-side": "ccq",
  "left-side__wrapper": "ccZ",
  "left-side__wrapper-title": "ccR",
  "left-side__wrapper-details": "ccQ",
  "left-side__wrapper-details-item": "cce",
  "right-side": "ccB",
  "multi-accounts": "ccE",
  "right-side__wrapper": "ccd",
  "right-side__wrapper-name": "ccI",
  "right-side__wrapper-address": "cct",
  "right-side__wrapper-iban-dashed": "ccM",
  "right-side__wrapper-address-label": "ccP",
  "right-side__wrapper-iban-label": "ccl",
  "right-side__wrapper-bic-label": "ccX",
  "right-side__wrapper-address-dashed": "ccC",
  "right-side__wrapper-bic-dashed": "cck",
  "dropdown-icon": "ccJ",
  "dropdown": "ccs",
  "multi-accounts-illustration": "ccF"
};
