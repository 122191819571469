/* import __COLOCATED_TEMPLATE__ from './invoice-attachment.hbs'; */
import Component from '@glimmer/component';

import dayjs from 'dayjs';
import { bool } from 'macro-decorators';

import { ErrorState } from 'qonto/react/components/transactions/sidebar/details/attachments/error-state';

export default class InvoiceAttachmentComponent extends Component {
  errorState = ErrorState;

  @bool('args.localFiles.0') hasFiles;

  get invoiceAttachmentDate() {
    return dayjs(this.args.feeCreatedDate).endOf('month').add(1, 'day');
  }
}
