/* import __COLOCATED_TEMPLATE__ from './review-and-pay.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class ReviewAndPayComponent extends Component {
  @service segment;
  @service router;

  @action
  handleButtonClick() {
    this.segment.track('request_review_clicked', { request_type: 'expense_report' });
    this.router.transitionTo('reimbursements.pending', {
      queryParams: { highlight: this.args.context.requestExpenseReport.id },
    });
  }
}
