/* import __COLOCATED_TEMPLATE__ from './no-mandate.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

export default class NoMandatesComponent extends Component {
  @service toastFlashMessages;
  @service intl;
  @service organizationManager;
  @service bankAccount;

  @reads('organizationManager.organization') organization;
  @reads('organizationManager.membership') membership;
  @reads('organizationManager.currentAccount') currentAccount;

  get hasMultiAccounts() {
    return this.organization.hasMultipleActiveAccounts;
  }

  handleDownloadRIB = dropTask(async account => {
    await this.bankAccount.downloadIbanPdf(account.id);
  });

  @action
  handleErrorCopy() {
    let message = this.intl.t('mandates.copy-clipboard.fail');
    this.toastFlashMessages.toastError(message);
  }
}
