/* import __COLOCATED_TEMPLATE__ from './footer.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class InContextIntegrationsSideDrawerFooterComponent extends Component {
  @service segment;

  @action
  handleClick() {
    let { page, bucketTitle } = this.args;

    this.segment.track('ICI_suggest_integration_clicked', {
      page,
      bucket: bucketTitle,
    });
  }
}
