/* import __COLOCATED_TEMPLATE__ from './upload.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { task } from 'ember-concurrency';
import window from 'ember-window-mock';
import { reads } from 'macro-decorators';

export default class FlowsMandateUploadComponent extends Component {
  @service modals;
  @service segment;
  @service intl;
  @service attachmentsManager;

  @tracked selectedFileIndex = 0;

  @tracked error = null;

  @reads('args.context.mandate') model;
  @reads('model.attachments') attachments;

  @action
  handleRemoveAttachment(file) {
    this.attachmentsManager.removeAttachment(false, this.model, file);

    // close the modal if there are no more attachments to display
    if (this.attachments.length === 0) {
      this.previewModal?.close();
    } else if (this.selectedFileIndex > 0) {
      this.selectedFileIndex--;
    }
  }

  @action
  handleDownloadAttachment(downloadUrl) {
    window.open(downloadUrl, '_blank');
  }

  @action
  handleShowAttachment(file) {
    let selectedAttachment = this.attachmentsManager.getAttachmentByFile(this.model, file);
    this.selectedFileIndex = this.attachments.indexOf(selectedAttachment);

    this.previewModal = this.modals.open('mandate-upload-preview-modal', {
      isFullScreenModal: true,
      files: this.model.attachmentsFiles,
      parent: this,
      onDownload: this.handleDownloadAttachment,
      onRemove: this.handleRemoveAttachment,
    });
  }

  @action
  handleCancelUploadAttachment(file) {
    this.attachmentsManager.cancelUploadAttachment(this.model, file);
  }

  attachMandateTask = task(async file => {
    await this.attachmentsManager.addAttachmentTask.perform(false, this.model, file);
    this.error = this.checkError();
  });

  @action
  handleContinue() {
    this.error = this.checkError();
    if (this.error) return;

    this.segment.track('app_sdd_mandate_uploaded');
    this.args.transitionToNext();
  }

  checkError() {
    return this.attachments.length === 0 || this.attachmentsManager.addAttachmentTask.isRunning
      ? this.intl.t('validations.errors.upload_by_laws_error')
      : null;
  }
}
