/* import __COLOCATED_TEMPLATE__ from './modelo-form.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { AmountField, Button, TextField } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

import { ACCOUNT_STATUS } from 'qonto/constants/bank-account';

export default class NrcModeloFormComponent extends Component {
  @service intl;
  @service localeManager;
  @service organizationManager;
  @service segment;

  constructor() {
    super(...arguments);

    this._prefillPeriodSelect();

    this.segment.track('nrc_form-details_opened');
  }

  @reads('args.context.selectedModelo') selectedModelo;
  @reads('args.context.nrcPayment') nrcPayment;

  @tracked fiscalYear;
  @tracked period;
  @tracked amount = this.nrcPayment.amount?.value || undefined;
  @tracked selectedBankAccount = this.nrcPayment.bankAccount || this.bankAccounts[0];
  @tracked standardReceiptCode = this.shouldShowStandardReceiptCode
    ? this.nrcPayment.receiptCode
    : undefined;
  @tracked isPreDeclaration = this.nrcPayment.isPreDeclaration || false;
  @tracked preDeclarationReceiptCode = this.shouldShowPreDeclaration
    ? this.nrcPayment.receiptCode
    : undefined;
  @tracked isPaymentInInstallments = this.nrcPayment.isPaymentInInstallments || false;

  @tracked hasAmountError = false;
  @tracked hasPeriodError = false;
  @tracked hasBankAccountError = false;
  @tracked hasStandardReceiptCodeError = false;
  @tracked hasPreDeclarationReceiptCodeError = false;

  @tracked standardReceiptCodeErrorMessage = '';
  @tracked preDeclarationReceiptCodeErrorMessage = '';

  _prefillPeriodSelect() {
    //this is in case the user navigates back
    if (this.shouldShowPeriodSelect) {
      this.fiscalYear = this.nrcPayment.fiscalYear || this.fiscalYearOptions[0].value;

      if (this.nrcPayment.period) {
        this.period = this.periodOptions.find(period => period.value === this.nrcPayment.period);
      } else if (this.periodOptions.length === 1) {
        this.period = this.periodOptions[0];
      }
    }
  }

  get modeloName() {
    return `${this.intl.t('aeat.pay.form-selection.card-title-prefix')} ${Number(this.selectedModelo.tax_code).toString()}`;
  }

  get shouldShowPeriodSelect() {
    return this.selectedModelo.allowed_periods.length;
  }

  get shouldShowInstallments() {
    return this.selectedModelo.can_pay_in_installments;
  }

  get shouldShowStandardReceiptCode() {
    let eligibleTaxTypes = ['023', '050', '051'];
    return eligibleTaxTypes.includes(this.selectedModelo.tax_type);
  }

  get shouldShowPreDeclaration() {
    return this.selectedModelo.can_be_pre_declared;
  }

  get fiscalYearOptions() {
    let currentYear = new Date().getFullYear();
    let options = [];

    for (let year = currentYear; year >= currentYear - 10; year--) {
      options.push({ label: year.toString(), value: year.toString() });
    }
    return options;
  }

  get periodOptions() {
    let periods = this.selectedModelo.allowed_periods;
    let options = [];

    for (let period of periods) {
      let match = period.match(/(\d+|0)([MTPA])/);
      if (!match) continue;

      let [, number, periodType] = match;
      let periodLabel;

      switch (periodType) {
        case 'M':
          periodLabel = this.intl.t('aeat.pay.field.period.mensual', {
            number: number.padStart(2, '0'),
          });
          break;
        case 'T':
          periodLabel = this.intl.t('aeat.pay.field.period.trimestre', {
            number: number.padStart(2, '0'),
          });
          break;
        case 'P':
          periodLabel = this.intl.t('aeat.pay.field.period.periodo', {
            number: number.padStart(2, '0'),
          });
          break;
        case 'A':
          periodLabel = this.intl.t('aeat.pay.field.period.anual');
          break;
        default:
          continue;
      }

      options.push({
        label: periodLabel,
        value: period,
      });
    }

    return options;
  }

  get bankAccounts() {
    let bankAccounts = this.organizationManager.organization.get('bankAccounts');
    return bankAccounts.filter(bankAccount => bankAccount.status === ACCOUNT_STATUS.ACTIVE);
  }

  @action
  updateFiscalYear(year) {
    this.fiscalYear = year.value;
  }

  @action
  clearPeriodError() {
    this.hasPeriodError = false;
  }

  @action
  updatePeriod(period) {
    this.period = period;
  }

  @action
  updateAmount(input) {
    this.amount = input;
    this.hasAmountError = false;
    this.hasBankAccountError = false;
  }

  @action updateStandardReceiptCode(event) {
    this.standardReceiptCode = event.currentTarget.value;
    this.hasStandardReceiptCodeError = false;
  }

  @action
  updateBankAccount(bankAccount) {
    this.selectedBankAccount = bankAccount;
    this.hasBankAccountError = false;
  }

  @action
  updateIsPaymentInInstallments(input) {
    this.isPaymentInInstallments = input;
  }

  @action
  updateIsPreDeclaration(input) {
    this.isPreDeclaration = input;
  }

  @action updatePreDeclarationReceiptCode(event) {
    this.preDeclarationReceiptCode = event.currentTarget.value;
    this.hasPreDeclarationReceiptCodeError = false;
  }

  _isInvalidReceiptCode(receiptCode) {
    return !/^[a-zA-Z0-9]{13}$/.test(receiptCode);
  }

  @action
  saveModelo() {
    let isValid = true;

    if (!this.amount || !Number(this.amount)) {
      this.hasAmountError = true;
      isValid = false;
    }

    if (this.selectedBankAccount.authorizedBalance < this.amount) {
      this.hasBankAccountError = true;
      isValid = false;
    }

    if (this.shouldShowPeriodSelect && !this.period) {
      this.hasPeriodError = true;
      isValid = false;
    }

    if (this.shouldShowStandardReceiptCode) {
      if (!this.standardReceiptCode) {
        this.hasStandardReceiptCodeError = true;
        this.standardReceiptCodeErrorMessage = this.intl.t(
          'aeat.pay.form-details.errors.justificante.empty'
        );
        isValid = false;
      } else if (this._isInvalidReceiptCode(this.standardReceiptCode)) {
        this.hasStandardReceiptCodeError = true;
        this.standardReceiptCodeErrorMessage = this.intl.t(
          'aeat.pay.form-details.errors.justificante.format'
        );
        isValid = false;
      }
    }

    if (this.shouldShowPreDeclaration && this.isPreDeclaration) {
      if (!this.preDeclarationReceiptCode) {
        this.hasPreDeclarationReceiptCodeError = true;
        this.preDeclarationReceiptCodeErrorMessage = this.intl.t(
          'aeat.pay.form-details.errors.justificante.empty'
        );
        isValid = false;
      } else if (this._isInvalidReceiptCode(this.preDeclarationReceiptCode)) {
        this.hasPreDeclarationReceiptCodeError = true;
        this.preDeclarationReceiptCodeErrorMessage = this.intl.t(
          'aeat.pay.form-details.errors.justificante.format'
        );
        isValid = false;
      }
    }

    if (isValid) {
      let { context, transitionToNext } = this.args;

      context.nrcPayment.amount = { value: this.amount, currency: 'EUR' };
      context.nrcPayment.bankAccount = this.selectedBankAccount;
      context.nrcPayment.fiscalYear = this.fiscalYear;
      context.nrcPayment.period = this.period?.value;
      context.nrcPayment.isPreDeclaration = this.isPreDeclaration;
      context.nrcPayment.isPaymentInInstallments = this.isPaymentInInstallments;
      context.nrcPayment.receiptCode = this.isPreDeclaration
        ? this.preDeclarationReceiptCode
        : this.standardReceiptCode;

      transitionToNext();
    }

    this.segment.track('nrc_form-details_completed');
  }

  amountField = AmountField;
  button = Button;
  textField = TextField;
}
