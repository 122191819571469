/* import __COLOCATED_TEMPLATE__ from './upload-documents.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { TrackedArray } from 'tracked-built-ins';

import { CLAIM_TYPES } from 'qonto/constants/transactions';

export default class FlowsCardChargebackUploadDocumentsComponent extends Component {
  @service intl;
  @service segment;

  errors = new TrackedArray();

  get isAtm() {
    return this.args.context.claimType === CLAIM_TYPES.atm;
  }

  get subtitle() {
    return this.isAtm
      ? this.intl.t('dispute-flow.documents-upload.subtitle.atm')
      : this.intl.t('dispute-flow.documents-upload.subtitle.fraud');
  }

  @action
  continue() {
    let { context, transitionToNext } = this.args;

    this.segment.track('cards-chargeback_document-upload-continue-cta_clicked', {
      type: context.claimType,
    });

    transitionToNext();
  }
}
