export default {
  "invitations-password-form": "ckX",
  "mobile-mode": "ckC",
  "logo": "ckk",
  "header": "ckJ",
  "controls": "cks",
  "footer": "ckF",
  "submit-cta": "ckg",
  "subtitle": "ckD"
};
