export default {
  "two-column-layout": "ces",
  "points-list": "ceF",
  "point-title": "ceg",
  "point-content": "ceD",
  "old-container": "ceV",
  "columns": "ceL",
  "left-column": "ceT",
  "right-column": "cez",
  "video-wrapper": "cea",
  "selling-points-wrapper": "ceH",
  "actions": "ceO"
};
