export default {
  "row": "Yz",
  "active": "Ya",
  "cell": "YH",
  "empty": "YO",
  "supplier": "Ym",
  "supplier-content": "Yp",
  "supplier-avatar": "Yx mr-16",
  "financing-status": "Yw body-2",
  "installments": "YN body-2",
  "overflow-hidden": "Yv",
  "ellipsis": "Yo",
  "next-installment-date": "Yn",
  "next-installment-amount": "Yi body-2",
  "amount": "YA",
  "disclaimer": "YY",
  "disclaimer-icon": "Yh",
  "warning": "Yf",
  "error": "YK",
  "total-repayment": "YG body-1"
};
