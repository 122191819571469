/* import __COLOCATED_TEMPLATE__ from './role-button.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { ROLES } from 'qonto/constants/membership';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class FlowsInviteRoleButtonComponent extends Component {
  @service intl;
  @service memberManager;
  @service segment;
  @service sentry;
  @service organizationManager;

  @action
  handleSelectRole(context, role, transitionToNext, pushFlow) {
    this.handleSelectRoleTask
      .perform(context, role, transitionToNext, pushFlow)
      .catch(ignoreCancelation);
  }

  handleSelectRoleTask = dropTask(async (context, role, transitionToNext, pushFlow) => {
    this.segment.track('team-invite_role-chosen_clicked');

    await this.memberManager.handleSelectRoleTask
      .perform(context, role, transitionToNext, pushFlow, 'role-details')
      .catch(error => {
        if (error.shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });
  });

  hasManagerRole = roleKey => {
    return [ROLES.MANAGER, ROLES.ADVANCED_MANAGER].includes(roleKey);
  };
}
