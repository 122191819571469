/* import __COLOCATED_TEMPLATE__ from './personal-information-form.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { INVITATION_TYPES, MEMBER_STATUS } from 'qonto/constants/membership';

export default class PersonalInformationForm extends Component {
  @tracked enableInviteValidations = false;

  @service organizationManager;
  @service abilities;
  @service segment;
  @service store;
  @service intl;

  isMemberEmailNonEmpty = Boolean(this.args.context.member.email?.trim());

  @tracked emailErrorMessage;
  @tracked existingMember;

  constructor() {
    super(...arguments);
    this.updateContextMemberGbRPartner();
  }

  get pageTitle() {
    if (this.isSuggestedMember) {
      return this.intl.t('invitation-flow.hr-member.personal-info.title');
    }
    if (this.isGuest) {
      return this.intl.t('accountant-access.invite.personal-info');
    }

    return this.intl.t('members.invite.personal-info');
  }

  get canUpdateTeam() {
    return this.abilities.can('update team membership');
  }

  get teams() {
    return this.organizationManager.organization.teams;
  }

  get team() {
    return this.args.context.member?.team;
  }
  get isGuest() {
    return this.args.context.invitationType === INVITATION_TYPES.GUEST;
  }

  get isFirstNameReadonly() {
    if (this.isSuggestedMember) {
      return false;
    }
    return this.isSuggestedMember;
  }

  get isLastNameReadonly() {
    if (this.isSuggestedMember) {
      return false;
    }
    return this.isSuggestedMember;
  }

  get isEmailReadonly() {
    if (this.isSuggestedMember && this.isMemberEmailNonEmpty) {
      return true;
    }
    if (this.isGbR) {
      return this.isSuggestedMember && this.isMemberEmailNonEmpty;
    }
    return false;
  }

  get isSuggestedMember() {
    return this.args.context.member?.suggested;
  }

  get isGbR() {
    return this.organizationManager.organization.isGbR;
  }

  get emailDisclaimerText() {
    let name = this.existingMember.fullName;

    switch (this.existingMember.status) {
      case MEMBER_STATUS.REVOKED:
        return this.intl.t('invitation-flow.personal-info.email.disclaimer.revoked.main', {
          name,
        });
      case MEMBER_STATUS.PENDING:
        return this.intl.t('invitation-flow.personal-info.email.disclaimer.pending.main', {
          name,
        });
      default:
        return this.intl.t('invitation-flow.personal-info.email.disclaimer.active.main', {
          name,
        });
    }
  }

  get emailDisclaimerLinkText() {
    switch (this.existingMember.status) {
      case MEMBER_STATUS.REVOKED:
        return this.intl.t('invitation-flow.personal-info.email.disclaimer.revoked.link');
      case MEMBER_STATUS.PENDING:
        return this.intl.t('invitation-flow.personal-info.email.disclaimer.pending.link');
      default:
        return this.intl.t('invitation-flow.personal-info.email.disclaimer.active.link');
    }
  }

  @action
  updateTeam(team) {
    this.args.context.member.team = team;
    this.segment.track('team_invite_team_chosen');
  }

  @action
  updateContextMemberEmail(email) {
    this.args.context.member.email = email;
    this.updateContextMemberGbRPartner();
    this.emailErrorMessage = null;
    this.existingMember = null;
  }

  @action
  updateContextMemberGbRPartner() {
    if (this.isGbR) {
      let { context } = this.args;
      let isEmailFromSuggestedMembers = Boolean(
        this.suggestedMembers.find(({ email }) => email === context.member.email?.trim())
      );

      context.isGbRPartner = isEmailFromSuggestedMembers;
    }
  }

  @cached
  get suggestedMembers() {
    return this.store
      .peekAll('membership')
      .filter(({ status }) => status === MEMBER_STATUS.INVITABLE);
  }

  continueTask = dropTask(async () => {
    let { validations } = await this.args.context.member.validate({
      on: ['firstName', 'lastName', 'email', 'team'],
    });

    let hasEmailErrors = Boolean(validations.errors.find(error => error.attribute === 'email'));

    this.enableInviteValidations = true;
    this.emailErrorMessage = null;

    let isEmailFree = true;

    if (!hasEmailErrors && !this.isSuggestedMember) {
      isEmailFree = await this.validateEmailTask.perform();

      if (!isEmailFree) {
        this.emailErrorMessage = this.intl.t('invitation-flow.personal-info.email.already-in-use');
      }
    }

    if (validations.isValid && isEmailFree) {
      this.segment.track('team-invite_info-filled_submitted', { origin: 'team_page' });
      this.args.transitionToNext();
    }
  });

  validateEmailTask = dropTask(async () => {
    let result = await this.store.query('membership', {
      organization_id: this.organizationManager.organization.id,
      filters: {
        email: this.args.context.member.email,
      },
    });
    this.existingMember =
      result.length && result[0].id !== this.args.context.member.id ? result[0] : null;

    return !this.existingMember;
  });
}
