/* import __COLOCATED_TEMPLATE__ from './renewal-upsell.hbs'; */
import { action, set } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { reads } from 'macro-decorators';

import {
  CARD_FEES,
  CARD_LEVELS,
  CARD_LEVELS_TRACKING,
  CUSTOMIZABLE_CARDS_LEVELS,
  FORMAT_PRICE_OPTIONS,
  PLUS_CARD_WITHDRAWALS_INCLUDED,
} from 'qonto/constants/cards';

export default class FlowsCardsRenewalUpsell extends Component {
  @service intl;
  @service segment;

  @tracked selectedUpsellLevel = this.isCurrentCardStandard ? CARD_LEVELS.PLUS : CARD_LEVELS.METAL;

  @reads('args.context.cardsLimits') cardsLimits;
  @reads('args.context.card.cardLevel') currentCardLevel;

  benefits = {
    plus: [
      this.intl.t('cards.steps.renewal-upsell.plus.benefits.payment-limit', {
        multiple: this._nearestHalf(
          this.cardsLimits.plus.extended_payment_monthly_limit_maximum /
            this.cardsLimits[this.currentCardLevel].extended_payment_monthly_limit_maximum
        ),
        amount: this._formatPrice(this.cardsLimits.plus.extended_payment_monthly_limit_maximum),
        htmlSafe: true,
      }),
      this.intl.t('cards.steps.renewal-upsell.plus.benefits.atm-limit', {
        multiple: this._nearestHalf(
          this.cardsLimits.plus.atm_monthly_limit_maximum /
            this.cardsLimits[this.currentCardLevel].atm_monthly_limit_maximum
        ),
        amount: this._formatPrice(this.cardsLimits.plus.atm_monthly_limit_maximum),
        htmlSafe: true,
      }),
      this.intl.t('cards.steps.renewal-upsell.plus.benefits.withdrawals', {
        number: PLUS_CARD_WITHDRAWALS_INCLUDED,
      }),
      this.intl.t('cards.steps.renewal-upsell.plus.benefits.fees', {
        percentage: this.intl.formatNumber(CARD_FEES.plus.foreignFees, { style: 'percent' }),
      }),
      this.intl.t('cards.steps.renewal-upsell.plus.benefits.print-type'),
      this.intl.t('cards.steps.renewal-upsell.plus.benefits.insurance'),
    ],
    metal: [
      this.intl.t('cards.steps.renewal-upsell.metal.benefits.payment-limit', {
        multiple: this._nearestHalf(
          this.cardsLimits.metal.extended_payment_monthly_limit_maximum /
            this.cardsLimits[this.currentCardLevel].extended_payment_monthly_limit_maximum
        ),
        amount: this._formatPrice(this.cardsLimits.metal.extended_payment_monthly_limit_maximum),
        htmlSafe: true,
      }),
      this.intl.t('cards.steps.renewal-upsell.metal.benefits.atm-limit', {
        multiple: this._nearestHalf(
          this.cardsLimits.metal.atm_monthly_limit_maximum /
            this.cardsLimits[this.currentCardLevel].atm_monthly_limit_maximum
        ),
        amount: this._formatPrice(this.cardsLimits.metal.atm_monthly_limit_maximum),
        htmlSafe: true,
      }),
      this.intl.t('cards.steps.renewal-upsell.metal.benefits.withdrawals'),
      this.intl.t('cards.steps.renewal-upsell.metal.benefits.fees'),
      this.intl.t('cards.steps.renewal-upsell.metal.benefits.print-type'),
      this.intl.t('cards.steps.renewal-upsell.metal.benefits.insurance'),
    ],
  };

  get title() {
    return this.currentCardLevel === CARD_LEVELS.STANDARD
      ? this.intl.t('cards.steps.renewal-upsell.from-one.title')
      : this.intl.t('cards.steps.renewal-upsell.from-plus.title');
  }

  get subtitle() {
    return this.currentCardLevel === CARD_LEVELS.STANDARD
      ? this.intl.t('cards.steps.renewal-upsell.from-one.subtitle')
      : this.intl.t('cards.steps.renewal-upsell.from-plus.subtitle');
  }

  get isCurrentCardStandard() {
    return this.currentCardLevel === CARD_LEVELS.STANDARD;
  }

  get monthlyCost() {
    let cost = this._formatPrice(
      this.args.context.estimates[this.selectedUpsellLevel].monthly_cost
    );
    return `${cost}${this.intl.t('cards.steps.renewal-upsell.vat')}`;
  }

  get cta() {
    return this.selectedUpsellLevel === CARD_LEVELS.PLUS
      ? this.intl.t('cards.steps.renewal-upsell.plus.cta')
      : this.intl.t('cards.steps.renewal-upsell.metal.cta');
  }

  @action
  handleNext(isUpsellSelected) {
    let { card } = this.args.context;

    let cardUpsellLevel = isUpsellSelected ? this.selectedUpsellLevel : null;
    let cardId = isUpsellSelected ? card.id : null;
    let customizableCardLevel = cardUpsellLevel || card.cardLevel;

    let shouldShowCardCustomization = CUSTOMIZABLE_CARDS_LEVELS.includes(customizableCardLevel);

    // to reset the card design and type of print, when coming back from the customization step
    set(this.args.context, 'cardDesign', null);
    set(this.args.context, 'typeOfPrint', null);

    set(this.args.context, 'showCardCustomization', shouldShowCardCustomization);
    set(this.args.context, 'cardUpsellLevel', cardUpsellLevel);
    set(this.args.context, 'cardId', cardId);

    this._track(isUpsellSelected, card.cardLevel, this.selectedUpsellLevel);
    this.args.transitionToNext();
  }

  @action
  selectUpsellLevel(value) {
    this.selectedUpsellLevel = value;
  }

  _nearestHalf(num) {
    return Math.round(num * 2) / 2;
  }

  _formatPrice(price) {
    return this.intl.formatNumber(price, FORMAT_PRICE_OPTIONS);
  }

  _track(isUpsellSelected, origin, selectedLevel) {
    if (isUpsellSelected) {
      this.segment.track('cards_renewal_upsell_confirmed', {
        card_level_origin: CARD_LEVELS_TRACKING[origin],
        card_level_upsell: CARD_LEVELS_TRACKING[selectedLevel],
      });
    } else {
      this.segment.track('cards_renewal_upsell_skipped', {
        card_level: CARD_LEVELS_TRACKING[origin],
      });
    }
  }
}
