/* import __COLOCATED_TEMPLATE__ from './multiple.hbs'; */
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import RSVP from 'rsvp';

import { dropTask } from 'ember-concurrency';

import { MEMBER_STATUS } from 'qonto/constants/membership';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import { sortByKey } from 'qonto/utils/sort-by-keys';

const usersStatusActiveAndRevoked = [MEMBER_STATUS.ACTIVE, MEMBER_STATUS.REVOKED];
const SENTRY_IGNORE_HTTP_STATUSES = [401];

export default class MemberSelectMultiple extends Component {
  @service intl;
  @service organizationManager;
  @service store;
  @service sentry;
  @service toastFlashMessages;

  @tracked filters = {};
  @tracked defaultOptions = [];
  @tracked allOptions = [];

  constructor() {
    super(...arguments);

    this._updateAllOptions();

    // Errors will be caught in the _findMembers function
    this.findMemberTask.perform().catch(ignoreCancelation);
  }

  get selectedMembers() {
    if (this.allOptions.length) {
      return this.args.memberIds
        .map(memberKey => this.allOptions.find(({ key }) => key === memberKey))
        .filter(Boolean);
    }
  }

  _updateAllOptions() {
    this.allOptions = this._peekAllMembers()
      .filter(({ status }) => [MEMBER_STATUS.ACTIVE, MEMBER_STATUS.REVOKED].includes(status))
      .map(member => ({
        key: member.id,
        value: member.fullName,
        url: member.avatarThumb?.fileUrl,
        status: member.status,
      }));
  }

  _mapMembers(members) {
    let membersArray = members
      .map(({ id }) => this.allOptions.find(({ key }) => key === id))
      .filter(Boolean);

    membersArray = membersArray.sort(sortByKey('value'));

    let activeMembers = membersArray.filter(({ status }) => status === MEMBER_STATUS.ACTIVE);
    let revokedMembers = membersArray.filter(({ status }) => status === MEMBER_STATUS.REVOKED);

    return [...activeMembers, ...revokedMembers];
  }

  async _findMembers(query) {
    this.filters['status'] = usersStatusActiveAndRevoked;

    try {
      return await this.organizationManager.findMembers(query, this.filters);
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      let errorInfo = ErrorInfo.for(error);

      if (!SENTRY_IGNORE_HTTP_STATUSES.includes(error.status) && errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      return this._peekAllMembers();
    }
  }

  _performMemberSearch(query, resolve, reject) {
    return this._findMembers(query)
      .then(records => {
        this._updateAllOptions();
        let filteredOptions = this._mapMembers(records);
        return resolve(filteredOptions);
      })
      .catch(error => reject(error));
  }

  _peekAllMembers() {
    let organizationId = this.organizationManager.organization.id;
    return this.store
      .peekAll('membership')
      .filter(membership => membership.belongsTo('organization').id() === organizationId);
  }

  @action
  handleMemberSearch(query) {
    return new RSVP.Promise((resolve, reject) => {
      debounce(this, this._performMemberSearch, query, resolve, reject, 300);
    });
  }

  findMemberTask = dropTask(async () => {
    let members = await this._findMembers();

    this._updateAllOptions();
    this.defaultOptions = this._mapMembers(members);
  });
}
