/* import __COLOCATED_TEMPLATE__ from './terms-link-fields.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import scrollIntoView from 'qonto/utils/scroll-into-view';

export default class InvoiceSettingsFormTermsLinkFields extends Component {
  @service intl;
  @service organizationManager;
  @service toastFlashMessages;
  @service networkManager;
  @service sentry;
  @service segment;

  termsLinkFieldsId = `${guidFor(this)}-terms-link-field`;

  @tracked showTermsLinkFields = Boolean(
    this.args.settings.tcLinkUrl || this.args.settings.tcLinkText
  );

  @action
  handleAddTermsConditionsLink() {
    this.showTermsLinkFields = true;
    next(() => {
      let element = document.getElementById(this.termsLinkFieldsId);
      scrollIntoView(element);
    });
  }

  @action
  handleRemoveTermsConditionsLink() {
    this.showTermsLinkFields = false;
    this.args.settings.setProperties({
      tcLinkText: null,
      tcLinkUrl: null,
    });
  }

  @action
  handleFocusOutTcLinkUrl() {
    if (this.args.settings.tcLinkUrl) {
      this.args.settings.setProperties({
        tcLinkUrl: this._normalizeLinkUrl(this.args.settings.tcLinkUrl),
      });
    }
  }

  /**
   * @todo: This can be refactored to use URL.parse() after updating core-js to 3.38.0
   */
  _normalizeLinkUrl(urlInput) {
    try {
      let normalizedUrl = new URL(urlInput);
      if (normalizedUrl.protocol !== 'https:') {
        return urlInput.replace(/(.*):\/\//, 'https://');
      }
      return urlInput;
    } catch {
      return `https://${urlInput}`;
    }
  }
}
