export default {
  "guests-loading": "cPe",
  "header": "cPB",
  "body": "cPE",
  "members": "cPd",
  "title": "cPI caption-bold",
  "placeholder-container": "cPt",
  "icon": "cPM",
  "details": "cPP"
};
