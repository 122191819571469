/* import __COLOCATED_TEMPLATE__ from './payment-limits.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { CARD_LEVELS_TRACKING } from 'qonto/constants/cards';

export default class FlowsCardsPaymentLimits extends Component {
  @service segment;

  @action
  handleNext() {
    let {
      context: { card, flowTrackingOrigin, isUserCardHolder },
      transitionToNext,
    } = this.args;

    this.segment.track('cards_order_payment_limits_set', {
      card_holder: isUserCardHolder ? 'self' : 'team_member',
      card_type: CARD_LEVELS_TRACKING[card.cardLevel],
      daily_payment_limit: card.paymentDailyLimitOption,
      payment_limit: card.paymentMonthlyLimit,
      transaction_payment_limit: card.paymentTransactionLimitOption,
      origin: flowTrackingOrigin,
    });

    transitionToNext();
  }
}
