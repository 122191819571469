export default {
  "checks": "jk",
  "wrapper": "jJ",
  "form": "js",
  "title": "jF",
  "wrapper-body": "jg",
  "aside": "jD",
  "steps-container": "jV",
  "step": "jL",
  "container-dot": "jT",
  "text": "jz",
  "text-title": "ja",
  "text-desc": "jH",
  "start-button": "jO"
};
