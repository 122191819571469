export default {
  "container": "cdz",
  "content": "cda",
  "sticky": "cdH",
  "divider": "cdO",
  "tooltip": "cdm",
  "detail": "cdp",
  "detail-value": "cdx",
  "color-secondary": "cdw",
  "beneficiary-information": "cdN",
  "beneficiary-source": "cdv",
  "currency": "cdo",
  "break-word": "cdn",
  "disclaimers": "cdi",
  "sticky-panel": "cdA"
};
