/* import __COLOCATED_TEMPLATE__ from './dummy-data.hbs'; */
import Component from '@glimmer/component';

import { CODES } from 'qonto/constants/empty-states/system';

export default class EmptyStatesRequestsTasksDummyDataComponent extends Component {
  sortBy = `created_at:desc`;
  noop() {}

  get isRequestsDummyData() {
    return this.args.emptyStateCode === CODES.ES_A3;
  }

  get isTasksDummyData() {
    return this.args.emptyStateCode === CODES.ES_I3;
  }
}
