/* import __COLOCATED_TEMPLATE__ from './mapping-column.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class MappingColumnComponent extends Component {
  get selectedValue() {
    return this.args.mapping?.[this.args.column];
  }

  get selectedOption() {
    return this.args.options.find(o => o.id === this.selectedValue) ?? {};
  }

  get isMapped() {
    return Boolean(this.selectedValue);
  }

  get data() {
    return this.args.rows.slice(0, 5).map(row => row[this.args.column]);
  }

  @action
  onChange(option) {
    this.args.onMappingUpdate(this.args.column, option.id);
  }

  @action
  onClear() {
    this.args.onMappingUpdate(this.args.column, undefined);
  }
}
