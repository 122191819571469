/* import __COLOCATED_TEMPLATE__ from './tile.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { isTesting, macroCondition } from '@embroider/macros';
import { task, timeout, waitForQueue } from 'ember-concurrency';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class FinancingPayLaterCockpitInsightsTileComponent extends Component {
  @service intl;

  @tracked _counter = 0;

  constructor() {
    super(...arguments);

    this.animateTask.perform().catch(ignoreCancelation);
  }

  get stateLocalClass() {
    switch (this.args.state) {
      case 'error':
        return 'error';
      case 'warning':
        return 'warning';
      default:
        return '';
    }
  }

  get counter() {
    return this.intl.formatNumber(this._counter, {
      style: 'currency',
      currency: this.args.amount.currency,
    });
  }

  animateTask = task(async () => {
    await waitForQueue('afterRender');

    let duration = macroCondition(isTesting()) ? 0 : 500;
    let delay = macroCondition(isTesting()) ? 0 : 10;
    let startAmount = this._counter;
    let endAmount = this.args.amount.value;
    let increment = (endAmount - startAmount) / (duration / delay);

    for (let currentAmount = startAmount; currentAmount < endAmount; currentAmount += increment) {
      this._counter = currentAmount;
      await timeout(delay);
    }

    this._counter = endAmount;
  });
}
