export default {
  "container": "rM",
  "back-button": "rP",
  "card-list": "rl",
  "upsell-badge": "rX",
  "pricing": "rC",
  "pricing-period": "rk",
  "ad-modal-button": "rJ",
  "eligibility-disclaimer": "rs",
  "feature": "rF",
  "highlighted": "rg"
};
