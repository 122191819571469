export default {
  "container": "cSp",
  "content": "cSx",
  "buttons": "cSw",
  "genders": "cSN",
  "birth-details": "cSv",
  "has-error": "cSo",
  "gender-option": "cSn",
  "radio-button": "cSi"
};
