export default {
  "container": "ha",
  "company-details": "hH",
  "sideview": "hO",
  "form": "hm",
  "french-company-details-fieldset": "hp",
  "footer": "hx",
  "pdf-preview": "hw",
  "de-layout": "hN",
  "de-pdf-preview": "hv",
  "german-preview": "ho"
};
