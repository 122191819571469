/* import __COLOCATED_TEMPLATE__ from './error-page.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import window from 'ember-window-mock';

export default class ErrorPageComponent extends Component {
  @action
  goBack() {
    window.location.href = '/';
  }
}
