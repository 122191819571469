export default {
  "header-cell": "cJy",
  "empty": "cJU",
  "header-content": "cJj caption-bold",
  "active": "cJW",
  "align-right": "cJu",
  "first-col": "csS",
  "mid-col": "csc",
  "status-col": "csq"
};
