/* import __COLOCATED_TEMPLATE__ from './preview.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask } from 'ember-concurrency';

export default class PreviewComponent extends Component {
  @service intl;
  @service organizationManager;
  @service toastFlashMessages;
  @service sentry;
  @service segment;

  constructor() {
    super(...arguments);
    this.segment.track('nrc_summary_opened');
  }

  get amount() {
    return this.args.context.nrcPayment.amount;
  }

  get receiptCode() {
    return this.args.context.nrcPayment.receiptCode;
  }

  get selectedModelo() {
    return `Modelo ${Number(this.args.context.selectedModelo.tax_code)}`;
  }

  get bankAccount() {
    return this.args.context.nrcPayment.bankAccount;
  }

  get legalName() {
    return this.args.context.nrcPayment.legalName;
  }

  get documentId() {
    return this.args.context.nrcPayment.documentId;
  }

  get fiscalYear() {
    return this.args.context.nrcPayment.fiscalYear;
  }

  get period() {
    let period = this.args.context.nrcPayment.period;
    if (!period) return null;

    let match = period.match(/(\d+|0)([MTPA])/);
    if (!match) return period;

    let [, number, periodType] = match;

    switch (periodType) {
      case 'M':
        return this.intl.t('aeat.pay.field.period.mensual', {
          number: number.padStart(2, '0'),
        });
      case 'T':
        return this.intl.t('aeat.pay.field.period.trimestre', {
          number: number.padStart(2, '0'),
        });
      case 'P':
        return this.intl.t('aeat.pay.field.period.periodo', {
          number: number.padStart(2, '0'),
        });
      case 'A':
        return this.intl.t('aeat.pay.field.period.anual');
      default:
        return period;
    }
  }

  get isPaymentInInstallments() {
    return this.args.context.nrcPayment.isPaymentInInstallments;
  }

  get paymentYear() {
    return Number(this.args.context.nrcPayment.fiscalYear) + 1;
  }

  get currentPayment() {
    if (!this.isPaymentInInstallments || !this.amount) return null;
    return {
      value: this.amount.value * 0.6,
      currency: this.amount.currency,
    };
  }

  get futurePayment() {
    if (!this.isPaymentInInstallments || !this.amount) return null;
    return {
      value: this.amount.value * 0.4,
      currency: this.amount.currency,
    };
  }

  proceedToPayment = dropTask(async () => {
    try {
      let response = await this.args.context.nrcPayment.createPayment();
      this.args.context.requestId = response.request_id;
      this.segment.track('nrc_summary_completed');
      this.args.transitionToNext();
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      } else {
        this.segment.track('nrc_summary_completed');
      }

      if (error?.status === 422) {
        this.args.context.showErrorScreen = true;
        this.args.transitionToNext();
        this.sentry.captureException(error);
      } else {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.try-later'));
      }
    }
  });
}
