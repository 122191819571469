/* import __COLOCATED_TEMPLATE__ from './team-selection.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { waitForQueue } from 'ember-concurrency';

import { ROLES } from 'qonto/constants/membership';
import { TeamPlaceholder } from 'qonto/react/components/budgets/create/team-placeholder';

export default class FlowsBudgetsCreateBudgetTeamSelection extends Component {
  @service segment;

  teamPlaceholder = TeamPlaceholder;

  constructor() {
    super(...arguments);
    this.trackTeamSelectionRendered();
  }

  async trackTeamSelectionRendered() {
    await waitForQueue('afterRender');
    this.segment.track('budget-creation_team-selection_displayed');
  }

  @action
  createTeam() {
    this.segment.track('budget-creation_new-team_clicked');
    this.args.pushForwardFlow('create-team', 'name');
  }

  @action
  nextStep({ team, memberships }) {
    this.args.context.team = team;
    this.segment.track('budget-creation_team-selection_clicked');

    let managers = memberships.filter(({ role }) => role === ROLES.MANAGER);
    this.args.context.skipInviteManagerStep = managers.length > 0;

    this.args.transitionToNext();
  }
}
