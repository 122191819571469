/* import __COLOCATED_TEMPLATE__ from './application-card.hbs'; */
import { getOwner } from '@ember/owner';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { task } from 'ember-concurrency';

import { HrisConnection } from 'qonto/models/hris-integration';
import { ApplicationCard } from 'qonto/react/components/flows/invoices/import-clients/application-card';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class ApplicationCardComponent extends Component {
  @service intl;
  @service sentry;
  @service store;
  @service router;
  @service connectManager;
  @service organizationManager;

  applicationCard = ApplicationCard;

  get isIntegration() {
    let { integration_type, tray_solution_id, kombo_connect_provider_name } = this.args.application;

    return (
      integration_type === 'internal_integration' || tray_solution_id || kombo_connect_provider_name
    );
  }

  installApplicationTask = task(async () => {
    let solutionId = this.args.application.tray_solution_id;
    let applicationSlug = this.args.application.slug;
    let komboConnectProviderName = this.args.application.kombo_connect_provider_name;

    try {
      let [hubConnection] = this.store.query('hub-connection', {
        organization_id: this.args.organization.id,
        filters: {
          external_service: applicationSlug,
        },
      });

      let [solutionInstance] = solutionId
        ? this.store.query('solution-instance', {
            organization_id: this.args.organization.id,
            solution_id: solutionId,
          })
        : [];

      let [hubApplication] = this.store.query('hub-application', {
        organization_id: this.args.organization.id,
        filters: { slug: applicationSlug },
      });

      let hrisConnection =
        komboConnectProviderName && HrisConnection.create(getOwner(this), komboConnectProviderName);

      let model = {
        hubApplication,
        hubConnection,
        prismicData: this.args.application,
        solutionInstance,
        hrisConnection,
      };
      await this.connectManager.installApplicationTask.perform(model, applicationSlug);
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.router.transitionTo(
        'settings.connect-hub.applications.hub-application.details.index',
        this.organizationManager.organization.slug,
        this.args.application.slug
      );
    }
  });
}
