/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { DEFAULT_SORT_ORDER, F24_V2_SORT_BYS } from 'qonto/constants/sort';

export default class F24TableHeaderComponent extends Component {
  SORT_BYS = F24_V2_SORT_BYS;

  get sortBy() {
    let { sortBy } = this.args;
    return sortBy?.split(':');
  }

  get sortField() {
    return this.sortBy?.[0];
  }

  get sortOrder() {
    return this.sortBy?.[1] ?? DEFAULT_SORT_ORDER;
  }

  @action
  handleSortBy(name) {
    let { onSortByChanged } = this.args;
    let sortOrder = DEFAULT_SORT_ORDER;

    if (name === this.sortField) {
      sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    }

    return onSortByChanged?.(`${name}:${sortOrder}`);
  }
}
