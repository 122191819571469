/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { ROLES } from 'qonto/constants/membership';
import { ONBOARDING_STEP_STATUS } from 'qonto/constants/receivable-invoice';
import { NEW_TEAM_MEMBER_COST } from 'qonto/constants/teams';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class ReviewComponent extends Component {
  disclaimerBlock = Disclaimer.Block;

  @service toastFlashMessages;
  @service intl;
  @service organizationManager;
  @service localeManager;
  @service segment;
  @service subscriptionManager;
  @service sentry;
  @service abilities;
  @service freeUsersDiscountManager;
  @service modals;
  @service featuresManager;
  @service flow;

  @tracked eligibilityCriteria;

  constructor() {
    super(...arguments);
    this.fetchFreeUsersDiscountTask();
  }

  async fetchFreeUsersDiscountTask() {
    try {
      if (this.abilities.can('read discount')) {
        let discount = await this.freeUsersDiscountManager.fetchDiscountTask.perform({
          includeInvalid: true,
        });
        this.eligibilityCriteria = this.freeUsersDiscountManager.getEligibilityCriteria(discount);
      }
    } catch (error) {
      this.toastFlashMessages.toastError(error);
    }
  }

  get isReporting() {
    return this.args.selectedRole.key === ROLES.REPORTING;
  }

  get isEmployee() {
    return this.args.selectedRole.key === ROLES.EMPLOYEE;
  }

  get canDisplayTransferLimitsSummary() {
    return this.args.context.member.customPermissions?.groups
      ?.create_transfers_and_review_request_transfers;
  }

  get monthlyTransferLimit() {
    let value = this.args.context.member.monthlyTransferLimit?.value ?? null;
    return value
      ? `€${this.intl.formatNumber(value)}`
      : this.intl.t('invitation-flow.expense-permissions.transfers.limits.unlimited');
  }

  get perTransferLimit() {
    let value = this.args.context.member.perTransferLimit?.value ?? null;
    return value
      ? `€${this.intl.formatNumber(value)}`
      : this.intl.t('invitation-flow.expense-permissions.transfers.limits.unlimited');
  }

  get shouldShowTeamPricingExperiment() {
    return this.isEmployee && this.featuresManager.isEnabled('teamPlanPricingExperiment');
  }

  get monthlyCost() {
    return this.hasReachedUserLimit &&
      !this.isReporting &&
      !this.eligibilityCriteria?.canAddFreeUsers
      ? this.intl.t('invitation-flow.recap.fields.monthly-cost.value', {
          amount_currency: this.formattedPrice,
        })
      : this.intl.t('invitation-flow.recap.fields.monthly-cost.free');
  }

  get monthlyCostDescription() {
    if (this.isReporting || this.hasUnlimitedFreeUsers) {
      return this.intl.t('invitation-flow.recap.fields.monthly-cost.subtitle-included-soloplan');
    }
    if (this.eligibilityCriteria?.canAddFreeUsers) {
      return this.intl.t(
        'invitation-flow.recap.fields.monthly-cost.subtitle-included-special-offer'
      );
    }
    if (this.hasReachedUserLimit) {
      return this.intl.t('invitation-flow.recap.fields.monthly-cost.subtitle-not-included');
    }
    return this.intl.t('invitation-flow.recap.fields.monthly-cost.subtitle-included', {
      count: this.usersLeft,
    });
  }

  get showTooltip() {
    return !this.isReporting && !this.hasUnlimitedFreeUsers;
  }

  get tooltipContent() {
    if (this.eligibilityCriteria?.canAddFreeUsers) {
      return this.intl.t('invitation-flow.recap.fields.monthly-cost.tooltip.special-offer');
    }

    return this.hasReachedUserLimit
      ? this.intl.t('invitation-flow.recap.fields.monthly-cost.tooltip.paying')
      : this.intl.t('invitation-flow.recap.fields.monthly-cost.tooltip.free', {
          amount_currency: this.formattedPrice,
        });
  }

  get hasReachedUserLimit() {
    return this.subscriptionManager.hasReachedUserLimit;
  }

  get formattedPrice() {
    let { price, currency } = this.abilities.can('add admin only member')
      ? this.priceFromSubscription
      : { price: NEW_TEAM_MEMBER_COST, currency: 'EUR' };

    return this.intl.formatNumber(price, {
      minimumFractionDigits: 0,
      currency,
      style: 'currency',
    });
  }

  get priceFromSubscription() {
    let additionalUserCost = this.subscriptionManager.options.additionalUser;
    return {
      price: additionalUserCost?.value ? Number(additionalUserCost.value) : NEW_TEAM_MEMBER_COST,
      currency: additionalUserCost?.currency ?? 'EUR',
    };
  }

  get usersLeft() {
    return (
      this.subscriptionManager.getLimitByFeatureCode('additional_users') -
      this.organizationManager.organization.membershipsCountingTowardsPlanLimitCount
    );
  }

  get hasUnlimitedFreeUsers() {
    return this.subscriptionManager.getLimitByFeatureCode('additional_users') === null;
  }

  get scope() {
    return this.args.context.member.customPermissions?.scope;
  }

  get scopeCopy() {
    if (this.scope === 'team') {
      return this.intl.t('invitation-flow.recap.fields.scope.team');
    }

    return this.intl.t('invitation-flow.recap.fields.scope.organization');
  }

  get shouldShowEligibilityDisclaimer() {
    return (
      !this.eligibilityCriteria.isAboveMinimum ||
      this.eligibilityCriteria.isBelowMaximum ||
      !this.eligibilityCriteria.isCurrentPricePlanAllowed
    );
  }

  @action
  openEligibilityCriteriaModal() {
    let { minimumRequiredActiveUsersCount, voucherPricePlanIds } = this.eligibilityCriteria;
    this.modals.open('flows/member/review/voucher-information-modal', {
      isFullScreenModal: true,
      minimumRequiredActiveUsersCount,
      voucherPricePlanIds,
      title: this.intl.t('invitation-flow.recap.modal.eligibility-criteria.title'),
    });
  }

  @action
  openTermsAndConditionModal() {
    let { freeAdditionalUsers, finishOn } = this.eligibilityCriteria;

    let voucherEndDate = this.freeUsersDiscountManager.getExpirationDate(finishOn);
    let title = this.intl.t('invitation-flow.recap.modal.terms-conditions.title', {
      voucherEndDate: dateToken({
        date: new Date(finishOn),
        locale: this.localeManager.locale,
        token: DATE_FORMAT_TOKENS.DATE_YEAR_L,
      }),
    });
    let conditions = [
      this.intl.t('invitation-flow.recap.modal.terms-conditions.item-1', {
        voucherEndDate,
        freeAdditionalUsers,
      }),
      this.intl.t('invitation-flow.recap.modal.terms-conditions.item-2'),
      this.intl.t('invitation-flow.recap.modal.terms-conditions.item-3', {
        additionalUserCost: this.formattedPrice,
      }),
    ];

    this.modals.open('flows/member/review/voucher-information-modal', {
      isFullScreenModal: true,
      title,
      conditions,
    });
  }

  inviteTask = dropTask(async member => {
    let { organization } = this.organizationManager;

    if (member.role === ROLES.ADVANCED_MANAGER) {
      member.role = ROLES.MANAGER;
    }

    try {
      await member.save();
      this.args.context.invitedMember = member;

      organization.activeMembershipsCount++;

      this.segment.track('team_invite_sent', {
        role: member.role,
        origin: 'team_page',
      });

      if (member.customPermissions?.groups?.manage_supplier_invoices) {
        this.segment.track('manager-invitation_supplier-invoices-permission_on');
      }

      await organization.reload();

      if (this.flow.dataContext.isAccountReceivableOnboardingFlow) {
        this.flow.dataContext.onboardingState.stepStatusAccountantAccess =
          ONBOARDING_STEP_STATUS.CONFIRMED;
        this.flow.dataContext.onboardingState.save();
      }

      this.args.transitionToNext();
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      } else if (
        error.isAdapterError &&
        error.errors?.[0]?.source?.pointer === '/data/attributes/email'
      ) {
        this.toastFlashMessages.toastError(error.errors[0].detail);
      } else {
        this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }
    }
  });
}
