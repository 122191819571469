/* import __COLOCATED_TEMPLATE__ from './creditor-info.hbs'; */
import { action, set, setProperties } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { reads } from 'macro-decorators';

import { DGFIP_ICS_VALUE, FR_CREDITORS_CODE } from 'qonto/constants/mandates';

export default class FlowsMandateCreditorInfoComponent extends Component {
  @service intl;
  @service abilities;
  @service segment;
  @service organizationManager;

  @tracked selectedCreditorError = null;
  @tracked selectedCategoryError = null;
  @tracked creditorNameError = null;

  @reads('args.context.mandate.activityTag') selectedCategory;
  @reads('organizationManager.organization.legalCountry') legalCountry;

  get selectedCreditor() {
    let code = this.legalCountry === 'FR' ? this.args.context.mandate.code : 'other';
    return this.creditorsList.find(option => option.code === code);
  }

  get otherCreditorOptionSelected() {
    return this.selectedCreditor?.code === 'other';
  }

  @action
  updateCreditorName(value) {
    this.creditorNameError = null;
    this.args.context.mandate.creditorName = value.replace(/([^( w+)a-zA-Z0-9.,:/?-]+)/gi, '');
  }

  @action
  handleCreditorFocusOut() {
    this.selectedCreditorError = this._checkCreditorError();
  }

  @action
  handleCategoryFocusOut() {
    this.selectedCategoryError = this._checkCategoryError();
  }

  @action
  handleSelectCreditorChange(creditor) {
    set(this.args.context, 'mandate.code', creditor.code);
    if (creditor.code !== 'other') {
      set(this.args.context, 'mandate.activityTag', 'tax');
    } else {
      set(this.args.context, 'mandate.activityTag', null);
      set(this.args.context, 'mandate.creditorName', null);
    }
    this.selectedCreditorError = this._checkCreditorError();
  }

  @action
  handleSelectCategoryChange(activityTag) {
    this.args.context.mandate.activityTag = activityTag;
    this.selectedCategoryError = this._checkCategoryError();
  }

  @action
  handleNextStep() {
    this._checkErrors();
    if (this.creditorNameError || this.selectedCreditorError || this.selectedCategoryError) return;

    if (!this.otherCreditorOptionSelected) {
      this.args.context.mandate.creditorName = this.selectedCreditor.label;
    }

    setProperties(this.args.context, {
      'mandate.creditorIdentifier':
        this.selectedCreditor.code === FR_CREDITORS_CODE.DGFIP ? DGFIP_ICS_VALUE : null,
      'mandate.recurrent': true,
      taxInstitution: this.selectedCreditor,
    });

    this.segment.track('app_sdd_creditor_info_filled', {
      creditor_name_chosen: this.args.context.mandate.creditorName,
    });

    this.args.transitionToNext();
  }

  /**
   * Build a list of creditor options that will populate the creditor suggestion dropdown
   * @computed ceditorList
   * @returns {Array} An array of creditor option items
   */
  get creditorsList() {
    return [
      {
        code: 'dgfip',
        label: this.intl.t('mandates.creditor.tax_institution_dgfip'),
      },
      {
        code: 'taxDepartment',
        label: this.intl.t('mandates.creditor.tax_institution_taxDepartment'),
      },
      {
        code: 'urssaf',
        label: this.intl.t('mandates.creditor.tax_institution_urssaf'),
      },
      {
        code: 'other',
        label: this.intl.t('mandates.creditor.tax_institution_other'),
      },
    ];
  }

  _checkCreditorError() {
    return !this.selectedCreditor ? this.intl.t('validations.errors.blank') : null;
  }

  _checkCreditorNameError() {
    return !this.args.context.mandate.creditorName ? this.intl.t('validations.errors.blank') : null;
  }

  _checkCategoryError() {
    if (this.abilities.can('assign category') || this.selectedCategory) {
      return null;
    }

    return this.intl.t('validations.errors.blank');
  }

  _checkErrors() {
    if (this.otherCreditorOptionSelected) {
      this.creditorNameError = this._checkCreditorNameError();
      this.selectedCategoryError = this._checkCategoryError();
    } else {
      this.selectedCreditorError = this._checkCreditorError();
    }
  }
}
