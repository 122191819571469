/* import __COLOCATED_TEMPLATE__ from './confirm-unrevoke-accountant.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { PRICE_PLAN_LINEUPS } from 'qonto/constants/price-plan';

export default class ConfirmUnrevokeAccountantModal extends Component {
  lottiePlayer = LottiePlayer;

  @service errors;
  @service segment;
  @service flowLinkManager;
  @service sensitiveActions;

  confirmTask = dropTask(async () => {
    try {
      let { confirmTask, membership } = this.args.data;

      await this.sensitiveActions.runTask.perform(confirmTask, membership);

      this.args.close();
    } catch (error) {
      this.errors.handleError(error);
    }
  });

  @action
  reactivateMember() {
    this.segment.track('upsell_reactivate-paid-member_clicked', {
      price_plan: this.args.data.pricePlanCode,
    });

    this.args.close();

    this.flowLinkManager.transitionTo({
      name: 'subscription-change',
      stepId: 'plans',
      queryParams: { lineup: PRICE_PLAN_LINEUPS.TEAMS },
    });
  }
}
