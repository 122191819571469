/* import __COLOCATED_TEMPLATE__ from './error.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class FlowsCheckErrorComponent extends Component {
  @action
  backToSummary() {
    this.args.backToStep('summary');
  }
}
