/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';

import { getEmptyStateConfig } from 'qonto/constants/empty-states/insurance-hub';

export default class InsuranceHubTableComponent extends Component {
  @service emptyStates;
  @service intl;
  @service organizationManager;
  @service router;
  @service segment;

  @reads('organizationManager.organization.isGerman') isGermanOrganization;

  get emptyStateOptions() {
    return this.emptyStates.getEmptyStateOptions({
      hasActiveFilterOrSearch: false,
      isEmptyGlobally: true,
      isEmptyLocally: true,
      config: getEmptyStateConfig(this.intl, {
        ctaCallback: this.ctaCallback,
        isGermanOrganization: this.isGermanOrganization,
      }),
    });
  }

  @action
  ctaCallback() {
    this.segment.track('insurance-hub_empty-state_explore-insuraces-button_clicked');
    this.router.transitionTo('insurance-hub');
  }
}
