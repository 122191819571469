/* import __COLOCATED_TEMPLATE__ from './addons.hbs'; */
import { action, setProperties } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer, Spinner, ToggleButton } from '@repo/design-system-kit';
import {
  AddOnDetailLoaderWithButton,
  SubscriptionError,
  SubscriptionHeader,
} from '@repo/domain-kit/pricing';
import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { apiBaseURL, apiNamespace, billingNamespace } from 'qonto/constants/hosts';
import { SUBSCRIPTION_RECURRENCES, TRACKING_TRIAL_STATE } from 'qonto/constants/subscriptions';
import { codeToName } from 'qonto/models/subscription-product';
import { AddonCards } from 'qonto/react/components/flows/addon-change/addon-cards';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

import lottie from '/lotties/error-404.json';

export default class FlowsAddonChangeAddonsComponent extends Component {
  @service intl;
  @service organizationManager;
  @service subscriptionManager;
  @service networkManager;
  @service sentry;
  @service toastFlashMessages;
  @service segment;
  @service router;

  toggleButton = ToggleButton;
  loaderWithButton = AddOnDetailLoaderWithButton;
  disclaimer = Disclaimer.Block;
  subscriptionHeader = SubscriptionHeader;
  subscriptionError = SubscriptionError;
  addonCards = AddonCards;
  SUBSCRIPTION_RECURRENCES = SUBSCRIPTION_RECURRENCES;
  lottie = lottie;
  spinner = Spinner;

  @tracked addons = [];
  @tracked traits = [];
  @tracked selectedAddonCode = '';
  @tracked shouldSkipAddon = true;

  constructor() {
    super(...arguments);
    this.initTask.perform().catch(ignoreCancelation);
  }

  get currentSubscriptionRecurrence() {
    return this.subscriptionManager.currentSubscription?.recurrence;
  }

  get shouldShowRecurrenceDisclaimer() {
    return (
      this.currentSubscriptionRecurrence === SUBSCRIPTION_RECURRENCES.MONTHLY &&
      this.args.context.recurrence === SUBSCRIPTION_RECURRENCES.ANNUAL
    );
  }

  get showDisclaimerForItalianOrganizations() {
    return (
      variation('feature--boolean-pricing-italian-disclaimers') &&
      this.organizationManager.organization.legalCountry === 'IT'
    );
  }

  get italianDisclaimerText() {
    return this.intl.t('subscription.change.bank-of-italy-disclaimer.body', {
      faqUrl: htmlSafe(
        `<a href="https://support-it.qonto.com/hc/it/articles/26999640842513-Restrizioni-in-Italia-e-miglioramento-delle-misure-di-antiriciclaggio" target="_blank" rel="noopener noreferrer"
            data-test-addons-italian-disclaimer-link>${this.intl.t(
              'subscription.change.bank-of-italy-disclaimer.link'
            )}</a>`
      ),
      htmlSafe: true,
    });
  }

  get showDisclaimerForLegacyPlans() {
    return this.subscriptionManager.currentPricePlan?.disabled;
  }

  get showDisclaimer() {
    return (
      this.showDisclaimerForItalianOrganizations ||
      this.showDisclaimerForLegacyPlans ||
      this.shouldShowRecurrenceDisclaimer
    );
  }

  trialState() {
    return this.subscriptionManager.currentSubscription?.hasInitialTrial
      ? TRACKING_TRIAL_STATE.INITIAL_TRIAL
      : TRACKING_TRIAL_STATE.NONE;
  }

  confirmationType(addonCode) {
    let recurrence = this.args.context.recurrence;
    let isAddedWithAnyRecurrence = Boolean(this.subscriptionManager.getProduct(addonCode));

    let isAddedWithRecurrence = this.subscriptionManager.hasProductAndRecurrence(
      addonCode,
      recurrence
    );
    if (isAddedWithRecurrence) {
      return 'removal';
    }
    if (!isAddedWithAnyRecurrence) {
      return 'add';
    }
    if (recurrence === SUBSCRIPTION_RECURRENCES.MONTHLY) {
      return 'downsize';
    }
    if (recurrence === SUBSCRIPTION_RECURRENCES.ANNUAL) {
      return 'upsize';
    }
    return '';
  }

  @action
  selectRecurrence(recurrence) {
    this.segment.track('addon_management_toggle_clicked', {
      recurrence,
    });
    setProperties(this.args.context, {
      recurrence,
    });
  }

  @action
  onHyperlinkClick(addonCode) {
    this.segment.track('addon_management_annual-hyperlink_clicked', {
      addon_code: addonCode,
    });
    setProperties(this.args.context, {
      recurrence: this.SUBSCRIPTION_RECURRENCES.ANNUAL,
    });
  }

  @action
  onConfirm(addon) {
    let isAdded = this.subscriptionManager.hasProductAndRecurrence(
      addon.code,
      this.args.context.recurrence
    );
    this.trackCtaClick({ isRemoval: Boolean(isAdded), addon });

    setProperties(this.args.context, {
      isRemoval: Boolean(isAdded),
    });

    // Remove the addon
    if (isAdded) {
      setProperties(this.args.context, {
        targetAddon: addon,
        tracking: this.getTrackingInfo(addon.code),
      });
      this.args.transitionToNext();
    }
    // Add the addon
    else {
      this.confirmTask.perform(addon).catch(ignoreCancelation);
    }
  }

  @action
  reloadAddons() {
    this.initTask.perform().catch(ignoreCancelation);
  }

  trackCtaClick({ isRemoval, addon }) {
    let isAdded = this.subscriptionManager.getProduct(addon.code);
    let recurrence = this.args.context.recurrence;
    let trialState = this.trialState();

    let ctaType = '';
    if (isRemoval) {
      ctaType = 'remove';
    } else if (!isAdded) {
      ctaType = 'add_to_plan';
    } else if (recurrence === SUBSCRIPTION_RECURRENCES.MONTHLY) {
      ctaType = 'switch_monthly';
    } else if (recurrence === SUBSCRIPTION_RECURRENCES.ANNUAL) {
      ctaType = 'switch_annually';
    }

    this.segment.track('addon_management_cta_clicked', {
      cta_type: ctaType,
      addon_code: addon.code,
      addon_current_toggle_recurrence: recurrence,
      trial_state: trialState,
    });
  }

  initTask = dropTask(async () => {
    if (!this.args.context.addonCode) {
      this.shouldSkipAddon = false;
      this.segment.track('addon_management_displayed', {
        origin: this.router.currentRoute?.queryParams?.origin || 'none',
      });
    }

    try {
      let response = await this.fetchAddonsTask.perform();
      this.addons = response.products;

      // If we pass an addon code in the query params, we directly go to the checkout page
      // This is used for upsell flows
      if (this.args.context.addonCode) {
        let addon = response.products.find(({ code }) => code === this.args.context.addonCode);
        await this.confirmTask.perform(addon, this.args.context.recurrence);
        this.shouldSkipAddon = false;
      }

      this.traits = response.traits;
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  fetchAddonsTask = dropTask(async () => {
    return await this.networkManager.request(
      `${apiBaseURL}/${apiNamespace}/pricing-catalog/products?type=addon&legal_country=${this.organizationManager.organization.legalCountry}`
    );
  });

  confirmTask = dropTask(async (addon, rec) => {
    try {
      let recurrence = rec ?? this.args.context.recurrence;
      this.selectedAddonCode = addon.code;
      let payload = await this.networkManager.request(
        `${apiBaseURL}/${billingNamespace}/subscriptions/estimate`,
        {
          method: 'POST',
          data: {
            subscription: {
              organization_id: this.organizationManager.organization.id,
              product_id: addon.id,
              recurrence,
            },
          },
        }
      );
      this._handleNextStepWithData(payload, addon);
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.httpStatus === 422) {
        this._handleNextStepWithData(error.responseJSON, addon);
        return;
      }
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      let errorMessage = this.intl.t('toasts.errors.generic');
      this.toastFlashMessages.toastError(errorMessage);
    }
  });

  getTrackingInfo(addonCode, pricePlanCode) {
    let recurrence = this.args.context.recurrence;
    return {
      origin: this.router.currentRoute?.queryParams?.origin || 'other',
      confirmation_type: this.confirmationType(addonCode),
      addon_code: addonCode,
      addon_recurrence: recurrence,
      current_plan_recurrence: this.currentSubscriptionRecurrence,
      current_plan: this.subscriptionManager.currentPricePlan.code,
      target_plan: pricePlanCode || this.subscriptionManager.currentPricePlan.code,
      trial_state: this.trialState(),
    };
  }

  _handleNextStepWithData(payload, addon) {
    let { warnings, extra, target_subscriptions, total_estimate, errors } = payload;
    let hasInsufficientFunds = errors?.some(it => it.code === 'balance_insufficient_funds');

    let addonInfo = target_subscriptions.find(ep => ep.product_type === 'addon');
    addonInfo.benefits = addon.benefit_groups[0].benefits;
    addonInfo.productName = this.addons.find(a => a.code === addon.code).brand_name;

    let pricePlan = target_subscriptions.find(ep => ep.product_type === 'core');
    if (pricePlan) {
      pricePlan.productName = codeToName(this.intl, pricePlan.product_group_code);
    }

    this._handleNextStep({
      warnings,
      extra,
      total_estimate,
      hasInsufficientFunds,
      targetAddon: addonInfo,
      targetPricePlan: pricePlan,
      tracking: this.getTrackingInfo(addonInfo.product_code, pricePlan?.product_code),
    });
  }

  _handleNextStep({
    warnings,
    extra,
    total_estimate,
    targetAddon,
    targetPricePlan,
    hasInsufficientFunds,
    tracking,
  }) {
    setProperties(this.args.context, {
      warnings,
      extra,
      total_estimate,
      targetAddon,
      targetPricePlan,
      hasInsufficientFunds,
      tracking,
      // Reset it to make sure the user can hit back
      addonCode: undefined,
      isItalyError: false,
    });
    this.args.transitionToNext();
  }
}
