/* import __COLOCATED_TEMPLATE__ from './delivery-address.hbs'; */
import { action, set, setProperties } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { task } from 'ember-concurrency';
import { or, reads } from 'macro-decorators';

import { CARD_DELIVERY_ESTIMATE_DAYS, CARD_LEVELS_TRACKING, ORIGIN } from 'qonto/constants/cards';
import { KYC_STATUS } from 'qonto/constants/membership';
import { addBusinessDays, getCurrentParisDate } from 'qonto/utils/date';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const TRACKING_SHIPPING_INFO = {
  holder: 'personal',
  organization: 'professional',
  custom: 'new',
};

export default class FlowsCardsDeliveryAddress extends Component {
  @service abilities;
  @service intl;
  @service modals;
  @service organizationManager;
  @service segment;
  @service sensitiveActions;

  @or('args.context.card.holder', 'args.context.holder') holder;

  @reads('organizationManager.organization.legalCountry') legalCountry;
  @reads('organizationManager.organization.name') organizationName;
  @reads('organizationManager.organization.address.fullAddress') organizationFullAddress;
  @reads('holder.fullName') holderFullName;
  @reads('holder.address.fullAddress') holderFullAddress;

  get cardToDeliverCount() {
    return this.args.context.cardToRenewCount ?? 1;
  }

  get title() {
    return this.args.context.isOnboardingFlow
      ? this.intl.t('cards.steps.choose-delivery-address.title.onboarding')
      : this.intl.t('cards.steps.choose-delivery-address.title.default');
  }

  get canReadDeliveryDate() {
    let { card, isCardsRenewal } = this.args.context;
    if (isCardsRenewal && this.legalCountry === 'FR') {
      return true;
    }
    return this.abilities.can('see estimated delivery date card', card);
  }

  get deliveryDate() {
    let { card } = this.args.context;

    if (!this.canReadDeliveryDate) {
      return null;
    }
    let days = CARD_DELIVERY_ESTIMATE_DAYS[card?.cardLevel] || 5;

    return addBusinessDays(getCurrentParisDate(), days);
  }

  get subtitle() {
    if (this.isMetalCardChosen) {
      return this.intl.t('cards.steps.choose-delivery-address.subtitle-x-card');
    }

    let { origin } = this.args.context;

    if (!this.isKycValidated && origin !== ORIGIN.MEMBER_INVITATION) {
      return this.intl.t('cards.steps.choose-delivery-address.subtitle-not-validated');
    }

    return this.intl.t('cards.steps.choose-delivery-address.subtitle', {
      count: this.cardToDeliverCount,
    });
  }

  get isMetalCardChosen() {
    let { card, isCardsRenewal } = this.args.context;
    return !isCardsRenewal && card.isMetal;
  }

  get isKycValidated() {
    let { card, isCardsRenewal } = this.args.context;
    return isCardsRenewal || card.get('holder.kycStatus') === KYC_STATUS.ACCEPTED;
  }

  get isShippingToBusiness() {
    return this.args.context.shippingOption === 'organization';
  }

  get holderAddress() {
    let address = this.holder.get('address');
    return address.country ? address : null;
  }

  _getAppropriateCardShippingAddress() {
    let shippingOption = this.args.context.shippingOption;
    switch (shippingOption) {
      case 'holder':
        return this.holderAddress;
      case 'organization':
        return this.organizationManager.organization.address;
      case 'custom':
        return this.args.context.customShippingAddress;
    }
  }

  @action
  updateShippingOption(option, renewTask) {
    set(this.args.context, 'shippingOption', option);
    let { cardUpsellLevel, isCardsRenewal, shippingOption } = this.args.context;

    if (!shippingOption) return false;

    if (isCardsRenewal) {
      this._renewCard(renewTask, shippingOption);
    } else if (cardUpsellLevel) {
      this._trackUpsellAddress(shippingOption, cardUpsellLevel);
    } else {
      this._updateAddress(shippingOption);
    }
  }

  @action
  openCustomAddressModal() {
    let { customShippingAddress } = this.args.context;

    this.modals.open('address-form-modal', {
      cardToDeliverCount: this.cardToDeliverCount,
      isFullScreenModal: true,
      confirmTask: this.handleFormSubmitTask,
      customShippingAddress,
    });
  }

  handleFormSubmitTask = task(async address => {
    await setProperties(this.args.context, {
      customShippingAddress: address,
      shippingOption: 'custom',
    });
  });

  _renewCard(renewTask, shippingOption) {
    this.segment.track('cards_renewal_address_chosen', {
      address_type: TRACKING_SHIPPING_INFO[shippingOption],
    });
    this.sensitiveActions.runTask
      .perform(this._confirmRenewalAddressTask, renewTask)
      .catch(ignoreCancelation);
  }

  _trackUpsellAddress(shippingOption, cardUpsellLevel) {
    let { card, isUserCardHolder } = this.args.context;
    this.segment.track('cards_upsell_address_chosen', {
      address_type: TRACKING_SHIPPING_INFO[shippingOption],
      card_level_origin: CARD_LEVELS_TRACKING[card.cardLevel],
      card_level_upsell: CARD_LEVELS_TRACKING[cardUpsellLevel],
      card_holder: isUserCardHolder ? 'self' : 'team_member',
    });

    set(this.args.context, 'shippingAddress', this._getAppropriateCardShippingAddress());
    set(this.args.context, 'shipToBusiness', this.isShippingToBusiness);
    this.args.transitionToNext();
  }

  _updateAddress(shippingOption) {
    let { card, flowTrackingOrigin, isUserCardHolder } = this.args.context;

    this.segment.track('cards_order_address_chosen', {
      address_type: TRACKING_SHIPPING_INFO[shippingOption],
      card_type: CARD_LEVELS_TRACKING[card.cardLevel],
      card_holder: isUserCardHolder ? 'self' : 'team_member',
      origin: flowTrackingOrigin,
    });

    card.setProperties({
      address: this._getAppropriateCardShippingAddress(),
      shipToBusiness: this.isShippingToBusiness,
    });

    this.args.transitionToNext();
  }

  _confirmRenewalAddressTask = task(async renewTask => {
    let address = this._getAppropriateCardShippingAddress().serialize();

    set(this.args.context, 'address', address);
    set(this.args.context, 'shipToBusiness', this.isShippingToBusiness);

    // in case there is a single expiring card, we go to the next step
    if (this.args.context.hasSingleCardToRenew) return this.args.transitionToNext();

    // errors are handled in the renewTask itself
    let response = await renewTask.perform(address, this.isShippingToBusiness);

    set(this.args.context, 'renewalCardsCount', response?.renewalCardsCount);
    set(this.args.context, 'renewalCard', response?.renewalCard);

    if (response?.renewalCardsCount) {
      this.args.transitionToNext();
    }
  });
}
