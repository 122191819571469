/* import __COLOCATED_TEMPLATE__ from './team-supervisors-selection.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { waitForQueue } from 'ember-concurrency';

import { SupervisorsSelectionPlaceholder } from 'qonto/react/components/budgets/create/supervisors-selection/placeholder';

export default class FlowsBudgetsCreateBudgetTeamSelection extends Component {
  @service segment;
  @service intl;

  @tracked selectedTeam = null;
  @tracked selectedMembers = [];
  @tracked teamError = null;

  supervisorsSelectionPlaceholder = SupervisorsSelectionPlaceholder;

  constructor() {
    super(...arguments);
    this.trackTeamSelectionRendered();
  }

  async trackTeamSelectionRendered() {
    await waitForQueue('afterRender');
    this.segment.track('budget-creation_team-selection_displayed');
  }

  @action
  setSelectedTeam({ team, members }) {
    this.selectedTeam = team;
    this.selectedMembers = members || [];
    this.teamError = null;
  }

  @action
  nextStep() {
    if (!this.selectedTeam) {
      this.teamError = this.intl.t('validations.errors.presence');
      return;
    }

    this.segment.track('budget-creation_team-selection_continue', {
      team_managers: this.selectedTeam.team.teamManagers.length,
      budget_supervisors: this.selectedMembers.length,
      team_id: this.selectedTeam.team.id,
      budget_supervisor_ids: this.selectedMembers,
    });

    this.args.context.team = this.selectedTeam.team;
    this.args.context.supervisors = this.selectedMembers;

    this.args.transitionToNext();
  }
}
