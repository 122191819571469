/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import window from 'ember-window-mock';

export default class HelpCenterFaqLinkComponent extends Component {
  @service segment;

  @action
  trackLinkOpeningEvent() {
    this.segment.track('transaction-helper_faq-page_clicked', {
      page_url: window.location.href,
    });
  }
}
