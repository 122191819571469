/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import Component from '@glimmer/component';

import { BadgeHighlight } from '@repo/design-system-kit';

import { ProviderLogo } from 'qonto/react/components/insurance-hub/provider-logo';

export default class InsurancesHeaderComponent extends Component {
  badgeHighlight = BadgeHighlight;
  providerLogo = ProviderLogo;
}
