/* import __COLOCATED_TEMPLATE__ from './mandate-info.hbs'; */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { task } from 'ember-concurrency';
import { equal, reads } from 'macro-decorators';

import { FR_CREDITORS_CODE } from 'qonto/constants/mandates';
import MandatesDetailsValidations from 'qonto/validations/forms/mandate-info';

export default class FlowsMandateMandateInfoComponent extends Component.extend(
  MandatesDetailsValidations
) {
  tagName = '';

  @service segment;
  @service organizationManager;

  @reads('context.mandate') model;
  @reads('context.taxInstitution.code') taxInstitutionCode;
  @reads('validations.attrs.model.creditorIdentifier') icsValidations;
  @reads('validations.attrs.model.umr') umrValidations;

  @tracked showUmrErrorMessage = false;
  @tracked showIcsErrorMessage = false;

  @equal('taxInstitutionCode', FR_CREDITORS_CODE.DGFIP) isDGFIP;

  get urmErrorMessage() {
    return this.showUmrErrorMessage
      ? this.umrValidations.message || this.umrValidations.warningMessage
      : null;
  }

  get icsErrorMessage() {
    return this.showIcsErrorMessage
      ? this.icsValidations.message || this.icsValidations.warningMessage
      : null;
  }

  @action
  handleUmrUpdate(value) {
    this.showUmrErrorMessage = false;
    this.set('model.umr', value);
  }

  @action
  handleIcsUpdate(value) {
    this.showIcsErrorMessage = false;
    this.set('model.creditorIdentifier', value);
  }

  handleNextStepTask = task(async () => {
    let { validations } = await this.validations.validate();

    if (validations.isValid && !validations.hasWarnings) {
      this.segment.track('app_sdd_mandate_info_filled');
      this.set(
        'context.hasMultipleActiveAccounts',
        this.organizationManager.organization.hasMultipleActiveAccounts
      );
      this.transitionToNext();
    } else {
      this.showUmrErrorMessage = true;
      this.showIcsErrorMessage = true;
    }
  });
}
